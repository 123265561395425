import { Modal } from '@shopify/polaris'
import { Formik } from 'formik'
import { useCallback, useState } from 'react'
import { mutate } from 'swr'

import { backendFetchResult, Result } from '../common/api'
import { DialogActivator, gidToId, ResultBanner, ResultToast } from '../common/helpers'
import { SubscriptionContract } from '../common/subscription-models'
import { Form } from './Form'
import { DeliveryDetailsForm, deliveryDetailsToFormData } from './models-form'
import { deliveryDetailsFormDataToInput, DeliveryDetailsInput } from './models-input'

export const updateDeliveryDetails = async (
  scGid: string,
  deliveryDetailsInput: DeliveryDetailsInput
): Promise<Result<any>> => {
  const url = `/subscriptions/${gidToId(scGid)}`
  const body = JSON.stringify({
    input: deliveryDetailsInput,
  })
  const data = (await backendFetchResult('PUT', url, { body })) as Result<any>

  if (data.status === 'success') {
    mutate(['subscription-contract', `${gidToId(scGid)}`])
  }

  return data
}
interface Props {
  sc: SubscriptionContract
  activator: DialogActivator
}

export const DeliveryDetailsEditDialog = (props: Props) => {
  const [saveResponse, setSaveResponse] = useState<Result<any> | null>(null)

  const initialData = deliveryDetailsToFormData(
    props.sc.gql_data.deliveryMethod,
    props.sc.gql_data.deliveryPrice
  )

  const handleSubmit = useCallback(
    async (deliveryDetailsForm: DeliveryDetailsForm, actions: any) => {
      actions.setSubmitting(true)
      const res = await updateDeliveryDetails(
        props.sc.gid,
        deliveryDetailsFormDataToInput(deliveryDetailsForm)
      )

      if (res.status === 'error' && res.message && res.field) {
        actions.setFieldError(res.field, res.message)
        res.field = undefined
        res.message = 'Please fix errors below and retry submitting the form'
      }

      setSaveResponse(res)
      actions.setSubmitting(false)

      if (res.status === 'success') {
        props.activator.close()
      }
    },
    [props]
  )

  return (
    <>
      <Formik initialValues={initialData} onSubmit={handleSubmit} enableReinitialize>
        {(formik) => (
          <Modal
            open={props.activator.open}
            onClose={() => {
              formik.resetForm()
              props.activator.close()
            }}
            title="Edit delivery details"
            primaryAction={{
              content: 'Save',
              loading: formik.isSubmitting,
              onAction: formik.submitForm,
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                disabled: formik.isSubmitting,
                onAction: () => {
                  formik.resetForm()
                  props.activator.close()
                },
              },
            ]}
          >
            <Modal.Section>
              <ResultBanner result={saveResponse} setResult={setSaveResponse} />
              <Form currencyCode={props.sc.gql_data.currencyCode} />
            </Modal.Section>
          </Modal>
        )}
      </Formik>
      <ResultToast result={saveResponse} setResult={setSaveResponse} />
    </>
  )
}
