import { Icon, Tooltip } from '@shopify/polaris'
import { RefreshMajor } from '@shopify/polaris-icons'

export const DunningIcon = ({ children }: DunningIconProps) => (
  <Tooltip content={children} dismissOnMouseOut={false}>
    <Icon source={RefreshMajor} color="warning" />
  </Tooltip>
)

interface DunningIconProps {
  children: any
}
