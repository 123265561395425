import { Badge } from '@shopify/polaris'
import React from 'react'

import { SubscriptionContractSubscriptionStatus } from './api'
import { capitalize } from './helpers'
import { SubscriptionContract } from './subscription-models'

export type BadgeStatus =
  | 'success'
  | 'info'
  | 'attention'
  | 'critical'
  | 'warning'
  | 'new'
  | undefined

const statusBadgeStatus = (status: SubscriptionContractSubscriptionStatus): BadgeStatus => {
  if (status === SubscriptionContractSubscriptionStatus.Active) {
    return 'success'
  }

  if (status === SubscriptionContractSubscriptionStatus.Failed) {
    return 'critical'
  }

  if (status === SubscriptionContractSubscriptionStatus.Paused) {
    return 'attention'
  }

  return undefined
}

interface Props {
  sc: SubscriptionContract
}

export const SubscriptionStatusBadge = ({ sc }: Props) => (
  <Badge status={statusBadgeStatus(sc.gql_status)}>
    {sc.activated_at || sc.gql_status !== 'PAUSED' ? capitalize(sc.gql_status) : 'Inactive'}
  </Badge>
)
