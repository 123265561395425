import { IndexTable, LegacyStack, SelectOption, Text } from '@shopify/polaris'

import { Paginator } from '../common/api'
import { DunningIcon } from '../common/dunningIcon'
import { CommaList, InternalLink, NonEmptyArray, ShortDate } from '../common/helpers'
import { LineItem, SubscriptionContract } from '../common/subscription-models'
import { SubscriptionStatusBadge } from '../common/SubscriptionStatusBadge'
import * as urls from '../common/urls'
import { useUserSettings } from '../settings-page/api'

export enum SubscriptionTableColumn {
  BulkCheckbox = '',
  ID = 'ID',
  Status = 'Status',
  PaymentRetries = 'Payment retries',
  Customer = 'Customer',
  Products = 'Products',
  SellingPlan = 'Selling plan',
  Currency = 'Currency',
  Created = 'Created',
  Updated = 'Updated',
  Paused = 'Paused',
  NextOrder = 'Next scheduled order',
  NextAttempt = 'Next payment attempt',
  Finishing = 'Finishing',
  Finished = 'Finished',
}

const subscriptionTableColumns = Object.values(SubscriptionTableColumn).slice(1)

// Before removing any of these columns, fix widths and alignments in the custom CSS
const subscriptionTableAlwaysVisibleColumns: Partial<Record<SubscriptionTableColumn, boolean>> = {
  [SubscriptionTableColumn.ID]: true,
  [SubscriptionTableColumn.Status]: true,
  [SubscriptionTableColumn.Customer]: true,
  [SubscriptionTableColumn.Products]: true,
}

export const subscriptionTableColumnChoices = subscriptionTableColumns.map((c) => ({
  value: c,
  label: c,
  disabled: subscriptionTableAlwaysVisibleColumns[c],
}))

export const subscriptionTableDefaultColumns: NonEmptyArray<SubscriptionTableColumn> = [
  SubscriptionTableColumn.ID,
  SubscriptionTableColumn.Status,
  SubscriptionTableColumn.Customer,
  SubscriptionTableColumn.Products,
  SubscriptionTableColumn.Created,
  SubscriptionTableColumn.NextOrder,
  SubscriptionTableColumn.Finished,
]

const subscriptionTableColumnStyles = {
  [SubscriptionTableColumn.BulkCheckbox]: 'width: 1%; white-space: nowrap;',
  [SubscriptionTableColumn.ID]: 'width: 1%; white-space: nowrap;',
  [SubscriptionTableColumn.Status]: 'width: 1%; white-space: nowrap;',
  [SubscriptionTableColumn.PaymentRetries]: 'width: 1%; white-space: nowrap; text-align: center;',
  [SubscriptionTableColumn.Customer]: 'width: 1%; white-space: nowrap;',
  [SubscriptionTableColumn.Products]: 'white-space: break-spaces; ',
  [SubscriptionTableColumn.SellingPlan]: 'white-space: break-spaces; ',
  [SubscriptionTableColumn.Currency]: 'width: 1%; white-space: nowrap; text-align: center;',
  [SubscriptionTableColumn.Created]: 'width: 1%; white-space: nowrap; text-align: center;',
  [SubscriptionTableColumn.Updated]: 'width: 1%; white-space: nowrap; text-align: center;',
  [SubscriptionTableColumn.Paused]: 'width: 1%; white-space: nowrap; text-align: center;',
  [SubscriptionTableColumn.NextOrder]: 'width: 1%; white-space: nowrap; text-align: center;',
  [SubscriptionTableColumn.NextAttempt]: 'width: 1%; white-space: nowrap; text-align: center;',
  [SubscriptionTableColumn.Finishing]: 'width: 1%; white-space: nowrap; text-align: center;',
  [SubscriptionTableColumn.Finished]: 'width: 1%; white-space: nowrap; text-align: center;',
}

export const subscriptionTableSortOptions = (includeRelevance: boolean): SelectOption[] =>
  (includeRelevance ? [{ label: 'Relevance', value: 'relevance' }] : []).concat([
    { label: 'Created (newest first)', value: 'created_desc' },
    { label: 'Created (oldest first)', value: 'created_asc' },
    { label: 'Updated (newest first)', value: 'updated_desc' },
    { label: 'Updated (oldest first)', value: 'updated_asc' },
    { label: 'Paused (newest first)', value: 'paused_desc' },
    { label: 'Paused (oldest first)', value: 'paused_asc' },
    { label: 'Next scheduled order (newest first)', value: 'next_order_date_desc' },
    { label: 'Next scheduled order (oldest first)', value: 'next_order_date_asc' },
  ])

export const SubscriptionTable = ({
  loading,
  paginator,
  onBulkUpdate,
  hasUnfinishedBulkActions,
  selectedIds,
  selectedAll,
  handleSelectionChange,
  columns,
}: {
  loading: boolean
  paginator: Paginator<SubscriptionContract>
  selectedIds: string[]
  selectedAll: boolean
  handleSelectionChange: any
  onBulkUpdate: (ids: string[], all: boolean) => void
  hasUnfinishedBulkActions: boolean
  columns: NonEmptyArray<SubscriptionTableColumn>
}) => {
  const scs = paginator.data

  const selectedColumns = subscriptionTableColumns.filter((c) => columns.includes(c))

  const userSettings = useUserSettings()

  return (
    <>
      <style>
        {[SubscriptionTableColumn.BulkCheckbox].concat(selectedColumns).map(
          (title, index) =>
            `
                .subscription-table td:nth-child(${index + 1}),
                .subscription-table th:nth-child(${index + 1}) {
                  ${subscriptionTableColumnStyles[title]}
                }
              `
        )}
      </style>

      <div className="subscription-table">
        <IndexTable
          headings={selectedColumns.map((title) => ({ title })) as NonEmptyArray<any>}
          itemCount={scs.length}
          selectedItemsCount={selectedAll ? 'All' : selectedIds.length}
          hasMoreItems={!!paginator.next_page_url || !!paginator.prev_page_url}
          resourceName={{
            singular: 'subscription',
            plural: 'subscriptions',
          }}
          loading={loading}
          onSelectionChange={handleSelectionChange}
          selectable={true}
          promotedBulkActions={[
            {
              content: 'Bulk update',
              onAction: () => onBulkUpdate(selectedIds, selectedAll),
              disabled: hasUnfinishedBulkActions,
            },
          ]}
        >
          {scs.map((sc: SubscriptionContract, index: number) => (
            <IndexTable.Row
              id={sc.rest_id.toString()}
              key={sc.rest_id.toString()}
              position={index}
              selected={selectedIds.includes(sc.rest_id.toString())}
            >
              {columns.includes(SubscriptionTableColumn.ID) && (
                <IndexTable.Cell>
                  <InternalLink url={urls.subscriptionUrl(sc.gid)}>
                    <Text as="span" fontWeight="semibold">
                      #{sc.rest_id}
                    </Text>
                  </InternalLink>
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.Status) && (
                <IndexTable.Cell>
                  <LegacyStack wrap={false} spacing="extraTight">
                    <SubscriptionStatusBadge sc={sc} />
                    {sc.gql_status === 'ACTIVE' && sc.next_order_date !== sc.next_attempt_at && (
                      <DunningIcon children="Payment failed: Subscription is in the payment retry process."></DunningIcon>
                    )}
                  </LegacyStack>
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.PaymentRetries) && (
                <IndexTable.Cell>
                  {sc.gql_status === 'ACTIVE' &&
                  sc.pw_data.dunningRetries !== null &&
                  sc.next_order_date !== sc.next_attempt_at ? (
                    <div>
                      {sc.pw_data.dunningRetries} / {userSettings.data?.attempt_retry_count}
                    </div>
                  ) : (
                    <span>&mdash;</span>
                  )}
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.Customer) && (
                <IndexTable.Cell>
                  {sc.gql_data.customer ? sc.gql_data.customer.displayName : 'Unavailable'}
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.Products) && (
                <IndexTable.Cell>
                  <CommaList>
                    {sc.gql_data.lines.map((item: any) => item.displayData?.title || item.title)}
                  </CommaList>
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.SellingPlan) && (
                <IndexTable.Cell>
                  <CommaList>
                    {sc.gql_data.lines
                      .map((item: LineItem) => item.sellingPlanName)
                      .filter((x) => !!x)
                      .pwUnique()}
                  </CommaList>
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.Currency) && (
                <IndexTable.Cell>{sc.gql_data.currencyCode}</IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.Created) && (
                <IndexTable.Cell>
                  <ShortDate date={new Date(sc.gql_data.createdAt)} />
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.Updated) && (
                <IndexTable.Cell>
                  <ShortDate date={new Date(sc.updated_at)} />
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.Paused) && (
                <IndexTable.Cell>
                  {sc.pw_data.pausedAt ? (
                    <ShortDate date={new Date(sc.pw_data.pausedAt)} />
                  ) : (
                    <span>&mdash;</span>
                  )}
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.NextOrder) && (
                <IndexTable.Cell>
                  {sc.next_order_date ? (
                    <ShortDate date={new Date(sc.next_order_date)} />
                  ) : (
                    <span>&mdash;</span>
                  )}
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.NextAttempt) && (
                <IndexTable.Cell>
                  {sc.next_attempt_at ? (
                    <ShortDate date={new Date(sc.next_attempt_at)} />
                  ) : (
                    <span>&mdash;</span>
                  )}
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.Finishing) && (
                <IndexTable.Cell>
                  {sc.finish_at ? (
                    <ShortDate date={new Date(sc.finish_at)} />
                  ) : (
                    <span>&mdash;</span>
                  )}
                </IndexTable.Cell>
              )}
              {columns.includes(SubscriptionTableColumn.Finished) && (
                <IndexTable.Cell>
                  {sc.finished_at ? (
                    <ShortDate date={new Date(sc.finished_at)} />
                  ) : (
                    <span>&mdash;</span>
                  )}
                </IndexTable.Cell>
              )}
            </IndexTable.Row>
          ))}
        </IndexTable>
      </div>
    </>
  )
}
