import { HorizontalStack, Tag, Text, VerticalStack } from '@shopify/polaris'

import { idToGid } from '../common/helpers'
import { AdvancedDashboardFilter } from './advanced-filters'

export const AdvancedDashboardFilterLabels = ({ filter }: { filter: AdvancedDashboardFilter }) => {
  if (!filter.isFiltered) return null

  return (
    <VerticalStack gap="2">
      {!!filter.products.length && (
        <HorizontalStack gap="2" blockAlign="center">
          <Text as="strong">Selected products:</Text>{' '}
          {filter.products.map((product) => (
            <Tag key={product.restId} onRemove={() => filter.onProductRemove(product.id)}>
              {product.title}
            </Tag>
          ))}
        </HorizontalStack>
      )}

      {!!filter.productVariants.length && (
        <HorizontalStack gap="2" blockAlign="center">
          <Text as="strong">Selected product variants:</Text>{' '}
          {filter.productVariants.map((variant) => (
            <Tag key={variant.restId} onRemove={() => filter.onProductVariantRemove(variant.id)}>
              {variant.productTitle}: {variant.variantTitle}
            </Tag>
          ))}
        </HorizontalStack>
      )}

      {!!filter.sellingPlanIds.length && (
        <HorizontalStack gap="2" blockAlign="center">
          <Text as="strong">Selected selling plans:</Text>{' '}
          {filter.sellingPlanIds.map((planId) => (
            <Tag key={planId}>{filter.sellingPlanNames[idToGid(planId, 'SellingPlan')]}</Tag>
          ))}
        </HorizontalStack>
      )}
    </VerticalStack>
  )
}
