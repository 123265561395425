import useSWR from 'swr'

import { backendFetchResultData } from './api'

export type PaypalExpressSubscriptionsGatewayStatus = 'DISABLED' | 'ENABLED' | 'PENDING'

export interface ShopShopifySettings {
  shopUsingThirdPartyFulfillmentServices: boolean
  paypalExpressSubscriptionGatewayStatus: PaypalExpressSubscriptionsGatewayStatus
  shopifyNewCustomerAccountsEnabled: boolean
}

export const useShopShopifySettingsSWR = () => {
  return useSWR<ShopShopifySettings>(['shopify-settings'], () =>
    backendFetchResultData<ShopShopifySettings>('GET', '/shop/shopify-settings')
  )
}
