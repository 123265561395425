import { SelectOption } from '@shopify/polaris'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import { backendFetchResult, emptyGQLPaginatorPageInfo, GQLPaginator, Result } from '../common/api'
import { Collection } from '../common/collections'
import { gidToId } from '../common/helpers'

export const collectionGidOptions = (cols: Collection[]): SelectOption[] => [
  { label: 'All products', value: '' },
  ...cols.map((col) => ({
    label: `${col.title} (${col.productsCount} products)`,
    value: col.id,
  })),
]

export const collectionIdOptions = (cols: Collection[]): SelectOption[] => [
  { label: 'All products', value: '' },
  ...cols.map((col) => ({
    label: `${col.title} (${col.productsCount} products)`,
    value: gidToId(col.id),
  })),
]
export interface ProductResult {
  id: string
  image: string
  title: string
  attached: boolean
}

export type ProductResultSet = GQLPaginator<ProductResult>

const updateProducts = (
  result: Result<ProductResultSet>,
  attachedGids: string[],
  detachedGids: string[]
): Result<ProductResultSet> => ({
  ...result,
  data: result.data
    ? {
        ...result.data,
        nodes: (result.data.nodes ?? []).map((product) => ({
          ...product,
          attached: attachedGids.includes(product.id)
            ? true
            : detachedGids.includes(product.id)
            ? false
            : product.attached,
        })),
      }
    : undefined,
})

const searchProducts = async (_: string, query: string): Promise<Result<ProductResultSet>> =>
  await backendFetchResult<ProductResultSet>('GET', `/products/search-products?${query}`)

export const useProductData = (query: string, pageData: PageData) => {
  const pageDataKey = JSON.stringify(pageData)
  const swrKey = useMemo(() => ['products', query, pageDataKey], [query, pageDataKey])
  const swr = useSWR(swrKey, searchProducts)
  const mutate = swr.mutate
  const loading = swr.isValidating
  const error = swr.data && swr.data.status !== 'success'
  const resultSet = swr.data?.data
  const page = swr.data?.data?.page ?? emptyGQLPaginatorPageInfo

  // Used to keep products from previous page visible when loading another page
  const [savedProducts, setSavedProducts] = useState<ProductResult[]>([])
  const products = useMemo(
    () => (loading && !resultSet ? savedProducts : resultSet?.nodes ?? []),
    [loading, resultSet, savedProducts]
  )

  useEffect(() => {
    if (swr.data?.status === 'success' && swr.data.data) {
      setSavedProducts(swr.data.data.nodes)
    }
  }, [swr.data])

  const update = useCallback(
    (attachedProductGids: string[], detachedProductGids: string[]) => {
      mutate(async (data): Promise<Result<ProductResultSet> | undefined> => {
        return data ? updateProducts(data, attachedProductGids, detachedProductGids) : undefined
      }, false)
    },
    [mutate]
  )

  return useMemo(
    () => ({
      products,
      page,
      loading,
      error,
      update,
    }),
    [products, page, loading, error, update]
  )
}

export interface PageQuery {
  filter?: string
  before?: string
  after?: string
  collection?: string
}

export interface BulkAction {
  type: string
  collectionTitle?: string
  totalCount: number
  processedCount: number
}

export interface PageData {
  merchantCode: string
  bulkAction: BulkAction | null
}
