import { Loading } from '@shopify/polaris'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { backendFetchResultData } from '../common/api'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { SubscriptionContract } from '../common/subscription-models'
import { TabIndex, Tabs } from '../common/Tabs'
import { PageContent } from './PageContent'
import { PageSkeleton } from './PageSkeleton'

const fetchSubscriptionContract = async (_: any, id: string): Promise<SubscriptionContract> => {
  return backendFetchResultData('GET', `/subscriptions/${id}`)
}

export const SubscriptionPage = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error, isValidating } = useSWR(
    ['subscription-contract', id],
    fetchSubscriptionContract
  )

  return (
    <>
      {isValidating && <Loading />}
      <Tabs selected={TabIndex.Subscriptions} />

      {error && <LoadErrorPage />}
      {!error && !data && <PageSkeleton />}
      {!error && data && <PageContent sub={data} />}
    </>
  )
}
