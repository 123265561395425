import { Loading } from '@shopify/polaris'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import { backendFetchResultData } from '../common/api'
import { formatLocalISODate, queryString, useQuery, useShopTimeZone } from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import { PageContent } from './PageContent'
import { Skeleton } from './Skeleton'

export interface PaymentEvent {
  subscriptionRestId: number
  baId?: number
  customerName?: string
  status: string
  date: string
  amount?: string
  errorMessage?: string
  orderGid?: string
  orderName?: string
  nextActionUrl?: string
}

const fetchDashboardPayments = (
  _: string,
  date: string,
  status: string,
  zone?: string
): Promise<PaymentEvent[]> =>
  backendFetchResultData(
    'GET',
    `/dashboard/payments?${queryString({
      date,
      status,
      tz: DateTime.now().setZone(zone).toFormat('ZZ'),
    })}`
  )

export type PaymentStatus = 'Success' | 'Failed' | 'Upcoming'
export interface PageQuery {
  date: string
  status: PaymentStatus
  page: number
}

export const usePageQuery = (): PageQuery => {
  const query = useQuery()

  const date = query.get('date') ?? formatLocalISODate(new Date())
  const status = (query.get('status') ?? 'Success') as PaymentStatus
  const page = Number.parseInt(query.get('page') ?? '1') || 1

  return useMemo(
    () => ({
      date,
      status,
      page,
    }),
    [date, status, page]
  )
}

export const DashboardPaymentsPage = () => {
  const zone = useShopTimeZone()

  const pageQuery = usePageQuery()

  const { data, error, isValidating } = useSWR(
    ['dashboard-payments', pageQuery.date, pageQuery.status, zone],
    fetchDashboardPayments
  )

  const [payments, setPayments] = useState<PaymentEvent[]>([])

  useEffect(() => {
    if (data) {
      setPayments(data)
    }
  }, [data])

  return (
    <>
      {isValidating && <Loading />}
      <Tabs selected={TabIndex.Dashboard} />

      {error && <LoadErrorPage />}
      {!error && !payments && <Skeleton />}
      {!error && payments && <PageContent payments={payments} />}
    </>
  )
}
