import {
  Button,
  HorizontalStack,
  LegacyCard,
  Modal,
  Text,
  TextField,
  VerticalStack,
} from '@shopify/polaris'
import { useCallback, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'

import { backendFetchResultData } from '../common/api'
import {
  CenteredSpinner,
  formatMoney,
  pluralize,
  useDialogActivator,
  useSystemData,
} from '../common/helpers'
import { SettingsContext } from '../settings-page/api'
import { ChartCard, ChartData } from './ChartCard'

interface CustomerValueAndChurnReportResponse {
  churnRates: ChartData
  avgMonthlyCustomerValue: {
    avgMonthlyValue: number | null
    customersCount: number
    subscriptionsCount: number
  }
}

const fetchChurnRateReport = (_: any, query: string) => {
  const url = `/dashboard/churn-rate?${query}`
  const data = backendFetchResultData<CustomerValueAndChurnReportResponse>('GET', url)

  return data
}

export const CustomerValueAndChurnReport = ({
  filterQuery,
  filterLabel,
}: {
  filterQuery: string
  filterLabel: string
}) => {
  const systemData = useSystemData()
  const userSettings = useContext(SettingsContext)

  const currency = systemData?.shopData.currency ?? 'USD'
  const [churnRate, setChurnRate] = useState<number | null>(null)
  const [churnRateInput, setChurnRateInput] = useState<string>()

  const churnRateActivator = useDialogActivator()

  const { data: churnRateReportData } = useSWR<CustomerValueAndChurnReportResponse>(
    ['churn-rate-report', filterQuery, userSettings?.analytics_start_date],
    fetchChurnRateReport,
    {
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    setChurnRate(churnRateReportData?.churnRates.total ?? null)
    setChurnRateInput(churnRateReportData?.churnRates.total?.toFixed(2) ?? '')
  }, [churnRateReportData])

  const overwriteChurnRate = useCallback(
    (val: number) => {
      if (val > 0 && val <= 100) {
        setChurnRate(val)
      }
      churnRateActivator.close()
    },
    [churnRateActivator]
  )

  const positiveValueChurnRates = churnRateReportData?.churnRates.series[0].data.filter(
    (val) => val
  )
  const canCalculateAverageCustomerValue =
    (positiveValueChurnRates ?? []).length > 1 &&
    churnRateReportData &&
    !!churnRateReportData.avgMonthlyCustomerValue.avgMonthlyValue &&
    !!churnRate

  return (
    <VerticalStack gap="2">
      <ChartCard
        title="12-month churn rate"
        tooltip="The churn rate for a given month is the percentage of all subscriptions that were created by the end of that month and had their last billing cycle end in that month."
        filterLabel={filterLabel}
        height="200"
        data={churnRateReportData?.churnRates}
        valueFormatter={(val: any) => `${Number(val).toFixed(2)}%`}
        fullWidth
      />
      <LegacyCard
        title="Projected customer value"
        sectioned
        actions={[{ content: filterLabel, disabled: true }]}
      >
        {!churnRateReportData && <CenteredSpinner />}
        {churnRateReportData && (
          <>
            {canCalculateAverageCustomerValue &&
              !!churnRateReportData.avgMonthlyCustomerValue.avgMonthlyValue &&
              !!churnRate && (
                <VerticalStack gap="4">
                  <HorizontalStack blockAlign="end" gap="2">
                    <Text variant="heading2xl" as="p">
                      {formatMoney(
                        (churnRateReportData.avgMonthlyCustomerValue.avgMonthlyValue * 100) /
                          (churnRate / 100),
                        currency
                      )}
                    </Text>
                    <Text as="p">
                      at <Text as="strong">{churnRate.toFixed(2)}%</Text> churn rate (
                      <Button plain onClick={churnRateActivator.show}>
                        overwrite
                      </Button>
                      )
                    </Text>
                  </HorizontalStack>
                  <HorizontalStack>
                    <Text as="p">
                      This projected calculation is based on your store's performance over the last
                      12 months. It uses the average monthly customer value of{' '}
                      <Text as="strong">
                        {formatMoney(
                          churnRateReportData.avgMonthlyCustomerValue.avgMonthlyValue * 100,
                          currency
                        )}
                      </Text>{' '}
                      from{' '}
                      <Text as="strong">
                        {churnRateReportData.avgMonthlyCustomerValue.subscriptionsCount}
                      </Text>{' '}
                      subscriptions created by{' '}
                      <Text as="strong">
                        {churnRateReportData.avgMonthlyCustomerValue.customersCount}
                      </Text>{' '}
                      {pluralize(
                        churnRateReportData.avgMonthlyCustomerValue.customersCount,
                        'customer'
                      )}
                      .
                    </Text>
                  </HorizontalStack>
                </VerticalStack>
              )}
            {!canCalculateAverageCustomerValue && (
              <Text color="subdued" as="p">
                We need more data to calculate this metric.
              </Text>
            )}
          </>
        )}
        <Modal
          open={churnRateActivator.open}
          onClose={churnRateActivator.close}
          title="Churn rate"
          primaryAction={{
            content: 'Update',
            onAction: () => overwriteChurnRate(Number(churnRateInput)),
          }}
          secondaryActions={[
            {
              content: 'Reset',
              onAction: () => setChurnRateInput(churnRateReportData?.churnRates.total?.toFixed(2)),
            },
            {
              content: 'Cancel',
              onAction: churnRateActivator.close,
            },
          ]}
          sectioned
        >
          <TextField
            label="Churn rate"
            labelHidden
            value={churnRateInput}
            autoComplete="off"
            prefix="%"
            min="0.01"
            max="100"
            type="number"
            step={0.5}
            onChange={setChurnRateInput}
          />
        </Modal>
      </LegacyCard>
    </VerticalStack>
  )
}
