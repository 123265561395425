import {
  Button,
  Icon,
  LegacyCard,
  LegacyStack,
  OptionList,
  Popover,
  RadioButton,
  Text,
} from '@shopify/polaris'
import { DeleteMajor } from '@shopify/polaris-icons'
import React, { useCallback } from 'react'

import { useToggle } from '../common/helpers'
import {
  WorkflowFilter,
  workflowFilterLabels,
  WorkflowFilterType,
  WorkflowRootFilterType,
} from '../common/workflow-models'
import {
  sellingPlanGroupOptions,
  sellingPlanNames,
  sellingPlanOptionListSections,
  useSellingPlans,
} from './api'
import { OptionsFilter } from './OptionsFilter'
import { PaymentNumberFilter, PaymentNumberFilterParams } from './PaymentNumberFilter'
import { ProductFieldFilter, ProductFieldFilterParams } from './ProductFieldFilter'
import { ProductFilter } from './ProductFilter'
import { ProductVariantFilter } from './ProductVariantFilter'

const workflowFilterDefaultParams = (type: WorkflowFilterType): any[] => {
  if (
    type === 'subscription-contains-any-matching-product-title' ||
    type === 'subscription-contains-any-matching-product-variant-title'
  ) {
    return ['equals', '']
  }

  if (type === 'after-nth-order') {
    return ['1']
  }

  return []
}

type FilterOption = {
  label: string
  value: WorkflowFilterType
}

type FilterSection = {
  options: FilterOption[]
  title: string
}

const filterSectionOptions = (filterTypes: WorkflowFilterType[]) =>
  filterTypes.map((filter) => ({
    label: workflowFilterLabels[filter],
    value: filter,
  }))

export const workflowFilterSections = (excludeOther?: boolean): FilterSection[] => {
  const sections = [
    {
      options: filterSectionOptions([
        'subscription-contains-any-of-selected-selling-plans',
        'subscription-contains-any-of-selected-selling-plan-groups',
      ]),
      title: 'Selling plan',
    },
    {
      options: filterSectionOptions([
        'subscription-contains-any-matching-product-title',
        'subscription-contains-all-selected-products',
        'subscription-contains-any-of-selected-products',
        'subscription-does-not-contain-any-of-selected-products',
      ]),
      title: 'Product',
    },
    {
      options: filterSectionOptions([
        'subscription-contains-any-matching-product-variant-title',
        'subscription-contains-all-selected-product-variants',
        'subscription-contains-any-of-selected-product-variants',
        'subscription-does-not-contain-any-of-selected-product-variants',
      ]),
      title: 'Product variant',
    },
  ]

  const otherSection = [
    {
      options: filterSectionOptions(['customer-has-no-active-subscriptions', 'after-nth-order']),
      title: 'Other',
    },
  ]

  return excludeOther ? sections : [...sections, ...otherSection]
}

export interface WorkflowFiltersProps {
  filters: WorkflowFilter[]
  onChange: (filters: WorkflowFilter[]) => any
  rootFilterType: WorkflowRootFilterType
  onRootFilterTypeChange: (rootFilterType: WorkflowRootFilterType) => any
  hideRootFilterSelection?: boolean
  workflowFilterOptions: FilterSection[]
}

export const WorkflowFilters = ({
  filters,
  onChange,
  rootFilterType,
  onRootFilterTypeChange,
  hideRootFilterSelection,
  workflowFilterOptions,
}: WorkflowFiltersProps) => {
  const [filterPopoverActive, toggleFilterPopover] = useToggle(false)

  const spData = useSellingPlans()
  const spNames = sellingPlanNames(spData)

  const removeFilter = useCallback(
    (index: number) => {
      const newFilters = [...filters]
      newFilters.splice(index, 1)
      onChange(newFilters)
    },
    [filters, onChange]
  )

  const updateFilter = useCallback(
    (params: any[], index: number) => {
      const newFilters = [...filters]
      newFilters[index] = [newFilters[index][0], params]
      onChange(newFilters)
    },
    [filters, onChange]
  )

  return (
    <>
      {!hideRootFilterSelection && filters.length > 1 && (
        <LegacyCard.Section>
          <LegacyStack vertical spacing="extraTight">
            <RadioButton
              label="Run if ALL of the following conditions are true"
              checked={rootFilterType === 'and'}
              id="and"
              name="filter-type"
              onChange={(_checked, type: WorkflowRootFilterType) => onRootFilterTypeChange(type)}
            />
            <RadioButton
              label="Run if at least ONE of the following conditions is true"
              checked={rootFilterType === 'or'}
              id="or"
              name="filter-type"
              onChange={(_checked, type: WorkflowRootFilterType) => onRootFilterTypeChange(type)}
            />
          </LegacyStack>
        </LegacyCard.Section>
      )}
      {filters.map(([type, params]: WorkflowFilter, index: number) => (
        <LegacyCard.Section key={index}>
          <LegacyStack alignment="center" wrap={false}>
            <LegacyStack.Item fill>
              {type === 'customer-has-no-active-subscriptions' && (
                <p>{workflowFilterLabels[type]}</p>
              )}
              {(type === 'subscription-contains-any-of-selected-product-variants' ||
                type === 'subscription-contains-all-selected-product-variants' ||
                type === 'subscription-does-not-contain-any-of-selected-product-variants') && (
                <ProductVariantFilter
                  label={workflowFilterLabels[type]}
                  params={params}
                  onChange={updateFilter}
                  index={index}
                />
              )}
              {(type === 'subscription-contains-any-of-selected-products' ||
                type === 'subscription-contains-all-selected-products' ||
                type === 'subscription-does-not-contain-any-of-selected-products') && (
                <ProductFilter
                  label={workflowFilterLabels[type]}
                  params={params}
                  onChange={updateFilter}
                  index={index}
                />
              )}
              {type === 'subscription-contains-any-matching-product-title' && (
                <ProductFieldFilter
                  label="Product title"
                  params={params as ProductFieldFilterParams}
                  onChange={updateFilter}
                  index={index}
                />
              )}
              {type === 'subscription-contains-any-matching-product-variant-title' && (
                <ProductFieldFilter
                  label="Product variant title"
                  params={params as ProductFieldFilterParams}
                  onChange={updateFilter}
                  index={index}
                />
              )}
              {type === 'subscription-contains-any-of-selected-selling-plans' && (
                <OptionsFilter
                  label={workflowFilterLabels[type]}
                  buttonLabel="Add selling plans"
                  params={params as ProductFieldFilterParams}
                  onChange={updateFilter}
                  index={index}
                  sections={sellingPlanOptionListSections(spData)}
                  optionLabels={spNames}
                />
              )}
              {type === 'subscription-contains-any-of-selected-selling-plan-groups' && (
                <OptionsFilter
                  label={workflowFilterLabels[type]}
                  buttonLabel="Add selling plan group"
                  params={params as ProductFieldFilterParams}
                  onChange={updateFilter}
                  index={index}
                  options={sellingPlanGroupOptions(spData)}
                  optionLabels={spNames}
                />
              )}
              {type === 'after-nth-order' && (
                <PaymentNumberFilter
                  params={params as PaymentNumberFilterParams}
                  onChange={updateFilter}
                  index={index}
                />
              )}
            </LegacyStack.Item>
            <Button
              onClick={() => removeFilter(index)}
              icon={<Icon source={DeleteMajor} color="base" />}
            />
          </LegacyStack>
        </LegacyCard.Section>
      ))}

      <LegacyCard.Section>
        <Popover
          active={filterPopoverActive}
          activator={
            <Button onClick={toggleFilterPopover} disclosure>
              Add condition
            </Button>
          }
          onClose={toggleFilterPopover}
          fluidContent
        >
          <OptionList
            onChange={(selected: WorkflowFilterType[]) => {
              onChange([...filters, [selected[0], workflowFilterDefaultParams(selected[0])]])
              toggleFilterPopover()
            }}
            sections={workflowFilterOptions}
            selected={[]}
          />
        </Popover>
      </LegacyCard.Section>
    </>
  )
}

export const FiltersCard = ({
  filters,
  onChange,
  rootFilterType,
  onRootFilterTypeChange,
}: WorkflowFiltersProps) => {
  return (
    <LegacyCard title="Conditions (optional)">
      {filters.length === 0 && (
        <LegacyCard.Section>
          <Text as="span" color="subdued">
            Run workflow unconditionally
          </Text>
        </LegacyCard.Section>
      )}
      <WorkflowFilters
        filters={filters}
        onChange={onChange}
        rootFilterType={rootFilterType}
        onRootFilterTypeChange={onRootFilterTypeChange}
        workflowFilterOptions={workflowFilterSections()}
      />
    </LegacyCard>
  )
}
