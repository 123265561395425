import { Action, LegacyCard, LegacyStack } from '@shopify/polaris'
import React from 'react'

import { ApiActionDialog } from '../common/ApiActionDialog'
import { gidToId, useDialogActivator } from '../common/helpers'
import { HelpTitle } from '../common/HelpIcon'
import { SubscriptionContract } from '../common/subscription-models'
import { PaymentMethodWidget } from '../payment-method-widget/PaymentMethodWidget'
import { PaymentMethodDialog } from './PaymentMethodDialog'

export const PaymentMethodCard = ({ sc }: { sc: SubscriptionContract }) => {
  const paymentMethodDialogActivator = useDialogActivator()
  const paymentMethodUpdateDialogActivator = useDialogActivator()
  const paymentMethodTitle = (
    <HelpTitle title="Payment Method">
      Update the payment method associated with this subscription or request the customer to update
      it by sending an email from Shopify with a secure link to make the update.
    </HelpTitle>
  )

  const paymentMethod = sc.gql_data.customerPaymentMethod
  const customerGid = sc.gql_data.customer?.id

  const action = sc.is_editable
    ? {
        content: 'Change',
        onAction: paymentMethodDialogActivator.show,
      }
    : null

  let footerActions: Action[] = []

  if (customerGid) {
    footerActions.push({
      content: 'Manage on the customer page',
      onAction: () => {
        window.open(`shopify://admin/customers/${gidToId(customerGid).toString()}`, '_top')
      },
    })
  }

  if (paymentMethod?.id) {
    footerActions.push({
      content: 'Send link to update payment method',
      onAction: paymentMethodUpdateDialogActivator.show,
    })
  }

  return (
    <LegacyCard
      sectioned
      title={paymentMethodTitle}
      actions={action ? [action] : undefined}
      secondaryFooterActions={footerActions.length > 0 ? footerActions : undefined}
      secondaryFooterActionsDisclosureText="More actions"
      footerActionAlignment="left"
    >
      <LegacyStack vertical>
        <PaymentMethodWidget paymentMethod={paymentMethod} />
      </LegacyStack>

      <PaymentMethodDialog
        sc={sc}
        open={paymentMethodDialogActivator.open}
        close={paymentMethodDialogActivator.close}
      />

      <ApiActionDialog
        activator={paymentMethodUpdateDialogActivator}
        url={`/subscriptions/${sc.rest_id}/send-payment-method-update-email`}
        method="POST"
        title="Send link to update card"
        message={
          <>
            An email containing a secure link to update the payment method will be sent directly
            from Shopify to the customer. Do you want to continue?
          </>
        }
        primaryButtonTitle="Send email"
        cancelButtonTitle="Cancel"
      />
    </LegacyCard>
  )
}
