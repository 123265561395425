import { ContextualSaveBar, Form } from '@shopify/polaris'
import { Formik } from 'formik'
import { useCallback, useState } from 'react'

import { Result } from '../common/api'
import { ResultToastOrBanner } from '../common/helpers'
import { patchTranslationSettings, TranslationSet } from './api'
import { TranslationGroupCards } from './TranslationGroupCards'

interface Props {
  defaults: TranslationSet
  initialValues: TranslationSet
  language: string
}

export const TranslationsForm = ({ defaults, initialValues, language }: Props) => {
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const handleSubmit = useCallback(async (translations: TranslationSet, actions: any) => {
    actions.setSubmitting(true)
    const res = await patchTranslationSettings(translations)
    actions.setSubmitting(false)
    setSaveResult(res)
  }, [])

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <ResultToastOrBanner result={saveResult} setResult={setSaveResult} />

          <TranslationGroupCards language={language} defaults={defaults} />

          {formik.dirty && (
            <ContextualSaveBar
              message="Unsaved changes"
              saveAction={{
                onAction: formik.handleSubmit,
                loading: formik.isSubmitting,
                disabled: false,
              }}
              discardAction={{
                onAction: formik.resetForm,
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}
