import { Text } from '@shopify/polaris'
import React from 'react'

import {
  creditCardLastDigits,
  formatPaymentMethodBrand,
  ShortDate,
  suffix,
} from '../common/helpers'
import {
  CustomerCreditCard,
  CustomerPaypalBillingAgreement,
  CustomerShopPayAgreement,
  PaymentMethod,
} from '../common/subscription-models'
import { PaymentMethodIcon } from './PaymentMethodIcon'
import styles from './PaymentMethodWidget.module.css'

const RevokeInfo = ({ revokedAt }: { revokedAt?: string }) => {
  if (!revokedAt) {
    return null
  }

  return (
    <p>
      <Text as="span" color="critical">
        Revoked on <ShortDate date={revokedAt} />
      </Text>
    </p>
  )
}

const InactiveInfo = ({ inactive }: { inactive?: boolean }) =>
  inactive ? (
    <p>
      <Text as="span" color="critical">
        Inactive
      </Text>
    </p>
  ) : null

const CreditCard = ({ card, revokedAt }: { card: CustomerCreditCard; revokedAt?: string }) => {
  const title = formatPaymentMethodBrand(card.brand)
  const lastDigits = creditCardLastDigits(card)
  const expiryMonth = card.expiryMonth.toString().padStart(2, '0')
  const expiryYear = suffix(card.expiryYear.toString(), 2)
  const iconBrand =
    card.source === 'apple_pay' || card.source === 'google_pay' ? card.source : card.brand

  return (
    <div className={styles.widget}>
      <PaymentMethodIcon brand={iconBrand} />
      <div>
        <p>
          {title} ending in {lastDigits}
        </p>
        <p>
          <Text as="span" color="subdued">
            Expires {expiryMonth}/{expiryYear}
          </Text>
        </p>
        <RevokeInfo revokedAt={revokedAt} />
        <InactiveInfo inactive={card.inactive} />
      </div>
    </div>
  )
}

const ShopPay = ({
  agreement,
  revokedAt,
}: {
  agreement: CustomerShopPayAgreement
  revokedAt?: string
}) => (
  <CreditCard
    revokedAt={revokedAt}
    card={{
      ...agreement,
      brand: 'shop_pay',
    }}
  />
)

const PayPal = ({
  agreement,
  revokedAt,
}: {
  agreement: CustomerPaypalBillingAgreement
  revokedAt?: string
}) => (
  <div className={styles.widget}>
    <PaymentMethodIcon brand="payPal" />
    <div>
      <p>PayPal</p>
      <p>
        <Text as="span" color="subdued">
          {agreement.paypalAccountEmail}
        </Text>
      </p>
      <RevokeInfo revokedAt={revokedAt} />
      <InactiveInfo inactive={agreement.inactive} />
    </div>
  </div>
)

const UnknownInstrument = ({ revokedAt }: { revokedAt?: string }) => (
  <>
    <p>Unknown</p>
    <RevokeInfo revokedAt={revokedAt} />
  </>
)

export const PaymentMethodWidget = ({ paymentMethod }: { paymentMethod: PaymentMethod | null }) => {
  const revokedAt = paymentMethod?.revokedAt
  const instrument = paymentMethod?.instrument

  if ((instrument && !instrument.__typename) || instrument?.__typename === 'CustomerCreditCard') {
    return <CreditCard card={instrument as CustomerCreditCard} revokedAt={revokedAt} />
  }

  if (instrument?.__typename === 'CustomerShopPayAgreement') {
    return <ShopPay agreement={instrument as CustomerShopPayAgreement} revokedAt={revokedAt} />
  }

  if (instrument?.__typename === 'CustomerPaypalBillingAgreement') {
    return <PayPal agreement={instrument as CustomerPaypalBillingAgreement} revokedAt={revokedAt} />
  }

  return <UnknownInstrument revokedAt={revokedAt} />
}
