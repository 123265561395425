import {
  Form as PolarisForm,
  FormLayout,
  Layout,
  LegacyStack,
  Select,
  SelectOption,
  Spinner,
  Text,
  TextContainer,
  TextField,
} from '@shopify/polaris'
import { getIn, useFormikContext } from 'formik'
import React, { useEffect } from 'react'

import { useFormikHandleChangeCallback } from '../common/helpers'
import { LegacyHelpLabel } from '../common/HelpIcon'
import { useLocations } from '../common/locations'
import { Location } from '../common/subscription-models'
import { countryCodeOptions, provinceOptions } from './models'
import { DeliveryDetailsForm, DeliveryMethodType } from './models-form'

const deliveryMethodTypeLabels: Record<DeliveryMethodType, string> = {
  none: 'None',
  shipping: 'Shipping',
  'local-delivery': 'Local Delivery',
  pickup: 'Pickup',
}

const deliveryMethodTypes = Object.keys(deliveryMethodTypeLabels) as DeliveryMethodType[]

const deliveryMethodTypeOptions = deliveryMethodTypes.map((deliveryMethod) => ({
  label: deliveryMethodTypeLabels[deliveryMethod],
  value: deliveryMethod,
}))

export const Form = ({ currencyCode }: { currencyCode: string }) => {
  const formik = useFormikContext<DeliveryDetailsForm>()
  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)

  const locations = useLocations()

  const locationOptions = locations.map((location: Location) => ({
    label: location.name,
    value: location.id,
  }))

  const loadingLocationsOptions = [
    {
      label: 'Loading locations...',
      value: '',
      disabled: true,
    } as SelectOption,
  ]

  useEffect(() => {
    if (!formik.values.locationId && locations.length) {
      formik.setFieldValue('locationId', locations[0].id)
    }
  }, [locations, formik])

  useEffect(() => {
    if (formik.values.type === 'pickup' && formik.values.price !== '0.00') {
      formik.setFieldValue('price', '0.00')
    }
  }, [formik])

  const currentProvinceOptions = provinceOptions[formik.values.address?.countryCode || 'US']

  return (
    <PolarisForm onSubmit={formik.handleSubmit}>
      <Layout>
        <Layout.Section>
          <LegacyStack vertical>
            <Text variant="headingMd" as="h2">
              Delivery method
            </Text>
            <FormLayout>
              <Select
                id="type"
                name="type"
                label="Type"
                options={deliveryMethodTypeOptions}
                value={formik.values.type}
                onChange={handleChange}
              />
              {formik.values.type !== 'none' && (
                <LegacyStack vertical>
                  <TextField
                    id="title"
                    name="title"
                    label={
                      <LegacyHelpLabel title="Title">
                        This label is displayed to customers on the order and subscription details
                        pages
                      </LegacyHelpLabel>
                    }
                    value={formik.values.title}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <TextField
                    id="code"
                    name="code"
                    label={
                      <LegacyHelpLabel title="Code">
                        An arbitrary identifier that may be used for integration with 3rd party apps
                      </LegacyHelpLabel>
                    }
                    value={formik.values.code}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </LegacyStack>
              )}
              {formik.values.type === 'pickup' && (
                <>
                  <Select
                    id="locationId"
                    name="locationId"
                    label={
                      <LegacyStack alignment="center">
                        <TextContainer>Pickup location </TextContainer>
                        {locations.length === 0 && (
                          <Spinner accessibilityLabel="Loading locations" size="small" />
                        )}
                      </LegacyStack>
                    }
                    options={locations.length ? locationOptions : loadingLocationsOptions}
                    value={formik.values.locationId}
                    onChange={handleChange}
                  />
                </>
              )}
              <TextField
                id="price"
                name="price"
                label={
                  <LegacyHelpLabel title="Price">
                    This will be the customers recurring delivery charge. Please note, no charge may
                    be applied if Pickup is selected.
                  </LegacyHelpLabel>
                }
                type="currency"
                min={0}
                step={0.01}
                prefix={currencyCode}
                value={formik.values.price}
                onChange={handleChange}
                autoComplete="off"
                disabled={formik.values.type === 'pickup'}
                error={getIn(formik.errors, `deliveryPrice`)}
              />
            </FormLayout>
          </LegacyStack>
        </Layout.Section>
        {formik.values.type !== 'none' && formik.values.type !== 'pickup' && (
          <Layout.Section>
            <LegacyStack vertical>
              <Text variant="headingMd" as="h2">
                Delivery address
              </Text>
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    id="address.firstName"
                    name="address.firstName"
                    label="First name"
                    type="text"
                    value={formik.values.address?.firstName}
                    onChange={handleChange}
                    autoComplete="off"
                    error={getIn(formik.errors, `address.firstName`)}
                  />
                  <TextField
                    id="address.lastName"
                    name="address.lastName"
                    label="Last name"
                    type="text"
                    value={formik.values.address?.lastName}
                    onChange={handleChange}
                    autoComplete="off"
                    error={getIn(formik.errors, `address.lastName`)}
                  />
                </FormLayout.Group>
                <TextField
                  id="address.company"
                  name="address.company"
                  label="Company"
                  type="text"
                  value={formik.values.address?.company ?? ''}
                  onChange={handleChange}
                  autoComplete="off"
                  error={getIn(formik.errors, `address.company`)}
                />
                <TextField
                  id="address.address1"
                  name="address.address1"
                  label="Address"
                  type="text"
                  value={formik.values.address?.address1 ?? ''}
                  onChange={handleChange}
                  autoComplete="off"
                  error={getIn(formik.errors, `address.address1`)}
                />
                <TextField
                  id="address.address2"
                  name="address.address2"
                  label="Apartment, suite, etc."
                  type="text"
                  value={formik.values.address?.address2 ?? ''}
                  onChange={handleChange}
                  autoComplete="off"
                  error={getIn(formik.errors, `address.address2`)}
                />
                <TextField
                  id="address.city"
                  name="address.city"
                  label="City"
                  type="text"
                  value={formik.values.address?.city ?? ''}
                  onChange={handleChange}
                  autoComplete="off"
                  error={getIn(formik.errors, `address.city`)}
                />
                <FormLayout.Group condensed>
                  <Select
                    id="address.countryCode"
                    name="address.countryCode"
                    label="Country"
                    options={countryCodeOptions}
                    value={formik.values.address?.countryCode ?? 'US'}
                    onChange={handleChange}
                    error={getIn(formik.errors, `address.countryCode`)}
                  />
                  {currentProvinceOptions.length > 0 && (
                    <Select
                      id="address.province"
                      name="address.province"
                      label="Province"
                      options={currentProvinceOptions}
                      value={formik.values.address?.province}
                      onChange={handleChange}
                      error={getIn(formik.errors, `address.province`)}
                    />
                  )}
                  <TextField
                    id="address.zip"
                    name="address.zip"
                    label="ZIP code"
                    type="text"
                    value={formik.values.address?.zip ?? ''}
                    onChange={handleChange}
                    autoComplete="off"
                    error={getIn(formik.errors, `address.zip`)}
                  />
                </FormLayout.Group>
                <TextField
                  id="address.phone"
                  name="address.phone"
                  label="Phone"
                  type="tel"
                  value={formik.values.address?.phone ?? ''}
                  onChange={handleChange}
                  autoComplete="off"
                  error={getIn(formik.errors, `address.phone`)}
                />
              </FormLayout>
            </LegacyStack>
          </Layout.Section>
        )}
        {formik.values.type === 'local-delivery' && (
          <Layout.Section>
            <LegacyStack vertical>
              <Text variant="headingMd" as="h2">
                Delivery instructions
              </Text>
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                type="tel"
                value={formik.values.phone ?? ''}
                onChange={handleChange}
                autoComplete="off"
                error={getIn(formik.errors, `phone`)}
              />
              <TextField
                id="instructions"
                name="instructions"
                label="Instructions"
                value={formik.values.instructions ?? ''}
                onChange={handleChange}
                autoComplete="off"
                multiline
              />
            </LegacyStack>
          </Layout.Section>
        )}
      </Layout>
    </PolarisForm>
  )
}
