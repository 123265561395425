import { Label, LegacyStack } from '@shopify/polaris'
import React from 'react'

import { MemoedSelect, MemoedTextField } from '../common/helpers'

export type Operator =
  | 'equals'
  | 'is-not-equal'
  | 'starts-with'
  | 'ends-with'
  | 'contains'
  | 'does-not-contain'

export const operatorLabels: Record<Operator, string> = {
  equals: 'is equal to',
  'is-not-equal': 'is not equal to',
  contains: 'contains',
  'does-not-contain': 'does not contain',
  'starts-with': 'starts with',
  'ends-with': 'ends with',
}

export const operatorTypes = Object.keys(operatorLabels) as Operator[]
const operatorOptions = operatorTypes.map((key: Operator) => ({
  label: operatorLabels[key],
  value: key,
}))

export type ProductFieldFilterParams = [Operator, string]

interface ProductFieldFilterProps {
  label: string
  params: ProductFieldFilterParams
  onChange: (params: any, index: number) => any
  index: number
}

export const ProductFieldFilter = (props: ProductFieldFilterProps) => {
  const { label, params, onChange, index } = props
  const [operator, content] = params

  return (
    <LegacyStack vertical>
      <p>Subscription contains ANY product where</p>

      <LegacyStack alignment="center">
        <Label id="title">{label}</Label>
        <MemoedSelect
          label="Operator"
          labelHidden
          options={operatorOptions}
          value={operator}
          onChange={(selected: Operator) => onChange([selected, content], index)}
        />
        <MemoedTextField
          label="Content"
          labelHidden
          value={content}
          onChange={(val: string) => onChange([operator, val], index)}
          autoComplete="off"
        />
      </LegacyStack>
    </LegacyStack>
  )
}
