import { AppliedFilterInterface, Button, FilterInterface, TextField } from '@shopify/polaris'
import { useCallback } from 'react'

import { useDialogActivator } from '../../common/helpers'
import { ProductModal, ProductResult } from '../../common/ProductModal'
import { SubscriptionFilter } from '../api'

const windowWithProductNameCache = window as unknown as {
  paywhirlProductNameCache: Record<number, string>
}

windowWithProductNameCache.paywhirlProductNameCache = {}

const formatProductId = (id?: number): string | undefined => {
  if (!id) {
    return undefined
  }

  const name = windowWithProductNameCache.paywhirlProductNameCache[id]

  return name ? `${name} (#${id})` : `#${id}`
}

export const useAddProductIdFilter = (
  filter: SubscriptionFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any,
  filters: FilterInterface[],
  appliedFilters: AppliedFilterInterface[]
) => {
  const activator = useDialogActivator()

  const onRemove = useCallback(() => updateFilter({ product_id: undefined }), [updateFilter])

  const onSelect = useCallback(
    (res: ProductResult) => {
      windowWithProductNameCache.paywhirlProductNameCache[res.restId] = res.title
      updateFilter({ product_id: res.restId })
      activator.close()
    },
    [activator, updateFilter]
  )

  const formattedProduct = formatProductId(filter.product_id)

  filters.push({
    key: 'product_id',
    label: 'Product',
    filter: (
      <>
        <TextField
          label=""
          labelHidden
          placeholder="Select product"
          readOnly={true}
          value={formattedProduct}
          connectedRight={<Button onClick={activator.show}>Search</Button>}
          autoComplete="off"
        />
        <ProductModal open={activator.open} onClose={activator.close} onSelect={onSelect} />
      </>
    ),
  })

  if (formattedProduct) {
    appliedFilters.push({
      key: 'product_id',
      label: `Product: ${formattedProduct}`,
      onRemove: onRemove,
    })
  }
}
