export interface GQLPaginatorPageInfo {
  firstCursor: string | null
  lastCursor: string | null
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export const emptyGQLPaginatorPageInfo: GQLPaginatorPageInfo = {
  firstCursor: null,
  lastCursor: null,
  hasPreviousPage: false,
  hasNextPage: false,
}

export interface GQLPaginator<T> {
  nodes: T[]
  page: GQLPaginatorPageInfo
}

export interface GQLCount {
  count: number
}
