import { FormLayout, Modal } from '@shopify/polaris'
import { useEffect, useState } from 'react'
import { mutate } from 'swr'

import { Result } from '../common/api'
import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { DateTimePicker } from '../common/DateTimePicker'
import { FulfillmentOrder, SubscriptionContract } from '../common/subscription-models'

interface Props {
  sc: SubscriptionContract
  fo: FulfillmentOrder
  open: boolean
  close: () => any
  showCounter: number
}

export const RescheduleFulfillmentOrderDialog = ({ open, close, showCounter, sc, fo }: Props) => {
  const [date, setDate] = useState<string>(fo.fulfillAt)
  const [requestResultError, setRequestResultError] = useState<Result<any> | null>(null)

  useEffect(() => {
    setDate(fo.fulfillAt)
  }, [fo, showCounter])

  useEffect(() => {
    setRequestResultError(null)
  }, [date])

  const saveAction = useApiActionState({
    method: 'POST',
    url: `/subscriptions/${sc.rest_id}/reschedule-fulfillment-order`,
    opts: {
      body: JSON.stringify({ fulfillmentOrderId: fo.id, fulfillAt: date, orderId: fo.orderId }),
    },
    onSuccess: (res) => {
      mutate(['subscription-contract', `${sc.rest_id}`], res.data, false)
      close()
    },
    onError: setRequestResultError,
    bannerPosition: 'external',
  })

  return (
    <>
      <Modal
        open={open}
        title="Reschedule fulfillment order"
        onClose={close}
        primaryAction={{ content: 'Save', onAction: saveAction.run, loading: saveAction.loading }}
        secondaryActions={[{ content: 'Cancel', onAction: close }]}
        sectioned
      >
        <ApiActionBanner state={saveAction} />
        <FormLayout>
          <DateTimePicker
            label="Date"
            initial={date}
            onChange={setDate}
            error={requestResultError?.field === 'date' ? requestResultError.message : null}
          />
        </FormLayout>
      </Modal>

      <ApiAction state={saveAction} />
    </>
  )
}
