import {
  Button,
  FormLayout,
  HorizontalStack,
  LegacyStack,
  Modal,
  RadioButton,
  Text,
  TextField,
  VerticalStack,
} from '@shopify/polaris'
import { useCallback, useEffect, useState } from 'react'

import { backendFetchResult, mutateWithResult, Result } from '../common/api'
import {
  formatMoneyAmount,
  MemoedSelect,
  range,
  ResultBanner,
  ResultToast,
  useDialogActivator,
} from '../common/helpers'
import { LegacyHelpIcon, LegacyHelpLabel } from '../common/HelpIcon'
import {
  addLimitedCyclesAttribute,
  getRemainingInstallmentsNumber,
  removeLimitedCyclesAttributes,
} from '../common/line-item'
import { ProductQuantityField } from '../common/ProductQuantityField'
import { ProductVariantModal, ProductVariantResult } from '../common/ProductVariantModal'
import { SellingPlanDescriptor, SellingPlanField } from '../common/SellingPlanField'
import { Attribute, LineItem, SubscriptionContract } from '../common/subscription-models'

interface Props {
  sc: SubscriptionContract
  item?: LineItem
  open: boolean
  showCounter: number
  onClose: () => any
}

const getInitialSellingPlan = (
  sc: SubscriptionContract,
  item?: LineItem
): SellingPlanDescriptor => {
  item = item ?? sc.gql_data.lines.find((i) => i.sellingPlanId)

  return {
    id: item?.sellingPlanId ?? null,
    name: item?.sellingPlanName ?? null,
  }
}

const fixedCyclesOptions = [
  { label: 'One-time', value: '1' },
  ...range(2, 100).map((i) => ({
    label: `${i} cycles`,
    value: `${i}`,
  })),
]

export const LineEditDialog = ({ sc, item, open, onClose, showCounter }: Props) => {
  const [saving, setSaving] = useState(false)
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const [variantId, setVariantId] = useState<string>('')
  const [variantTitle, setVariantTitle] = useState<string>('')
  const [price, setPrice] = useState<string>('')
  const [quantity, setQuantity] = useState<number>(0)
  const [sellingPlan, setSellingPlan] = useState<SellingPlanDescriptor>({ id: null, name: null })
  const [customAttributes, setCustomAttributes] = useState<Attribute[]>([])

  const variantDialogActivator = useDialogActivator()

  useEffect(() => {
    setVariantId(item?.variantId || '')
    setVariantTitle(item?.displayData?.title || '')
    setPrice(formatMoneyAmount(item?.currentPrice.amount))
    setQuantity(item?.quantity ?? 1)
    setSellingPlan(getInitialSellingPlan(sc, item))
    setCustomAttributes(item?.customAttributes ?? [])
  }, [showCounter, item, sc])

  const remainingInstallments = getRemainingInstallmentsNumber(
    customAttributes,
    sc.processed_installments
  )
  const isRecurring = remainingInstallments === null

  useEffect(() => {
    setSaveResult(null)
  }, [price])

  const handleFixedCyclesChange = useCallback(
    (fixedCycles: number | null) => {
      const newCustomAttributes = fixedCycles
        ? addLimitedCyclesAttribute(customAttributes, sc.processed_installments + fixedCycles)
        : removeLimitedCyclesAttributes(customAttributes)

      setCustomAttributes(newCustomAttributes)

      if (fixedCycles) {
        setSellingPlan({ id: null, name: null })
      }

      if (!fixedCycles && item) {
        setSellingPlan(getInitialSellingPlan(sc, item))
      }
    },
    [customAttributes, sc, item]
  )

  const save = useCallback(async () => {
    setSaving(true)

    const id = item?.id || null

    const input = {
      productVariantId: variantId,
      quantity: quantity,
      currentPrice: parseFloat(price) || 0,
      sellingPlanId: sellingPlan.id,
      sellingPlanName: sellingPlan.name,
      customAttributes: customAttributes,
    }

    const body = JSON.stringify(id ? { lineToUpdate: { id, input } } : { lineToAdd: { input } })

    const path = `/subscriptions/${sc.rest_id}`
    const res = await backendFetchResult<SubscriptionContract>('PUT', path, { body })

    if (res.status === 'success') {
      mutateWithResult(['subscription-contract', `${sc.rest_id}`], res)
    }

    setSaving(false)
    setSaveResult(res)

    if (res.status === 'success') {
      onClose()
    }
  }, [onClose, setSaving, sc, item, variantId, quantity, price, sellingPlan, customAttributes])

  const onVariantSelect = useCallback(
    (result: ProductVariantResult) => {
      setVariantTitle(result.title)
      setVariantId(result.id)
      setPrice(formatMoneyAmount(parseFloat(result.price) * sc.pre_paid_deliveries))
      variantDialogActivator.close()
    },
    [variantDialogActivator, sc]
  )

  return (
    <>
      <Modal
        open={open && !variantDialogActivator.open}
        title={item ? 'Edit item' : 'Add product'}
        onClose={onClose}
        primaryAction={{
          content: 'Save',
          onAction: save,
          loading: saving,
          disabled: !variantId || !quantity,
        }}
        secondaryActions={[{ content: 'Cancel', onAction: onClose }]}
        sectioned
      >
        <FormLayout>
          <ResultBanner result={saveResult} setResult={setSaveResult} bottomMargin={false} />

          <TextField
            label="Product variant"
            readOnly={true}
            value={variantTitle}
            connectedRight={<Button onClick={variantDialogActivator.show}>Select</Button>}
            autoComplete="off"
          />

          <ProductQuantityField value={quantity} onChange={setQuantity} />

          <TextField
            label={
              sc.pre_paid_deliveries > 1 ? (
                <LegacyHelpLabel title="Price">
                  Combined price for {sc.pre_paid_deliveries} pre-paid deliveries, excluding tax and
                  shipping
                </LegacyHelpLabel>
              ) : (
                'Price'
              )
            }
            type="number"
            min={0}
            step={0.01}
            prefix={sc.gql_data.currencyCode}
            value={price}
            onChange={setPrice}
            autoComplete="off"
            error={saveResult?.field === 'product.currentPrice' ? saveResult.message : false}
          />
          <LegacyStack vertical spacing="extraTight">
            <RadioButton
              label="Recurring"
              checked={isRecurring}
              name={`recurring`}
              onChange={() => handleFixedCyclesChange(null)}
            />
            <HorizontalStack blockAlign="center">
              <RadioButton
                label={
                  <VerticalStack gap="2">
                    <Text as="span">One-time or Fixed Cycles</Text>
                    {!isRecurring && (
                      <MemoedSelect
                        label=""
                        labelHidden
                        value={remainingInstallments.toString()}
                        onChange={(val: string) => handleFixedCyclesChange(parseInt(val))}
                        options={fixedCyclesOptions}
                      />
                    )}
                  </VerticalStack>
                }
                checked={!isRecurring}
                name={`recurring`}
                disabled={sc.pre_paid_deliveries > 1}
                onChange={() => handleFixedCyclesChange(1)}
              />
              {sc.pre_paid_deliveries > 1 && (
                <LegacyHelpIcon>
                  One-time or Fixed Cycles is not available on pre-paid subscriptions
                </LegacyHelpIcon>
              )}
            </HorizontalStack>
          </LegacyStack>
          <SellingPlanField plan={sellingPlan} onChange={setSellingPlan} disabled={!isRecurring} />
        </FormLayout>
      </Modal>

      <ProductVariantModal
        currency={sc.gql_data.currencyCode}
        open={variantDialogActivator.open}
        onClose={variantDialogActivator.close}
        onSelect={onVariantSelect}
      />

      <ResultToast result={saveResult} setResult={setSaveResult} />
    </>
  )
}
