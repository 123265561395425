import { Checkbox, FormLayout, Modal, TextField } from '@shopify/polaris'

import { LegacyHelpLabel } from '../common/HelpIcon'
import { UpdateShippingMethodPayload } from './common'

export const UpdateShippingMethodSection = ({
  setPayload,
  payload,
}: {
  setPayload: (payload: UpdateShippingMethodPayload) => any
  payload: UpdateShippingMethodPayload
}) => (
  <Modal.Section>
    <FormLayout>
      <TextField
        label={
          <Checkbox
            checked={payload.title !== undefined}
            onChange={(checked) => setPayload({ ...payload, title: checked ? '' : undefined })}
            label={
              <LegacyHelpLabel title="Title">
                Enable to change the shipping method label displayed to customers on the order and
                subscription details pages
              </LegacyHelpLabel>
            }
          />
        }
        value={payload.title}
        onChange={(title) => setPayload({ ...payload, title: title })}
        autoComplete="off"
        disabled={payload.title === undefined}
        placeholder="Enter shipping method title"
      />

      <TextField
        label={
          <Checkbox
            checked={payload.price !== undefined}
            onChange={(checked) => setPayload({ ...payload, price: checked ? '0.00' : undefined })}
            label={
              <LegacyHelpLabel title="Price">
                Enable to change shipping price in the selected subscriptions.
                <br />
                <br />
                Note: The price will be set numerically as-is in the subscription's currency. Be
                careful about bulk-updating subscriptions in different currencies.
              </LegacyHelpLabel>
            }
          />
        }
        type="number"
        min={0}
        step={0.01}
        onChange={(price) => setPayload({ ...payload, price: price })}
        value={payload.price}
        autoComplete="off"
        disabled={payload.price === undefined}
        placeholder="0.00"
      />
    </FormLayout>
  </Modal.Section>
)
