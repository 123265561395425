// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorCircle_circle__p0ZtP {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  box-shadow: inset 0 0 0 1px #00000030;
  flex-shrink: 0;
}

.ColorCircle_circle__p0ZtP:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/common/ColorCircle.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,qCAAqC;EACrC,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".circle {\n  border-radius: 50%;\n  height: 2.5rem;\n  width: 2.5rem;\n  box-shadow: inset 0 0 0 1px #00000030;\n  flex-shrink: 0;\n}\n\n.circle:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": `ColorCircle_circle__p0ZtP`
};
export default ___CSS_LOADER_EXPORT___;
