import { LegacyStack, Select, Spinner } from '@shopify/polaris'
import React, { useCallback, useState } from 'react'
import useSWR from 'swr'

import { backendFetchResultData } from '../common/api'
import { formatMoney } from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { urlWithParams } from '../common/urls'

const allowedCaps = [500, 1000, 2000, 5000, 10000]

const capOptions = allowedCaps.map((x) => ({
  value: x.toString(),
  label: formatMoney(x * 100),
}))

interface Props {
  default: number
  newPlanId?: string
  onChange: (cap: number) => any
  updating?: boolean
}

export const CapSelect = (props: Props) => {
  const [cap, setCap] = useState<number>(
    allowedCaps.indexOf(props.default) !== -1 ? props.default : allowedCaps[0]
  )

  const updateCap = useCallback(
    (val: string) => {
      const newCap = parseInt(val)
      setCap(newCap)
      props.onChange(newCap)
    },
    [props]
  )

  const { data: maxSalesVolumeCents } = useSWR<string>(
    ['pricing-plan-max-sales-volume', cap, props.newPlanId],
    () =>
      backendFetchResultData(
        'GET',
        urlWithParams('/pricing-plans/max-sales-volume', {
          capCents: cap * 100,
          newPlanId: props.newPlanId,
        })
      )
  )

  return (
    <LegacyStack vertical spacing="tight">
      <p>
        <span>{props.updating ? 'New monthly' : 'Monthly'} cap for usage charges</span>
        <LegacyHelpIcon>
          Usage caps are required by Shopify and should be set based on your anticipated monthly
          sales volume. Exceeding your usage cap may result in delayed subscription billing. We
          suggest leaving some room for growth, but caps can be adjusted later.
        </LegacyHelpIcon>
      </p>
      <Select
        label=""
        options={capOptions}
        value={cap.toString()}
        onChange={updateCap}
        helpText={
          <LegacyStack alignment="center" spacing="extraTight">
            <span>Max sales volume: </span>
            <span>{maxSalesVolumeCents || <Spinner size="small" />}</span>
          </LegacyStack>
        }
      />
    </LegacyStack>
  )
}
