import { HorizontalStack, LegacyStack, Text } from '@shopify/polaris'
import useSWR from 'swr'

import { backendFetchResultData } from '../common/api'
import { formatMoney, queryString, useShopTimeZone, useSystemData } from '../common/helpers'
import { HelpIcon } from '../common/HelpIcon'
import { useAdvancedDashboardFilters } from './advanced-filters'
import { AdvancedDasbordFilterSelectors } from './AdvancedDasbordFilterSelectors'
import { AdvancedDashboardFilterLabels } from './AdvancedDashboardFilterLabels'
import { BasicStatCard } from './BasicStats'
import { ChartCard, ChartData } from './ChartCard'
import { Period, periodISODateRange, periodLabel, PeriodSelector } from './PeriodSelector'

interface RecentActivityResponse {
  newSubscriptions: ChartData
  finishedSubscriptions: ChartData
  newOrders: ChartData
  revenue: ChartData
  distinctOrderSubscriptions: number
}

const fetchRecentActivity = (_: any, query: string) => {
  const url = `/dashboard/recent-activity-charts?${query}`
  const data = backendFetchResultData<RecentActivityResponse>('GET', url)

  return data
}

interface RecentActivityProps {
  period: Period
  periodStart: string | null
  periodEnd: string | null
  setPeriod: (period: Period, period_start?: string, period_end?: string) => void
  showEnhancedReports: boolean
}

export const RecentActivity = ({
  period,
  periodStart,
  periodEnd,
  setPeriod,
  showEnhancedReports,
}: RecentActivityProps) => {
  const systemData = useSystemData()

  const tz = useShopTimeZone()
  const advancedFilters = useAdvancedDashboardFilters()

  const currency = systemData?.shopData.currency ?? 'USD'

  const { data: recentActivityData } = useSWR<RecentActivityResponse>(
    [
      'recent-activity-charts',
      queryString({
        ...advancedFilters.filterParams,
        ...periodISODateRange(period, periodStart, periodEnd, tz),
      }),
    ],
    fetchRecentActivity,
    {
      revalidateOnFocus: false,
    }
  )

  const filterLabel = `${periodLabel(period, null, null)} ${
    advancedFilters.isFiltered ? ' - Filtered' : ''
  }`

  return (
    <LegacyStack vertical>
      <LegacyStack alignment="baseline">
        <LegacyStack.Item fill>
          <Text variant="headingMd" as="h1">
            Recent activity
          </Text>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <HorizontalStack gap="2" blockAlign="center">
            <AdvancedDasbordFilterSelectors
              filter={advancedFilters}
              disabled={!showEnhancedReports}
            />
            <PeriodSelector
              period={period}
              periodStart={periodStart}
              periodEnd={periodEnd}
              onChange={setPeriod}
            />
            {!showEnhancedReports && (
              <HelpIcon>Upgrade to our Traction plan or higher to unlock premium filters.</HelpIcon>
            )}
          </HorizontalStack>
        </LegacyStack.Item>
      </LegacyStack>
      {advancedFilters.isFiltered && <AdvancedDashboardFilterLabels filter={advancedFilters} />}
      <LegacyStack distribution="fillEvenly">
        <ChartCard
          title="New subscriptions"
          tooltip="Number of new subscriptions created within the selected period"
          filterLabel={filterLabel}
          data={recentActivityData?.newSubscriptions}
        />
        <ChartCard
          title="Finished subscriptions"
          tooltip="Number of subscriptions finished within the selected period"
          filterLabel={filterLabel}
          data={recentActivityData?.finishedSubscriptions}
        />
      </LegacyStack>
      <LegacyStack distribution="fillEvenly">
        <ChartCard
          title="Revenue"
          tooltip="Total value of orders containing subscription items, created within the selected period"
          filterLabel={filterLabel}
          data={recentActivityData?.revenue}
          valueFormatter={(val: number) => formatMoney(val, currency)}
        />
        <ChartCard
          title="New orders"
          tooltip="Number of orders created within the selected period"
          filterLabel={filterLabel}
          data={recentActivityData?.newOrders}
        />
      </LegacyStack>
      {showEnhancedReports && (
        <LegacyStack distribution="fillEvenly">
          <BasicStatCard
            title="Revenue per subscription"
            filterLabel={filterLabel}
            loading={!recentActivityData}
            tooltip="Total revenue divided by the number of subscriptions generating that revenue within the selected period"
          >
            {recentActivityData?.revenue.total &&
              formatMoney(
                recentActivityData.revenue.total / recentActivityData.distinctOrderSubscriptions
              )}
            {!recentActivityData && (
              <Text color="subdued" as="p">
                &ndash;
              </Text>
            )}
          </BasicStatCard>
          <BasicStatCard
            title="Revenue per order"
            filterLabel={filterLabel}
            loading={!recentActivityData}
            tooltip="Total revenue divided by the number of orders generating that revenue within the selected period"
          >
            {recentActivityData?.revenue.total &&
              recentActivityData?.newOrders.total &&
              formatMoney(recentActivityData.revenue.total / recentActivityData.newOrders.total)}
            {!recentActivityData && (
              <Text color="subdued" as="p">
                &ndash;
              </Text>
            )}
          </BasicStatCard>
        </LegacyStack>
      )}
    </LegacyStack>
  )
}
