import { TextField, TextFieldProps } from '@shopify/polaris'

interface Props extends Omit<TextFieldProps, 'autoComplete' | 'label' | 'value' | 'onChange'> {
  label?: React.ReactNode
  value: number
  onChange: (val: number) => any
}

export const ProductQuantityField = ({ label, value, onChange, ...props }: Props) => (
  <TextField
    label={label ?? 'Quantity'}
    type="number"
    min={0}
    step={1}
    autoComplete="off"
    value={props.disabled ? '' : value.toString()}
    onChange={(val: string) => onChange(val ? parseInt(val) : 0)}
    {...props}
  />
)
