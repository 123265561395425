import {
  Icon,
  LegacyStack,
  Modal,
  ResourceList,
  Text,
  TextField,
  Thumbnail,
} from '@shopify/polaris'
import { SearchMinor } from '@shopify/polaris-icons'
import React, { useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'

import { Address } from '../delivery-details-edit-dialog/models'
import { backendFetchResult, GQLPaginator } from './api'
import { DialogActivator, queryString, useDebounced } from './helpers'
import { PaymentMethod } from './subscription-models'

export interface CustomerResult {
  id: string
  restId: number
  email: string
  displayName: string
  image: string | null
  addresses: Address[]
  paymentMethods: PaymentMethod[]
}

interface CustomerQuery {
  search: string
  results: CustomerResult[]
}

const searchCustomers = async (_: any, search: string): Promise<CustomerQuery> => {
  const query = queryString({ search })
  const res = await backendFetchResult<GQLPaginator<CustomerResult>>(
    'GET',
    `/customers/search?${query}`
  )
  const results = res.status === 'success' && res.data ? res.data.nodes : []

  return { search, results }
}

const renderCustomerResult = (
  result: CustomerResult,
  onSelect: (result: CustomerResult) => any
) => {
  return (
    <ResourceList.Item
      id={result.id}
      media={<Thumbnail source={result.image || ''} alt="" size="small" />}
      onClick={() => onSelect(result)}
      verticalAlignment="center"
    >
      <h3>
        <Text as="span" fontWeight="semibold">
          {result.displayName}
        </Text>
        <p>{result.email}</p>
      </h3>
    </ResourceList.Item>
  )
}

interface ModalContentProps {
  onSelect: (result: CustomerResult) => any
}

const ModalContent = ({ onSelect }: ModalContentProps) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounced(search, 500)

  const [query, setQuery] = useState<CustomerQuery>({ search: '___', results: [] })
  const swr = useSWR(['product-variants', debouncedSearch], searchCustomers)

  const myRenderCustomerResult = useCallback(
    (result: CustomerResult) => renderCustomerResult(result, onSelect),
    [onSelect]
  )

  useEffect(() => {
    if (swr.data && swr.data.search === search) {
      setQuery(swr.data)
    }
  }, [swr.data, search, setQuery])

  return (
    <>
      <div
        style={{ padding: '1.25rem', borderBottom: '0.0625rem solid var(--p-divider, #dfe3e8)' }}
      >
        <TextField
          label=""
          placeholder="Search"
          prefix={<Icon source={SearchMinor} />}
          value={search}
          onChange={setSearch}
          autoComplete="off"
        />
      </div>

      <ResourceList
        items={query.results}
        renderItem={myRenderCustomerResult}
        loading={query.search !== search}
        emptyState={
          <LegacyStack distribution="center">
            <p style={{ padding: '2rem' }}>No customers found</p>
          </LegacyStack>
        }
      />
    </>
  )
}

export const CustomerModal = ({
  activator,
  onSelect,
}: {
  activator: DialogActivator
  onSelect: (result: CustomerResult) => any
}) => (
  <Modal title="Select customer" open={activator.open} onClose={activator.close}>
    <ModalContent onSelect={onSelect} />
  </Modal>
)
