import { useMemo } from 'react'
import useSWR from 'swr'

import { backendFetchResultData, Paginator } from '../common/api'
import { BulkAction } from '../common/bulk-actions'
import { useQuery } from '../common/helpers'
import { SubscriptionContract } from '../common/subscription-models'
import * as urls from '../common/urls'

export interface SubscriptionListResponse {
  paginator: Paginator<SubscriptionContract>
  bulkActions: BulkAction[]
}

export interface SubscriptionDateFilter {
  created?: string
  created_min?: string
  created_max?: string
  updated?: string
  updated_min?: string
  updated_max?: string
  paused?: string
  paused_min?: string
  paused_max?: string
  finished?: string
  finished_min?: string
  finished_max?: string
  next_order?: string
  next_order_min?: string
  next_order_max?: string
  finishing?: string
  finishing_min?: string
  finishing_max?: string
  next_attempt?: string
  next_attempt_min?: string
  next_attempt_max?: string
}

export interface SubscriptionFilter extends SubscriptionDateFilter {
  ids?: string[]
  status?: string
  pause_reason?: string
  search?: string
  customer_id?: number
  selling_plan_id?: number
  product_id?: number
  product_sku?: string
  variant_id?: number
  variant_removed?: number
  currency?: string
  page?: number
  retrying?: string
  dunning_retries?: string
  sort?: string
}

export const useSubscriptionFilter = (): SubscriptionFilter => {
  const query = useQuery()

  const page = parseInt(query.get('page') || '1')
  const sort = query.get('sort') || undefined
  const status = query.get('status') ?? undefined
  const retrying = query.get('retrying') ?? undefined
  const dunning_retries = query.get('dunning_retries') ?? undefined
  const pause_reason = query.get('pause_reason') ?? undefined
  const search = query.get('search') || undefined
  const customer_id = query.get('customer_id')
    ? parseInt(query.get('customer_id') ?? '0')
    : undefined
  const product_id = query.get('product_id') ? parseInt(query.get('product_id') ?? '0') : undefined
  const product_sku = query.get('product_sku') || undefined
  const variant_id = query.get('variant_id') ? parseInt(query.get('variant_id') ?? '0') : undefined
  const variant_removed = query.has('variant_removed') ? 1 : undefined
  const selling_plan_id = query.get('selling_plan_id')
    ? parseInt(query.get('selling_plan_id') ?? '0')
    : undefined
  const currency = query.get('currency') || undefined
  const created = query.get('created') || undefined
  const created_min = query.get('created_min') || undefined
  const created_max = query.get('created_max') || undefined
  const updated = query.get('updated') || undefined
  const updated_min = query.get('updated_min') || undefined
  const updated_max = query.get('updated_max') || undefined
  const paused = query.get('paused') || undefined
  const paused_min = query.get('paused_min') || undefined
  const paused_max = query.get('paused_max') || undefined
  const finished = query.get('finished') || undefined
  const finished_min = query.get('finished_min') || undefined
  const finished_max = query.get('finished_max') || undefined
  const next_order = query.get('next_order') || undefined
  const next_order_min = query.get('next_order_min') || undefined
  const next_order_max = query.get('next_order_max') || undefined
  const finishing = query.get('finishing') || undefined
  const finishing_min = query.get('finishing_min') || undefined
  const finishing_max = query.get('finishing_max') || undefined
  const next_attempt = query.get('next_attempt') || undefined
  const next_attempt_min = query.get('next_attempt_min') || undefined
  const next_attempt_max = query.get('next_attempt_max') || undefined

  return useMemo(
    () => ({
      page,
      sort,
      status,
      retrying,
      dunning_retries,
      pause_reason,
      search,
      customer_id,
      product_id,
      product_sku,
      variant_id,
      variant_removed,
      selling_plan_id,
      currency,
      created,
      created_min,
      created_max,
      updated,
      updated_min,
      updated_max,
      paused,
      paused_min,
      paused_max,
      finished,
      finished_min,
      finished_max,
      next_order,
      next_order_min,
      next_order_max,
      finishing,
      finishing_min,
      finishing_max,
      next_attempt,
      next_attempt_min,
      next_attempt_max,
    }),
    [
      page,
      sort,
      status,
      retrying,
      dunning_retries,
      pause_reason,
      search,
      customer_id,
      product_id,
      product_sku,
      variant_id,
      variant_removed,
      selling_plan_id,
      currency,
      created,
      created_min,
      created_max,
      updated,
      updated_min,
      updated_max,
      paused,
      paused_min,
      paused_max,
      finished,
      finished_min,
      finished_max,
      next_order,
      next_order_min,
      next_order_max,
      finishing,
      finishing_min,
      finishing_max,
      next_attempt,
      next_attempt_min,
      next_attempt_max,
    ]
  )
}

export const fetchSubscriptionList = (_: string, filter: SubscriptionFilter) =>
  backendFetchResultData<SubscriptionListResponse>(
    'GET',
    urls.urlWithParams('/subscriptions', filter)
  )

export const useSubscriptionListSWR = (filter: SubscriptionFilter, polling: boolean) =>
  useSWR<SubscriptionListResponse>(['subscriptions', filter], fetchSubscriptionList, {
    refreshInterval: polling ? 7000 : undefined,
  })
