// eslint-disable-next-line simple-import-sort/imports
import FullCalendar, {
  EventClickArg,
  EventContentArg,
  EventInput,
  EventSourceFunc,
  MountArg,
} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { AdjustMinor } from '@shopify/polaris-icons'

import {
  Text,
  Pagination,
  Spinner,
  LegacyStack,
  HorizontalStack,
  Card,
  Popover,
  OptionList,
  Button,
} from '@shopify/polaris'
import { DateTime } from 'luxon'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { backendFetchResult } from '../common/api'
import {
  FormattedDate,
  queryString,
  shouldHandleLinkClickEvent,
  useShopTimeZone,
  useToggle,
} from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import * as urls from '../common/urls'
import styles from './PaymentSchedule.module.css'
import { SettingsContext } from '../settings-page/api'

const fetchEvents = async (
  info: any,
  success: any,
  failure: any,
  eventType: CalendarEventsType
): Promise<EventInput[]> => {
  const start = DateTime.fromISO(info.startStr)
    .setZone(info.timeZone, { keepLocalTime: true })
    .toISO({ suppressMilliseconds: true })

  const end = DateTime.fromISO(info.endStr)
    .setZone(info.timeZone, { keepLocalTime: true })
    .toISO({ suppressMilliseconds: true })

  const query = queryString({ start, end, type: eventType })

  const res = await backendFetchResult<EventInput[]>(
    'GET',
    `/dashboard/payment-calendar-events?${query}`
  )

  if (res.status !== 'success') {
    return Promise.reject(res.message ?? 'Unknown error')
  }

  return res.data ?? []
}

const handleEventDidMount = (arg: MountArg<EventContentArg>) => {
  arg.el.innerHTML = arg.event.extendedProps.description
  arg.el.title = arg.el.innerText
}

interface CalendarProps {
  eventTypes: CalendarEventsType[]
  setEventTypes: (eventTypes: CalendarEventsType[]) => void
  date: string
  setDate: (date: string) => void
}

const Calendar = (props: CalendarProps) => {
  const userSettings = useContext(SettingsContext)
  const history = useHistory()
  const calendarRef = useRef<FullCalendar>(null)
  const setDate = props.setDate
  const timeZone = useShopTimeZone()

  const [loading, setLoading] = useState(true)

  const fetchPaymentEvents: EventSourceFunc = useCallback(
    async (info, success, failure): Promise<EventInput[]> =>
      fetchEvents(info, success, failure, 'payments'),
    []
  )

  const fetchFulfillmentEvents: EventSourceFunc = useCallback(
    async (info, success, failure): Promise<EventInput[]> =>
      fetchEvents(info, success, failure, 'fulfillments'),
    []
  )

  useEffect(() => {
    calendarRef.current?.getApi().refetchEvents()
  }, [userSettings?.analytics_start_date])

  const eventSources = useMemo(
    () =>
      props.eventTypes.map((eventType) =>
        eventType === 'payments' ? fetchPaymentEvents : fetchFulfillmentEvents
      ),
    [props.eventTypes, fetchPaymentEvents, fetchFulfillmentEvents]
  )

  const eventUrl = useCallback(
    (date: string, status: string, eventType: Omit<CalendarEventsType, 'all'>) => {
      const params = {
        date,
        status,
        page: 1,
        event_types: props.eventTypes.join(','),
      }

      if (eventType === 'payments') {
        return urls.dashboardPaymentsUrl(params)
      }

      return urls.dashboardFulfillmentsUrl(params)
    },
    [props.eventTypes]
  )

  const handleEventClick = useCallback(
    (arg: EventClickArg) => {
      if (shouldHandleLinkClickEvent(arg.jsEvent)) {
        arg.jsEvent.preventDefault()
        history.push(
          eventUrl(
            arg.event.extendedProps.filterDate,
            arg.event.extendedProps.status,
            arg.event.extendedProps.eventType
          )
        )
      }
    },
    [history, eventUrl]
  )

  const calendarChanged = useCallback(
    (_: any) => {
      const fcDate = calendarRef.current?.getApi()?.getDate()

      if (!fcDate) {
        return
      }

      const isoDate = DateTime.fromJSDate(fcDate, { zone: 'UTC' }).toISODate()

      setDate(isoDate)
    },
    [calendarRef, setDate]
  )

  return (
    <LegacyStack vertical>
      <LegacyStack alignment="center">
        <LegacyStack.Item fill>
          <Text variant="headingMd" as="h1">
            <LegacyStack alignment="center">
              <FormattedDate
                date={DateTime.fromISO(props.date, { zone: timeZone }).toJSDate()}
                format={{ month: 'long', year: 'numeric' }}
              />
              {loading && (
                <span className={styles.spinnerWrapper}>
                  <Spinner size="small" />
                </span>
              )}
            </LegacyStack>
          </Text>
        </LegacyStack.Item>
        <div className={styles.paginationWrapper}>
          <Pagination
            hasPrevious={true}
            hasNext={true}
            onPrevious={() => {
              calendarRef.current?.getApi().prev()
            }}
            onNext={() => {
              calendarRef.current?.getApi().next()
            }}
          />
        </div>
      </LegacyStack>
      <FullCalendar
        viewClassNames={styles.calendarWrapper}
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={false}
        height="auto"
        dayMaxEventRows={4}
        stickyHeaderDates={false}
        timeZone={timeZone}
        eventClick={handleEventClick}
        datesSet={calendarChanged}
        eventSources={eventSources}
        eventsSet={calendarChanged}
        eventDidMount={handleEventDidMount}
        eventOrder="sortKey"
        initialDate={props.date}
        loading={setLoading}
      />
    </LegacyStack>
  )
}

export type CalendarEventsType = 'payments' | 'fulfillments'

const calendarEventsTypeLabels: Record<CalendarEventsType, string> = {
  payments: 'Payments',
  fulfillments: 'Fulfillments',
}

export const PaymentSchedule = (props: CalendarProps) => {
  const [active, toggleActive] = useToggle(false)

  const activator = (
    <Button disclosure onClick={toggleActive} icon={AdjustMinor} size="slim">
      {props.eventTypes.length > 0
        ? props.eventTypes
            .sort((a, b) => (a === 'fulfillments' ? 1 : -1))
            .map((type) => calendarEventsTypeLabels[type])
            .join(' & ')
        : 'Select events type'}
    </Button>
  )

  return (
    <LegacyStack vertical>
      <LegacyStack.Item fill>
        <HorizontalStack blockAlign="center">
          <LegacyStack.Item fill>
            <Text variant="headingMd" as="h1">
              Payments and fulfillments schedule{' '}
              <LegacyHelpIcon>
                For each subscription, only one upcoming payment and its associated fulfillment
                orders are shown, in addition to existing payments and fulfillment orders.
              </LegacyHelpIcon>
            </Text>
          </LegacyStack.Item>
          <Popover active={active} activator={activator} onClose={toggleActive}>
            <OptionList
              allowMultiple={true}
              onChange={(values) => props.setEventTypes(values as CalendarEventsType[])}
              selected={props.eventTypes}
              options={Object.keys(calendarEventsTypeLabels).map((type) => ({
                value: type,
                label: calendarEventsTypeLabels[type as CalendarEventsType],
              }))}
            />
          </Popover>
        </HorizontalStack>
      </LegacyStack.Item>
      <Card>
        <Calendar {...props} />
      </Card>
    </LegacyStack>
  )
}
