import { ContextualSaveBar, Form, Layout } from '@shopify/polaris'
import { Formik } from 'formik'
import { useCallback, useState } from 'react'

import { Result } from '../common/api'
import { ResultToastOrBanner, ShowIntercomButton } from '../common/helpers'
import * as urls from '../common/urls'
import { createSellingPlanGroup, updateSellingPlanGroup } from './api'
import {
  parseSellingPlanGroupResponse,
  SellingPlanGroupFormData,
  sellingPlanGroupFormDataWithDefaultValues,
  validateSellingPlanGroupFormData,
} from './models-form'
import { sellingPlanGroupFormDataToInput, sellingPlanGroupFormDataToPWData } from './models-input'
import { OverviewCard } from './OverviewCard'
import { VariantCard } from './VariantCard'

export const SellingPlanGroupForm = ({
  initialValues,
  afterSave,
}: {
  initialValues: SellingPlanGroupFormData
  afterSave: (groupId: string) => any
}) => {
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const handleSubmit = useCallback(
    async (values: SellingPlanGroupFormData, actions: any) => {
      const id = values.id
      const fixedValues = sellingPlanGroupFormDataWithDefaultValues(values)

      const validationError = validateInput(fixedValues)

      if (validationError) {
        setSaveResult({
          status: 'error',
          message: validationError,
          temporary: false,
        })
        window.scrollTo(0, 0)
        return
      }

      const input = sellingPlanGroupFormDataToInput(fixedValues)

      const pwData = sellingPlanGroupFormDataToPWData(fixedValues)
      const res = id
        ? await updateSellingPlanGroup(id, input, pwData)
        : await createSellingPlanGroup(input, pwData)

      actions.setSubmitting(false)

      setSaveResult(res)

      if (res.status === 'success' && res.data) {
        afterSave(res.data.gid)
        actions.resetForm({ values: parseSellingPlanGroupResponse(res.data) })
      } else {
        window.scrollTo(0, 0)
      }
    },
    [afterSave]
  )

  const validateInput = (formData: SellingPlanGroupFormData) => {
    const invalidPlan = formData.sellingPlans.find(
      (plan) => plan.type === 'specific-dates' && plan.deliveryPolicy.recurring.anchors.length === 0
    )

    if (invalidPlan) {
      return `${invalidPlan.name} plan must have at least one anchor date`
    }

    return null
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validate={validateSellingPlanGroupFormData}
        validateOnChange={true}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Layout.Section>
              <ResultToastOrBanner result={saveResult} setResult={setSaveResult} />

              <OverviewCard />

              {formik.values.sellingPlans.map((_plan: any, index: number) => (
                <VariantCard key={index} index={index} />
              ))}
            </Layout.Section>

            {(formik.dirty || !initialValues.id) && (
              <ContextualSaveBar
                message="Unsaved changes"
                alignContentFlush={true}
                secondaryMenu={
                  <ShowIntercomButton
                    message={
                      initialValues.id
                        ? 'I have a question about editing selling plan groups.'
                        : 'I have a question about creating selling plan groups.'
                    }
                  >
                    Chat with us
                  </ShowIntercomButton>
                }
                saveAction={{
                  onAction: formik.handleSubmit,
                  loading: formik.isSubmitting,
                  disabled: !formik.isValid,
                }}
                discardAction={{
                  onAction: initialValues.id ? formik.resetForm : undefined,
                  url: initialValues.id ? undefined : urls.plansUrl,
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
