import {
  Banner,
  Button,
  ComplexAction,
  ContextualSaveBar,
  FormLayout,
  Layout,
  LegacyCard,
  Page,
  PageActions,
  Select,
  SelectOption,
  TextField,
} from '@shopify/polaris'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { backendFetchResult, Result } from '../common/api'
import { currencyOptions } from '../common/currencies'
import { CustomerModal, CustomerResult } from '../common/CustomerModal'
import { DateTimePicker } from '../common/DateTimePicker'
import { ResultToastOrBanner, ShowIntercomButton, useDialogActivator } from '../common/helpers'
import { SellingPlanInterval } from '../common/plan-models'
import { useShopCurrency } from '../common/Price'
import { ProductQuantityField } from '../common/ProductQuantityField'
import { ProductVariantModal, ProductVariantResult } from '../common/ProductVariantModal'
import { IntervalCountField, IntervalField } from '../common/ScheduleFields'
import { SubscriptionContract } from '../common/subscription-models'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { Address } from '../delivery-details-edit-dialog/models'
import { paymentMethodOptions as makePaymentMethodOptions } from '../subscription-page/PaymentMethodDialog'

const windowWithCache = window as unknown as {
  paywhirlSubscriptionCreateCache?: {
    currencyCode: string
    variant: ProductVariantResult | null
    currentPrice: string
    quantity: number
    interval: SellingPlanInterval
    intervalCount: number
    nextBillingDate: string | null
    deliveryPrice: string
  }
}

const makeAddressOptions = (addresses: Address[]): SelectOption[] =>
  addresses.map((adr: Address, idx: number) => ({
    label: (adr.formatted ?? []).join(', '),
    value: `${idx}`,
  }))

// Skip unnecessary fields and rename countryCodeV2 to countryCode
const addressInputFromAddress = (address: Address): Address => ({
  address1: address.address1,
  address2: address.address2,
  city: address.city,
  company: address.company,
  countryCode: address.countryCodeV2,
  firstName: address.firstName,
  lastName: address.lastName,
  phone: address.phone,
  provinceCode: address.provinceCode,
  zip: address.zip,
})

export const SubscriptionCreatePage = () => {
  const cache = windowWithCache.paywhirlSubscriptionCreateCache

  const shopCurrency = useShopCurrency()
  const [currencyCode, setCurrencyCode] = useState<string>(cache?.currencyCode ?? shopCurrency)

  const [customer, setCustomer] = useState<CustomerResult | null>(null)
  const customerDialogActivator = useDialogActivator()

  const [variant, setVariant] = useState<ProductVariantResult | null>(cache?.variant ?? null)
  const variantDialogActivator = useDialogActivator()
  const [quantity, setQuantity] = useState<number>(cache?.quantity ?? 1)
  const [currentPrice, setCurrentPrice] = useState<string>(cache?.currentPrice ?? '0')

  const [paymentMethodOptions, setPaymentMethodOptions] = useState<SelectOption[]>([])
  const [paymentMethodId, setPaymentMethodId] = useState<string>('')
  const [nextBillingDate, setNextBillingDate] = useState<string>(
    cache?.nextBillingDate ?? new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString()
  )
  const [intervalCount, setIntervalCount] = useState<number>(cache?.intervalCount ?? 1)
  const [interval, setInterval] = useState<SellingPlanInterval>(
    cache?.interval ?? SellingPlanInterval.MONTH
  )

  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([])
  const [addressIndex, setAddressIndex] = useState<number>(0)
  const [deliveryPrice, setDeliveryPrice] = useState<string>(cache?.deliveryPrice ?? '0')

  const [saving, setSaving] = useState(false)
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const history = useHistory()

  useEffect(() => {
    windowWithCache.paywhirlSubscriptionCreateCache = {
      currencyCode,
      nextBillingDate,
      interval,
      intervalCount,
      variant,
      currentPrice,
      quantity,
      deliveryPrice,
    }
  }, [
    currencyCode,
    nextBillingDate,
    interval,
    intervalCount,
    variant,
    currentPrice,
    quantity,
    deliveryPrice,
  ])

  useEffect(() => {
    setSaveResult(null)
  }, [quantity, currentPrice, nextBillingDate, addressIndex, deliveryPrice])

  const onCustomerSelect = useCallback(
    (result: CustomerResult) => {
      setCustomer(result)
      setAddressOptions(makeAddressOptions(result.addresses))
      setAddressIndex(0)
      setPaymentMethodOptions(makePaymentMethodOptions(result.paymentMethods))
      setPaymentMethodId(result.paymentMethods[0]?.id ?? '')
      customerDialogActivator.close()
    },
    [customerDialogActivator]
  )

  const onVariantSelect = useCallback(
    (result: ProductVariantResult) => {
      setVariant(result)
      setCurrentPrice(result.price)
      variantDialogActivator.close()
    },
    [variantDialogActivator]
  )

  const inputData = useMemo(() => {
    const address = customer?.addresses?.[addressIndex] ?? null

    if (!customer || !paymentMethodId || !address || !variant) {
      return null
    }

    const draftInput = {
      customerId: customer.id,
      currencyCode,
      nextBillingDate,
      contract: {
        status: 'PAUSED',
        nextBillingDate,
        paymentMethodId,
        billingPolicy: { interval, intervalCount },
        deliveryPolicy: { interval, intervalCount },
        deliveryPrice,
        deliveryMethod: {
          shipping: {
            address: addressInputFromAddress(address),
          },
        },
      },
    }

    const lineInputs = [
      {
        productVariantId: variant.id,
        currentPrice,
        quantity,
      },
    ]

    return { draftInput, lineInputs }
  }, [
    currencyCode,
    customer,
    variant,
    currentPrice,
    quantity,
    paymentMethodId,
    nextBillingDate,
    interval,
    intervalCount,
    addressIndex,
    deliveryPrice,
  ])

  const onCreate = useCallback(async () => {
    if (!inputData) {
      return
    }

    setSaving(true)

    const body = JSON.stringify(inputData)

    const path = `/subscriptions`
    const res = await backendFetchResult<SubscriptionContract>('POST', path, { body })

    setSaving(false)
    setSaveResult(res)

    if (res.status === 'success' && res.data) {
      history.push(urls.subscriptionUrl(res.data.gid))
    }
  }, [inputData, history])

  const saveAction: ComplexAction = useMemo(
    () => ({
      content: 'Create',
      onAction: onCreate,
      loading: saving,
      disabled: !inputData,
    }),
    [onCreate, saving, inputData]
  )

  const discardAction: ComplexAction = useMemo(
    () => ({
      content: 'Cancel',
      url: urls.subscriptionsUrl(),
    }),
    []
  )

  return (
    <>
      <Tabs selected={TabIndex.Subscriptions} />
      <Page
        title="Create subscription"
        backAction={{ content: 'Subscriptions', url: urls.subscriptionsUrl() }}
      >
        <Layout>
          <Layout.Section>
            <Banner status="warning">
              The new subscription will be created <b>inactive</b>, and won't be visible to the
              customer until it's activated
            </Banner>
            <ResultToastOrBanner
              result={saveResult}
              setResult={setSaveResult}
              bottomMargin={false}
            />
          </Layout.Section>
          <Layout.AnnotatedSection
            title="Basic settings"
            description="Note: basic settings cannot be edited later"
          >
            <LegacyCard sectioned>
              <FormLayout>
                <Select
                  label="Currency"
                  options={currencyOptions}
                  value={currencyCode}
                  onChange={setCurrencyCode}
                />
                <TextField
                  label="Customer"
                  readOnly={true}
                  value={customer?.displayName ?? ''}
                  connectedRight={<Button onClick={customerDialogActivator.show}>Select</Button>}
                  autoComplete="off"
                  error={
                    customer
                      ? customer.paymentMethods.length === 0
                        ? "This customer doesn't have active payment methods"
                        : customer.addresses.length === 0
                        ? "This customer doesn't have a valid address"
                        : undefined
                      : undefined
                  }
                />
              </FormLayout>
            </LegacyCard>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="First item"
            description="You can add additional product(s) and customize the subscription further after it's drafted, in the next step."
          >
            <LegacyCard sectioned>
              <FormLayout>
                <TextField
                  label="Product variant"
                  readOnly={true}
                  value={variant?.title ?? ''}
                  connectedRight={<Button onClick={variantDialogActivator.show}>Select</Button>}
                  autoComplete="off"
                />

                <ProductQuantityField
                  value={quantity}
                  onChange={setQuantity}
                  error={saveResult?.field === 'product.quantity' ? saveResult.message : false}
                />

                <TextField
                  label="Price"
                  type="number"
                  min={0}
                  step={0.01}
                  prefix={currencyCode}
                  value={currentPrice}
                  onChange={setCurrentPrice}
                  autoComplete="off"
                  error={saveResult?.field === 'product.currentPrice' ? saveResult.message : false}
                />
              </FormLayout>
            </LegacyCard>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Payments"
            description="Select the payment method for the subscription. Customer must have an existing card on file to create a subscription manually."
          >
            <LegacyCard sectioned>
              <FormLayout>
                <Select
                  label="Payment method"
                  options={paymentMethodOptions}
                  value={paymentMethodId}
                  onChange={setPaymentMethodId}
                />

                <DateTimePicker
                  label="First order"
                  initial={nextBillingDate}
                  onChange={setNextBillingDate}
                  error={saveResult?.field === 'nextBillingDate' ? saveResult.message : null}
                />

                <FormLayout.Group>
                  <IntervalCountField
                    label="Interval"
                    value={intervalCount}
                    onChange={setIntervalCount}
                  />
                  <IntervalField label="  " value={interval} onChange={setInterval} />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Shipping"
            description="Select a shipping address and specify the price of shipping for subscription orders."
          >
            <LegacyCard sectioned>
              <FormLayout>
                <Select
                  label="Address"
                  options={addressOptions}
                  value={addressIndex.toString()}
                  onChange={(val: string) => setAddressIndex(parseInt(val) || 0)}
                  error={saveResult?.field?.match('address.') ? 'Invalid address' : false}
                />
                <TextField
                  label="Price"
                  type="number"
                  min={0}
                  step={0.01}
                  prefix={currencyCode}
                  value={deliveryPrice}
                  onChange={(val: string) => setDeliveryPrice(val)}
                  autoComplete="off"
                  error={saveResult?.field === 'deliveryPrice' ? saveResult.message : false}
                />
              </FormLayout>
            </LegacyCard>
          </Layout.AnnotatedSection>

          <Layout.Section>
            <PageActions primaryAction={saveAction} secondaryActions={[discardAction]} />
          </Layout.Section>
        </Layout>
        <br />
        <br />
        <ContextualSaveBar
          message="Unsaved subscription"
          alignContentFlush={true}
          saveAction={saveAction}
          discardAction={discardAction}
          secondaryMenu={
            <ShowIntercomButton
              message={'I have a question about creating a subscription manually.'}
            >
              Chat with us
            </ShowIntercomButton>
          }
        />
        <CustomerModal activator={customerDialogActivator} onSelect={onCustomerSelect} />
        <ProductVariantModal
          currency={currencyCode}
          open={variantDialogActivator.open}
          onClose={variantDialogActivator.close}
          onSelect={onVariantSelect}
        />
      </Page>
    </>
  )
}
