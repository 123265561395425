import {
  Layout,
  LegacyCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage as PolarisSkeletonPage,
  TextContainer,
} from '@shopify/polaris'
import React from 'react'

import { VariablesCard } from './VariablesCard'

interface Props {
  title: string
}

export const SkeletonPage = (props: Props) => (
  <PolarisSkeletonPage title={props.title} backAction>
    <Layout>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
      <Layout.Section secondary>
        <VariablesCard />
      </Layout.Section>
    </Layout>
  </PolarisSkeletonPage>
)
