import { LegacyStack, Modal, Select, Spinner, TextContainer } from '@shopify/polaris'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWR, { mutate } from 'swr'

import { backendFetchResult, GQLPaginator, Result } from '../common/api'
import {
  creditCardLastDigits,
  formatPaymentMethodBrand,
  queryString,
  ResultBanner,
  ResultToast,
  suffix,
} from '../common/helpers'
import {
  CustomerCreditCard,
  CustomerPaypalBillingAgreement,
  PaymentMethod,
  SubscriptionContract,
} from '../common/subscription-models'

const listPaymentMethods = async (_: any, gid: string): Promise<PaymentMethod[]> => {
  const query = queryString({ gid })
  const res = await backendFetchResult<GQLPaginator<PaymentMethod>>(
    'GET',
    `/customers/payment-methods?${query}`
  )
  return res.status === 'success' && res.data ? res.data.nodes : []
}

const formatCreditCard = (card: CustomerCreditCard): string => {
  const title = formatPaymentMethodBrand(card.brand)
  const lastDigits = creditCardLastDigits(card)
  const expiryMonth = card.expiryMonth.toString().padStart(2, '0')
  const expiryYear = suffix(card.expiryYear.toString(), 2)

  return `${title} ending with ${lastDigits} (expires ${expiryMonth}/${expiryYear})`
}

const formatPayPal = (agreement: CustomerPaypalBillingAgreement): string => {
  const email = agreement.paypalAccountEmail ? ` (${agreement.paypalAccountEmail})` : ''

  return `PayPal${email}`
}

interface SelectOption {
  value: string
  label: string
  disabled?: boolean
}

export const paymentMethodOptions = (methods: PaymentMethod[]): SelectOption[] => {
  let ret = [{ value: '', label: 'Select a payment method', disabled: true } as SelectOption]

  for (const method of methods) {
    const instrument = method.instrument
    let label: string | null = null

    if (!instrument) {
      continue
    }

    if (instrument && (!instrument.__typename || instrument.__typename === 'CustomerCreditCard')) {
      label = formatCreditCard(instrument as CustomerCreditCard)
    }

    if (instrument && instrument.__typename === 'CustomerPaypalBillingAgreement') {
      label = formatPayPal(instrument as CustomerPaypalBillingAgreement)
    }

    if (instrument && instrument.__typename === 'CustomerShopPayAgreement') {
      label = formatCreditCard({ ...instrument, brand: 'shop_pay' } as CustomerCreditCard)
    }

    if (label) {
      ret.push({ value: method.id, label } as SelectOption)
    }
  }

  return ret
}

interface PaymentMethodListProps {
  sc: SubscriptionContract
  selected: string
  onChange: (gid: string) => any
}

const PaymentMethodField = (props: PaymentMethodListProps) => {
  const swr = useSWR(['customer-payment-methods', props.sc.gql_customer_gid], listPaymentMethods)

  const options = useMemo(() => (swr.data ? paymentMethodOptions(swr.data) : []), [swr.data])

  useEffect(() => {
    let optionExists = true

    if (swr.data) {
      optionExists = options.filter((option) => option.value === props.selected).length > 0
    }

    if (!optionExists) {
      props.onChange('')
    }
  }, [swr.data, options, props])

  if (swr.isValidating) {
    return (
      <LegacyStack distribution="center">
        <Spinner />
      </LegacyStack>
    )
  }

  if (!swr.data || swr.data.length === 0) {
    return (
      <LegacyStack distribution="center">
        <TextContainer>No active payment methods found</TextContainer>
      </LegacyStack>
    )
  }

  return (
    <Select
      label="New payment method"
      options={options}
      value={props.selected}
      onChange={props.onChange}
    />
  )
}

interface Props {
  sc: SubscriptionContract
  open: boolean
  close: () => any
}

export const PaymentMethodDialog = ({ open, close, sc }: Props) => {
  const [saving, setSaving] = useState(false)
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const prevId = sc.gql_data.customerPaymentMethod?.id || ''
  const [newId, setNewId] = useState<string>(prevId)

  const save = useCallback(async () => {
    setSaving(true)

    const opts = { body: JSON.stringify({ input: { paymentMethodId: newId } }) }
    const resp = await backendFetchResult('PUT', `/subscriptions/${sc.rest_id}`, opts)

    setSaving(false)
    mutate(['subscription-contract', `${sc.rest_id}`])
    setSaveResult(resp)

    if (resp.status === 'success') {
      close()
    }
  }, [close, setSaving, sc, newId])

  return (
    <>
      <Modal
        open={open}
        title="Change payment method"
        onClose={close}
        primaryAction={{
          content: 'Save',
          onAction: save,
          loading: saving,
          disabled: !newId,
        }}
        secondaryActions={[{ content: 'Cancel', onAction: close }]}
        sectioned
      >
        <ResultBanner result={saveResult} setResult={setSaveResult} />
        <PaymentMethodField sc={sc} selected={newId} onChange={setNewId} />
      </Modal>
      <ResultToast result={saveResult} setResult={setSaveResult} />
    </>
  )
}
