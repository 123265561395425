import { Layout, LegacyCard, Loading, MenuActionDescriptor, Page } from '@shopify/polaris'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'

import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { ApiActionDialog } from '../common/ApiActionDialog'
import { Footer } from '../common/Footer'
import { useDialogActivator, useShopPermission } from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { MailType } from '../settings-page/NotificationsCard'
import { MailSettings, useMailSettings } from './api'
import { Form } from './Form'
import { PreviewDialog } from './PreviewDialog'
import { SkeletonPage } from './SkeletonPage'
import { VariablesCard } from './VariablesCard'

const pageTitles: Record<MailType, string> = {
  'new-subscription': 'New subscription',
  'subscription-expired': 'Subscription expired (completed)',
  'subscription-paused': 'Subscription paused',
  'subscription-paused-low-inventory': 'Subscription paused (low inventory)',
  'subscription-resumed': 'Subscription resumed',
  'subscription-edited': 'Subscription edited',
  'subscription-cancelled': 'Subscription cancelled',
  'subscription-reactivated': 'Subscription reactivated',
  'subscription-retrying': 'Payment failed (retrying)',
  'subscription-failed': 'Payment failed (last attempt - cancel subscription)',
  'payment-failed-skip-order': 'Payment failed (last attempt - skip order)',
  'payment-failed-pause-subscription': 'Payment failed (last attempt - pause subscription)',
  'order-skipped': 'Order skipped',
  'order-skipped-low-inventory': 'Order skipped (low inventory)',
  'payment-notification': 'Payment reminder',
  'annual-reminder': 'Annual subscription reminder',
  'address-changed': 'Address changed',
}

const LoadedPage = ({
  mailType,
  initialValues,
}: {
  mailType: MailType
  initialValues: MailSettings
}) => {
  const [previewDialogVisible, setPreviewDialogVisible] = useState(false)
  const sendActionState = useApiActionState({
    url: `/settings/mail-settings/${mailType}/sample`,
    bannerPosition: 'external',
  })
  const revertDialogActivator = useDialogActivator()
  const customEmailTemplatesEnabled = useShopPermission('customEmailTemplatesEnabled')

  const actions = [
    { content: 'Send test email', onAction: sendActionState.run },
    { content: 'Preview', onAction: () => setPreviewDialogVisible(true) },
    customEmailTemplatesEnabled
      ? {
          content: 'Revert to default',
          onAction: revertDialogActivator.show,
          destructive: true,
        }
      : null,
  ].filter((a) => a) as MenuActionDescriptor[]

  return (
    <Page
      title={pageTitles[mailType]}
      secondaryActions={actions}
      backAction={{ content: 'Settings', url: urls.settingsUrl() }}
    >
      <Layout>
        {sendActionState.banner && sendActionState.message && (
          <Layout.Section>
            <ApiActionBanner state={sendActionState} bottomMargin={false} />
          </Layout.Section>
        )}
        <Layout.Section>
          <LegacyCard sectioned>
            <Form mailType={mailType} initialValues={initialValues} />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <VariablesCard />
        </Layout.Section>
        <Layout.Section>
          <Footer />
        </Layout.Section>
      </Layout>
      <PreviewDialog
        mailType={mailType}
        open={previewDialogVisible}
        onClose={() => setPreviewDialogVisible(false)}
      />
      <ApiAction state={sendActionState} />
      <ApiActionDialog
        activator={revertDialogActivator}
        onSuccess={() => {
          mutate(['mail-settings', mailType])
          mutate(['mail-sample', mailType])
        }}
        url={`/settings/mail-settings/${mailType}`}
        method="DELETE"
        title="Revert email template to default?"
        message="Are you sure you want to revert this template to the default settings? This action cannot be reversed and any changes you have made to the subject or content will be lost."
        primaryButtonTitle="Revert to default"
        destructive={true}
      />
    </Page>
  )
}

export const MailSettingsPage = () => {
  const { mailType } = useParams<{ mailType: MailType }>()
  const { data, error, isValidating } = useMailSettings(mailType)

  return (
    <>
      {isValidating && <Loading />}
      <Tabs selected={TabIndex.Settings} />

      {error && <LoadErrorPage />}
      {!error && !data && <SkeletonPage title={pageTitles[mailType]} />}
      {!error && data && <LoadedPage mailType={mailType} initialValues={data} />}
    </>
  )
}
