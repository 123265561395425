import { Button, InlineError, LegacyStack, Tag, Text, TextField } from '@shopify/polaris'
import { PlusMinor } from '@shopify/polaris-icons'
import React, { useCallback, useState } from 'react'

import { useDialogActivator } from '../common/helpers'
import {
  WorkflowAction,
  workflowActionLabels,
  WorkflowActionTagType,
} from '../common/workflow-models'
import { WorkflowVariablesModal } from './WorkflowVariablesModal'

interface TagsActionProps {
  actionType: WorkflowActionTagType
  tags: string[]
  disabled?: boolean
  disabledLabel?: React.ReactNode
  onChange: (action: WorkflowAction, index: number) => any
  error: string | null
  index: number
}

export const TagsAction = ({
  actionType,
  tags,
  disabled,
  disabledLabel,
  onChange,
  error,
  index,
}: TagsActionProps) => {
  const variablesModalActivator = useDialogActivator()
  const [tag, setTag] = useState('')

  const onAdd = useCallback(() => {
    const newTags = [...tags, tag.trim()]
    onChange([actionType, newTags], index)
    setTag('')
  }, [onChange, tags, tag, index, actionType])

  const onRemove = useCallback(
    (tagIndex: number) => {
      const tagsTmp = [...tags]
      tagsTmp.splice(tagIndex, 1)
      onChange([actionType, tagsTmp], index)
    },
    [onChange, tags, index, actionType]
  )

  return (
    <LegacyStack vertical>
      {disabled && disabledLabel && (
        <Text as="span" color="subdued">
          {disabledLabel}
        </Text>
      )}
      <Text variant="headingSm" as="h2">
        {workflowActionLabels[actionType]}
      </Text>
      {!disabled && (
        <LegacyStack spacing="tight">
          {tags.map((tag: string, index: number) => (
            <Tag key={tag} onRemove={() => onRemove(index)}>
              {tag}
            </Tag>
          ))}
          {tags.length === 0 && (
            <Text as="span" color="subdued">
              No tags selected
            </Text>
          )}
        </LegacyStack>
      )}

      <LegacyStack vertical spacing="extraTight">
        <TextField
          label=""
          labelHidden
          value={tag}
          type="text"
          disabled={disabled}
          onChange={setTag}
          autoComplete="off"
          connectedRight={<Button onClick={onAdd} icon={PlusMinor} disabled={tag.trim() === ''} />}
        />
        <Button plain onClick={variablesModalActivator.show}>
          Available variables
        </Button>
      </LegacyStack>
      {error && <InlineError message={error} fieldID="" />}
      <WorkflowVariablesModal
        open={variablesModalActivator.open}
        onClose={variablesModalActivator.close}
      />
    </LegacyStack>
  )
}
