import { SellingPlanAnchor, SellingPlanPreAnchorBehavior } from '../common/anchor-models'
import { SellingPlanInterval, SellingPlanPricingPolicyAdjustmentType } from '../common/plan-models'
import { SellingPlanGroupPWData, SellingPlanInventoryPolicyReserve } from './models-common'
import {
  SellingPlanDeliveryPolicyFormData,
  SellingPlanFixedPricingPolicyFormData,
  SellingPlanFormData,
  SellingPlanGroupFormData,
} from './models-form'

export interface SellingPlanRecurringBillingPolicyInput {
  anchors: SellingPlanAnchor[]
  interval?: SellingPlanInterval
  intervalCount?: number
  maxCycles: number | null
  minCycles: number | null
}

export interface SellingPlanBillingPolicyInput {
  recurring: SellingPlanRecurringBillingPolicyInput
}

export interface SellingPlanRecurringDeliveryPolicyInput {
  anchors: SellingPlanAnchor[]
  cutoff: number | null
  interval?: SellingPlanInterval
  intervalCount?: number
  preAnchorBehavior: SellingPlanPreAnchorBehavior
}

export interface SellingPlanDeliveryPolicyInput {
  recurring: SellingPlanRecurringDeliveryPolicyInput
}

export interface SellingPlanPricingPolicyValueInput {
  fixedValue?: string
  percentage?: number
}

export interface SellingPlanFixedPricingPolicyInput {
  adjustmentType: SellingPlanPricingPolicyAdjustmentType
  adjustmentValue: SellingPlanPricingPolicyValueInput
}

export interface SellingPlanRecurringPricingPolicyInput {
  adjustmentType: SellingPlanPricingPolicyAdjustmentType
  adjustmentValue: SellingPlanPricingPolicyValueInput
  afterCycle: number
}

export interface SellingPlanPricingPolicyInput {
  fixed?: SellingPlanFixedPricingPolicyInput
  recurring?: SellingPlanRecurringPricingPolicyInput
}

export interface SellingPlanInventoryPolicyInput {
  reserve: SellingPlanInventoryPolicyReserve
}

export interface SellingPlanInput {
  billingPolicy: SellingPlanBillingPolicyInput
  category: string
  deliveryPolicy: SellingPlanDeliveryPolicyInput
  description: string
  id?: string
  inventoryPolicy: SellingPlanInventoryPolicyInput
  name: string
  options: string[]
  position?: number
  pricingPolicies: SellingPlanPricingPolicyInput[]
}

export interface SellingPlanGroupInput {
  appId: string
  description?: string | null
  merchantCode: string
  name: string
  options: string[]
  position?: number
  sellingPlansToCreate: SellingPlanInput[]
  sellingPlansToDelete: string[]
  sellingPlansToUpdate: SellingPlanInput[]
}

const fixedPricingPolicyFormDataToInput = (
  data: SellingPlanFixedPricingPolicyFormData
): SellingPlanFixedPricingPolicyInput => {
  const ret: SellingPlanFixedPricingPolicyInput = {
    adjustmentType: data.adjustmentType,
    adjustmentValue: {},
  }

  if (data.adjustmentType === SellingPlanPricingPolicyAdjustmentType.PERCENTAGE) {
    ret.adjustmentValue.fixedValue = undefined
    ret.adjustmentValue.percentage = parseFloat(data.adjustmentValue) ?? 0
  } else {
    ret.adjustmentValue.fixedValue = data.adjustmentValue ?? '0'
    ret.adjustmentValue.percentage = undefined
  }

  if (data.adjustmentType === SellingPlanPricingPolicyAdjustmentType.NONE) {
    ret.adjustmentType = SellingPlanPricingPolicyAdjustmentType.FIXED_AMOUNT
    ret.adjustmentValue.fixedValue = '0'
  }

  return ret
}

const billingPolicyFormDataToInput = (data: SellingPlanFormData): SellingPlanBillingPolicyInput => {
  return {
    recurring: {
      ...data.billingPolicy.recurring,
      anchors: data.deliveryPolicy.recurring.anchor ? [data.deliveryPolicy.recurring.anchor] : [],
      interval: data.deliveryPolicy.recurring.interval,
      intervalCount: data.deliveryPolicy.recurring.intervalCount * data.prePaidDeliveries,
      createdAt: undefined,
      __typename: undefined,
    },
  } as SellingPlanBillingPolicyInput
}

const deliveryPolicyFormDataToInput = (
  deliveryData: SellingPlanDeliveryPolicyFormData
): SellingPlanDeliveryPolicyInput => {
  return {
    recurring: {
      ...deliveryData.recurring,
      anchors: deliveryData.recurring.anchor ? [deliveryData.recurring.anchor] : [],
      cutoff: deliveryData.recurring.anchor ? deliveryData.recurring.cutoff : null,
      preAnchorBehavior: deliveryData.recurring.preAnchorBehavior,
      anchor: undefined,
      createdAt: undefined,
      __typename: undefined,
    },
  } as SellingPlanDeliveryPolicyInput
}

/**
 * Convert form data of SellingPlan to SellingPlanInput
 */
const sellingPlanFormDataToInput = (data: SellingPlanFormData): SellingPlanInput => {
  return {
    billingPolicy: billingPolicyFormDataToInput(data),
    category: data.category,
    deliveryPolicy: deliveryPolicyFormDataToInput(data.deliveryPolicy),
    description: data.description || '',
    id: data.id,
    inventoryPolicy: data.inventoryPolicy,
    name: data.name,
    options: [data.planSelectorLabel],
    pricingPolicies: [{ fixed: fixedPricingPolicyFormDataToInput(data.fixedPricingPolicy) }],
  }
}

export const sellingPlanGroupFormDataToInput = (
  data: SellingPlanGroupFormData
): SellingPlanGroupInput => {
  const ret: SellingPlanGroupInput = {
    appId: 'paywhirl',
    description: null, // data.description || '',
    merchantCode: data.merchantCode,
    name: data.name,
    options: [data.planSelectorTitle],
    sellingPlansToCreate: [],
    sellingPlansToUpdate: [],
    sellingPlansToDelete: data.sellingPlansToDelete || [],
  }

  data.sellingPlans.forEach((planData, index) => {
    const planInput = sellingPlanFormDataToInput(planData)
    planInput.position = index + 1

    if (planInput.id) {
      ret.sellingPlansToUpdate.push(planInput)
    } else {
      ret.sellingPlansToCreate.push(planInput)
    }
  })

  return ret
}

export const sellingPlanGroupFormDataToPWData = (
  data: SellingPlanGroupFormData
): SellingPlanGroupPWData => ({
  sellingPlans: data.sellingPlans.map((planData) => ({
    id: planData.id,
    name: planData.name,
    finishingBehavior: planData.finishingBehavior,
    type: planData.type,
    tags: planData.tags
      .split(',')
      .map((t) => t.trim())
      .filter((t) => t.length > 0),
  })),
})
