import { List, Modal } from '@shopify/polaris'

import { DialogActivator } from '../common/helpers'

export const LearnMoreModal = ({ activator }: { activator: DialogActivator }) => (
  <Modal
    primaryAction={{ content: 'Close', onAction: activator.close }}
    sectioned
    title="How bulk update works"
    open={activator.open}
    onClose={activator.close}
  >
    <List type="bullet">
      <List.Item>
        The chosen action will be attempted one by one on every selected subscription which is
        eligible for this action
      </List.Item>
      <List.Item>
        Subscriptions which are already finished won't be modified by bulk actions
      </List.Item>
      <List.Item>You can pause and cancel the bulk update process at any time</List.Item>
      <List.Item>
        When the process is completed, you'll receive a report with results of every action. You
        should review it to confirm that every subscription was successfully updated
      </List.Item>
      <List.Item>You can also request partial reports while the process is running</List.Item>
    </List>
  </Modal>
)
