import { Badge, HorizontalStack, LegacyCard, Text, VerticalStack } from '@shopify/polaris'

export type WorkflowCreateItemType = 'single' | 'subscription' | 'tagging'

export const workflowLabels: Record<
  WorkflowCreateItemType,
  { title: string; badgeLabel?: string; createButtonLabel: string }
> = {
  single: {
    title: 'Custom workflow',
    badgeLabel: 'Advanced',
    createButtonLabel: 'Create custom workflow',
  },
  subscription: {
    title: 'Subscription automation',
    badgeLabel: 'New',
    createButtonLabel: 'Create subscription automation',
  },
  tagging: { title: 'Customer tagging', createButtonLabel: 'Create customer tagging' },
}

export const workflowDescriptions: Record<WorkflowCreateItemType, React.ReactNode> = {
  single: (
    <Text as="p">Define arbitrary actions to trigger on specified events and conditions.</Text>
  ),
  subscription: (
    <Text as="p">
      Update your subscriptions after specified billing cycles, for example to add a discount, swap
      or add a product.
    </Text>
  ),
  tagging: (
    <Text as="p">
      Segment your customers by adding and removing tags depending on the current status of their
      subscriptions.
    </Text>
  ),
}

export const WorkflowCreateCard = ({
  type,
  url,
  onClick,
}: {
  type: WorkflowCreateItemType
  url?: string
  onClick?(): unknown
}) => (
  <LegacyCard
    sectioned
    primaryFooterAction={{
      content: workflowLabels[type].createButtonLabel,
      url,
      onAction: onClick,
    }}
    footerActionAlignment="left"
  >
    <VerticalStack gap="3">
      <HorizontalStack wrap={false} align="space-between" blockAlign="baseline" gap="2">
        <Text variant="headingMd" as="h2">
          {workflowLabels[type].title}
        </Text>
        {workflowLabels[type].badgeLabel && (
          <Badge status="attention">{workflowLabels[type].badgeLabel}</Badge>
        )}
      </HorizontalStack>
      {workflowDescriptions[type]}
    </VerticalStack>
  </LegacyCard>
)
