import { Address, addressWithFixedCountryAndProvince } from './models'
import { DeliveryDetailsForm } from './models-form'

export interface DeliveryDetailsInput {
  deliveryMethod: {
    shipping?: {
      address: Address
      shippingOption?: {
        title: string
        code: string
        presentmentTitle: string
      }
    }
    localDelivery?: {
      address: Address
      localDeliveryOption: {
        title: string
        code: string
        presentmentTitle: string
        phone: string
        instructions?: string
      }
    }
    pickup?: {
      pickupOption?: {
        title: string
        code: string
        presentmentTitle: string
        locationId: string
      }
    }
  } | null
  deliveryPrice: number
}

export const deliveryDetailsFormDataToInput = (
  deliveryMethodForm: DeliveryDetailsForm
): DeliveryDetailsInput => {
  let deliveryMethodInput = null

  if (deliveryMethodForm.type === 'shipping') {
    deliveryMethodInput = {
      shipping: {
        address: addressWithFixedCountryAndProvince(deliveryMethodForm.address),
        shippingOption: {
          title: deliveryMethodForm.title,
          code: deliveryMethodForm.code,
          presentmentTitle: deliveryMethodForm.title,
        },
      },
    }
  }

  if (deliveryMethodForm.type === 'local-delivery') {
    deliveryMethodInput = {
      localDelivery: {
        address: addressWithFixedCountryAndProvince(deliveryMethodForm.address),
        localDeliveryOption: {
          title: deliveryMethodForm.title,
          code: deliveryMethodForm.code,
          presentmentTitle: deliveryMethodForm.title,
          phone: deliveryMethodForm.phone,
          instructions: deliveryMethodForm.instructions,
        },
      },
    }
  }

  if (deliveryMethodForm.type === 'pickup') {
    deliveryMethodInput = {
      pickup: {
        pickupOption: {
          title: deliveryMethodForm.title,
          code: deliveryMethodForm.code,
          presentmentTitle: deliveryMethodForm.title,
          locationId: deliveryMethodForm.locationId,
        },
      },
    }
  }

  return {
    deliveryMethod: deliveryMethodInput,
    deliveryPrice: parseFloat(deliveryMethodForm.price),
  }
}
