import { Button } from '@shopify/polaris'
import React from 'react'

import { useToggle } from '../common/helpers'
import { useShopCurrency } from '../common/Price'
import { ProductVariantModal, ProductVariantResult } from '../common/ProductVariantModal'

interface ProductVariantSelectorProps {
  onSelect: (result: ProductVariantResult) => any
  disabled?: boolean
}

export const ProductVariantSelector = (props: ProductVariantSelectorProps) => {
  const shopCurrency = useShopCurrency()
  const [productModalActive, toggleProductModalActive] = useToggle(false)

  const handleSelect = (result: ProductVariantResult) => {
    props.onSelect(result)
    toggleProductModalActive()
  }

  return (
    <>
      <Button onClick={toggleProductModalActive} size="slim" disabled={props.disabled}>
        Product variant filter
      </Button>
      <ProductVariantModal
        currency={shopCurrency}
        open={productModalActive}
        onClose={toggleProductModalActive}
        onSelect={handleSelect}
      />
    </>
  )
}
