import { Banner, Page } from '@shopify/polaris'
import React, { useContext } from 'react'

import { SystemContext } from '../common/SystemData'

export const MaintenanceBanner = () => {
  const systemData = useContext(SystemContext)
  const maintenanceMessage = systemData?.app_maintenance_message

  if (!maintenanceMessage) {
    return null
  }

  return (
    <Page>
      <Banner status="warning">
        <span dangerouslySetInnerHTML={{ __html: maintenanceMessage }} />
      </Banner>
    </Page>
  )
}
