import { LegacyCard, LegacyStack, Text } from '@shopify/polaris'

import { CustomerLink, ShortDate } from '../common/helpers'
import { pauseReasonLabels, SubscriptionContract } from '../common/subscription-models'
import { SubscriptionStatusBadge } from '../common/SubscriptionStatusBadge'
import { DetailsItem, LongDetailsItem } from './DetailsItem'

export const OverviewCard = ({ sc }: { sc: SubscriptionContract }) => {
  const createdAt = new Date(sc.gql_data.createdAt)
  const updatedAt = new Date(sc.gql_data.updatedAt)
  const finishedAt = sc.finished_at ? new Date(sc.finished_at) : null
  const pausedAt = sc.pw_data.pausedAt ? new Date(sc.pw_data.pausedAt) : null

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <LegacyStack vertical>
          <LegacyStack distribution="equalSpacing">
            <Text variant="headingMd" as="h2">
              Overview
            </Text>
            <SubscriptionStatusBadge sc={sc} />
          </LegacyStack>
          <LegacyStack vertical spacing="extraTight">
            <DetailsItem label="Customer">
              {sc.gql_data.customer ? (
                <CustomerLink
                  gid={sc.gql_data.customer.id}
                  name={sc.gql_data.customer.displayName}
                />
              ) : (
                'Unavailable'
              )}
            </DetailsItem>
            <LongDetailsItem
              label="Email"
              content={sc.gql_data.customer?.email ?? 'Unavailable'}
            ></LongDetailsItem>
            <DetailsItem label="Created">
              <ShortDate date={createdAt} />
            </DetailsItem>
            <DetailsItem label="Updated">
              <ShortDate date={updatedAt} />
            </DetailsItem>
            {pausedAt && (
              <DetailsItem label="Paused">
                <ShortDate date={pausedAt} />
              </DetailsItem>
            )}
            {sc.pw_data.pauseReason && (
              <DetailsItem label="Pause reason">
                {pauseReasonLabels[sc.pw_data.pauseReason] ?? 'Unknown'}
              </DetailsItem>
            )}
            {finishedAt && (
              <DetailsItem label="Finished">
                <ShortDate date={finishedAt} />
              </DetailsItem>
            )}
            {sc.pw_data.cancellationReason && (
              <LongDetailsItem
                label="Cancellation reason"
                content={sc.pw_data.cancellationReason}
              />
            )}
            {sc.pw_data.cancellationFeedback && (
              <LongDetailsItem label="Feedback" content={sc.pw_data.cancellationFeedback} />
            )}
          </LegacyStack>
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  )
}
