import {
  Button,
  DatePicker,
  Error,
  HorizontalStack,
  Popover,
  Range,
  TextField,
} from '@shopify/polaris'
import { CalendarMinor } from '@shopify/polaris-icons'
import { useCallback, useEffect, useState } from 'react'

export const DateInputWithSelector = ({
  label,
  labelHidden,
  day,
  month,
  error,
  connectedRight,
  onUpdate,
}: {
  label: string
  labelHidden?: boolean
  day: number
  month: number
  error?: Error
  connectedRight?: React.ReactNode
  onUpdate: (day?: number, month?: number) => any
}) => {
  const leadingZeroNumberFormat = (val: number) => (val > 9 ? `${val}` : `0${val}`)
  const [dateInput, setDateInput] = useState(
    `${leadingZeroNumberFormat(month)}-${leadingZeroNumberFormat(day)}`
  )

  const parseDateInput = useCallback(() => {
    const [monthString, dayString] = dateInput.split('-')
    const month = parseInt(monthString, 10)
    const day = parseInt(dayString, 10)
    return { month, day }
  }, [dateInput])

  const datePickerDate = useCallback(() => {
    const { month, day } = parseDateInput()

    if (!isNaN(month) && !isNaN(day)) {
      return new Date(2024, month - 1, day)
    }

    return new Date()
  }, [parseDateInput])

  // Check whether month and day are valid year dates taking into account number of days in month
  const isInputValid = useCallback(() => {
    const { month, day } = parseDateInput()

    if (isNaN(month) || isNaN(day)) {
      return false
    }

    const daysInMonth = new Date(2024, month, 0).getDate()
    return month > 0 && month < 13 && day > 0 && day <= daysInMonth
  }, [parseDateInput])

  const handleOnBlur = useCallback(() => {
    if (isInputValid()) {
      const parsedInput = parseDateInput()
      onUpdate(parsedInput.day, parsedInput.month)
      return
    }

    onUpdate(undefined, undefined)
  }, [parseDateInput, onUpdate, isInputValid])

  const handleOnDateSelect = useCallback(
    (date: Date) => {
      const month = date.getMonth() + 1
      const day = date.getDate()
      setDateInput(`${leadingZeroNumberFormat(month)}-${leadingZeroNumberFormat(day)}`)
      onUpdate(day, month)
    },
    [onUpdate]
  )

  return (
    <>
      <TextField
        value={dateInput}
        placeholder="MM-DD"
        label={label}
        labelHidden={labelHidden}
        autoComplete="off"
        onChange={setDateInput}
        onBlur={handleOnBlur}
        error={error}
        connectedRight={
          <HorizontalStack blockAlign="center" gap="1">
            <DatePickerPopover
              date={datePickerDate().toISOString()}
              onSelect={handleOnDateSelect}
            />
            {connectedRight}
          </HorizontalStack>
        }
      />
    </>
  )
}

const getDateMonth = (date?: string) => {
  const dateObj = date ? new Date(date) : new Date()
  return dateObj.getMonth()
}

const getDateYear = (date?: string) => {
  const dateObj = date ? new Date(date) : new Date()
  return dateObj.getFullYear()
}

export const DatePickerPopover = ({
  date,
  onSelect,
}: {
  date: string
  onSelect: (date: Date) => any
}) => {
  const [month, setMonth] = useState<number>(getDateMonth(date))
  const [year, setYear] = useState<number>(getDateYear(date))

  const updateMonthDate = useCallback((month: number, year: number) => {
    setMonth(month)
    setYear(year)
  }, [])

  const [popoverActive, setPopoverActive] = useState(false)
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  )

  useEffect(() => {
    setMonth(getDateMonth(date))
  }, [date])

  return (
    <Popover
      active={popoverActive}
      activator={<Button icon={CalendarMinor} onClick={togglePopoverActive} />}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      sectioned
    >
      <DatePicker
        month={month}
        year={year}
        onMonthChange={updateMonthDate}
        onChange={(range: Range) => {
          onSelect(range.start)
          togglePopoverActive()
        }}
        selected={new Date(date)}
      />
    </Popover>
  )
}
