// eslint-disable-next-line simple-import-sort/imports
import FullCalendar, {
  EventClickArg,
  EventContentArg,
  EventInput,
  EventSourceFunc,
  MountArg,
} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import { LegacyCard, Text, Pagination, Spinner, LegacyStack } from '@shopify/polaris'
import { DateTime } from 'luxon'
import { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { backendFetchResult } from '../common/api'
import {
  FormattedDate,
  queryString,
  shouldHandleLinkClickEvent,
  useShopTimeZone,
} from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import * as urls from '../common/urls'
import styles from './PaymentSchedule.module.css'

const fetchEvents: EventSourceFunc = async (info, success, failure): Promise<EventInput[]> => {
  const start = DateTime.fromISO(info.startStr)
    .setZone(info.timeZone, { keepLocalTime: true })
    .toISO({ suppressMilliseconds: true })

  const end = DateTime.fromISO(info.endStr)
    .setZone(info.timeZone, { keepLocalTime: true })
    .toISO({ suppressMilliseconds: true })

  const query = queryString({ start, end })

  const res = await backendFetchResult<EventInput[]>(
    'GET',
    `/dashboard/payment-calendar-events?${query}`
  )

  if (res.status !== 'success') {
    return Promise.reject(res.message ?? 'Unknown error')
  }

  return res.data ?? []
}

const handleEventDidMount = (arg: MountArg<EventContentArg>) => {
  arg.el.innerHTML = arg.event.extendedProps.description
  arg.el.title = arg.el.innerText
}

interface CalendarProps {
  date: string
  setDate: (date: string) => void
}

const Calendar = (props: CalendarProps) => {
  const history = useHistory()
  const calendarRef = useRef<FullCalendar>(null)
  const setDate = props.setDate
  const timeZone = useShopTimeZone()

  const [loading, setLoading] = useState(true)

  const handleEventClick = useCallback(
    (arg: EventClickArg) => {
      if (shouldHandleLinkClickEvent(arg.jsEvent)) {
        arg.jsEvent.preventDefault()
        history.push(
          urls.dashboardPaymentsUrl({
            date: arg.event.extendedProps.filterDate,
            status: arg.event.extendedProps.status,
            page: 1,
          })
        )
      }
    },
    [history]
  )

  const calendarChanged = useCallback(
    (_: any) => {
      const fcDate = calendarRef.current?.getApi()?.getDate()

      if (!fcDate) {
        return
      }

      const isoDate = DateTime.fromJSDate(fcDate, { zone: 'UTC' }).toISODate()

      setDate(isoDate)
    },
    [calendarRef, setDate]
  )

  return (
    <LegacyStack vertical>
      <LegacyStack alignment="center">
        <LegacyStack.Item fill>
          <Text variant="headingMd" as="h1">
            <LegacyStack alignment="center">
              <FormattedDate
                date={DateTime.fromISO(props.date, { zone: timeZone }).toJSDate()}
                format={{ month: 'long', year: 'numeric' }}
              />
              {loading && (
                <span className={styles.spinnerWrapper}>
                  <Spinner size="small" />
                </span>
              )}
            </LegacyStack>
          </Text>
        </LegacyStack.Item>
        <div className={styles.paginationWrapper}>
          <Pagination
            hasPrevious={true}
            hasNext={true}
            onPrevious={() => {
              calendarRef.current?.getApi().prev()
            }}
            onNext={() => {
              calendarRef.current?.getApi().next()
            }}
          />
        </div>
      </LegacyStack>
      <FullCalendar
        viewClassNames={styles.calendarWrapper}
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={false}
        height="auto"
        dayMaxEventRows={4}
        stickyHeaderDates={false}
        timeZone={timeZone}
        eventClick={handleEventClick}
        datesSet={calendarChanged}
        events={fetchEvents}
        eventsSet={calendarChanged}
        eventDidMount={handleEventDidMount}
        eventOrder="sortKey"
        initialDate={props.date}
        loading={setLoading}
      />
    </LegacyStack>
  )
}

export const PaymentSchedule = (props: CalendarProps) => (
  <LegacyStack>
    <LegacyStack.Item>
      <Text variant="headingMd" as="h1">
        Payment schedule{' '}
        <LegacyHelpIcon>Only one upcoming payment is shown for every subscription</LegacyHelpIcon>
      </Text>
    </LegacyStack.Item>
    <LegacyStack.Item>
      <LegacyCard sectioned>
        <Calendar {...props} />
      </LegacyCard>
    </LegacyStack.Item>
  </LegacyStack>
)
