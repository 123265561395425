import {
  Badge,
  Button,
  Card,
  HorizontalGrid,
  Icon,
  LegacyStack,
  Text,
  VerticalStack,
} from '@shopify/polaris'
import { TickMinor } from '@shopify/polaris-icons'
import React, { useState } from 'react'

import { LegacyHelpIcon } from '../common/HelpIcon'
import { ActivateDialog } from './ActivateDialog'

interface Props {
  name: string
  badge?: string
  children: any
}

export const PricingPlan = (props: Props) => (
  <Card>
    <VerticalStack gap="4">
      <LegacyStack distribution="equalSpacing" alignment="center">
        <Text as="span" color="subdued">
          {props.name.toUpperCase()}
        </Text>
        {props.badge && <Badge status="attention">{props.badge}</Badge>}
      </LegacyStack>
      {props.children}
    </VerticalStack>
  </Card>
)

const ActivateButton = ({
  id,
  defaultCap,
  fullWidth,
}: {
  id: string
  defaultCap: number
  fullWidth: boolean
}) => {
  const [dialogVisible, setDialogVisible] = useState(false)

  return (
    <>
      <HorizontalGrid
        columns={{ xs: 1, sm: 1, md: 1, lg: fullWidth ? 1 : 2, xl: fullWidth ? 1 : 2 }}
      >
        <Button fullWidth primary onClick={() => setDialogVisible(true)}>
          Activate
        </Button>
      </HorizontalGrid>
      <ActivateDialog
        open={dialogVisible}
        onClose={() => setDialogVisible(false)}
        planId={id}
        defaultCap={defaultCap}
      />
    </>
  )
}

const Price = ({ period = 'month', children }: { period?: 'month' | null; children: any }) => (
  <LegacyStack spacing="tight" alignment="center">
    <Text variant="heading2xl" as="p">
      {children}
    </Text>
    {period && (
      <Text variant="headingLg" as="p">
        {' '}
        / {period}
      </Text>
    )}
  </LegacyStack>
)

const Fees = ({ children, helpText }: { children: any; helpText?: string }) => (
  <Text as="span" color="subdued">
    {children}
    <LegacyHelpIcon>
      {helpText ??
        'A transaction fee applies to the entire Shopify order total, including shipping and taxes, when at least one subscription item is present. This includes both one-time and subscription products in the initial order. Transaction fees do not apply to Shopify orders containing only one-time products.'}
    </LegacyHelpIcon>
  </Text>
)

const Features = ({ featuresLabel, features }: { featuresLabel?: string; features: string[] }) => (
  <>
    <VerticalStack gap="05">
      {featuresLabel && <Text as="strong">{featuresLabel}</Text>}
      {features.map((feature: string, index: number) => (
        <LegacyStack key={index} alignment="center" spacing="extraTight" wrap={false}>
          {feature && <Icon color="success" source={TickMinor} />}
          <p>{feature || <>&nbsp;</>}</p>
        </LegacyStack>
      ))}
      <br />
    </VerticalStack>
  </>
)

PricingPlan.ActivateButton = ActivateButton
PricingPlan.Features = Features
PricingPlan.Fees = Fees
PricingPlan.Price = Price
