import countries from './countries.json'

export interface Address {
  firstName?: string
  lastName?: string
  company?: string
  address1?: string
  address2?: string
  city?: string
  countryCode?: string
  countryCodeV2?: string
  province?: string
  provinceCode?: string
  zip?: string
  phone?: string
  formatted?: string[]
}

export const countryCodeOptions = countries.map((c) => ({
  label: c.title,
  value: c.value,
}))

export const provinceOptions = (() => {
  let ret: any = {}
  for (let country of countries) {
    ret[country.value] = country.provinces.map((p) => ({
      label: p.title,
      value: p.value,
    }))
  }
  return ret
})()

export const allowedProvinces = (() => {
  let ret: any = {}
  for (let country of countries) {
    ret[country.value] = country.provinces.map((p) => p.value)
  }
  return ret
})()

export const addressWithFixedCountryAndProvince = (address: Address): Address => {
  const countryCode = address.countryCode || 'US'
  const province =
    allowedProvinces[countryCode].indexOf(address.province) !== -1 ? address.province : ''

  return {
    ...address,
    countryCode,
    province,
    provinceCode: undefined,
  }
}
