import { Button, LegacyStack, OptionList, Popover, Tag, Text } from '@shopify/polaris'
import { OptionDescriptor } from '@shopify/polaris/build/ts/src/types'
import React, { useCallback } from 'react'

import { gidToId, useToggle } from '../common/helpers'

interface OptionsFilterSections {
  options: OptionDescriptor[]
  title?: string
}

interface OptionsFilterProps {
  label: string
  buttonLabel: string
  params: any[]
  onChange: (params: any[], index: number) => void
  index: number
  options?: OptionDescriptor[]
  sections?: OptionsFilterSections[]
  optionLabels: Record<string, string>
}

export const OptionsFilter = ({
  label,
  params,
  onChange,
  index,
  options,
  sections,
  optionLabels,
  buttonLabel,
}: OptionsFilterProps) => {
  const [popoverActive, togglePopoverActive] = useToggle(false)

  const handleRemove = useCallback(
    (spIndex: number) => {
      const spIds = [...params]
      spIds.splice(spIndex, 1)
      onChange(spIds, index)
    },
    [index, onChange, params]
  )

  return (
    <LegacyStack vertical>
      <p>{label}</p>

      {params.length === 0 && (
        <Text as="span" color="subdued">
          No options selected
        </Text>
      )}

      {params.length > 0 && (
        <LegacyStack spacing="tight" vertical>
          {params.map((val: string, index: number) => (
            <Tag key={index} onRemove={() => handleRemove(index)}>
              {optionLabels[val] || `#${gidToId(val)} (deleted)`}
            </Tag>
          ))}
        </LegacyStack>
      )}

      <Popover
        active={popoverActive}
        activator={
          <Button onClick={togglePopoverActive} disclosure>
            {buttonLabel}
          </Button>
        }
        onClose={togglePopoverActive}
      >
        <OptionList
          allowMultiple={true}
          onChange={(selected: string[]) => onChange(selected, index)}
          sections={sections}
          options={options}
          selected={params}
        />
      </Popover>
    </LegacyStack>
  )
}
