import { Banner, Label, LegacyStack, Modal, Text } from '@shopify/polaris'
import { useCallback, useEffect, useState } from 'react'

import { mutateWithResult, Result } from '../common/api'
import { ApiAction, useApiActionState } from '../common/ApiAction'
import { ColorCircle } from '../common/ColorCircle'
import { ColorPickerModal } from '../common/ColorPickerModal'
import { CenteredSpinner, DialogActivator, useDialogActivator } from '../common/helpers'
import { ThemeColors, useCustomerPortalCustomColors } from './api'

const colorGroups = [
  {
    label: 'General',
    colors: [
      { colorKey: 'color-text', label: 'Text color' },
      { colorKey: 'color-background', label: 'Background color' },
      { colorKey: 'color-border', label: 'Border color' },
    ],
  },
  {
    label: 'Buttons',
    colors: [
      { colorKey: 'color-button', label: 'Button color' },
      { colorKey: 'color-button-text--primary', label: 'Primary button text color' },
    ],
  },
]

export const CustomColorsModal = ({ activator }: { activator: DialogActivator }) => {
  const { data: initialColors, isValidating: loading } = useCustomerPortalCustomColors()

  const colorPickerActivator = useDialogActivator()

  const [colors, setColors] = useState(initialColors ?? {})
  const [editingColor, setEditingColor] = useState('color-button')

  const getColor = useCallback((colorKey: string) => colors[colorKey] ?? '#fff', [colors])
  const getColorLabel = useCallback(
    (colorKey: string) =>
      colorGroups
        .map((group) => group.colors)
        .flat()
        .find((colorData) => colorData.colorKey === colorKey)?.label,
    []
  )

  useEffect(() => {
    setColors(initialColors ?? {})
  }, [initialColors, activator.showCounter])

  const saveAction = useApiActionState({
    method: 'PATCH',
    url: '/settings/customer-portal-custom-colors',
    opts: {
      body: JSON.stringify({ colors: colors }),
    },
    onSuccess: (res: Result<ThemeColors>) => {
      activator.close()
      mutateWithResult(['customer-portal-custom-colors'], res)
    },
  })

  const resetAction = useApiActionState({
    method: 'GET',
    url: '/settings/customer-portal-custom-colors?defaults=1',
    onSuccess: (res: Result<ThemeColors>) => {
      if (res.data) {
        setColors(res.data)
      }
    },
  })

  return (
    <>
      <Modal
        title="Customize colors"
        open={activator.open && !colorPickerActivator.open}
        onClose={activator.close}
        sectioned
        primaryAction={{
          content: 'Save',
          onAction: saveAction.run,
          loading: saveAction.loading,
          disabled:
            initialColors === undefined || JSON.stringify(colors) === JSON.stringify(initialColors),
        }}
        secondaryActions={[
          { content: 'Reset', onAction: resetAction.run, loading: resetAction.loading },
          { content: 'Cancel', onAction: activator.close },
        ]}
      >
        {loading && initialColors === undefined && <CenteredSpinner />}
        {!loading && initialColors === undefined && (
          <Banner status="critical">Styles couldn't be loaded, please try again later</Banner>
        )}
        <>
          {initialColors && (
            <LegacyStack vertical spacing="loose">
              {colorGroups.map((group) => (
                <LegacyStack key={group.label} vertical>
                  <Text variant="headingSm" as="h3">
                    {group.label}
                  </Text>
                  {group.colors.map((colorData) => (
                    <LegacyStack key={colorData.colorKey} alignment="center">
                      <ColorCircle
                        color={getColor(colorData.colorKey)}
                        onClick={() => {
                          setEditingColor(colorData.colorKey)
                          colorPickerActivator.show()
                        }}
                      />
                      <Label id={colorData.colorKey}>
                        {colorData.label}
                        <br />
                        {getColor(colorData.colorKey)}
                      </Label>
                    </LegacyStack>
                  ))}
                </LegacyStack>
              ))}
            </LegacyStack>
          )}
        </>
      </Modal>
      <ApiAction state={saveAction} />
      <ColorPickerModal
        title={getColorLabel(editingColor) ?? ''}
        activator={colorPickerActivator}
        color={getColor(editingColor)}
        onSelect={(color) => setColors({ ...colors, [editingColor]: color })}
      />
    </>
  )
}
