import { Modal } from '@shopify/polaris'
import { useCallback, useState } from 'react'

import { backendFetchResult, Result } from '../common/api'
import { ResultBanner, ResultToast } from '../common/helpers'
import { CapSelect } from '../pricing-plans-page/CapSelect'

interface Props {
  open: boolean
  onSuccess?: (res: Result<any>) => any
  onClose: () => any
  previousCap: number
}

export const PricingPlanCapDialog = (props: Props) => {
  const [primaryButtonEnabled, setPrimaryButtonEnabled] = useState(true)
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)
  const [cap, setCap] = useState<number>(props.previousCap)

  const action = useCallback(async () => {
    setPrimaryButtonEnabled(false)

    const body = JSON.stringify({ dollars: cap })
    const res = await backendFetchResult<string>('POST', '/pricing-plans/update-cap', { body })

    setSaveResult(res)

    if (res.status === 'success' && res.data) {
      window.open(res.data, '_top')
    }

    setPrimaryButtonEnabled(true)
  }, [cap, setPrimaryButtonEnabled])

  const primaryAction = {
    content: 'Update',
    loading: !primaryButtonEnabled,
    onAction: action,
  }

  const cancelAction = { content: 'Cancel', onAction: props.onClose }

  return (
    <>
      <Modal
        open={props.open}
        title={'Update usage cap'}
        onClose={props.onClose}
        primaryAction={primaryAction}
        secondaryActions={[cancelAction]}
        sectioned
      >
        <ResultBanner result={saveResult} setResult={setSaveResult} />
        <CapSelect default={cap} onChange={setCap} updating={true} />
      </Modal>
      <ResultToast result={saveResult} setResult={setSaveResult} />
    </>
  )
}
