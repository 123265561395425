import React from 'react'

export enum MaintenanceStatus {
  None = '',
  Banner = 'banner',
  Full = 'full',
}

export interface PricingPlanFeatures {
  workflowsEnabled: boolean
  inventoryManagementEnabled: boolean
  failedPaymentWorkflowsEnabled: boolean
  klaviyoIntegrationEnabled: boolean
  customEmailTemplatesEnabled: boolean
  apiAllowed: boolean
  callbacksAllowed: boolean
  enhancedReportsEnabled: boolean
}

export interface SystemPricingPlanData extends PricingPlanFeatures {
  name: string
  createdAt: string
  currentPeriodEnd: string
  price: number
  balanceUsed: number
  totalRevenue: number | null
  cappedAmount: number
  formattedFee: string
}

export type FeatureAccessFlag = 'disabled' | 'by-plan' | 'enabled'

export interface ShopFlags {
  has_subscriptions: boolean
  is_beta_user: boolean
  live_subscriptions_status: string
  remaining_trial_seconds: number
  subscriptions_needing_attempt_when_enabled: number
  has_fulfillment_order_scopes: boolean
  has_inventory_scopes: boolean
  merchant_api_access: FeatureAccessFlag
  callbacks_access: FeatureAccessFlag
  enable_customer_portal_custom_script_url: boolean
  enable_customer_portal_extension_features: boolean
  klaviyo_integration: FeatureAccessFlag
  has_referral_partner_id: boolean
  eligible_for_referral_program: boolean
  enhanced_reports: FeatureAccessFlag
}

export interface FeatureFlags {}

export interface SystemData {
  domain: string
  installedAt: string
  shopifyAppStub: string
  customertPortalUrl: string
  flags: ShopFlags
  shopData: any
  pricingPlan: SystemPricingPlanData | null
  assetVersion: string
  featureFlags: FeatureFlags
  app_maintenance_status: MaintenanceStatus
  app_maintenance_message?: string
}

export const SystemContext = React.createContext<SystemData | undefined>(undefined)
