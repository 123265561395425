import { Button, HorizontalStack, LegacyCard, Text, VerticalStack } from '@shopify/polaris'
import { useState } from 'react'

import { Result } from './api'
import { useApiActionState } from './ApiAction'
import { MemoedTextField, pwCache, ResultToastOrBanner } from './helpers'

export const referralCodeAppCreditValue = '$25'

export const ReferralCode = () => {
  const [referralCode, setReferralCode] = useState('')
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const setReferralCodeAction = useApiActionState({
    method: 'POST',
    url: `/shop/referral-code`,
    opts: {
      body: JSON.stringify({ referral_code: referralCode }),
    },
    onSuccess: (res) => {
      pwCache.referralCodeSubmitted = true
      setSaveResult(res)
    },
    onError: setSaveResult,
    bannerPosition: 'none',
  })

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <VerticalStack gap="4">
          <ResultToastOrBanner result={saveResult} setResult={setSaveResult} bottomMargin={false} />
          {pwCache.referralCodeSubmitted && (
            <Text as="p">You have received a {referralCodeAppCreditValue} app credit!</Text>
          )}
          {!pwCache.referralCodeSubmitted && (
            <>
              <Text as="p">
                Have a referral code? Enter it here to receive{' '}
                <Text as="strong">{referralCodeAppCreditValue}</Text> in app credits.
              </Text>
              <HorizontalStack gap="2">
                <MemoedTextField
                  label="Referral code"
                  labelHidden
                  value={referralCode}
                  onChange={setReferralCode}
                  disabled={pwCache.referralCodeSubmitted}
                  autoComplete="off"
                  connectedRight={
                    !pwCache.referralCodeSubmitted && (
                      <Button
                        loading={setReferralCodeAction.loading}
                        onClick={setReferralCodeAction.run}
                      >
                        Submit
                      </Button>
                    )
                  }
                />
                <></>
              </HorizontalStack>
            </>
          )}
        </VerticalStack>
      </LegacyCard.Section>
    </LegacyCard>
  )
}
