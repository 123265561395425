import { Action, LegacyCard, LegacyStack, Text } from '@shopify/polaris'
import { DateStyle } from '@shopify/react-i18n'
import React from 'react'
import { mutate } from 'swr'

import { Result, SubscriptionContractSubscriptionStatus } from '../common/api'
import { ApiActionDialog } from '../common/ApiActionDialog'
import { FormattedDate, ShortDate, useDialogActivator } from '../common/helpers'
import { HelpTitle } from '../common/HelpIcon'
import { NextAction, SubscriptionContract } from '../common/subscription-models'
import { useUserSettings } from '../settings-page/api'
import { DetailsItem } from './DetailsItem'
import { NextActionDialog } from './NextActionDialog'

const nextActionLabels: Record<NextAction, string> = {
  payment: 'Create order',
  cancel: 'Cancel at',
  expire: 'Expire at',
  none: 'Create order',
}

export const NextActionCard = ({ sc }: { sc: SubscriptionContract }) => {
  const nextActionDate = sc.next_action_date
  const nextNextOrderDate = sc.next_next_order_date ? new Date(sc.next_next_order_date) : null
  const retryingAt = sc.retrying_at ? new Date(sc.retrying_at) : null
  const userSettings = useUserSettings()

  const editDialogActivator = useDialogActivator()
  const skipDialogActivator = useDialogActivator()

  const nextActionTitle = (
    <HelpTitle title="Next Action">
      Adjust the next scheduled action to charge and place an order, cancel the subscription, or
      have the subscription expire. It is useful when you want to schedule a cancellation at the end
      of the billing cycle. I.e. for membership access, services, etc.
    </HelpTitle>
  )

  if (sc.finished_at) {
    return null
  }

  let actions: Action[] = []

  if (sc.is_editable && sc.next_next_order_date) {
    actions.push({ content: 'Skip order', onAction: skipDialogActivator.show })
  }

  if (sc.is_editable) {
    actions.push({ content: 'Edit', onAction: editDialogActivator.show })
  }

  return (
    <LegacyCard title={nextActionTitle} sectioned actions={actions}>
      <LegacyStack vertical spacing="extraTight">
        <DetailsItem label={nextActionLabels[sc.next_action]}>
          {nextActionDate ? <ShortDate date={new Date(nextActionDate)} /> : <span>&mdash;</span>}
        </DetailsItem>

        {retryingAt && (
          <DetailsItem label="Retrying at">
            <ShortDate date={retryingAt} />
          </DetailsItem>
        )}

        {retryingAt && sc.pw_data.dunningRetries !== undefined && (
          <DetailsItem
            label="Payment retries"
            helpIcon="Payment retries are counted out of the max allowed in settings, excluding the first decline. Subscription is cancelled as 'Failed' after the last unsuccessful retry."
          >
            {sc.pw_data.dunningRetries} / {userSettings.data?.attempt_retry_count}
          </DetailsItem>
        )}
      </LegacyStack>

      {sc.gql_status === SubscriptionContractSubscriptionStatus.Paused && (
        <Text as="span" color="subdued">
          <br />
          Note: The action won't be executed until the subscription is resumed
        </Text>
      )}

      <NextActionDialog
        sc={sc}
        open={editDialogActivator.open}
        close={editDialogActivator.close}
        showCounter={editDialogActivator.showCounter}
      />

      {nextNextOrderDate && (
        <ApiActionDialog
          activator={skipDialogActivator}
          url={`/subscriptions/${sc.rest_id}/next-action`}
          opts={{
            body: JSON.stringify({ action: 'skip-order', date: nextNextOrderDate }),
          }}
          onSuccess={(res: Result<SubscriptionContract>) =>
            mutate(['subscription-contract', `${sc.rest_id}`], res.data, false)
          }
          method="PUT"
          title="Are you sure?"
          message={
            <>
              Do you want to skip the next order until{' '}
              <FormattedDate
                date={nextNextOrderDate}
                format={{ style: DateStyle.Short, hour: 'numeric', minute: 'numeric' }}
              />
              ?
            </>
          }
          primaryButtonTitle="Skip"
          cancelButtonTitle="Cancel"
        />
      )}
    </LegacyCard>
  )
}
