import {
  EmptyState,
  LegacyCard,
  LegacyStack,
  Loading,
  ResourceItem,
  ResourceList,
  Spinner,
  Text,
} from '@shopify/polaris'
import React, { useState } from 'react'
import useSWR from 'swr'

import { CardPagination } from '../common/CardPagination'
import { LoadErrorBanner } from '../common/LoadErrorPage'
import * as urls from '../common/urls'
import { fetchSellingPlanGroups, SellingPlanGroup } from './api'
import styles from './SellingPlanGroupList.module.css'

const MyEmptyState = (
  <div className={styles.wrapper}>
    <EmptyState
      heading="Create a new selling plan to get started"
      action={{ content: 'Create Plan', url: urls.newPlanUrl }}
      image="/images/setup-plans.svg"
    >
      <p>You can also create plans directly on your product pages</p>
    </EmptyState>
  </div>
)

const Loader = () => (
  <LegacyCard>
    <LegacyStack vertical alignment="center" distribution="center" spacing="extraTight">
      <br />
      <Spinner />
      <br />
    </LegacyStack>
  </LegacyCard>
)

const Item = (group: SellingPlanGroup) => {
  return (
    <ResourceItem id={group.id!} url={urls.planUrl(group.id!)}>
      <h3>
        <Text as="span" fontWeight="semibold">
          {group.merchantCode}
        </Text>
      </h3>
      {group.summary}
    </ResourceItem>
  )
}

export const SellingPlanGroupList = () => {
  const [sortKey, setSortKey] = useState('CREATED_AT_DESC')
  const [beforeCursor, setBeforeCursor] = useState('')
  const [afterCursor, setAfterCursor] = useState('')

  const { data, error, isValidating } = useSWR(
    ['selling-plan-groups', sortKey, beforeCursor, afterCursor],
    fetchSellingPlanGroups
  )
  const items = data?.data?.nodes || []

  return (
    <>
      {isValidating && <Loading />}

      {error && <LoadErrorBanner />}
      {!error && !data && <Loader />}
      {!error && data && (
        <LegacyCard>
          <ResourceList
            resourceName={{ singular: 'selling plan group', plural: 'selling plan groups' }}
            emptyState={MyEmptyState}
            items={items}
            renderItem={Item}
            sortOptions={[
              { label: 'Created (oldest first)', value: 'CREATED_AT_ASC' },
              { label: 'Created (newest first)', value: 'CREATED_AT_DESC' },
              { label: 'Updated (oldest first)', value: 'UPDATED_AT_ASC' },
              { label: 'Updated (newest first)', value: 'UPDATED_AT_DESC' },
            ]}
            sortValue={sortKey}
            onSortChange={(key: string) => {
              setSortKey(key)
              setBeforeCursor('')
              setAfterCursor('')
            }}
          />
          {items && (
            <CardPagination
              hasPrevious={data.data?.page.hasPreviousPage}
              hasNext={data.data?.page.hasNextPage}
              onPrevious={() => {
                setAfterCursor('')
                setBeforeCursor(data.data?.page?.firstCursor || '')
              }}
              onNext={() => {
                setBeforeCursor('')
                setAfterCursor(data.data?.page?.lastCursor || '')
              }}
            />
          )}
        </LegacyCard>
      )}
    </>
  )
}
