import { HorizontalGrid, Layout, LegacyStack, Page, useBreakpoints } from '@shopify/polaris'

import { Footer } from '../common/Footer'
import { ExternalLink, ShowIntercomLink } from '../common/helpers'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { PricingPlan } from './PricingPlan'

export const PricingPlansPage = () => {
  const { mdUp, lgUp } = useBreakpoints()

  const scalePlan = (
    <PricingPlan name="Scale" badge="Lowest rate!">
      <PricingPlan.Price>$149</PricingPlan.Price>
      <PricingPlan.Fees>0.75% transaction fees</PricingPlan.Fees>
      <PricingPlan.Features
        featuresLabel="All Traction features, plus:"
        features={[
          'Best Rates for High Volume',
          'Priority Support',
          'Dedicated Account Manager',
          '',
          '',
          '',
          '',
        ]}
      />
      <PricingPlan.ActivateButton id="scale-2023" defaultCap={5000} fullWidth />
    </PricingPlan>
  )

  return (
    <>
      <Tabs selected={TabIndex.Settings} />
      <Page title="Pricing plans" backAction={{ content: 'Settings', url: urls.settingsUrl() }}>
        <Layout>
          <Layout.Section>
            <LegacyStack vertical spacing="loose">
              <p>
                Select the best pricing plan for you. You can change it or cancel anytime. If you'd
                like to discuss a custom option, please{' '}
                <ShowIntercomLink>reach out</ShowIntercomLink> to the PayWhirl support team! To
                learn more, please visit{' '}
                <ExternalLink href="https://docs.paywhirl.com/en/articles/4793257-how-does-shopify-pricing-work">
                  Paywhirl docs
                </ExternalLink>
                .
              </p>
              <HorizontalGrid columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }} gap="2">
                <PricingPlan name="Pro" badge="Popular!">
                  <PricingPlan.Price>$9</PricingPlan.Price>
                  <PricingPlan.Fees>2% transaction fees</PricingPlan.Fees>
                  <PricingPlan.Features
                    features={[
                      'Unlimited Subscriptions & Orders',
                      'Expert Installation Included',
                      'Hassle-Free Migration',
                      'Analytics & Data Exports',
                      'Cancellation Prevention Flows',
                      'Customizable Email Templates',
                      'Enhanced Support',
                      '',
                    ].filter((feature) => feature || mdUp)}
                  />
                  <PricingPlan.ActivateButton id="pro-2023" defaultCap={1000} fullWidth={true} />
                </PricingPlan>

                <PricingPlan name="Traction" badge="Best value!">
                  <PricingPlan.Price>$29</PricingPlan.Price>
                  <PricingPlan.Fees>1.5% transaction fees</PricingPlan.Fees>
                  <PricingPlan.Features
                    featuresLabel={'All Pro features, plus:'}
                    features={[
                      'Subscription Automation Suite',
                      'Tag Management Automation',
                      'Low Inventory Controls',
                      'Failed Payment Workflow',
                      'API & Webhook Access',
                      'Klaviyo integration',
                      'Enhanced Reports',
                    ].filter((feature) => feature || mdUp)}
                  />
                  <PricingPlan.ActivateButton
                    id="traction-2023"
                    defaultCap={5000}
                    fullWidth={true}
                  />
                </PricingPlan>
                {lgUp && scalePlan}
              </HorizontalGrid>
              <HorizontalGrid columns={{ xs: 1, sm: 1, md: 2, lg: 1, xl: 1 }} gap="2">
                {!lgUp && scalePlan}
                <PricingPlan name="Free">
                  <PricingPlan.Price>$0</PricingPlan.Price>
                  <PricingPlan.Fees
                    helpText={
                      'A transaction fee applies to the entire Shopify order total, including shipping and taxes, when at least one subscription item is present. This includes both one-time and subscription products in the initial order. Transaction fees do not apply to Shopify orders containing only one-time products.'
                    }
                  >
                    0% fees on your first $5,000 processed, {!lgUp && <br />}
                    3% thereafter
                  </PricingPlan.Fees>
                  <PricingPlan.Features
                    features={[
                      'Unlimited Subscriptions & Orders',
                      'Expert Installation Included',
                      'Hassle-Free Migration',
                      'Analytics & Data Exports',
                      'Cancellation Prevention Flows',
                      '',
                      '',
                    ].filter((feature) => feature || !lgUp)}
                  />
                  <PricingPlan.ActivateButton id="free-2023" defaultCap={1000} fullWidth={!lgUp} />
                </PricingPlan>
              </HorizontalGrid>
            </LegacyStack>
          </Layout.Section>
          <Layout.Section>
            <Footer />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  )
}
