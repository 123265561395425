import { Modal, Select } from '@shopify/polaris'
import React, { useState } from 'react'

import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { GQLCollection } from '../common/collections'
import { collectionGidOptions } from './api'

interface BulkActionModalProps {
  actionType: 'attach' | 'detach'
  open: boolean
  onClose: () => any
  spgRestId: string
  collections: GQLCollection[]
}

export const BulkActionModal = (props: BulkActionModalProps) => {
  const [collectionGid, setCollectionGid] = useState('')

  const actionState = useApiActionState({
    method: 'POST',
    url: `/plans/${props.spgRestId}/start-bulk-action`,
    opts: { body: JSON.stringify({ collectionGid, actionType: props.actionType }) },
    onSuccess: props.onClose,
    bannerPosition: 'external',
  })

  return (
    <>
      <Modal
        title={{ attach: 'Add products', detach: 'Remove products' }[props.actionType]}
        open={props.open}
        onClose={props.onClose}
        sectioned
        primaryAction={{
          content: { attach: 'Add in bulk', detach: 'Remove in bulk' }[props.actionType],
          destructive: props.actionType === 'detach',
          onAction: actionState.run,
          loading: actionState.loading,
        }}
        secondaryActions={[{ content: 'Cancel', onAction: props.onClose }]}
      >
        <ApiActionBanner state={actionState} />
        <Select
          label="Choose a collection:"
          options={collectionGidOptions(props.collections)}
          value={collectionGid}
          onChange={setCollectionGid}
        />
        <br />
        <p>
          Note: this process can take from seconds up to a few hours, depending on the amount of
          products. Are you sure you want to continue?
        </p>
      </Modal>
      <ApiAction state={actionState} />
    </>
  )
}
