import { useI18n } from '@shopify/react-i18n'
import React from 'react'

import { useSystemData } from './helpers'

export interface Price {
  amount: string
  currencyCode: string
}

export const FormattedPrice = ({ price }: { price: Price }) => {
  const [i18n] = useI18n()

  return (
    <>
      {i18n.formatCurrency(parseFloat(price.amount), {
        currency: price.currencyCode,
        form: 'short',
      })}
    </>
  )
}

export const CentPrice = ({ cents, currencyCode }: { cents: number; currencyCode?: string }) => (
  <FormattedPrice price={{ amount: `${cents / 100}`, currencyCode: currencyCode ?? 'USD' }} />
)

export const currencySymbols: Record<string, string> = {
  USD: '$',
  CAD: '$',
  AUD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
}

export const useShopCurrency = (): string => useSystemData()?.shopData?.currency ?? 'USD'

export const useShopCurrencySymbol = (): string => {
  const currency = useShopCurrency()
  return currencySymbols[currency] ?? currency
}
