import {
  AppliedFilterInterface,
  Button,
  ChoiceList,
  FilterInterface,
  TextField,
} from '@shopify/polaris'
import { useCallback } from 'react'

import { useDialogActivator } from '../../common/helpers'
import { useShopCurrency } from '../../common/Price'
import { ProductVariantModal, ProductVariantResult } from '../../common/ProductVariantModal'
import { SubscriptionFilter } from '../api'

const windowWithProductVariantNameCache = window as unknown as {
  paywhirlProductVariantNameCache: Record<number, string>
}

windowWithProductVariantNameCache.paywhirlProductVariantNameCache = {}

const formatProductVariantId = (id?: number): string | undefined => {
  if (!id) {
    return undefined
  }

  const name = windowWithProductVariantNameCache.paywhirlProductVariantNameCache[id]

  return name ? `${name} (#${id})` : `#${id}`
}

export const useAddProductVariantIdFilter = (
  filter: SubscriptionFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any,
  filters: FilterInterface[],
  appliedFilters: AppliedFilterInterface[]
) => {
  const activator = useDialogActivator()
  const currency = useShopCurrency()

  const onRemove = useCallback(
    () => updateFilter({ variant_id: undefined, variant_removed: undefined }),
    [updateFilter]
  )

  const onSelectType = useCallback(
    (selected: string[]) => {
      if (selected[0] === 'removed') {
        updateFilter({ variant_id: undefined, variant_removed: 1 })
        return
      }

      updateFilter({ variant_removed: undefined })
    },
    [updateFilter]
  )

  const onSelect = useCallback(
    (res: ProductVariantResult) => {
      windowWithProductVariantNameCache.paywhirlProductVariantNameCache[res.restId] = res.title
      updateFilter({ variant_id: res.restId })
      activator.close()
    },
    [activator, updateFilter]
  )

  const formattedProductVariant = formatProductVariantId(filter.variant_id)

  const renderProductSelector = useCallback(
    (selected: boolean) => {
      return (
        selected && (
          <TextField
            label=""
            labelHidden
            placeholder="Select product variant"
            readOnly={true}
            value={formattedProductVariant}
            connectedRight={<Button onClick={activator.show}>Search</Button>}
            autoComplete="off"
          />
        )
      )
    },
    [formattedProductVariant, activator]
  )

  const productVariantOptions = [
    { value: 'existing', label: 'Existing', renderChildren: renderProductSelector },
    {
      value: 'removed',
      label: 'Deleted in Shopify',
    },
  ]

  filters.push({
    key: 'variant_id',
    label: 'Product variant',
    filter: (
      <>
        <ChoiceList
          title="Product variant"
          titleHidden
          choices={productVariantOptions}
          selected={[filter.variant_removed ? 'removed' : 'existing']}
          onChange={onSelectType}
        />
        <ProductVariantModal
          currency={currency}
          open={activator.open}
          onClose={activator.close}
          onSelect={onSelect}
        />
      </>
    ),
  })

  if (formattedProductVariant || filter.variant_removed) {
    appliedFilters.push({
      key: 'variant_id',
      label: `Product variant: ${
        filter.variant_removed ? 'deleted in Shopify' : formattedProductVariant
      }`,
      onRemove: onRemove,
    })
  }
}
