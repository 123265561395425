import { Text, TextContainer } from '@shopify/polaris'

import { BulkActionSubscriptionUpdateMethod } from '../common/bulk-actions'
import { pluralize, ShortDateTime } from '../common/helpers'
import { nextActionOptions } from '../common/subscription-models'
import {
  AddProductPayload,
  ApplyDiscountCodePayload,
  RemoveProductPayload,
  SwapProductPayload,
  UpdateNextActionPayload,
  UpdateShippingMethodPayload,
} from './common'

type SubscriptionsLabelPosition = 'prefix' | 'postfix'

const SubscriptionsLabel = ({
  total,
  position,
}: {
  total: number
  position?: SubscriptionsLabelPosition
}) => (
  <>
    {position === 'postfix' && ' '}
    <strong>{total}</strong> selected {pluralize(total, 'subscription')}
    {position === 'prefix' && ' '}
  </>
)

const SimpleActionSummary = ({
  subLabelPos,
  total,
  children,
}: {
  subLabelPos: SubscriptionsLabelPosition
  total: number
  children: any
}) => (
  <>
    {subLabelPos === 'prefix' && <SubscriptionsLabel total={total} position="prefix" />}
    {children}
    {subLabelPos === 'postfix' && <SubscriptionsLabel total={total} position="postfix" />}.
  </>
)

const UpdateNextActionSummary = ({
  total,
  payload,
}: {
  total: number
  payload: UpdateNextActionPayload
}) => {
  const action = (
    nextActionOptions.find(
      (opt: any) => opt.value === (payload as UpdateNextActionPayload).action
    ) as any
  ).label.toLowerCase()

  return (
    <SimpleActionSummary subLabelPos="prefix" total={total}>
      will be scheduled to <b>{action}</b> on{' '}
      <b>
        <ShortDateTime date={payload.date} />
      </b>
    </SimpleActionSummary>
  )
}

const UpdateShippingMethodSummary = ({
  total,
  payload,
}: {
  total: number
  payload: UpdateShippingMethodPayload
}) => (
  <SimpleActionSummary subLabelPos="postfix" total={total}>
    The{' '}
    {payload.title && (
      <>
        shipping method <b>title</b> will be changed to <b>{payload.title}</b>
      </>
    )}
    {payload.title && payload.price && ' and the '}
    {payload.price && (
      <>
        shipping <b>price</b> will be changed to <b>{payload.price}</b> (in the current subscription
        currency)
      </>
    )}{' '}
    in
  </SimpleActionSummary>
)

const BulkActionSummary = ({
  method,
  total,
  payload,
}: {
  method: BulkActionSubscriptionUpdateMethod
  total: number
  payload: any
}) => {
  switch (method) {
    case 'activate':
      return (
        <SimpleActionSummary subLabelPos="prefix" total={total}>
          will be <b>activated</b>
        </SimpleActionSummary>
      )
    case 'pause':
      return (
        <SimpleActionSummary subLabelPos="prefix" total={total}>
          will be <b>paused</b>
        </SimpleActionSummary>
      )
    case 'resume':
      return (
        <SimpleActionSummary subLabelPos="prefix" total={total}>
          will be <b>resumed</b>
        </SimpleActionSummary>
      )
    case 'cancel':
      return (
        <SimpleActionSummary subLabelPos="prefix" total={total}>
          will be <b>cancelled</b>
        </SimpleActionSummary>
      )
    case 'attempt-billing':
      return (
        <SimpleActionSummary subLabelPos="prefix" total={total}>
          will <b>charge</b> for the next order <b>immediately</b>
        </SimpleActionSummary>
      )
    case 'skip-order':
      return (
        <SimpleActionSummary subLabelPos="prefix" total={total}>
          will <b>skip</b> the next order
        </SimpleActionSummary>
      )
    case 'update-next-action':
      return <UpdateNextActionSummary total={total} payload={payload} />

    case 'add-product':
      return (
        <SimpleActionSummary subLabelPos="postfix" total={total}>
          <b>{(payload as AddProductPayload).variantTitle}</b> will be <b>added</b> to
        </SimpleActionSummary>
      )
    case 'swap-product':
      return (
        <SimpleActionSummary subLabelPos="postfix" total={total}>
          All line items with <b>{(payload as SwapProductPayload).sourceVariantTitle}</b> will be{' '}
          <b>swapped</b> to <b>{(payload as SwapProductPayload).variantTitle}</b> in
        </SimpleActionSummary>
      )
    case 'remove-product':
      return (
        <SimpleActionSummary subLabelPos="postfix" total={total}>
          All line items with <b>{(payload as RemoveProductPayload).variantTitle}</b> will be{' '}
          <b>removed</b> from
        </SimpleActionSummary>
      )
    case 'update-shipping-method':
      return <UpdateShippingMethodSummary total={total} payload={payload} />

    case 'apply-discount-code':
      return (
        <SimpleActionSummary subLabelPos="postfix" total={total}>
          The <b>{(payload as ApplyDiscountCodePayload).code}</b> discount code will be{' '}
          <b>applied</b> to
        </SimpleActionSummary>
      )
    case 'remove-discount':
      return (
        <SimpleActionSummary subLabelPos="postfix" total={total}>
          The active discount will be <b>removed</b> from
        </SimpleActionSummary>
      )
    default:
      return null
  }
}

export const SummarySection = ({
  method,
  total,
  payload,
}: {
  method: BulkActionSubscriptionUpdateMethod
  total: number
  payload: any
}) => (
  <TextContainer>
    <p>
      <BulkActionSummary method={method} total={total} payload={payload} />
    </p>
    <p>
      <Text as="span" color="subdued">
        Note: this bulk action will only be attempted on subscriptions which are eligible to it.
      </Text>
    </p>
  </TextContainer>
)
