const APP_ID = 'v67jkmqy'

export const loadIntercom = function () {
  var w = window
  var ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    var d = document
    var i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    w.Intercom = i
    var l = function () {
      var s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + APP_ID
      var x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
    } else if (w.attachEvent) {
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
}

export const setupIntercom = function (shopDetails) {
  const opts = {
    alignment: window.innerWidth > 770 ? 'right' : 'left',
    app_id: APP_ID,
    user_id: shopDetails.intercom_user_id,
    user_hash: shopDetails.intercom_user_hash,
    name: shopDetails.shop_owner,
    email: shopDetails.email,
    shopify_plan: shopDetails.plan_display_name,
    company: {
      id: shopDetails.myshopify_domain,
      name: shopDetails.name,
    },
    installed_shopwhirl: true,
    installed_shopwhirl_at: shopDetails.installed_shopwhirl_at,
    request_store_access: shopDetails.store_access_request_url,
  }

  window.Intercom('boot', opts)
}
