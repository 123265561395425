import { Modal, Text, VerticalStack } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'

import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { DialogActivator } from '../common/helpers'

export const KlaviyoBulkProfileImportDialog = ({
  activator,
  onStart,
}: {
  activator: DialogActivator
  onStart: () => void
}) => {
  const [started, setStarted] = useState(false)

  useEffect(() => {
    setStarted(false)
  }, [activator.showCounter, setStarted])

  const createBulkProfileImport = useApiActionState({
    method: 'POST',
    url: `/klaviyo/bulk-profile-import`,
    onSuccess: () => {
      onStart()
      activator.close()
    },
  })

  return (
    <>
      <Modal
        open={activator.open}
        title={'Import customer profiles to Klaviyo'}
        onClose={activator.close}
        primaryAction={{
          content: 'Import',
          onAction: () => {
            setStarted(true)
            createBulkProfileImport.run()
          },
          loading: createBulkProfileImport.loading,
          disabled: started,
        }}
        secondaryActions={[{ content: 'Cancel', onAction: activator.close }]}
        sectioned
      >
        <VerticalStack gap="2">
          <ApiActionBanner state={createBulkProfileImport} />
          <Text as="p">
            Import profiles of all your existing subscribers to your Klaviyo account. You only need
            to do it once.
          </Text>
          <Text as="p">
            Note: the import may take a while, depending on the amount of subscribers. You can close
            this page while it's running.
          </Text>
        </VerticalStack>
      </Modal>
      <ApiAction state={createBulkProfileImport} />
    </>
  )
}
