import {
  Banner,
  Icon,
  LegacyStack,
  Modal,
  ResourceList,
  Text,
  TextField,
  Thumbnail,
  VerticalStack,
} from '@shopify/polaris'
import { SearchMinor } from '@shopify/polaris-icons'
import React, { useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'

import { backendFetchResult } from './api'
import { GQLPaginator } from './gql-models-common'
import { queryString, useDebounced } from './helpers'

export interface ProductVariantResult {
  id: string
  restId: number
  title: string
  price: string
  productTitle: string
  variantTitle: string | null
  image: string | null
}

interface ProductVariantQuery {
  search: string
  results: ProductVariantResult[]
}

const searchProductVariants = async (
  _: any,
  search: string,
  currency: string
): Promise<ProductVariantQuery> => {
  const query = queryString({ search, currency, limit: 30 })
  const res = await backendFetchResult<GQLPaginator<ProductVariantResult>>(
    'GET',
    `/products/search-variants?${query}`
  )
  const results = res.status === 'success' && res.data ? res.data.nodes : []

  return { search, results }
}

const renderProductVariantResult = (
  result: ProductVariantResult,
  onSelect: (result: ProductVariantResult) => any
) => {
  return (
    <ResourceList.Item
      id={result.id}
      media={<Thumbnail source={result.image || ''} alt="" size="small" />}
      onClick={() => onSelect(result)}
      verticalAlignment="center"
    >
      <h3>
        <Text as="span" fontWeight="semibold">
          {result.productTitle}
        </Text>
        {result.variantTitle && <p>{result.variantTitle}</p>}
      </h3>
    </ResourceList.Item>
  )
}

interface ProductVariantModalContentProps {
  currency: string
  onSelect: (result: ProductVariantResult) => any
}

const ProductVariantModalContent = ({ currency, onSelect }: ProductVariantModalContentProps) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounced(search, 500)

  const [query, setQuery] = useState<ProductVariantQuery>({ search: '___', results: [] })
  const swr = useSWR(['product-variants', debouncedSearch, currency], searchProductVariants)

  const myRenderProductVariantResult = useCallback(
    (result: ProductVariantResult) => renderProductVariantResult(result, onSelect),
    [onSelect]
  )

  useEffect(() => {
    if (swr.data && swr.data.search === search) {
      setQuery(swr.data)
    }
  }, [swr.data, search, setQuery])

  return (
    <>
      <Modal.Section>
        <VerticalStack gap="2">
          <TextField
            label=""
            placeholder="Search"
            prefix={<Icon source={SearchMinor} />}
            value={search}
            onChange={setSearch}
            autoComplete="off"
          />

          <Banner status="info">
            Only products available in <Text as="strong">{currency}</Text> currency are being
            displayed.
          </Banner>
        </VerticalStack>
      </Modal.Section>
      <ResourceList
        items={query.results}
        renderItem={myRenderProductVariantResult}
        loading={query.search !== search}
        emptyState={
          <LegacyStack distribution="center">
            <p style={{ padding: '2rem' }}>No products found</p>
          </LegacyStack>
        }
      />
    </>
  )
}

interface Props {
  currency: string
  open: boolean
  onClose: () => any
  onSelect: (result: ProductVariantResult) => any
}

export const ProductVariantModal = (props: Props) => (
  <Modal title="Select product variant" open={props.open} onClose={props.onClose}>
    <ProductVariantModalContent currency={props.currency} onSelect={props.onSelect} />
  </Modal>
)
