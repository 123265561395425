import { SelectProps } from '@shopify/polaris'

import { MemoedSelect, mergeArrays } from './helpers'
import { HelpLabel } from './HelpIcon'
import {
  MemoedNumericSelect,
  MemoedOptionalNumericSelect,
  numbericIntervalCountOptions,
  numericRangeOptions,
  NumericSelectProps,
  OptionalNumericSelectOption,
  OptionalNumericSelectProps,
} from './NumericSelect'
import { SellingPlanInterval } from './plan-models'

const intervalOptions = [
  { label: 'Day(s)', value: SellingPlanInterval.DAY },
  { label: 'Week(s)', value: SellingPlanInterval.WEEK },
  { label: 'Month(s)', value: SellingPlanInterval.MONTH },
  { label: 'Year(s)', value: SellingPlanInterval.YEAR },
]

const intervalWithAnchorsOptions = [
  { label: 'Daily', value: SellingPlanInterval.DAY },
  { label: 'Weekly', value: SellingPlanInterval.WEEK },
  { label: 'Monthly', value: SellingPlanInterval.MONTH },
  { label: 'Yearly', value: SellingPlanInterval.YEAR },
  { label: 'On anchors', value: 'anchors' },
]

const anchorIntervalTypeOptions = [
  { label: 'Specific weekdays', value: SellingPlanInterval.WEEK },
  { label: 'Specific month days', value: SellingPlanInterval.MONTH },
  { label: 'Specific dates', value: SellingPlanInterval.YEAR },
]

interface IntervalFieldProps extends Omit<SelectProps, 'onChange' | 'options' | 'value'> {
  value: SellingPlanInterval
  hasMultipleAnchors?: boolean
  onChange: (value: SellingPlanInterval, id: string) => void
}

export const IntervalField = ({
  value,
  hasMultipleAnchors,
  onChange,
  ...props
}: IntervalFieldProps) => (
  <MemoedSelect
    options={hasMultipleAnchors ? intervalWithAnchorsOptions : intervalOptions}
    value={hasMultipleAnchors ? 'anchors' : value}
    onChange={(value: string, id: string) => onChange(value as SellingPlanInterval, id)}
    {...props}
  />
)

export const AnchorTypeField = ({ value, onChange, ...props }: IntervalFieldProps) => (
  <MemoedSelect
    options={anchorIntervalTypeOptions}
    value={value}
    onChange={(value: string, id: string) => onChange(value as SellingPlanInterval, id)}
    {...props}
  />
)

type IntervalCountFieldProps = Omit<NumericSelectProps, 'options'>

export const IntervalCountField = (props: IntervalCountFieldProps) => (
  <MemoedNumericSelect options={numbericIntervalCountOptions()} {...props} />
)

const cyclesOptions: OptionalNumericSelectOption[] = numericRangeOptions(1, 360)
const minCyclesOptions = mergeArrays([{ label: 'Disabled', value: null }], cyclesOptions)
const maxCyclesOptions = mergeArrays([{ label: 'Unlimited', value: null }], cyclesOptions)

type CyclesFieldProps = Omit<OptionalNumericSelectProps, 'label' | 'options'>

export const MinCyclesField = (props: CyclesFieldProps) => (
  <MemoedOptionalNumericSelect
    label={
      <HelpLabel tooltip="Minimum number of billing cycles before the customer can cancel">
        Min number of payments
      </HelpLabel>
    }
    options={minCyclesOptions}
    {...props}
  />
)

export const MaxCyclesField = (props: CyclesFieldProps) => (
  <MemoedOptionalNumericSelect
    label={
      <HelpLabel tooltip="The number of billing cycles after which the subscription automatically expires">
        Max number of payments
      </HelpLabel>
    }
    options={maxCyclesOptions}
    {...props}
  />
)

export const MaxCyclesPrepaidField = (props: CyclesFieldProps) => (
  <MemoedOptionalNumericSelect
    label={
      <HelpLabel tooltip="When set to 'Yes', subscriptions will automatically renew until they are cancelled. When set to 'No', subscriptions will expire after the first order.">
        Renews automatically
      </HelpLabel>
    }
    options={[
      { label: 'Yes', value: null },
      { label: 'No', value: 1 },
    ]}
    {...props}
  />
)
