import useSWR from 'swr'

import { backendFetchResult, backendFetchResultData } from '../common/api'
import { formatOrdinal, idToGid } from '../common/helpers'
import { WorkflowGroup } from '../common/workflow-group-models'
import {
  Workflow,
  WorkflowAction,
  WorkflowFilter,
  WorkflowRootFilter,
  WorkflowRootFilterType,
  WorkflowTriggerType,
} from '../common/workflow-models'

export interface SubscriptionFlowWorkflowFormData {
  id?: number
  afterOrderNumber: number
  triggers: WorkflowTriggerType[]
  extraFilters: WorkflowFilter[]
  actions: WorkflowAction[]
  _random?: number
  _newlyAdded: boolean
}

export interface SubscriptionFlowFormData {
  name: string
  is_active: boolean
  rootFilterType: WorkflowRootFilterType
  filters: WorkflowFilter[]
  workflows: SubscriptionFlowWorkflowFormData[]
}

export type WorkflowGroupWorkflowInput = Omit<Workflow, 'id'> & { id?: number }
export type WorkflowGroupInput = Omit<WorkflowGroup, 'id' | 'workflows'> & {
  workflows: WorkflowGroupWorkflowInput[]
}

export const initialSubscriptionFlowWorkflowFormData = (
  afterOrderNumber: number
): SubscriptionFlowWorkflowFormData => {
  return {
    afterOrderNumber,
    triggers: (afterOrderNumber === 1 ? ['subscription-activated'] : []).concat([
      'subscription-payment-succeeded',
    ]) as WorkflowTriggerType[],
    extraFilters: [],
    actions: [],
    _newlyAdded: true,
    _random: Math.random(),
  }
}

export interface SubscriptionFlowInitialParams {
  sellingPlanGroupId?: string
  sellingPlanId?: string
}

const initialSubscriptionFlowFilters = (initialParams: SubscriptionFlowInitialParams) => {
  if (initialParams.sellingPlanGroupId) {
    return [
      [
        'subscription-contains-any-of-selected-selling-plan-groups',
        [idToGid(initialParams.sellingPlanGroupId, 'SellingPlanGroup')],
      ] as WorkflowFilter,
    ]
  }

  if (initialParams.sellingPlanId) {
    return [
      [
        'subscription-contains-any-of-selected-selling-plans',
        [idToGid(initialParams.sellingPlanId, 'SellingPlan')],
      ] as WorkflowFilter,
    ]
  }

  return []
}

export const initialSubscriptionFlowFormData = (
  initialParams: SubscriptionFlowInitialParams = {}
): SubscriptionFlowFormData => ({
  name: '',
  is_active: false,
  rootFilterType: 'and',
  filters: initialSubscriptionFlowFilters(initialParams),
  workflows: [],
})

export const subscriptionFlowWorkflowFormExtraFilters = (workflowFilters: WorkflowFilter[]) => {
  return workflowFilters.filter((filter) => filter[0] !== 'after-nth-order')
}

export const subscriptionFlowWorkflowAfterOrderNumber = (workflow: Workflow): number => {
  if (workflow.filter.length < 1) {
    return 0
  }

  const workflowFilters = workflow.filter[1]
  const index = workflowFilters.findIndex((filter) => filter[0] === 'after-nth-order')

  return workflow.filter[1][index][1][0]
}

export const convertWorkflowGroupToSubscriptionFlowFormData = (
  workflowGroup: WorkflowGroup
): SubscriptionFlowFormData => {
  const rootGroupFilterType = workflowGroup.filter.length > 1 ? workflowGroup.filter[0] : 'and'
  const groupFilters = workflowGroup.filter.length > 1 ? workflowGroup.filter[1] : []
  const workflows = workflowGroup.workflows ?? []

  return {
    name: workflowGroup.name || '',
    is_active: workflows.length > 0 ? workflows[0].is_active : false,
    rootFilterType: rootGroupFilterType,
    filters: groupFilters,
    workflows: workflows
      .map((workflow) => {
        return {
          id: workflow.id,
          afterOrderNumber: subscriptionFlowWorkflowAfterOrderNumber(workflow),
          triggers: workflow.triggers,
          extraFilters: subscriptionFlowWorkflowFormExtraFilters(workflow.filter[1] ?? []),
          actions: workflow.actions,
          _random: Math.random(),
          _newlyAdded: false,
        }
      })
      .sort((a, b) => a.afterOrderNumber - b.afterOrderNumber),
  }
}

export const subscriptionFlowWorkflowName = (data: SubscriptionFlowWorkflowFormData) =>
  `Applies to ${formatOrdinal(data.afterOrderNumber + 1)} order`

const convertSubscriptionFlowWorkflowFormDataToInput = (
  data: SubscriptionFlowWorkflowFormData,
  is_active: boolean
): WorkflowGroupWorkflowInput => {
  const filtersInput: WorkflowRootFilter = [
    'and',
    [['after-nth-order', [data.afterOrderNumber]], ...data.extraFilters],
  ]

  return {
    id: data.id,
    name: subscriptionFlowWorkflowName(data),
    is_active,
    triggers: data.triggers,
    filter: filtersInput,
    actions: data.actions,
  }
}

const convertSubscriptionFlowFormDataToWorkflowGroupInput = (
  data: SubscriptionFlowFormData
): WorkflowGroupInput => {
  const groupFilter: WorkflowRootFilter = [
    data.filters.length > 1 ? data.rootFilterType : 'and',
    data.filters,
  ]

  return {
    name: data.name,
    filter: groupFilter,
    workflows: data.workflows.map((workflow) =>
      convertSubscriptionFlowWorkflowFormDataToInput(workflow, data.is_active)
    ),
  }
}

export const createOrUpdateWorkflowGroup = async (data: SubscriptionFlowFormData, id?: string) => {
  const input = convertSubscriptionFlowFormDataToWorkflowGroupInput(data)
  const method = id ? 'PUT' : 'POST'
  const url = id ? `/workflow-groups/${id}` : '/workflow-groups'
  const body = JSON.stringify(input)

  return backendFetchResult<WorkflowGroup>(method, url, { body })
}

const fetchWorkflowGroups = () => backendFetchResultData<WorkflowGroup[]>('GET', '/workflow-groups')

export const fetchWorkflowGroupFormData = async (_: any, id: string) => {
  const workflowGroup = await backendFetchResultData<WorkflowGroup>('GET', `/workflow-groups/${id}`)
  const formData = convertWorkflowGroupToSubscriptionFlowFormData(workflowGroup)

  return formData
}

export const useWorkflowGroupsSWR = () =>
  useSWR<WorkflowGroup[]>(['workflows-groups'], fetchWorkflowGroups)
