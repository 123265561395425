import { Label, LegacyStack } from '@shopify/polaris'
import React from 'react'

import { MemoedSelect, pluralize, range } from '../common/helpers'

export type PaymentNumberFilterParams = [string]

interface PaymentNumberFilterProps {
  params: PaymentNumberFilterParams
  onChange: (params: PaymentNumberFilterParams, index: number) => any
  index: number
}

const paymentNumberOptions = range(1, 100).map((i) => ({
  label: `${i}`,
  value: `${i}`,
}))

export const PaymentNumberFilter = (props: PaymentNumberFilterProps) => {
  const { params, onChange, index } = props
  const [paymentNumber] = params

  return (
    <LegacyStack alignment="center">
      <Label id="title">Subscription has {` `}</Label>
      <MemoedSelect
        label="Content"
        labelHidden
        value={paymentNumber}
        onChange={(val: string) => onChange([val], index)}
        options={paymentNumberOptions}
      />
      <Label id="title">
        {` `}
        {pluralize(parseInt(paymentNumber), 'order')}
      </Label>
    </LegacyStack>
  )
}
