import { HorizontalStack, Icon, Text, Tooltip } from '@shopify/polaris'
import { QuestionMarkMajor } from '@shopify/polaris-icons'

import styles from './HelpIcon.module.css'
interface HelpIconProps {
  children: any
}

export const LegacyHelpIcon = ({ children }: HelpIconProps) => (
  <Tooltip content={children} dismissOnMouseOut={false}>
    <span className={styles.wrap}>
      &nbsp;
      <Icon source={QuestionMarkMajor} color="base" />
    </span>
  </Tooltip>
)

interface LegacyHelpLabelProps {
  title: string
  children: any
}

export const LegacyHelpLabel = ({ title, children }: LegacyHelpLabelProps) => (
  <span>
    {title} <LegacyHelpIcon>{children}</LegacyHelpIcon>
  </span>
)

export const HelpIcon = ({ children }: HelpIconProps) => (
  <Tooltip content={children} dismissOnMouseOut={false}>
    <Icon source={QuestionMarkMajor} color="base" />
  </Tooltip>
)

interface HelpLabelProps {
  tooltip: any
  children: any
}

export const HelpLabel = ({ tooltip, children }: HelpLabelProps) => (
  <HorizontalStack blockAlign="center">
    <Text as="span">{children}</Text>&nbsp;
    <HelpIcon>{tooltip}</HelpIcon>
  </HorizontalStack>
)

export const HelpTitle = ({ title, children }: LegacyHelpLabelProps) => (
  <Text variant="headingMd" as="h2">
    {title}
    <LegacyHelpIcon>{children}</LegacyHelpIcon>
  </Text>
)
