import {
  Button,
  ButtonGroup,
  Checkbox,
  FormLayout,
  LegacyCard,
  LegacyStack,
  Select,
  Text,
} from '@shopify/polaris'
import { useFormikContext } from 'formik'

import { CodeSnippet } from '../common/CodeSnippet'
import {
  useDialogActivator,
  useFormikHandleChangeCallback,
  useShopFlag,
  useSystemData,
} from '../common/helpers'
import { LegacyHelpLabel } from '../common/HelpIcon'
import { useShopShopifySettingsSWR } from '../common/shopify-settings'
import { UserSettings } from './api'
import { CustomColorsModal } from './CustomColorsModal'
import { CustomScriptUrlModal } from './CustomScriptUrlModal'
import { CustomStylesModal } from './CustomStylesModal'

export const CustomerPortalThemeCard = () => {
  const customStylesActivator = useDialogActivator()
  const customScriptUrlActivator = useDialogActivator()
  const customColorsActivator = useDialogActivator()
  const customScriptUrlFlagEnabled = useShopFlag('enable_customer_portal_custom_script_url')
  const systemData = useSystemData()
  const shopifySettings = useShopShopifySettingsSWR()

  const formik = useFormikContext<UserSettings>()
  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)

  const standaloneEnabled =
    formik.values.standalone_customer_portal ||
    shopifySettings.data?.shopifyNewCustomerAccountsEnabled

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <LegacyStack vertical spacing="tight">
          <Text as="p">
            Add or share this URL anywhere you'd like to give customers access to the customer
            portal.
          </Text>
          <CodeSnippet code={systemData?.customertPortalUrl ?? ''} showCopyButton={true} />
        </LegacyStack>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <FormLayout>
          <Checkbox
            id="standalone_customer_portal"
            name="standalone_customer_portal"
            label={
              <LegacyHelpLabel title="Enable standalone version">
                <>
                  Enabling this setting directs your customers to the standalone version of the
                  customer portal, which supports only the 'Modern' theme. The standalone version is
                  also the default when New Customer Accounts are enabled in Shopify.
                </>
              </LegacyHelpLabel>
            }
            disabled={shopifySettings.data?.shopifyNewCustomerAccountsEnabled}
            checked={standaloneEnabled}
            onChange={handleChange}
          />
          <Select
            id="customer_portal_theme"
            name="customer_portal_theme"
            label="Theme"
            options={[
              { label: 'Classic', value: 'classic' },
              { label: 'Modern', value: 'modern' },
            ]}
            value={standaloneEnabled ? 'modern' : formik.values.customer_portal_theme}
            onChange={handleChange}
            disabled={standaloneEnabled}
          />
          {(standaloneEnabled || formik.values.customer_portal_theme === 'modern') && (
            <Text as="span" color="subdued">
              Note: <b>Modern</b> does not support the legacy{' '}
              <b>paywhirl-customer-portal-theme.css.liquid</b> snippet. Use{' '}
              <b>Add custom CSS rules</b> instead.
            </Text>
          )}
        </FormLayout>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <LegacyStack vertical>
          <ButtonGroup>
            {(standaloneEnabled || formik.values.customer_portal_theme === 'modern') && (
              <Button onClick={customColorsActivator.show}>Customize</Button>
            )}
            <Button onClick={customStylesActivator.show} plain>
              Add custom CSS rules
            </Button>
            {customScriptUrlFlagEnabled && (
              <Button onClick={customScriptUrlActivator.show} plain>
                Add custom Script URL
              </Button>
            )}
            <CustomStylesModal activator={customStylesActivator} />
            <CustomScriptUrlModal activator={customScriptUrlActivator} />
            <CustomColorsModal activator={customColorsActivator} />
          </ButtonGroup>
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  )
}
