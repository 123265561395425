import { LegacyStack, Loading, Modal, Spinner } from '@shopify/polaris'
import React, { useState } from 'react'

import { LoadErrorBanner } from '../common/LoadErrorPage'
import { MailSettings, useMailSample } from './api'

const PreviewContent = ({ sample }: { sample: MailSettings }) => {
  const [iframeHeight, setIframeHeight] = useState(200)

  const onIframeLoad = (evt: React.SyntheticEvent<HTMLIFrameElement>) => {
    const iframe = evt.target as HTMLIFrameElement
    if (iframe && iframe.contentWindow) {
      try {
        setIframeHeight(iframe.contentWindow.document.body.scrollHeight + 20)
      } catch {
        setIframeHeight(400)
      }
    }
  }

  return (
    <LegacyStack vertical>
      <p>
        <b>Subject:</b> {sample.subject}
      </p>
      <iframe
        srcDoc={sample.html}
        style={{
          height: `${iframeHeight}px`,
          width: '100%',
          border: '1px solid #d3dbe2',
          borderRadius: '3px',
        }}
        title="Preview"
        onLoad={onIframeLoad}
      />
    </LegacyStack>
  )
}

interface Props {
  mailType: string
  open: boolean
  onClose: () => any
}

export const PreviewDialog = (props: Props) => {
  const { data, error, isValidating } = useMailSample(props.mailType)

  return (
    <Modal
      title="Preview"
      sectioned
      open={props.open}
      onClose={props.onClose}
      secondaryActions={[{ content: 'Close', onAction: props.onClose }]}
    >
      {isValidating && <Loading />}

      {error && <LoadErrorBanner />}
      {!error && !data && (
        <LegacyStack distribution="center">
          <Spinner />
        </LegacyStack>
      )}
      {!error && data && <PreviewContent sample={data} />}
    </Modal>
  )
}
