import { Layout, LegacyCard, SkeletonBodyText, SkeletonPage } from '@shopify/polaris'
import React from 'react'

export const Skeleton = () => (
  <SkeletonPage title="Payment schedule" primaryAction backAction>
    <Layout sectioned>
      <LegacyCard sectioned>
        <SkeletonBodyText lines={10} />
      </LegacyCard>
    </Layout>
  </SkeletonPage>
)
