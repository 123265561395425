import {
  Banner,
  Button,
  FormLayout,
  LegacyCard,
  LegacyStack,
  Select,
  Text,
  TextField,
} from '@shopify/polaris'
import { useFormikContext } from 'formik'

import {
  InternalLink,
  useDialogActivator,
  useFormikHandleChangeCallback,
  useFormikHandleNumberFromStringChangeCallback,
  useShopFlag,
  useShopPermission,
} from '../common/helpers'
import { ScopesNeededModal } from '../common/ScopesNeededModal'
import { settingsUrl } from '../common/urls'
import * as urls from '../common/urls'
import {
  lowInventoryActionNone,
  lowInventoryActionPauseSubscription,
  lowInventoryActionSkipOrder,
  UserSettings,
} from './api'

export const InventoryManagementCard = () => {
  const hasInventoryScopes = useShopFlag('has_inventory_scopes')
  const inventoryManagementEnabled = useShopPermission('inventoryManagementEnabled')
  const scopesDialogActivator = useDialogActivator()
  const formik = useFormikContext<UserSettings>()

  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)
  const handleNumberFromStringChange = useFormikHandleNumberFromStringChangeCallback(
    formik.setFieldValue
  )

  if (!inventoryManagementEnabled) {
    return (
      <LegacyCard sectioned>
        <Banner status="warning">
          <Text as="p">
            Please{' '}
            <InternalLink url={urls.pricingPlansUrl()} target={'_blank'}>
              upgrade
            </InternalLink>{' '}
            to our <Text as="strong">Traction Plan</Text> to access our inventory management
            features and enjoy benefits like automatic order skipping or pausing when stock is low.
          </Text>
        </Banner>
      </LegacyCard>
    )
  }

  return (
    <LegacyCard sectioned>
      <FormLayout>
        <LegacyStack alignment="trailing">
          <LegacyStack.Item fill>
            <Select
              id="low_inventory_action"
              name="low_inventory_action"
              label={<span>Low inventory action</span>}
              options={[
                {
                  label: 'Skip order',
                  value: lowInventoryActionSkipOrder,
                  disabled: !hasInventoryScopes,
                },
                {
                  label: 'Pause subscription',
                  value: lowInventoryActionPauseSubscription,
                  disabled: !hasInventoryScopes,
                },
                {
                  label: 'Continue placing orders',
                  value: lowInventoryActionNone,
                },
              ]}
              value={formik.values.low_inventory_action}
              onChange={handleChange}
            />
          </LegacyStack.Item>
          {!hasInventoryScopes && (
            <>
              <Button onClick={scopesDialogActivator.show}>Enable</Button>
              <ScopesNeededModal
                activator={scopesDialogActivator}
                pagePath={settingsUrl()}
                requestActionUrl="/settings/request-low-inventory-tracking"
              >
                <p>
                  To start using low inventory handling, you need to accept additional permissions
                  in Shopify.
                </p>
              </ScopesNeededModal>
            </>
          )}
        </LegacyStack>
        {formik.values.low_inventory_action !== 'none' && (
          <TextField
            id="low_inventory_threshold"
            name="low_inventory_threshold"
            disabled={!hasInventoryScopes}
            label={<span>Prevent inventory quantity from falling below:</span>}
            type="number"
            min={0}
            step={1}
            autoComplete="off"
            value={formik.values.low_inventory_threshold.toString()}
            onChange={handleNumberFromStringChange}
          />
        )}
      </FormLayout>
    </LegacyCard>
  )
}
