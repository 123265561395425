import { AppliedFilterInterface, FilterInterface, Select } from '@shopify/polaris'
import { useCallback } from 'react'

import { currencyOptions } from '../../common/currencies'
import { SubscriptionFilter } from '../api'

export const useAddCurrencyFilter = (
  filter: SubscriptionFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any,
  filters: FilterInterface[],
  appliedFilters: AppliedFilterInterface[]
) => {
  const setCurrency = useCallback((currency?: string) => updateFilter({ currency }), [updateFilter])
  const clearCurrency = useCallback(() => setCurrency(undefined), [setCurrency])

  filters.push({
    key: 'currency',
    label: 'Currency',
    filter: (
      <Select
        label="Currency"
        labelHidden
        options={currencyOptions}
        value={filter.currency}
        onChange={setCurrency}
        placeholder="Select currency"
      />
    ),
  })

  if (filter.currency) {
    appliedFilters.push({
      key: 'currency',
      label: `Currency: ${filter.currency}`,
      onRemove: clearCurrency,
    })
  }
}
