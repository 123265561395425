import { Attribute, LineItem } from './subscription-models'

// One time line items
const limitedCyclesValuePattern = /^until-(\d+)$/
export const isLimitedCyclesAttribute = (attr: Attribute) =>
  attr.value?.match(limitedCyclesValuePattern)

export const hasLimitedCyclesAttribute = (customAttributes: Attribute[]) =>
  (customAttributes ?? []).some((attr) => isLimitedCyclesAttribute(attr))

export const isLimitedCyclesLineItem = (lineItem: LineItem): boolean =>
  (lineItem.customAttributes ?? []).some((attr) => isLimitedCyclesAttribute(attr))

export const removeLimitedCyclesAttributes = (customAttributes: Attribute[]): Attribute[] =>
  customAttributes.filter((attr) => !isLimitedCyclesAttribute(attr))

export const getUntilInstallmentNumber = (lineCustomAttriutes: Attribute[]): number | null => {
  const untilAttribute = lineCustomAttriutes.find((attr) => isLimitedCyclesAttribute(attr))
  const untilValue = untilAttribute?.value?.split('-')[1] ?? null
  return untilValue ? parseInt(untilValue) : null
}

export const getRemainingInstallmentsNumber = (
  lineCustomAttriutes: Attribute[],
  totalInstallments: number
): number | null => {
  const untilInstallment = getUntilInstallmentNumber(lineCustomAttriutes)
  return untilInstallment ? untilInstallment - totalInstallments : null
}

// Added by workflow to all upcoming payments
const allInstallmentsValuePattern = /^until-$/
export const isAllInstallmentsAttribute = (attr: Attribute) =>
  attr.value?.match(allInstallmentsValuePattern)

export const hasAllInstallmentsAttribute = (customAttributes: Attribute[]) =>
  (customAttributes ?? []).some((attr) => isAllInstallmentsAttribute(attr))

export const removeAllInstallmentsAttributes = (customAttributes: Attribute[]): Attribute[] =>
  customAttributes.filter((attr) => !isAllInstallmentsAttribute(attr))

export const isAddedByWorkflow = (lineItem: LineItem): boolean =>
  (lineItem.customAttributes ?? []).some((attr) => attr.key === 'Workflow')

export const addLimitedCyclesAttribute = (
  customAttributes: Attribute[],
  until: number
): Attribute[] => {
  const oneTimeAttr = { key: '_pw_fixed_cycles', value: `until-${until}` }

  let newCustomerAttributes = removeAllInstallmentsAttributes(customAttributes)
  newCustomerAttributes = removeLimitedCyclesAttributes(newCustomerAttributes)

  return [...newCustomerAttributes, oneTimeAttr]
}
