// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HelpIcon_wrap__Zj\\+bO .Polaris-Icon {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/common/HelpIcon.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".wrap :global(.Polaris-Icon) {\n  display: inline-block;\n  vertical-align: middle;\n  margin-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `HelpIcon_wrap__Zj+bO`
};
export default ___CSS_LOADER_EXPORT___;
