import { Button, LegacyStack, Text } from '@shopify/polaris'
import React, { useCallback, useState } from 'react'

import { ProductResult } from './api'
import styles from './ProductResourceItem.module.css'

export const ProductResourceItem = ({
  product,
  disableButtons,
  attachProducts,
  detachProducts,
}: {
  product: ProductResult
  disableButtons: boolean
  attachProducts: (gids: string[]) => any
  detachProducts: (gids: string[]) => any
}) => {
  const [loading, setLoading] = useState(false)

  const onAttach = useCallback(async () => {
    setLoading(true)
    await attachProducts([product.id])
    setLoading(false)
  }, [product, attachProducts])

  const onDetach = useCallback(async () => {
    setLoading(true)
    await detachProducts([product.id])
    setLoading(false)
  }, [product, detachProducts])

  return (
    <LegacyStack alignment="center" wrap={false}>
      <LegacyStack.Item fill>
        <Text as="span" fontWeight="semibold">
          {product.title}
        </Text>
      </LegacyStack.Item>
      {!product.attached && (
        <span className={styles.buttonWrap}>
          <Button onClick={onAttach} loading={loading} disabled={disableButtons}>
            Add
          </Button>
        </span>
      )}
      {product.attached && (
        <span className={styles.buttonWrap}>
          <Button onClick={onDetach} loading={loading} disabled={disableButtons} destructive>
            Remove
          </Button>
        </span>
      )}
    </LegacyStack>
  )
}
