import { Modal } from '@shopify/polaris'
import React, { useState } from 'react'

import { useApiActionState } from '../common/ApiAction'
import { DialogActivator, ExternalRedirect } from '../common/helpers'

interface ScopesNeededModalProps {
  children: any
  activator: DialogActivator
  pagePath: string
  requestActionUrl: string
}
export const ScopesNeededModal = ({
  children,
  activator,
  pagePath,
  requestActionUrl,
}: ScopesNeededModalProps) => {
  const [redirectActive, setRedirectActive] = useState(false)

  const requestAction = useApiActionState({
    url: requestActionUrl,
    method: 'POST',
    onSuccess: () => setRedirectActive(true),
  })

  return (
    <Modal
      open={activator.open}
      onClose={activator.close}
      title="PayWhirl"
      primaryAction={{
        content: 'Continue',
        onAction: requestAction.run,
        loading: requestAction.loading,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: activator.close,
        },
      ]}
      sectioned
    >
      {children}
      <ExternalRedirect path={pagePath} active={redirectActive} />
    </Modal>
  )
}
