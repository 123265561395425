import { RGBColor } from '@shopify/polaris'

export const hexToRgb = function (color: string): RGBColor {
  const match = color.match(/[a-f0-9]{6}|[a-f0-9]{3}/i)
  if (!match) {
    return { red: 0, green: 0, blue: 0 }
  }

  let colorString = match[0]

  if (match[0].length === 3) {
    colorString = colorString
      .split('')
      .map((char) => char + char)
      .join('')
  }

  const integer = parseInt(colorString, 16)
  const r = (integer >> 16) & 0xff
  const g = (integer >> 8) & 0xff
  const b = integer & 0xff

  return { red: r, green: g, blue: b }
}
