import { Button, ButtonProps, Modal } from '@shopify/polaris'

import { DialogActivator, useDialogActivator } from './helpers'

export const VideoModal = ({
  activator,
  src,
  title,
}: {
  activator: DialogActivator
  src: string
  title: string
}) => (
  <Modal open={activator.open} onClose={activator.close} title={title} large sectioned>
    <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 0,
        }}
        src={src}
        title="Video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </Modal>
)

interface VideoModalButtonProps extends ButtonProps {
  src: string
  title: string
}

export const VideoModalButton = ({ src, title, ...props }: VideoModalButtonProps) => {
  const activator = useDialogActivator()

  return (
    <>
      <Button onClick={activator.show} {...props} />
      <VideoModal activator={activator} src={src} title={title} />
    </>
  )
}
