import { LegacyCard, LegacyStack, List } from '@shopify/polaris'
import React from 'react'

const variables = [
  'contact_email',
  'payment_retry_days',
  'payment_notification_days',
  'payment_method',
  'payment_error_message',
  'portal_link',
  'shop_name',
  'shop_link',
  'subject',
  'subscription_id',
  'subscription_link',
  'customer_first_name',
  'customer_last_name',
  'customer_email',
  'customer_address',
]

export const VariablesCard = () => (
  <LegacyCard sectioned subdued title="Variables">
    <LegacyStack vertical>
      <p>You can use the following variables in your templates:</p>
      <List type="bullet">
        {variables.map((variable, index) => (
          <List.Item key={index}>
            <code style={{ background: '#ebeef0' }}>{`{{${variable}}}`}</code>
          </List.Item>
        ))}
      </List>
      <p>Other variables and control structures are currently unavailable</p>
    </LegacyStack>
  </LegacyCard>
)
