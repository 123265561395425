import { SellingPlanInterval } from './plan-models'

export type WorkflowTriggerType =
  | 'subscription-activated'
  | 'subscription-paused'
  | 'subscription-resumed'
  | 'subscription-cancelled'
  | 'subscription-expired'
  | 'subscription-payment-failed-retrying'
  | 'subscription-payment-failed-last-attempt'
  | 'subscription-payment-succeeded'

export const workflowTriggerLabels: Record<WorkflowTriggerType, string> = {
  'subscription-activated': 'Subscription is created',
  'subscription-paused': 'Subscription is paused',
  'subscription-resumed': 'Subscription is resumed',
  'subscription-cancelled': 'Subscription is cancelled',
  'subscription-expired': 'Subscription expires',
  'subscription-payment-failed-retrying': 'Subscription payment fails (retrying)',
  'subscription-payment-failed-last-attempt': 'Subscription payment fails (last attempt)',
  'subscription-payment-succeeded': 'Subscription payment succeeds',
}

export const workflowTriggerTypes = Object.keys(workflowTriggerLabels) as WorkflowTriggerType[]

export const workflowTriggersSupportOrderTags = (triggers: WorkflowTriggerType[]): boolean =>
  triggers.includes('subscription-activated') || triggers.includes('subscription-payment-succeeded')

export type WorkflowFilterType =
  | 'subscription-contains-any-of-selected-selling-plans'
  | 'subscription-contains-any-of-selected-selling-plan-groups'
  | 'subscription-contains-any-of-selected-products'
  | 'subscription-does-not-contain-any-of-selected-products'
  | 'subscription-contains-all-selected-products'
  | 'subscription-contains-any-of-selected-product-variants'
  | 'subscription-does-not-contain-any-of-selected-product-variants'
  | 'subscription-contains-all-selected-product-variants'
  | 'subscription-contains-any-matching-product-title'
  | 'subscription-contains-any-matching-product-variant-title'
  | 'customer-has-no-active-subscriptions'
  | 'after-nth-order'

export const workflowFilterLabels: Record<WorkflowFilterType, string> = {
  'subscription-contains-any-of-selected-selling-plans':
    'Subscription contains ANY of the selected selling plans',
  'subscription-contains-any-of-selected-selling-plan-groups':
    'Subscription contains ANY of the selected selling plan groups',
  'subscription-contains-any-of-selected-products':
    'Subscription contains ANY of the selected products',
  'subscription-does-not-contain-any-of-selected-products':
    'Subscription DOES NOT contain ANY of the selected products',
  'subscription-contains-all-selected-products':
    'Subscription contains ALL of the selected products',
  'subscription-contains-any-of-selected-product-variants':
    'Subscription contains ANY of the selected product variants',
  'subscription-does-not-contain-any-of-selected-product-variants':
    'Subscription DOES NOT contain ANY of the selected product variants',
  'subscription-contains-all-selected-product-variants':
    'Subscription contains ALL of the selected product variants',
  'subscription-contains-any-matching-product-title':
    'Subscription contains ANY product with matching title',
  'subscription-contains-any-matching-product-variant-title':
    'Subscription contains ANY product variant with matching title',
  'customer-has-no-active-subscriptions': 'Customer has no active subscriptions',
  'after-nth-order': 'Total orders count',
}

export const workflowFilterTypes = Object.keys(workflowFilterLabels) as WorkflowFilterType[]

export type WorkflowRootFilterType = 'and' | 'or'

export type WorkflowEmptyDataType = []
export type WorkflowTagsDataType = string[]
export type WorkflowProductDataType = [] | [string, string]
export type WorkflowSwapProductDataType =
  | [
      WorkflowProductDataType, // sourceProduct
      WorkflowProductDataType, // targetProduct
      number | null, // quantity
      string | null // currentPrice
    ]
  | []
export type WorkflowAddProductDataType =
  | [
      WorkflowProductDataType,
      number, // quantity
      string, // currentPrice
      number | null // recurringCycleLimit
    ]
  | []
export type WorkflowAddDiscountDataType = [
  string, // discountCodeName
  string | null, // recurringCycleLimit
  'percentage' | 'fixedAmount', // discountType
  string, // discountPercentage or discountFixedAmount
  number? // appliesOnEachItem
]

export type WorkflowEditDeliveryFrequencyDataType = [
  number, // interval frequency
  SellingPlanInterval, // interval type
  number // pre-paid deliveries (1 for non pre-paid subscriptions)
]

export type WorkflowActionAddCustomerTagsType = ['add-customer-tags', WorkflowTagsDataType]
export type WorkflowActionRemoveCustomerTagsType = ['remove-customer-tags', WorkflowTagsDataType]
export type WorkflowActionAddOrderTagsType = ['add-order-tags', WorkflowTagsDataType]
export type WorkflowActionAddOneTimeGiftProductType = [
  'add-one-time-gift-product',
  WorkflowProductDataType
]
export type WorkflowActionAddProductType = ['add-product', WorkflowAddProductDataType]
export type WorkflowActionRemoveProductType = ['remove-product', WorkflowProductDataType]
export type WorkflowActionRemoveProductVariantType = [
  'remove-product-variant',
  WorkflowProductDataType
]
export type WorkflowActionSwapProductType = ['swap-product', WorkflowSwapProductDataType]
export type WorkflowActionAddDiscountType = ['add-discount', WorkflowAddDiscountDataType]
export type WorkflowActionRemoveOneTimeGiftProductType = [
  'remove-one-time-gift-products',
  WorkflowEmptyDataType
]
export type WorkflowActionRemoveExpiredDiscountType = [
  'remove-expired-discount',
  WorkflowEmptyDataType
]

export type WorkflowActionEditDeliveryFrequencyType = [
  'edit-delivery-frequency',
  WorkflowEditDeliveryFrequencyDataType
]

export type WorkflowAction =
  | WorkflowActionAddCustomerTagsType
  | WorkflowActionRemoveCustomerTagsType
  | WorkflowActionAddOrderTagsType
  | WorkflowActionAddOneTimeGiftProductType
  | WorkflowActionAddProductType
  | WorkflowActionSwapProductType
  | WorkflowActionRemoveProductType
  | WorkflowActionRemoveProductVariantType
  | WorkflowActionAddDiscountType
  | WorkflowActionRemoveOneTimeGiftProductType
  | WorkflowActionRemoveExpiredDiscountType
  | WorkflowActionEditDeliveryFrequencyType

type WorkflowActionTypeMapping<T extends WorkflowAction> = T[0]
type WorkflowActionDataMapping<T extends WorkflowAction> = T[1]
export type WorkflowActionType = WorkflowActionTypeMapping<WorkflowAction>
export type WorkflowActionTagType = Extract<
  WorkflowActionType,
  'add-customer-tags' | 'remove-customer-tags' | 'add-order-tags'
>

export type WorkflowActionDataType = WorkflowActionDataMapping<WorkflowAction>

export const workflowActionLabels: Record<WorkflowActionType, string> = {
  'add-customer-tags': 'Add tags to the customer',
  'remove-customer-tags': 'Remove tags from the customer',
  'add-order-tags': 'Add tags to the order',
  'add-one-time-gift-product': 'Add one-time free gift product',
  'add-product': 'Add product',
  'swap-product': 'Swap product',
  'remove-product': 'Remove product',
  'remove-product-variant': 'Remove product variant',
  'add-discount': 'Add discount',
  'remove-one-time-gift-products': 'Remove one-time products',
  'remove-expired-discount': 'Remove expired discount',
  'edit-delivery-frequency': 'Edit delivery frequency',
}

export const workflowActionTypes = Object.keys(workflowActionLabels) as WorkflowActionType[]

export const actionIsSupportedByTriggers = (
  actionType: WorkflowActionType,
  triggers: WorkflowTriggerType[]
): boolean =>
  actionType !== 'add-order-tags' ||
  (actionType === 'add-order-tags' && workflowTriggersSupportOrderTags(triggers))

export type WorkflowTemplateID = 'tag-active-subscribers'

export const workflowTemplateLabels: Record<WorkflowTemplateID, string> = {
  'tag-active-subscribers': 'Tag active subscribers',
}

export const workflowTemplateIDs = Object.keys(workflowTemplateLabels) as WorkflowTemplateID[]

export type WorkflowFilter = [WorkflowFilterType, any[]]
export type WorkflowRootFilter = [WorkflowRootFilterType, WorkflowFilter[]]

export interface Workflow {
  id: number
  name: string | null
  is_active: boolean
  triggers: WorkflowTriggerType[]
  filter: WorkflowRootFilter
  actions: WorkflowAction[]
}
