import { backendFetchResultData } from '../common/api'
import { GQLPaginator } from '../common/gql-models-common'
import { urlWithParams } from '../common/urls'

export interface SellingPlanGroup {
  id: string
  merchantCode: string
  summary: string
}

export const fetchSellingPlanGroups = (
  _: string,
  sortKey: string,
  beforeCursor: string,
  afterCursor: string,
  search: string = ''
): Promise<GQLPaginator<SellingPlanGroup>> =>
  backendFetchResultData(
    'GET',
    urlWithParams('/plans', {
      'sort-key': sortKey,
      before: beforeCursor,
      after: afterCursor,
      search: search || undefined,
    })
  )
