// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentSchedule_paginationWrapper__8X6P- .Polaris-ButtonGroup__Item {
  z-index: 1;
}

.PaymentSchedule_calendarWrapper__N4bJz .fc-popover-body {
  max-height: 400px;
  overflow-y: scroll;
}

.PaymentSchedule_calendarWrapper__N4bJz .fc-daygrid-event {
  cursor: pointer;
  padding: 0px 4px;
  white-space: normal;
}

.PaymentSchedule_spinnerWrapper__40rdX svg{
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard-page/PaymentSchedule.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".paginationWrapper :global(.Polaris-ButtonGroup__Item) {\n  z-index: 1;\n}\n\n.calendarWrapper :global(.fc-popover-body) {\n  max-height: 400px;\n  overflow-y: scroll;\n}\n\n.calendarWrapper :global(.fc-daygrid-event) {\n  cursor: pointer;\n  padding: 0px 4px;\n  white-space: normal;\n}\n\n.spinnerWrapper svg{\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationWrapper": `PaymentSchedule_paginationWrapper__8X6P-`,
	"calendarWrapper": `PaymentSchedule_calendarWrapper__N4bJz`,
	"spinnerWrapper": `PaymentSchedule_spinnerWrapper__40rdX`
};
export default ___CSS_LOADER_EXPORT___;
