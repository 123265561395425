import {
  ActionList,
  Banner,
  Button,
  ButtonGroup,
  FormLayout,
  HorizontalStack,
  Icon,
  LegacyCard,
  LegacyStack,
  Popover,
  Tag,
  Text,
  Tooltip,
  VerticalStack,
} from '@shopify/polaris'
import { ArrowDownMinor, ArrowUpMinor, DeleteMajor } from '@shopify/polaris-icons'
import { getIn, useFormikContext } from 'formik'
import { useCallback, useState } from 'react'

import { SellingPlanAnchor, SellingPlanPreAnchorBehavior } from '../common/anchor-models'
import {
  AnchorsEditModal,
  anchorTypeSellingPlanIntervals,
  cutoffOptions,
  cutoffWindowTooltip,
  firstDeliveryTooltip,
  preAnchorBehaviorOptions,
} from '../common/AnchorsEditModal'
import { ConfirmDialog, useConfirmDialogState } from '../common/ConfirmDialog'
import {
  gidToId,
  MemoedSelect,
  MemoedTextField,
  sortAnchors,
  useDialogActivator,
  useFormikHandleChangeCallback,
  useFormikHandleNumberFromStringChangeCallback,
} from '../common/helpers'
import { HelpLabel } from '../common/HelpIcon'
import { MemoedOptionalNumericSelect } from '../common/NumericSelect'
import { SellingPlanInterval, SellingPlanType } from '../common/plan-models'
import { useShopCurrencySymbol } from '../common/Price'
import {
  PriceAdjustmentTypeField,
  PriceAdjustmentValueField,
} from '../common/PriceAdjustmentFields'
import {
  IntervalCountField,
  IntervalField,
  MaxCyclesField,
  MaxCyclesPrepaidField,
  MinCyclesField,
} from '../common/ScheduleFields'
import { useShopShopifySettingsSWR } from '../common/shopify-settings'
import { FinishingBehaviorSelect } from '../common/subscription-fields'
import * as urls from '../common/urls'
import { anchorLabel } from '../subscription-page/ScheduleCard'
import {
  defaultSellingPlanLabel,
  defaultSellingPlanName,
  initialSellingPlanFormData,
  SellingPlanGroupFormData,
  sellingPlanTypeFields,
} from './models-form'
import { PrePaidDeliveriesField } from './PrePaidDeliveriesField'
import { sellingPlanDeleteWarning } from './SellingPlanPage'

const sellingPlanTypeLabels: Record<SellingPlanType, string> = {
  basic: 'Ongoing subscription',
  prepaid: 'Prepaid',
  'specific-dates': 'Specific dates',
  advanced: 'Custom/Advanced',
}

const sellingPlanTypeOptions: { label: string; value: SellingPlanType }[] = Object.keys(
  sellingPlanTypeLabels
).map((planType) => ({
  label: sellingPlanTypeLabels[planType as SellingPlanType],
  value: planType as SellingPlanType,
}))

export const VariantCard = ({ index }: { index: number }) => {
  const currencySymbol = useShopCurrencySymbol()
  const confirmDialogState = useConfirmDialogState()
  const editAnchorsActivator = useDialogActivator()

  const { data: shopShopifySettingsData } = useShopShopifySettingsSWR()

  const formik = useFormikContext<SellingPlanGroupFormData>()

  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)
  const handleNumberFromStringChange = useFormikHandleNumberFromStringChangeCallback(
    formik.setFieldValue
  )

  const [addSellingPlanPopoverActive, setAddSellingPlanPopoverActive] = useState(false)

  const toggleAddSellingPlanPopoverActive = useCallback(
    () =>
      setAddSellingPlanPopoverActive((addSellingPlanPopoverActive) => !addSellingPlanPopoverActive),
    []
  )

  const handleRemovingSellingPlan = useCallback(
    (index: number) => {
      const planId = formik.values.sellingPlans[index].id

      const plans = [...(formik.values.sellingPlans || [])]
      plans.splice(index, 1)
      formik.setFieldValue('sellingPlans', plans)

      if (planId) {
        const plansToDelete = [...(formik.values.sellingPlansToDelete || [])]
        plansToDelete.push(planId)
        formik.setFieldValue('sellingPlansToDelete', plansToDelete)
      }
    },
    [formik]
  )

  const handleAddSellingPlan = useCallback(
    (index: number, type: SellingPlanType) => {
      const plans = [...(formik.values.sellingPlans || [])]
      plans.splice(index, 0, initialSellingPlanFormData(type))
      formik.setFieldValue('sellingPlans', plans)
      setAddSellingPlanPopoverActive(false)
    },
    [formik]
  )

  const handleMoveUpSellingPlan = useCallback(() => {
    const plans = [...(formik.values.sellingPlans || [])]
    const deleted = plans.splice(index, 1)
    plans.splice(index - 1, 0, deleted[0])
    formik.setFieldValue('sellingPlans', plans)
  }, [formik, index])

  const handleMoveDownSellingPlan = useCallback(() => {
    const plans = [...(formik.values.sellingPlans || [])]
    const deleted = plans.splice(index, 1)
    plans.splice(index + 1, 0, deleted[0])
    formik.setFieldValue('sellingPlans', plans)
  }, [formik, index])

  const onTypeChange = useCallback(
    (type: SellingPlanType) => {
      formik.setFieldValue(`sellingPlans[${index}].type`, type)

      if (type === 'advanced') {
        return
      }

      if (type === 'specific-dates') {
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.minCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.maxCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].finishingBehavior`, 'immediately')
        formik.setFieldValue(`sellingPlans[${index}].prePaidDeliveries`, 1)
        formik.setFieldValue(`sellingPlans[${index}].deliveryPolicy.recurring.intervalCount`, 1)
        if (formik.values.sellingPlans[index].deliveryPolicy.recurring.interval === 'DAY') {
          formik.setFieldValue(
            `sellingPlans[${index}].deliveryPolicy.recurring.interval`,
            SellingPlanInterval.YEAR
          )
        }
        return
      }

      if (type === 'prepaid') {
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.minCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.maxCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].finishingBehavior`, 'end-of-cycle')
        formik.setFieldValue(`sellingPlans[${index}].deliveryPolicy.recurring.anchors`, [])

        return
      }

      if (type === 'basic') {
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.minCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].billingPolicy.recurring.maxCycles`, null)
        formik.setFieldValue(`sellingPlans[${index}].finishingBehavior`, 'immediately')
        formik.setFieldValue(`sellingPlans[${index}].deliveryPolicy.recurring.anchors`, [])
        formik.setFieldValue(`sellingPlans[${index}].prePaidDeliveries`, 1)
      }
    },
    [formik, index]
  )

  const handleAnchorsUpdate = useCallback(
    (
      anchors: SellingPlanAnchor[],
      preAnchorBehavior?: SellingPlanPreAnchorBehavior | undefined,
      cutoff?: number | null
    ) => {
      formik.setFieldValue(`sellingPlans[${index}].deliveryPolicy.recurring.anchors`, anchors)
      formik.setFieldValue(
        `sellingPlans[${index}].deliveryPolicy.recurring.preAnchorBehavior`,
        preAnchorBehavior
      )
      formik.setFieldValue(`sellingPlans[${index}].deliveryPolicy.recurring.cutoff`, cutoff)

      if (anchors.length === 0) {
        return
      }

      formik.setFieldValue(
        `sellingPlans[${index}].deliveryPolicy.recurring.interval`,
        anchorTypeSellingPlanIntervals[anchors[0].type]
      )

      if (anchors.length > 1) {
        formik.setFieldValue(`sellingPlans[${index}].deliveryPolicy.recurring.intervalCount`, 1)
      }
    },
    [formik, index]
  )

  const planGid = formik.values.sellingPlans[index].id
  const planTitle = 'Selling Plan' + (planGid ? ` #${gidToId(planGid)}` : '')
  const type = formik.values.sellingPlans[index].type
  const anchors = sortAnchors(formik.values.sellingPlans[index].deliveryPolicy.recurring.anchors)
  const isDailyInterval =
    formik.values.sellingPlans[index].deliveryPolicy.recurring.interval === SellingPlanInterval.DAY

  return (
    <>
      <LegacyCard sectioned>
        <LegacyStack vertical spacing="loose">
          <HorizontalStack gap="3" align="space-between" blockAlign="start" wrap={false}>
            <HorizontalStack gap="3" blockAlign="center" wrap>
              <Text variant="headingMd" as="h2">
                {planTitle}
              </Text>
              <MemoedSelect
                name={`sellingPlans[${index}].type`}
                id={`sellingPlans[${index}].type`}
                label="Type"
                labelHidden
                options={sellingPlanTypeOptions}
                value={formik.values.sellingPlans[index].type}
                onChange={onTypeChange}
              />
            </HorizontalStack>
            <HorizontalStack blockAlign="center" gap="2" wrap={false}>
              <ButtonGroup segmented>
                {index !== 0 && (
                  <Tooltip content="Move up" dismissOnMouseOut>
                    <Button
                      accessibilityLabel="Move up"
                      icon={<Icon source={ArrowUpMinor} color="base" />}
                      onClick={handleMoveUpSellingPlan}
                    />
                  </Tooltip>
                )}
                {index !== formik.values.sellingPlans.length - 1 && (
                  <Tooltip content="Move down" dismissOnMouseOut>
                    <Button
                      accessibilityLabel="Move down"
                      icon={<Icon source={ArrowDownMinor} color="base" />}
                      onClick={handleMoveDownSellingPlan}
                    />
                  </Tooltip>
                )}
              </ButtonGroup>
              <Button onClick={confirmDialogState.show} destructive icon={DeleteMajor} />
            </HorizontalStack>
          </HorizontalStack>
          <FormLayout>
            {shopShopifySettingsData?.shopUsingThirdPartyFulfillmentServices &&
              formik.values.sellingPlans[index].prePaidDeliveries > 1 && (
                <Banner status="warning">
                  <p>
                    Prepaid orders in Shopify may require you to alter settings in your fulfillment
                    provider. We recommend running tests to ensure scheduled fulfillments can be
                    processed by your provider. Please reach out to your fullfillment provider for
                    more details.
                  </p>
                </Banner>
              )}
            <FormLayout.Group>
              <MemoedTextField
                name={`sellingPlans[${index}].name`}
                id={`sellingPlans[${index}].name`}
                type="text"
                label={
                  <HelpLabel tooltip="Selling plan name displayed in the cart and during checkout. It's recommended that it includes the frequency of deliveries">
                    Subscription plan name
                  </HelpLabel>
                }
                placeholder={defaultSellingPlanName(
                  formik.values.name,
                  formik.values.sellingPlans[index].deliveryPolicy.recurring.interval,
                  formik.values.sellingPlans[index].deliveryPolicy.recurring.intervalCount,
                  formik.values.sellingPlans[index].type
                )}
                value={formik.values.sellingPlans[index].name}
                onChange={handleChange}
                autoComplete="off"
                error={getIn(formik.errors, `sellingPlans[${index}].name`)}
              />
              <MemoedTextField
                name={`sellingPlans[${index}].planSelectorLabel`}
                id={`sellingPlans[${index}].planSelectorLabel`}
                type="text"
                placeholder={defaultSellingPlanLabel(
                  formik.values.sellingPlans[index].deliveryPolicy.recurring.interval,
                  formik.values.sellingPlans[index].deliveryPolicy.recurring.intervalCount,
                  formik.values.sellingPlans[index].deliveryPolicy.recurring.anchors,
                  formik.values.sellingPlans[index].type
                )}
                label={
                  <HelpLabel tooltip="Short label displayed in the plan selector on the product page">
                    Subscription selection display label
                  </HelpLabel>
                }
                value={formik.values.sellingPlans[index].planSelectorLabel}
                onChange={handleChange}
                autoComplete="off"
                error={getIn(formik.errors, `sellingPlans[${index}].planSelectorLabel`)}
              />
            </FormLayout.Group>
            {(sellingPlanTypeFields(type).interval || sellingPlanTypeFields(type).prepaid) && (
              <FormLayout.Group>
                <LegacyStack distribution="fillEvenly" spacing="loose">
                  <LegacyStack.Item fill>
                    {sellingPlanTypeFields(type).interval && (
                      <LegacyStack distribution="fillEvenly">
                        {anchors.length <= 1 && (
                          <IntervalCountField
                            name={`sellingPlans[${index}].deliveryPolicy.recurring.intervalCount`}
                            id={`sellingPlans[${index}].deliveryPolicy.recurring.intervalCount`}
                            label="Delivery frequency"
                            onChange={handleChange}
                            value={
                              formik.values.sellingPlans[index].deliveryPolicy.recurring
                                .intervalCount
                            }
                          />
                        )}
                        <LegacyStack alignment="trailing">
                          <LegacyStack.Item fill>
                            <IntervalField
                              name={`sellingPlans[${index}].deliveryPolicy.recurring.interval`}
                              id={`sellingPlans[${index}].deliveryPolicy.recurring.interval`}
                              label={anchors.length > 1 ? 'Delivery frequency' : '  '}
                              onChange={(val: SellingPlanInterval, id: string) => {
                                handleChange(val, id)
                                handleChange(
                                  [],
                                  `sellingPlans[${index}].deliveryPolicy.recurring.anchors`
                                )
                              }}
                              value={
                                formik.values.sellingPlans[index].deliveryPolicy.recurring.interval
                              }
                              hasMultipleAnchors={anchors.length > 1}
                            />
                          </LegacyStack.Item>
                        </LegacyStack>
                      </LegacyStack>
                    )}
                  </LegacyStack.Item>
                  {sellingPlanTypeFields(type).prepaid && (
                    <LegacyStack distribution="fillEvenly">
                      {sellingPlanTypeFields(type).prepaid && (
                        <PrePaidDeliveriesField
                          value={formik.values.sellingPlans[index].prePaidDeliveries}
                          onChange={(v) =>
                            formik.setFieldValue(`sellingPlans[${index}].prePaidDeliveries`, v)
                          }
                          pagePath={urls.planUrl(formik.values.id)}
                        />
                      )}
                    </LegacyStack>
                  )}
                </LegacyStack>
              </FormLayout.Group>
            )}
            {anchors.length > 0 && (
              <LegacyStack distribution="fillEvenly" spacing="loose">
                <VerticalStack gap="3">
                  <HorizontalStack>
                    <LegacyStack.Item fill>
                      <Text as="span">Delivery anchors</Text>
                    </LegacyStack.Item>
                    <Button plain onClick={editAnchorsActivator.show}>
                      Edit delivery anchors
                    </Button>
                  </HorizontalStack>
                  <HorizontalStack wrap gap="1">
                    {anchors.map((anchor, i) => (
                      <Tag key={i}>{anchorLabel(anchor)}</Tag>
                    ))}
                  </HorizontalStack>
                </VerticalStack>
                <LegacyStack distribution="fillEvenly">
                  <MemoedSelect
                    name={`sellingPlans[${index}].deliveryPolicy.recurring.preAnchorBehavior`}
                    id={`sellingPlans[${index}].deliveryPolicy.recurring.preAnchorBehavior`}
                    label={<HelpLabel tooltip={firstDeliveryTooltip}>First Delivery</HelpLabel>}
                    options={preAnchorBehaviorOptions}
                    onChange={handleChange}
                    value={
                      formik.values.sellingPlans[index].deliveryPolicy.recurring.preAnchorBehavior
                    }
                  />
                  <MemoedOptionalNumericSelect
                    name={`sellingPlans[${index}].deliveryPolicy.recurring.cutoff`}
                    id={`sellingPlans[${index}].deliveryPolicy.recurring.cutoff`}
                    label={<HelpLabel tooltip={cutoffWindowTooltip}>Cutoff window</HelpLabel>}
                    options={cutoffOptions}
                    onChange={handleNumberFromStringChange}
                    value={formik.values.sellingPlans[index].deliveryPolicy.recurring.cutoff}
                  />
                </LegacyStack>
              </LegacyStack>
            )}
            {sellingPlanTypeFields(type).anchors && anchors.length === 0 && !isDailyInterval && (
              <FormLayout.Group>
                <Button primary onClick={editAnchorsActivator.show}>
                  Add delivery anchors
                </Button>
              </FormLayout.Group>
            )}
            {sellingPlanTypeFields(type).minMaxCyclesAndFinishingBehavior && (
              <FormLayout.Group>
                <LegacyStack distribution="fillEvenly">
                  {type !== 'prepaid' && (
                    <LegacyStack distribution="fillEvenly">
                      <MinCyclesField
                        name={`sellingPlans[${index}].billingPolicy.recurring.minCycles`}
                        id={`sellingPlans[${index}].billingPolicy.recurring.minCycles`}
                        onChange={handleNumberFromStringChange}
                        value={formik.values.sellingPlans[index].billingPolicy.recurring.minCycles}
                      />
                      <MaxCyclesField
                        name={`sellingPlans[${index}].billingPolicy.recurring.maxCycles`}
                        id={`sellingPlans[${index}].billingPolicy.recurring.maxCycles`}
                        onChange={handleNumberFromStringChange}
                        value={formik.values.sellingPlans[index].billingPolicy.recurring.maxCycles}
                      />
                    </LegacyStack>
                  )}
                  {type === 'prepaid' && (
                    <LegacyStack distribution="fillEvenly">
                      <MaxCyclesPrepaidField
                        name={`sellingPlans[${index}].billingPolicy.recurring.maxCycles`}
                        id={`sellingPlans[${index}].billingPolicy.recurring.maxCycles`}
                        onChange={handleNumberFromStringChange}
                        value={formik.values.sellingPlans[index].billingPolicy.recurring.maxCycles}
                      />
                    </LegacyStack>
                  )}
                  <FinishingBehaviorSelect
                    name={`sellingPlans[${index}].finishingBehavior`}
                    id={`sellingPlans[${index}].finishingBehavior`}
                    onChange={handleChange as (val: string, id: string) => any}
                    value={formik.values.sellingPlans[index].finishingBehavior}
                  />
                </LegacyStack>
              </FormLayout.Group>
            )}
            <FormLayout.Group>
              <PriceAdjustmentTypeField
                name={`sellingPlans[${index}].fixedPricingPolicy.adjustmentType`}
                id={`sellingPlans[${index}].fixedPricingPolicy.adjustmentType`}
                onChange={handleChange}
                value={formik.values.sellingPlans[index].fixedPricingPolicy.adjustmentType}
              />
              <PriceAdjustmentValueField
                name={`sellingPlans[${index}].fixedPricingPolicy.adjustmentValue`}
                id={`sellingPlans[${index}].fixedPricingPolicy.adjustmentValue`}
                prePaidDeliveries={formik.values.sellingPlans[index].prePaidDeliveries}
                currencySymbol={currencySymbol}
                adjustmentType={formik.values.sellingPlans[index].fixedPricingPolicy.adjustmentType}
                value={formik.values.sellingPlans[index].fixedPricingPolicy.adjustmentValue}
                error={getIn(
                  formik.errors,
                  `sellingPlans[${index}].fixedPricingPolicy.adjustmentValue`
                )}
                onChange={handleChange}
              />
            </FormLayout.Group>
            <MemoedTextField
              name={`sellingPlans[${index}].description`}
              id={`sellingPlans[${index}].description`}
              type="text"
              label={
                <HelpLabel tooltip="Detailed commitment description displayed on the product page when the plan is selected (optional)">
                  Description
                </HelpLabel>
              }
              placeholder=""
              multiline={3}
              value={formik.values.sellingPlans[index].description}
              onChange={handleChange}
              autoComplete="off"
            />
          </FormLayout>
        </LegacyStack>
      </LegacyCard>

      <HorizontalStack align="start">
        <Popover
          active={addSellingPlanPopoverActive}
          activator={
            <div style={{ margin: '12px' }}>
              <Button
                plain
                disclosure={addSellingPlanPopoverActive ? 'up' : 'down'}
                onClick={toggleAddSellingPlanPopoverActive}
              >
                {index === formik.values.sellingPlans.length - 1
                  ? 'Add selling plan'
                  : 'Insert selling plan'}
              </Button>
            </div>
          }
          onClose={toggleAddSellingPlanPopoverActive}
        >
          <ActionList
            actionRole="menuitem"
            items={Object.keys(sellingPlanTypeLabels).map((planType) => ({
              content: sellingPlanTypeLabels[planType as SellingPlanType],
              onAction: () => handleAddSellingPlan(index + 1, planType as SellingPlanType),
            }))}
          />
        </Popover>
      </HorizontalStack>

      <AnchorsEditModal
        activator={editAnchorsActivator}
        anchors={formik.values.sellingPlans[index].deliveryPolicy.recurring.anchors}
        interval={formik.values.sellingPlans[index].deliveryPolicy.recurring.interval}
        preAnchorBehavior={
          formik.values.sellingPlans[index].deliveryPolicy.recurring.preAnchorBehavior
        }
        cutoff={formik.values.sellingPlans[index].deliveryPolicy.recurring.cutoff}
        onChange={handleAnchorsUpdate}
        pagePath={urls.planUrl(formik.values.id)}
      />

      <ConfirmDialog
        state={confirmDialogState}
        destructive
        onConfirm={() => handleRemovingSellingPlan(index)}
        message={sellingPlanDeleteWarning}
        confirmButtonTitle={'Remove'}
      />
    </>
  )
}
