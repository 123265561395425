import {
  AppliedFilterInterface,
  FilterInterface,
  LegacyFilters,
  LegacyStack,
  Select,
} from '@shopify/polaris'

import { NonEmptyArray } from '../common/helpers'
import { TableColumnSelector } from '../common/TableColumnSelector'
import { SubscriptionFilter } from './api'
import { useAddCurrencyFilter } from './filters/Currency'
import { useAddCustomerIdFilter } from './filters/CustomerId'
import { useAddDateFilter } from './filters/Date'
import { useAddDunningRetriesFilter } from './filters/DunningRetries'
import { useAddProductIdFilter } from './filters/Product'
import { useAddProductSkuFilter } from './filters/ProductSku'
import { useAddProductVariantIdFilter } from './filters/ProductVariant'
import { useSearchState } from './filters/Search'
import { useAddSellingPlanIdFilter } from './filters/SellingPlan'
import { useAddStatusFilter } from './filters/Status'
import {
  SubscriptionTableColumn,
  subscriptionTableColumnChoices,
  subscriptionTableSortOptions,
} from './SubscriptionTable'

export const SubscriptionFilterForm = ({
  filter,
  updateFilter,
  clearFilter,
  columns,
  onColumnsChange,
}: {
  filter: SubscriptionFilter
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any
  clearFilter: () => any
  columns: NonEmptyArray<SubscriptionTableColumn>
  onColumnsChange: (columns: NonEmptyArray<SubscriptionTableColumn>) => any
}) => {
  const filters: FilterInterface[] = []
  const appliedFilters: AppliedFilterInterface[] = []

  const searchState = useSearchState(filter, updateFilter)
  useAddStatusFilter(filter, updateFilter, filters, appliedFilters)
  useAddDunningRetriesFilter(filter, updateFilter, filters, appliedFilters)
  useAddCustomerIdFilter(filter, updateFilter, filters, appliedFilters)
  useAddProductIdFilter(filter, updateFilter, filters, appliedFilters)
  useAddProductSkuFilter(filter, updateFilter, filters, appliedFilters)
  useAddProductVariantIdFilter(filter, updateFilter, filters, appliedFilters)
  useAddSellingPlanIdFilter(filter, updateFilter, filters, appliedFilters)
  useAddCurrencyFilter(filter, updateFilter, filters, appliedFilters)
  useAddDateFilter('created', filter, updateFilter, filters, appliedFilters)
  useAddDateFilter('updated', filter, updateFilter, filters, appliedFilters)
  useAddDateFilter('paused', filter, updateFilter, filters, appliedFilters)
  useAddDateFilter('next_order', filter, updateFilter, filters, appliedFilters)
  useAddDateFilter('next_attempt', filter, updateFilter, filters, appliedFilters)
  useAddDateFilter('finishing', filter, updateFilter, filters, appliedFilters)
  useAddDateFilter('finished', filter, updateFilter, filters, appliedFilters)

  return (
    <LegacyStack spacing="tight">
      <LegacyStack.Item fill>
        <LegacyFilters
          filters={filters}
          appliedFilters={appliedFilters}
          queryValue={searchState.search ?? ''}
          queryPlaceholder="Search"
          onQueryChange={searchState.updateSearch}
          onQueryClear={searchState.clearSearch}
          onClearAll={clearFilter}
        />
      </LegacyStack.Item>

      <TableColumnSelector
        columns={columns}
        onChange={onColumnsChange as (columns: NonEmptyArray<string>) => any}
        choices={subscriptionTableColumnChoices}
      />

      <Select
        labelInline
        label="Sort by"
        options={subscriptionTableSortOptions(!!filter.search)}
        value={!!filter.sort ? filter.sort : 'created_desc'}
        onChange={(sort: string) => updateFilter({ sort })}
      />
    </LegacyStack>
  )
}
