import useSWR from 'swr'

import { backendFetchResultData } from './api'
import { Location } from './subscription-models'

export const fetchLocations = async (_: string): Promise<Location[]> =>
  await backendFetchResultData('GET', '/locations/search')

export const useLocationsSWR = () =>
  useSWR<Location[]>('locations', fetchLocations, {
    revalidateOnFocus: false,
  })

export const useLocations = () => useLocationsSWR().data ?? []
