// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentMethodWidget_widget__n7O3F {
  display: flex;
}

.PaymentMethodWidget_widget__n7O3F > * {
  min-width: 2.375rem;
}

.PaymentMethodWidget_widget__n7O3F > *:not(:first-child) {
  margin-left: 1.0rem;
}
`, "",{"version":3,"sources":["webpack://./src/payment-method-widget/PaymentMethodWidget.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".widget {\n  display: flex;\n}\n\n.widget > * {\n  min-width: 2.375rem;\n}\n\n.widget > *:not(:first-child) {\n  margin-left: 1.0rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget": `PaymentMethodWidget_widget__n7O3F`
};
export default ___CSS_LOADER_EXPORT___;
