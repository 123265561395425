import {
  ContextualSaveBar,
  Layout,
  LegacyCard,
  LegacyStack,
  Text,
  VerticalStack,
} from '@shopify/polaris'
import React, { useEffect, useMemo, useState } from 'react'

import { Result } from '../common/api'
import { MemoedTextField, resultErrorField, ShowIntercomButton } from '../common/helpers'
import { ActionsCard } from './ActionsCard'
import { WorkflowFormData } from './api'
import { FiltersCard, workflowFilterSections } from './FiltersCard'
import { TriggersCard } from './TriggersCard'
import { WorkflowDescription } from './WorkflowDescription'
import { WorkflowStatusBadge } from './WorkflowStatusBadge'

interface WorkflowFormProps {
  initialData: WorkflowFormData
  onSave: (data: WorkflowFormData) => any
  isSaving: boolean
  saveResult: Result<any> | null
}

export const WorkflowForm = (props: WorkflowFormProps) => {
  const { initialData } = props

  const [data, setData] = useState<WorkflowFormData>(initialData)
  useEffect(() => setData(initialData), [initialData])

  const isDirty = useMemo(
    () => JSON.stringify(data) !== JSON.stringify(initialData),
    [data, initialData]
  )

  return (
    <Layout>
      <Layout.Section>
        <LegacyCard title="Name">
          <LegacyCard.Section>
            <MemoedTextField
              name="name"
              type="text"
              label="Name"
              labelHidden
              value={data.name}
              onChange={(val) => setData({ ...data, name: val })}
              autoComplete="off"
              error={resultErrorField(props.saveResult, 'name') ?? undefined}
            />
          </LegacyCard.Section>
        </LegacyCard>
        <TriggersCard
          triggers={data.triggers}
          onChange={(triggers) => setData({ ...data, triggers })}
          error={resultErrorField(props.saveResult, 'triggers')}
        />
        <FiltersCard
          filters={data.filters}
          onChange={(filters) => setData({ ...data, filters })}
          rootFilterType={data.rootFilterType}
          onRootFilterTypeChange={(rootFilterType) => setData({ ...data, rootFilterType })}
          workflowFilterOptions={workflowFilterSections()}
        />
        <ActionsCard
          data={data}
          onChange={(actions) => setData({ ...data, actions })}
          saveResult={props.saveResult}
        />
      </Layout.Section>
      <Layout.Section oneThird>
        <VerticalStack gap="4">
          <LegacyCard sectioned>
            <LegacyStack distribution="equalSpacing">
              <Text variant="headingMd" as="h2">
                Summary
              </Text>
              <WorkflowStatusBadge data={data} />
            </LegacyStack>
            <br />
            <WorkflowDescription
              filters={data.filters}
              actions={data.actions}
              triggers={data.triggers}
              rootFilterType={data.rootFilterType}
            />
          </LegacyCard>
          {initialData.id && (
            <LegacyStack.Item>
              <ShowIntercomButton message="I have questions about editing workflows.">
                Chat with us
              </ShowIntercomButton>
            </LegacyStack.Item>
          )}
        </VerticalStack>
      </Layout.Section>

      {isDirty && (
        <ContextualSaveBar
          message="Unsaved changes"
          alignContentFlush={true}
          saveAction={{
            onAction: () => props.onSave(data),
            loading: props.isSaving,
            disabled: false,
          }}
          discardAction={{
            onAction: () => setData(initialData),
          }}
          secondaryMenu={
            !initialData.id && (
              <ShowIntercomButton message="I have a question about creating a workflow.">
                Chat with us
              </ShowIntercomButton>
            )
          }
        />
      )}
    </Layout>
  )
}
