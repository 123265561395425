import { Layout, LinkAction, Loading, Page, SkeletonBodyText } from '@shopify/polaris'
import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'

import { Result } from '../common/api'
import { ApiActionDialog } from '../common/ApiActionDialog'
import { Footer } from '../common/Footer'
import { ResultToastOrBanner, showIntercom, useDialogActivator } from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import {
  convertWorkflowToFormData,
  createOrUpdateWorkflow,
  fetchWorkflowFormData,
  SellingPlansContext,
  useSellingPlansSWR,
  WorkflowFormData,
} from './api'
import { WorkflowForm } from './WorkflowForm'

const useWorkflowFormData = (id?: string) =>
  useSWR<WorkflowFormData>(['workflow', id], fetchWorkflowFormData, {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenHidden: false,
    refreshWhenOffline: false,
  })

const Skeleton = () => (
  <Layout>
    <Layout.Section>
      <SkeletonBodyText lines={10} />
      <br />
      <SkeletonBodyText lines={10} />
    </Layout.Section>
    <Layout.Section oneThird>
      <SkeletonBodyText lines={7} />
    </Layout.Section>
  </Layout>
)

export const WorkflowPage = () => {
  const { id } = useParams<{ id?: string }>()

  const sellingPlansSWR = useSellingPlansSWR()
  const workflowFormSWR = useWorkflowFormData(id)
  const workflowFormData = workflowFormSWR.data

  const [isSaving, setSaving] = useState(false)

  const deleteDialogActivator = useDialogActivator()

  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const history = useHistory()

  const onSave = useCallback(
    async (data: WorkflowFormData) => {
      setSaving(true)

      const res = await createOrUpdateWorkflow(data, id)

      setSaving(false)

      setSaveResult(res)

      if (res.status !== 'success' || !res.data) {
        return
      }

      if (id) {
        mutate(['workflow', id], convertWorkflowToFormData(res.data), false)
      } else {
        history.replace(urls.workflowUrl(res.data.id))
      }
    },
    [id, history]
  )

  const afterDelete = useCallback(
    () => setTimeout(() => history.replace(urls.workflowsUrl), 10),
    [history]
  )

  const backAction: LinkAction = { content: 'Tag workflows', url: urls.workflowsUrl }

  const primaryAction =
    id && workflowFormData
      ? {
          content: 'Delete',
          onAction: deleteDialogActivator.show,
          disabled: isSaving,
          destructive: true,
        }
      : undefined
  const secondaryActions =
    id && workflowFormData
      ? [
          {
            content: workflowFormData.is_active ? 'Disable' : 'Activate',
            onAction: () => onSave({ ...workflowFormData, is_active: !workflowFormData.is_active }),
            disabled: isSaving,
          },
        ]
      : [
          {
            content: 'Chat with us',
            onAction: () => showIntercom('I have a question about creating a workflow.'),
          },
        ]

  return (
    <>
      {(workflowFormSWR.isValidating || isSaving) && <Loading />}
      <Tabs selected={TabIndex.Workflows} />

      {workflowFormSWR.error && <LoadErrorPage />}
      {!workflowFormSWR.error && (
        <Page
          title={id ? 'Edit workflow' : 'Create workflow'}
          backAction={backAction}
          primaryAction={primaryAction}
          secondaryActions={secondaryActions}
        >
          {(!workflowFormData || !sellingPlansSWR.data) && <Skeleton />}

          {workflowFormData && sellingPlansSWR.data && (
            <SellingPlansContext.Provider value={sellingPlansSWR.data ?? []}>
              <ResultToastOrBanner result={saveResult} setResult={setSaveResult} />
              <WorkflowForm
                initialData={workflowFormData}
                onSave={onSave}
                isSaving={isSaving}
                saveResult={saveResult}
              />
            </SellingPlansContext.Provider>
          )}
          <Layout>
            <Layout.Section>
              <Footer />
            </Layout.Section>
          </Layout>
        </Page>
      )}

      {id && (
        <ApiActionDialog
          activator={deleteDialogActivator}
          onSuccess={afterDelete}
          url={`/workflows/${id}`}
          method="DELETE"
          message="This action cannot be reverted"
          destructive={true}
          primaryButtonTitle="Delete"
        />
      )}
    </>
  )
}
