import { Modal } from '@shopify/polaris'
import { useEffect, useMemo, useState } from 'react'

import { useApiActionState } from '../common/ApiAction'
import { DatePickerWithInput } from '../common/DatePickerWithInput'
import { DialogActivator } from '../common/helpers'
import { HelpLabel } from '../common/HelpIcon'
import { useUserSettings } from '../settings-page/api'

export const DashboardSettingsModal = ({ activator }: { activator: DialogActivator }) => {
  const userSettings = useUserSettings()
  const [date, setDate] = useState<string | null>(null)
  const [dateError, setDateError] = useState<string>('')

  useEffect(() => {
    if (userSettings.data) {
      setDate(userSettings.data?.analytics_start_date)
    }
  }, [userSettings.data, activator])

  const isDateValid = useMemo(() => date === null || dateError === '', [date, dateError])

  const saveActionState = useApiActionState({
    method: 'POST',
    url: `/dashboard/report-settings`,
    opts: {
      body: JSON.stringify({ analytics_start_date: date }),
    },
    onSuccess: () => {
      userSettings.mutate()
      activator.close()
    },
    bannerPosition: 'external',
  })

  return (
    <Modal
      open={activator.open}
      title="Dashboard settings"
      onClose={activator.close}
      primaryAction={{
        content: 'Save',
        onAction: saveActionState.run,
        loading: saveActionState.loading,
        disabled: !isDateValid,
      }}
      secondaryActions={[{ content: 'Cancel', onAction: activator.close }]}
    >
      <Modal.Section>
        <DatePickerWithInput
          label={
            <HelpLabel tooltip="Choose a date to exclude test data before your official launch. Stats and reports will only reflect data from this date onward.">
              Set stats start date
            </HelpLabel>
          }
          date={date ?? ''}
          updateDate={(date) => setDate(date ?? null)}
          datePickerPopover
          setError={setDateError}
        />
      </Modal.Section>
    </Modal>
  )
}
