import { Modal, Text } from '@shopify/polaris'

import { WorkflowFilter } from '../common/workflow-models'
import { WorkflowFilters, workflowFilterSections } from '../workflow-page/FiltersCard'

export interface SubscriptionFlowTriggerFiltersModalProps {
  open: boolean
  onClose: () => any
  filters: WorkflowFilter[]
  onChange: (filters: WorkflowFilter[]) => any
}

export const SubscriptionFlowTriggerFiltersModal = (
  props: SubscriptionFlowTriggerFiltersModalProps
) => {
  return (
    <Modal
      title="Extra conditions"
      open={props.open}
      onClose={props.onClose}
      primaryAction={{ content: 'Done', onAction: props.onClose }}
    >
      {props.filters.length === 0 && (
        <Modal.Section>
          <Text as="span" color="subdued">
            No extra conditions specified
          </Text>
        </Modal.Section>
      )}
      <WorkflowFilters
        filters={props.filters}
        onChange={props.onChange}
        rootFilterType={'and'}
        onRootFilterTypeChange={() => {}}
        hideRootFilterSelection
        workflowFilterOptions={workflowFilterSections(true)}
      />
    </Modal>
  )
}
