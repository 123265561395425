import { BulkActionSubscriptionUpdateMethod } from '../common/bulk-actions'
import { SellingPlanDescriptor } from '../common/SellingPlanField'
import { NextAction } from '../common/subscription-models'

export const methodOptions: { label: string; value: BulkActionSubscriptionUpdateMethod }[] = [
  { label: 'Activate inactive subscriptions', value: 'activate' },
  { label: 'Pause subscriptions', value: 'pause' },
  { label: 'Resume subscriptions', value: 'resume' },
  { label: 'Cancel subscriptions', value: 'cancel' },
  { label: 'Charge now', value: 'attempt-billing' },
  { label: 'Skip order', value: 'skip-order' },
  { label: 'Update the next scheduled action', value: 'update-next-action' },
  { label: 'Add product', value: 'add-product' },
  { label: 'Add one-time free gift product', value: 'add-one-time-gift-product' },
  { label: 'Swap product', value: 'swap-product' },
  { label: 'Remove product', value: 'remove-product' },
  { label: 'Update shipping method', value: 'update-shipping-method' },
  { label: 'Apply discount code', value: 'apply-discount-code' },
  { label: 'Remove discount', value: 'remove-discount' },
]

export interface UpdateNextActionPayload {
  action: NextAction
  date: string
}

export interface AddProductPayload {
  variantId: number
  variantTitle?: string
  quantity: number
  currentPrice: string
  sellingPlan: SellingPlanDescriptor
}

export interface AddOneTimeGiftProductPayload {
  variantId: number
  variantTitle?: string
}

const validateAddProductPayload = (payload: AddProductPayload): boolean => {
  return payload.variantId !== 0 && payload.currentPrice !== '' && payload.quantity > 0
}

const validateAddOneTimeGiftProductPayload = (payload: AddProductPayload): boolean =>
  payload.variantId !== 0

export interface SwapProductPayload {
  sourceVariantId?: number
  sourceVariantTitle?: string
  sourceVariantDeleted?: boolean
  sourceLineItemTitle?: string
  variantId: number
  variantTitle?: string
  quantity?: number
  currentPrice?: string
  sellingPlan?: SellingPlanDescriptor
}

const validateSwapProductPayload = (payload: SwapProductPayload): boolean => {
  return (
    payload.variantId !== 0 &&
    payload.sourceVariantId !== 0 &&
    (payload.currentPrice === undefined || payload.currentPrice !== '') &&
    (payload.quantity === undefined || payload.quantity > 0)
  )
}

export interface RemoveProductPayload {
  variantId: number
  variantTitle?: string
}

const validateRemoveProductPayload = (payload: RemoveProductPayload): boolean => {
  return payload.variantId !== 0
}

export interface ApplyDiscountCodePayload {
  code: string
}

const validateApplyDiscountCodePayload = (payload: ApplyDiscountCodePayload): boolean => {
  return payload.code.trim().length > 0
}

export interface UpdateShippingMethodPayload {
  title?: string
  price?: string
}

const validateUpdateShippingMethodPayload = (payload: UpdateShippingMethodPayload): boolean => {
  return (
    (payload.title !== undefined && payload.title.trim().length > 0) ||
    (payload.price !== undefined && payload.price.trim().length > 0)
  )
}

export const defaultPayload = (method: BulkActionSubscriptionUpdateMethod) => {
  switch (method) {
    case 'update-next-action':
      return {
        action: 'payment',
        date: new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString(),
      } as UpdateNextActionPayload
    case 'add-product':
      return {
        variantId: 0,
        quantity: 1,
        currentPrice: '0.00',
        sellingPlan: { id: null, name: null },
      } as AddProductPayload
    case 'add-one-time-gift-product':
      return {
        variantId: 0,
      } as AddOneTimeGiftProductPayload
    case 'swap-product':
      return {
        sourceVariantId: 0,
        variantId: 0,
        quantity: undefined,
        currentPrice: undefined,
        sellingPlan: undefined,
      } as SwapProductPayload
    case 'remove-product':
      return { variantId: 0 }
    case 'apply-discount-code':
      return { code: '' } as ApplyDiscountCodePayload
    default:
      return {}
  }
}

export const validatePayload = (
  method: BulkActionSubscriptionUpdateMethod,
  payload: any
): boolean => {
  switch (method) {
    case 'add-product':
      return validateAddProductPayload(payload)
    case 'add-one-time-gift-product':
      return validateAddOneTimeGiftProductPayload(payload)
    case 'swap-product':
      return validateSwapProductPayload(payload)
    case 'remove-product':
      return validateRemoveProductPayload(payload)
    case 'apply-discount-code':
      return validateApplyDiscountCodePayload(payload)
    case 'update-shipping-method':
      return validateUpdateShippingMethodPayload(payload)
    default:
      return true
  }
}
