import { Redirect, Route, Switch } from 'react-router-dom'

import { CallbackDeliveriesPage } from '../callback-deliveries-page/CallbackDeliveriesPage'
import { useSystemData } from '../common/helpers'
import { DashboardPage } from '../dashboard-page/DashboardPage'
import { DashboardPaymentsPage } from '../dashboard-payments-page/DashboardPaymentsPage'
import { GettingStartedPage } from '../getting-started-page/GettingStartedPage'
import { LegacyPlanSelectorInstallPage } from '../legacy-plan-selector-install-page/LegacyPlanSelectorInstallPage'
import { MailSettingsPage } from '../mail-settings-page/MailSettingsPage'
import { NewsPage } from '../news-page/NewsPage'
import { PricingPlansPage } from '../pricing-plans-page/PricingPlansPage'
import { FirstSellingPlanPage } from '../selling-plan-page/FirstSellingPlanPage'
import { SellingPlanPage } from '../selling-plan-page/SellingPlanPage'
import { SellingPlansProductsPage } from '../selling-plan-products-page/SellingPlanProductsPage'
import { SellingPlansPage } from '../selling-plans-index-page/SellingPlansPage'
import { SettingsPage } from '../settings-page/SettingsPage'
import { SubscriptionCreatePage } from '../subscription-create-page/SubscriptionCreatePage'
import { SubscriptionFlowPage } from '../subscription-flow-page/SubscriptionFlowPage'
import { SubscriptionPage } from '../subscription-page/SubscriptionPage'
import { SubscriptionsIndexPage } from '../subscriptions-index-page/SubscriptionsIndexPage'
import { TranslationsPage } from '../translations-page/TranslationsPage'
import { WorkflowLogsPage } from '../workflow-logs-page/WorkflowLogsPage'
import { WorkflowPage } from '../workflow-page/WorkflowPage'
import { WorkflowsIndexPage } from '../workflows-index-page/WorkflowsIndexPage'

export const PageSwitch = () => {
  const systemData = useSystemData()
  const hasSubscriptions = systemData?.flags.has_subscriptions ?? false

  return (
    <Switch>
      <Route path="/getting-started/customer-portal">
        <Redirect to={'/getting-started'} />
      </Route>
      <Route path="/getting-started/legacy-themes">
        <LegacyPlanSelectorInstallPage />
      </Route>
      <Route path="/getting-started/plan-selector">
        <Redirect to={'/getting-started'} />
      </Route>
      <Route path="/getting-started/selling-plan">
        <FirstSellingPlanPage />
      </Route>
      <Route path="/getting-started">
        <GettingStartedPage />
      </Route>
      <Route path="/__dashboard">
        <DashboardPage />
      </Route>
      <Route path="/dashboard/news">
        <NewsPage />
      </Route>
      <Route path="/dashboard/payments">
        <DashboardPaymentsPage />
      </Route>
      <Route path="/dashboard">
        <DashboardPage />
      </Route>
      <Route path="/plans/new">
        <SellingPlanPage />
      </Route>
      <Route path="/plans/:id/products">
        <SellingPlansProductsPage />
      </Route>
      <Route path="/plans/:id">
        <SellingPlanPage />
      </Route>
      <Route path="/plans">
        <SellingPlansPage />
      </Route>
      <Route path="/subscriptions/create">
        <SubscriptionCreatePage />
      </Route>
      <Route path="/subscriptions/:id">
        <SubscriptionPage />
      </Route>
      <Route path="/subscriptions">
        <SubscriptionsIndexPage />
      </Route>
      <Route path="/installation">
        <Redirect to="/getting-started" />
      </Route>
      <Route path="/snippets">
        <Redirect to={'/getting-started/plan-selector'} />
      </Route>
      <Route path="/settings/emails/:mailType">
        <MailSettingsPage />
      </Route>
      <Route path="/settings/translations">
        <TranslationsPage />
      </Route>
      <Route path="/settings/webhook-deliveries">
        <CallbackDeliveriesPage />
      </Route>
      <Route path="/settings/pricing-plans">
        <PricingPlansPage />
      </Route>
      <Route path="/settings">
        <SettingsPage />
      </Route>
      <Route path="/news">
        <Redirect to={'/dashboard/news'} />
      </Route>
      <Route path="/workflows/create">
        <WorkflowPage />
      </Route>
      <Route path="/workflows/logs">
        <WorkflowLogsPage />
      </Route>
      <Route path="/workflows/:id">
        <WorkflowPage />
      </Route>
      <Route path="/workflows">
        <WorkflowsIndexPage />
      </Route>
      <Route path="/subscription-flows/create">
        <SubscriptionFlowPage />
      </Route>
      <Route path="/subscription-flows/:id">
        <SubscriptionFlowPage />
      </Route>
      <Route path="/">
        <Redirect to={hasSubscriptions ? '/dashboard' : '/getting-started'} />
      </Route>
    </Switch>
  )
}
