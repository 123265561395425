import { InlineError, LegacyCard, OptionList } from '@shopify/polaris'

import {
  workflowTriggerLabels,
  WorkflowTriggerType,
  workflowTriggerTypes,
} from '../common/workflow-models'

interface TriggerOption {
  label: string
  value: WorkflowTriggerType
}

const triggerOptions: TriggerOption[] = workflowTriggerTypes.map((trigger) => ({
  label: workflowTriggerLabels[trigger],
  value: trigger,
}))

interface TriggersCardProps {
  triggers: WorkflowTriggerType[]
  onChange: (triggers: WorkflowTriggerType[]) => any
  error: string | null
}

export const TriggersCard = (props: TriggersCardProps) => {
  return (
    <LegacyCard title="Events" sectioned>
      <OptionList
        id="triggers"
        allowMultiple={true}
        onChange={props.onChange}
        options={triggerOptions}
        selected={props.triggers}
      />
      {props.error && <InlineError message={props.error} fieldID={'triggers'} />}
    </LegacyCard>
  )
}
