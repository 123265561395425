import { Banner, FormLayout, Modal, Text } from '@shopify/polaris'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { mutate } from 'swr'

import { Result, SubscriptionContractSubscriptionStatus } from '../common/api'
import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { DateTimePicker } from '../common/DateTimePicker'
import { DialogActivator } from '../common/helpers'
import { SubscriptionContract } from '../common/subscription-models'

interface Props {
  sc: SubscriptionContract
  activator: DialogActivator
}

export const ReactivateDialog = ({ sc, activator }: Props) => {
  const initialDate = DateTime.now().plus({ hours: 1 }).toUTC().toISO()
  const [date, setDate] = useState<string>(initialDate)
  const [requestResultError, setRequestResultError] = useState<Result<any> | null>(null)

  useEffect(() => {
    setDate(initialDate)
  }, [activator, setDate, initialDate])

  useEffect(() => {
    setRequestResultError(null)
  }, [date])

  const submitAction = useApiActionState({
    method: 'POST',
    url: `/subscriptions/${sc.rest_id}/reactivate`,
    opts: {
      body: JSON.stringify({ date }),
    },
    onSuccess: (res) => {
      setRequestResultError(null)
      mutate(['subscription-contract', `${sc.rest_id}`], res.data, false)
      activator.close()
    },
    onError: setRequestResultError,
    bannerPosition: 'external',
  })

  return (
    <>
      <Modal
        open={activator.open}
        title={'Reactivate'}
        onClose={activator.close}
        primaryAction={{
          content: 'Reactivate',
          onAction: submitAction.run,
          loading: submitAction.loading,
        }}
        secondaryActions={[{ content: 'Cancel', onAction: activator.close }]}
        sectioned
      >
        <ApiActionBanner state={submitAction} />
        <FormLayout>
          {sc.gql_status === SubscriptionContractSubscriptionStatus.Expired && (
            <Banner status="warning">
              <Text as="p">
                This subscription has <Text as="strong">expired</Text>. After reactivation, please
                adjust the <Text as="strong">maximum number of installments</Text> to avoid future
                subscription expiration after the next scheduled payment.
              </Text>
            </Banner>
          )}
          <DateTimePicker
            label="Next payment date"
            initial={date}
            onChange={setDate}
            error={requestResultError?.field === 'date' ? requestResultError.message : null}
          />
          <Text as="p">
            Note: You will be able to edit the subscription after the reactivation.
          </Text>
        </FormLayout>
      </Modal>

      <ApiAction state={submitAction} />
    </>
  )
}
