import { Button, LegacyStack, TextField } from '@shopify/polaris'
import { QuestionMarkMajor } from '@shopify/polaris-icons'

import {
  anchorMonthNames,
  anchorWeekDayNames,
  SellingPlanAnchor,
  SellingPlanAnchorType,
} from './anchor-models'
import { formatOrdinal, mergeArrays, range, useDialogActivator, useShopFlag } from './helpers'
import { HelpLabel } from './HelpIcon'
import {
  MemoedNumericSelect,
  MemoedOptionalNumericSelect,
  NumericSelectOption,
  OptionalNumericSelectOption,
} from './NumericSelect'
import { SellingPlanInterval } from './plan-models'
import { PrepaidScopesNeededModal } from './PrepaidScopeNeededModal'

const intervalAnchorTypes: Record<string, SellingPlanAnchorType> = {
  [SellingPlanInterval.WEEK]: 'WEEKDAY',
  [SellingPlanInterval.MONTH]: 'MONTHDAY',
  [SellingPlanInterval.YEAR]: 'YEARDAY',
}

const disabledOptions: OptionalNumericSelectOption[] = [{ label: 'Disabled', value: null }]

const weekDayOptions: OptionalNumericSelectOption[] = mergeArrays(
  disabledOptions,
  range(1, 7).map((i) => ({ label: anchorWeekDayNames[i]!, value: i }))
)

const monthDayOptions = mergeArrays(
  disabledOptions,
  range(1, 31).map((i) => ({
    label: `${formatOrdinal(i)} of the month`,
    value: i,
  })) as OptionalNumericSelectOption[]
)

const yearDayOptions = mergeArrays(
  disabledOptions,
  range(1, 31).map((i) => ({ label: formatOrdinal(i), value: i })) as OptionalNumericSelectOption[]
)

const dayOptions: Record<string, OptionalNumericSelectOption[]> = {
  [SellingPlanInterval.DAY]: disabledOptions,
  [SellingPlanInterval.WEEK]: weekDayOptions,
  [SellingPlanInterval.MONTH]: monthDayOptions,
  [SellingPlanInterval.YEAR]: yearDayOptions,
}

const monthOptions: NumericSelectOption[] = range(1, 12).map((i) => ({
  label: anchorMonthNames[i]!,
  value: i,
}))

interface MockFieldProps {
  interval: SellingPlanInterval
  pagePath: string
}

const anchorDayHelpLabel = (
  <HelpLabel tooltip="Schedule deliveries for specified day">Anchor day</HelpLabel>
)

const MockField = (props: MockFieldProps) => {
  const dialogActivator = useDialogActivator()

  return (
    <>
      <TextField
        label={anchorDayHelpLabel}
        value="Disabled"
        onChange={() => null}
        disabled={true}
        connectedRight={<Button icon={QuestionMarkMajor} onClick={dialogActivator.show} />}
        autoComplete="off"
      />
      <PrepaidScopesNeededModal activator={dialogActivator} pagePath={props.pagePath}>
        <p>To start using anchors, you need to accept additional permissions in Shopify</p>
      </PrepaidScopesNeededModal>
    </>
  )
}

interface AnchorFieldProps {
  id?: string
  anchor: SellingPlanAnchor | null
  onChange: (anchor: SellingPlanAnchor | null, id: string) => any
  interval: SellingPlanInterval
  pagePath: string
}

export const AnchorField = ({ id, anchor, onChange, interval, pagePath }: AnchorFieldProps) => {
  const hasFulfillmentOrderScopes = useShopFlag('has_fulfillment_order_scopes')

  const newAnchorType = intervalAnchorTypes[interval]
  const newAnchor = {
    day: anchor?.day ?? 1,
    month: anchor?.month ?? (newAnchorType === 'YEARDAY' ? 1 : null),
    type: intervalAnchorTypes[interval],
  }

  if (!hasFulfillmentOrderScopes && anchor === null) {
    return <MockField pagePath={pagePath} interval={interval} />
  }

  return (
    <LegacyStack distribution="fillEvenly">
      <MemoedOptionalNumericSelect
        label={anchorDayHelpLabel}
        options={dayOptions[interval]}
        onChange={(day: number | null) =>
          onChange(day === null ? null : { ...newAnchor, day }, id ?? '')
        }
        disabled={interval === SellingPlanInterval.DAY}
        value={anchor?.day ?? null}
      />
      {interval === SellingPlanInterval.YEAR && (
        <MemoedNumericSelect
          label="Month"
          options={monthOptions}
          onChange={(month: number) => onChange({ ...newAnchor, month }, id ?? '')}
          disabled={!anchor}
          value={anchor?.month ?? 1}
        />
      )}
    </LegacyStack>
  )
}
