import { LegacyCard, Text, VerticalStack } from '@shopify/polaris'
import React, { useMemo } from 'react'
import Chart from 'react-apexcharts'

import { CenteredSpinner } from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { buildChartOptions } from './chart-options'

export interface ChartData {
  categories: string[]
  series: {
    name: string
    data: (number | null)[]
  }[]
  total: number | null
  count?: number
}

interface ChartCardProps {
  title: string
  tooltip: string
  filterLabel: string
  data?: ChartData
  height?: string | number
  valueFormatter?: (val: number, opts?: any) => string
  fullWidth?: boolean
}

export const ChartCard = (props: ChartCardProps) => {
  const { data, valueFormatter } = props

  const filteredData = (data?.series[0]?.data ?? []).filter(
    (value: number | null) => value !== null
  ) as number[]

  const options = useMemo(() => {
    return {
      ...buildChartOptions(filteredData, data?.categories ?? [], valueFormatter, props.fullWidth),
    }
  }, [data, valueFormatter, props.fullWidth, filteredData])

  return (
    <LegacyCard
      title={
        <Text variant="headingMd" as="h2">
          {props.title} <LegacyHelpIcon>{props.tooltip}</LegacyHelpIcon>
        </Text>
      }
      actions={[{ content: props.filterLabel, disabled: true }]}
      sectioned
    >
      {!data && <CenteredSpinner />}
      {data && (
        <VerticalStack gap="1">
          {data.total !== null && (
            <Text variant="heading2xl" as="p">
              {valueFormatter ? valueFormatter(data.total) : data.total}
            </Text>
          )}
          {!!data.count && (
            <Text as="p" color="subdued" variant="bodyMd">
              Based on <Text as="strong">{data.count}</Text> subscriptions.
            </Text>
          )}
          {data.total === null && (
            <Text color="subdued" as="p">
              &ndash;
            </Text>
          )}
          <Chart options={options} series={data.series} type="line" height={props.height} />
        </VerticalStack>
      )}
    </LegacyCard>
  )
}
