import { Select, SelectProps } from '@shopify/polaris'
import useSWR from 'swr'

import { backendFetchResultData } from './api'
import { ExternalAdminLink } from './helpers'
import { urlWithParams } from './urls'

export interface Theme {
  id: number
  name: string
  role: string
  theme_store_id: number | null
  title: string
  supports_plan_selector_block: boolean | null
  plan_selector_preview_path: string
  customer_portal_template_paths: {
    account_page: string | null
    customer_addresses: string | null
    customer_login: string | null
  }
}

export const useThemesSWR = () =>
  useSWR<Theme[]>(['shop-themes'], () => backendFetchResultData('GET', '/shop/themes'))

export const useThemeSWR = (themeId: number) =>
  useSWR<Theme>(
    ['shop-theme', themeId],
    () => backendFetchResultData('GET', `/shop/theme/${themeId}`),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

interface ThemeSelectProps extends Omit<SelectProps, 'options' | 'value' | 'onChange'> {
  themes: Theme[]
  onChange: (theme: Theme) => void
  value: Theme
}

export const ThemeSelect = (props: ThemeSelectProps) => {
  const { themes, onChange, value, ...selectProps } = props

  return (
    <Select
      options={themes.map((t, i) => ({ label: t.title, value: i.toString() }))}
      onChange={(val) => props.onChange(props.themes[parseInt(val)])}
      value={themes.indexOf(value).toString()}
      {...selectProps}
    />
  )
}

export const ThemeLink = ({ id, path, children }: { id: number; path: string; children: any }) => (
  <ExternalAdminLink url={`/themes/${id}?key=${encodeURIComponent(path)}`} children={children} />
)

export const ThemeEditorLink = ({
  id,
  previewPath = undefined,
  context = undefined,
  children,
}: {
  id: number
  previewPath?: string
  context?: 'apps'
  children: any
}) => {
  const url = urlWithParams(`/themes/${id}/editor`, { previewPath, context })
  return <ExternalAdminLink url={url} children={children} />
}
