import { Banner, Button, LegacyStack, Modal, TextField } from '@shopify/polaris'
import { useEffect, useState } from 'react'

import { mutateWithResult, Result } from '../common/api'
import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { CenteredSpinner, DialogActivator } from '../common/helpers'
import { useCustomerPortalCustomStyles } from './api'

export const Content = ({ activator }: { activator: DialogActivator }) => {
  const { data: initialStyles, isValidating: loading } = useCustomerPortalCustomStyles()

  const [styles, setStyles] = useState(initialStyles ?? '')

  useEffect(() => {
    setStyles(initialStyles ?? '')
  }, [initialStyles, activator.showCounter])

  const saveAction = useApiActionState({
    method: 'PATCH',
    url: '/settings/customer-portal-custom-styles',
    opts: {
      body: JSON.stringify({ styles: styles }),
    },
    onSuccess: (res: Result<string>) => {
      mutateWithResult(['customer-portal-custom-styles'], res)
    },
    bannerPosition: 'external',
  })

  return (
    <>
      <Modal.Section>
        <ApiActionBanner state={saveAction} />
        {loading && initialStyles === undefined && <CenteredSpinner />}
        {!loading && initialStyles === undefined && (
          <Banner status="critical">Styles couldn't be loaded, please try again later</Banner>
        )}
        {initialStyles !== undefined && (
          <TextField
            label="Custom CSS rules"
            labelHidden
            value={styles}
            onChange={setStyles}
            multiline={25}
            autoComplete="off"
            autoFocus
            monospaced
            spellCheck={false}
            maxHeight="250px"
            placeholder="/* Add custom CSS rules for your customer portal */"
          />
        )}
      </Modal.Section>
      <Modal.Section>
        <LegacyStack distribution="trailing" spacing="tight">
          <Button onClick={activator.close}>Close</Button>
          <Button
            primary
            onClick={saveAction.run}
            loading={saveAction.loading}
            disabled={initialStyles === undefined || styles === initialStyles}
          >
            Save
          </Button>
        </LegacyStack>
      </Modal.Section>
      <ApiAction state={saveAction} />
    </>
  )
}

export const CustomStylesModal = ({ activator }: { activator: DialogActivator }) => (
  <Modal title="Custom CSS rules" open={activator.open} onClose={activator.close} large>
    <Content activator={activator} />
  </Modal>
)
