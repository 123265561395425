import { DialogActivator } from '../common/helpers'
import { ScopesNeededModal } from './ScopesNeededModal'

interface PrepaidScopesNeededModalProps {
  children: any
  activator: DialogActivator
  pagePath: string
}
export const PrepaidScopesNeededModal = ({
  children,
  activator,
  pagePath,
}: PrepaidScopesNeededModalProps) => {
  return (
    <ScopesNeededModal
      children={children}
      activator={activator}
      pagePath={pagePath}
      requestActionUrl="/settings/request-pre-paid-plans"
    />
  )
}
