import { Layout, LinkAction, Loading, Page } from '@shopify/polaris'
import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'

import { ApiActionDialog } from '../common/ApiActionDialog'
import { Footer } from '../common/Footer'
import { showIntercom, useDialogActivator } from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import { Timeline } from '../common/Timeline'
import * as urls from '../common/urls'
import { fetchSellingPlanGroup } from './api'
import {
  initialSellingPlanGroupFormData,
  parseSellingPlanGroupResponse,
  SellingPlanGroupFormData,
} from './models-form'
import { PageSkeleton } from './PageSkeleton'
import { SellingPlanGroupForm } from './SellingPlanGroupForm'

const fetchSellingPlanGroupFormData = async (
  _: string,
  id?: string
): Promise<SellingPlanGroupFormData> => {
  if (!id) {
    return initialSellingPlanGroupFormData()
  }

  const spg = await fetchSellingPlanGroup(id)
  const data = parseSellingPlanGroupResponse(spg)

  return data
}

export const sellingPlanDeleteWarning = `Warning: Any existing customers subscribed to this plan
  won't be able to edit their products, unless you manually reassign them to a different plan`

export const SellingPlanPage = () => {
  const backAction: LinkAction = { content: 'Manage Plans', url: urls.plansUrl }

  const { id } = useParams<{ id?: string }>()
  const { data, error, isValidating } = useSWR(
    ['selling-plan-group', id],
    fetchSellingPlanGroupFormData,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
    }
  )

  const history = useHistory()

  const afterSave = useCallback(
    (groupId: string) => {
      history.replace(urls.planUrl(groupId))
      mutate(['selling-plan-group', id])
    },
    [id, history]
  )

  const afterDelete = useCallback(() => history.replace(urls.plansUrl), [history])

  const deleteDialogActivator = useDialogActivator()

  return (
    <>
      {isValidating && <Loading />}
      <Tabs selected={TabIndex.Plans} />
      {error && <LoadErrorPage />}
      {!error && (
        <Page
          title={id ? 'Edit selling plan group' : 'Create selling plan group'}
          backAction={backAction}
          primaryAction={
            id
              ? {
                  content: (data?.productsCount ?? 0) > 0 ? 'Edit products' : 'Add products',
                  url: urls.planProductsUrl(id),
                }
              : undefined
          }
          secondaryActions={
            id && data
              ? [
                  {
                    content: 'Chat with us',
                    onAction: () =>
                      showIntercom('I have a question about editing selling plan groups.'),
                  },
                  { content: 'Delete', onAction: deleteDialogActivator.show },
                ]
              : undefined
          }
        >
          <Layout>
            {!error && !data && <PageSkeleton />}
            {!error && data && <SellingPlanGroupForm initialValues={data} afterSave={afterSave} />}
            {!error && data?.createdAt && (
              <Layout.Section>
                <Timeline
                  activities={data.activities}
                  createdAt={data.createdAt}
                  recordingSince={'2022-08-07T08:00:00Z'}
                />
              </Layout.Section>
            )}
            <Layout.Section>
              <Footer />
            </Layout.Section>
          </Layout>

          {id && (
            <ApiActionDialog
              activator={deleteDialogActivator}
              onSuccess={afterDelete}
              url={`/plans/${id}`}
              method="DELETE"
              message={sellingPlanDeleteWarning}
              destructive={true}
              primaryButtonTitle="Delete"
            />
          )}
        </Page>
      )}
    </>
  )
}
