import { SelectProps, TextFieldProps } from '@shopify/polaris'

import { MemoedSelect, MemoedTextField } from './helpers'
import { HelpLabel } from './HelpIcon'
import { SellingPlanPricingPolicyAdjustmentType } from './plan-models'

const priceAdjustmentTypeOptions = [
  {
    label: 'None',
    value: SellingPlanPricingPolicyAdjustmentType.NONE,
  },
  {
    label: 'Fixed amount discount',
    value: SellingPlanPricingPolicyAdjustmentType.FIXED_AMOUNT,
  },
  { label: 'Percentage discount', value: SellingPlanPricingPolicyAdjustmentType.PERCENTAGE },
  { label: 'Manual price', value: SellingPlanPricingPolicyAdjustmentType.PRICE },
]

interface PriceAdjustmentTypeFieldProps
  extends Omit<SelectProps, 'label' | 'onChange' | 'options' | 'value'> {
  value: SellingPlanPricingPolicyAdjustmentType
  onChange: (val: SellingPlanPricingPolicyAdjustmentType, id: string) => void
}

export const PriceAdjustmentTypeField = ({ ...props }: PriceAdjustmentTypeFieldProps) => (
  <MemoedSelect label="Discount or custom price" options={priceAdjustmentTypeOptions} {...props} />
)

type PriceAdjustmentValueFieldProps = Omit<
  TextFieldProps & {
    prePaidDeliveries: number
    adjustmentType: SellingPlanPricingPolicyAdjustmentType
    currencySymbol: string
    onChange: (value: string, id: string) => void
  },
  'label' | 'type' | 'autoComplete' | 'prefix' | 'suffix'
>

export const getPriceAdjustmentValueError = (
  type: SellingPlanPricingPolicyAdjustmentType,
  value?: string
): string | undefined => {
  if (!value || isNaN(parseFloat(value))) {
    return undefined
  }

  if (parseFloat(value) < 0) {
    return 'Adjustment value must be greater than or equal to 0'
  }

  if (type === SellingPlanPricingPolicyAdjustmentType.PERCENTAGE && parseFloat(value) > 100) {
    return 'Adjustment value must be less than or equal 100'
  }

  return undefined
}

export const PriceAdjustmentValueField = ({
  prePaidDeliveries,
  adjustmentType,
  currencySymbol,
  value,
  disabled,
  ...props
}: PriceAdjustmentValueFieldProps) => (
  <MemoedTextField
    label={
      prePaidDeliveries > 1 ? (
        <HelpLabel tooltip="Note: Shopify may adjust the final price to be evenly divisible by the amount of pre-paid deliveries">
          Adjustment value
        </HelpLabel>
      ) : (
        <>Adjustment value</>
      )
    }
    type="number"
    autoComplete="off"
    prefix={
      adjustmentType !== SellingPlanPricingPolicyAdjustmentType.PERCENTAGE
        ? currencySymbol
        : undefined
    }
    suffix={adjustmentType === SellingPlanPricingPolicyAdjustmentType.PERCENTAGE ? '%' : undefined}
    disabled={disabled || adjustmentType === SellingPlanPricingPolicyAdjustmentType.NONE}
    value={adjustmentType === SellingPlanPricingPolicyAdjustmentType.NONE ? '0' : value}
    min="0"
    {...props}
  />
)
