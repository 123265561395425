import { Button, LegacyStack, Tag, Text } from '@shopify/polaris'
import React, { useCallback } from 'react'

import { gidToId, useToggle } from '../common/helpers'
import { ProductModal, ProductResult } from '../common/ProductModal'

interface ProductFilterProps {
  label: string
  params: [string, string][]
  onChange: (params: any, index: number) => any
  index: number
}

export const ProductFilter = (props: ProductFilterProps) => {
  const { label, params, onChange, index } = props

  const [productModalActive, toggleProductModalActive] = useToggle(false)

  const handleUpdate = useCallback(
    (selected: ProductResult) => {
      onChange([...params, [gidToId(selected.id), selected.title]], index)
      toggleProductModalActive()
    },
    [params, index, onChange, toggleProductModalActive]
  )

  const handleRemove = useCallback(
    (variantIndex: number) => {
      const newParams = [...params]
      newParams.splice(variantIndex, 1)
      onChange(newParams, index)
    },
    [params, index, onChange]
  )

  return (
    <LegacyStack vertical>
      <p>{label}</p>

      {params.length === 0 && (
        <Text as="span" color="subdued">
          No products selected
        </Text>
      )}

      {params.length > 0 && (
        <LegacyStack spacing="tight">
          {params.map(([id, name], index: number) => (
            <Tag key={id} onRemove={() => handleRemove(index)}>
              {name}
            </Tag>
          ))}
        </LegacyStack>
      )}

      <Button onClick={toggleProductModalActive} size="slim">
        Add product
      </Button>

      <ProductModal
        open={productModalActive}
        onClose={toggleProductModalActive}
        onSelect={handleUpdate}
      />
    </LegacyStack>
  )
}
