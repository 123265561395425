import { ContextualSaveBar, Form, Layout, Text } from '@shopify/polaris'
import { Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { mutate } from 'swr'

import { Result } from '../common/api'
import {
  ExternalLink,
  ResultToastOrBanner,
  ShowIntercomLink,
  useQuery,
  useShopFlag,
} from '../common/helpers'
import { ReferralCode } from '../common/ReferralCode'
import { FeatureAccessFlag } from '../common/SystemData'
import * as urls from '../common/urls'
import { patchUserSettings, UserSettings } from './api'
import { BillingCard } from './BillingCard'
import { CallbacksCard } from './CallbacksCard'
import { CancellationFlowCard } from './CancellationFlowCard'
import { CustomerPortalCard } from './CustomerPortalCard'
import { CustomerPortalThemeCard } from './CustomerPortalThemeCard'
import { InventoryManagementCard } from './InventoryManagementCard'
import { KlaviyoCard } from './KlaviyoCard'
import { MerchantApiTokensCard } from './MerchantApiTokensCard'
import { NotificationsCard } from './NotificationsCard'
import { PaywhirlAppsCard } from './PaywhirlAppsCard'
import { PricingPlanCard } from './PricingPlanCard'
import { SystemNotificationsCard } from './SystemNotificationsCard'

interface Props {
  initialValues: UserSettings
}

export const SettingsForm = ({ initialValues }: Props) => {
  const query = useQuery()
  const history = useHistory()

  const klaviyoAuthResult = query.get('klaviyo-auth')
  const klaviyoIntegrationAccess = useShopFlag('klaviyo_integration') as FeatureAccessFlag
  const hasReferralPartnerId = useShopFlag('has_referral_partner_id')
  const isEligibleForReferralProgram = useShopFlag('eligible_for_referral_program')

  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const handleSubmit = useCallback(async (settings: UserSettings, actions: any) => {
    actions.setSubmitting(true)
    const res = await patchUserSettings(settings)
    actions.setSubmitting(false)

    setSaveResult(res)

    if (res.status === 'success' && res.data) {
      mutate(['user-settings'], res.data)
    } else {
      window.scrollTo(0, 0)
    }
  }, [])

  useEffect(() => {
    if (klaviyoAuthResult === 'success') {
      setSaveResult({ status: 'success', message: 'Klaviyo integration enabled', temporary: false })
    }

    if (klaviyoAuthResult === 'error') {
      setSaveResult({
        status: 'error',
        message:
          'There was an error when trying to authorize your Klaviyo account. Please try again or contact PayWhirl support team if this error persists.',
        temporary: false,
      })
    }
  }, [klaviyoAuthResult])

  if (query.get('klaviyo-auth')) {
    history.replace(urls.settingsUrl())
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <ResultToastOrBanner result={saveResult} setResult={setSaveResult} />

          <Layout>
            <Layout.AnnotatedSection
              title="Pricing Plan"
              description={
                <Text as="p">
                  Manage and customize your pricing plan. Questions?{' '}
                  <ShowIntercomLink message="I have questions about pricing.">
                    Chat with us
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <PricingPlanCard />
            </Layout.AnnotatedSection>

            {!hasReferralPartnerId && isEligibleForReferralProgram && (
              <Layout.AnnotatedSection
                title="Referral Code"
                description="Enter your PayWhirl partner program referral code."
              >
                <ReferralCode />
              </Layout.AnnotatedSection>
            )}

            <Layout.AnnotatedSection
              title="Explore Our Apps"
              description={
                <Text as="p">
                  Discover seamless integrations with apps made by the PayWhirl team. Questions?{' '}
                  <ShowIntercomLink message="I want to know more about PayWhirl apps and integrations.">
                    Let's chat
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <PaywhirlAppsCard />
            </Layout.AnnotatedSection>

            <Layout.AnnotatedSection
              title="Payments"
              description={
                <Text as="p">
                  Configure dunning settings and manage how payments are retried.{' '}
                  <ShowIntercomLink message="I have questions about failed payment management.">
                    Chat with us
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <BillingCard />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Inventory Management"
              description={
                <Text as="p">
                  Set rules for low inventory actions. (
                  <ExternalLink href="https://docs.paywhirl.com/en/articles/7134444-inventory-management-settings-in-paywhirl">
                    learn more
                  </ExternalLink>
                  ) Questions?{' '}
                  <ShowIntercomLink message="I have questions about inventory management settings.">
                    Let's chat
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <InventoryManagementCard />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Customer Portal Design"
              description={
                <Text as="p">
                  Customize the look and feel of the customer portal.{' '}
                  <ShowIntercomLink message="I have questions about the Customer Portal settings.">
                    Chat with us
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <CustomerPortalThemeCard />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Customer Portal Actions"
              description={
                <Text as="p">
                  Define the options available to customers in the self-serve portal. Questions?{' '}
                  <ShowIntercomLink message="I want to discuss the Customer Portal Actions section.">
                    Let's chat
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <CustomerPortalCard />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Cancellation Flow"
              description={
                <Text as="p">
                  Manage the subscription cancellation process.{' '}
                  <ShowIntercomLink message="I need assistance with the Cancellation Flow section.">
                    Chat with us
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <CancellationFlowCard />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Customer Notifications"
              description={
                <Text as="p">
                  Configure email notifications for your customers. Click the title to edit.
                  Questions?{' '}
                  <ShowIntercomLink message="I have questions about the Customer Notifications section.">
                    Let's chat
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <NotificationsCard />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="System Notifications"
              description={
                <Text as="p">
                  Manage email notifications sent to store owners.{' '}
                  <ShowIntercomLink message="I need help with the System Notifications section.">
                    Chat with us
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <SystemNotificationsCard />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="API Tokens"
              description={
                <Text as="p">
                  Generate and manage API access tokens. (
                  <ExternalLink href="https://api.shop.paywhirl.com/">learn more</ExternalLink>){' '}
                  <ShowIntercomLink message="I want to discuss the API Tokens section.">
                    Chat with us
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <MerchantApiTokensCard />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Webhooks"
              description={
                <Text as="p">
                  Configure JSON notifications for subscription events. (
                  <ExternalLink href="https://api.shop.paywhirl.com/">learn more</ExternalLink>){' '}
                  <ShowIntercomLink message="I need assistance with the Webhooks section.">
                    Chat with us
                  </ShowIntercomLink>
                  .
                </Text>
              }
            >
              <CallbacksCard />
            </Layout.AnnotatedSection>
            {klaviyoIntegrationAccess !== 'disabled' && (
              <Layout.AnnotatedSection
                title="Klaviyo Integration"
                description={
                  <>
                    Track and analyze subscription events in Klaviyo. (
                    <ExternalLink href="https://docs.paywhirl.com/en/articles/8769188-how-to-send-emails-and-sms-messages-with-our-klaviyo-integration">
                      learn more
                    </ExternalLink>
                    ) Questions?{' '}
                    <ShowIntercomLink message="I have questions about the Klaviyo Integration section.">
                      Let's chat
                    </ShowIntercomLink>
                    .
                  </>
                }
              >
                <KlaviyoCard userSettings={initialValues} />
              </Layout.AnnotatedSection>
            )}
          </Layout>
          {formik.dirty && (
            <ContextualSaveBar
              message="Unsaved changes"
              saveAction={{
                onAction: formik.handleSubmit,
                loading: formik.isSubmitting,
                disabled: false,
              }}
              discardAction={{
                onAction: formik.resetForm,
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}
