import {
  Banner,
  FormLayout,
  LegacyCard,
  LegacyStack,
  Select,
  Text,
  VerticalStack,
} from '@shopify/polaris'
import { useFormikContext } from 'formik'
import React from 'react'

import {
  capitalize,
  InternalLink,
  useFormikHandleChangeCallback,
  useShopPermission,
} from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { NumericSelect, NumericSelectOption } from '../common/NumericSelect'
import {
  PaypalExpressSubscriptionsGatewayStatus,
  useShopShopifySettingsSWR,
} from '../common/shopify-settings'
import * as urls from '../common/urls'
import {
  failedDunningActionCancelSubscription,
  failedDunningActionPauseSubscription,
  failedDunningActionSkipOrder,
  UserSettings,
} from './api'

const daySeconds = 86400

const optionsForRetryDelay: NumericSelectOption[] = [
  { label: '1 minute (use only for testing)', value: 60 },
  { label: '1 day', value: daySeconds * 1 },
  { label: '2 days', value: daySeconds * 2 },
  { label: '3 days', value: daySeconds * 3 },
  { label: '5 days', value: daySeconds * 5 },
  { label: '7 days', value: daySeconds * 7 },
  { label: '14 days', value: daySeconds * 14 },
  { label: '30 days', value: daySeconds * 30 },
]

const optionsForTimeOut: NumericSelectOption[] = [
  { label: '5 minutes (use only for testing)', value: 300 },
  { label: '1 day', value: daySeconds * 1 },
  { label: '2 days', value: daySeconds * 2 },
  { label: '3 days', value: daySeconds * 3 },
  { label: '5 days', value: daySeconds * 5 },
  { label: '7 days', value: daySeconds * 7 },
  { label: '14 days', value: daySeconds * 14 },
  { label: '30 days', value: daySeconds * 30 },
]

const optionsForRetryCount: NumericSelectOption[] = [
  { label: "Don't retry", value: 0 },
  { label: 'Once', value: 1 },
  { label: 'Twice', value: 2 },
  { label: '3 times', value: 3 },
  { label: '4 times', value: 4 },
  { label: '5 times', value: 5 },
]

const paypalStatusColor: Record<
  PaypalExpressSubscriptionsGatewayStatus,
  'success' | 'critical' | 'warning' | 'subdued' | 'text-inverse'
> = {
  DISABLED: 'critical',
  ENABLED: 'success',
  PENDING: 'warning',
}

const paypalBannerStatus: Record<
  PaypalExpressSubscriptionsGatewayStatus,
  'success' | 'info' | 'warning' | 'critical'
> = {
  DISABLED: 'critical',
  ENABLED: 'success',
  PENDING: 'warning',
}

export const BillingCard = () => {
  const formik = useFormikContext<UserSettings>()
  const failedPaymentWorkflowsEnabled = useShopPermission('failedPaymentWorkflowsEnabled')

  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)
  const { data: shopifyShopSettingsData } = useShopShopifySettingsSWR()

  return (
    <LegacyCard sectioned>
      <FormLayout>
        {shopifyShopSettingsData?.paypalExpressSubscriptionGatewayStatus && (
          <Banner
            status={
              paypalBannerStatus[shopifyShopSettingsData.paypalExpressSubscriptionGatewayStatus]
            }
          >
            <Text as="p">
              PayPal Express subscriptions gateway status in Shopify:{' '}
              <Text
                as="strong"
                color={
                  paypalStatusColor[shopifyShopSettingsData.paypalExpressSubscriptionGatewayStatus]
                }
              >
                {capitalize(shopifyShopSettingsData.paypalExpressSubscriptionGatewayStatus)}
              </Text>
            </Text>
          </Banner>
        )}
        <NumericSelect
          id="attempt_retry_count"
          name="attempt_retry_count"
          label={
            <span>
              Retry failed payments{' '}
              <LegacyHelpIcon>
                The number of times to retry a failed payment before the last failed attempt action
                is taken
              </LegacyHelpIcon>
            </span>
          }
          options={optionsForRetryCount}
          value={formik.values.attempt_retry_count}
          onChange={handleChange}
        />
        <NumericSelect
          id="attempt_retry_delay"
          name="attempt_retry_delay"
          label="Delay before retrying a failed payment"
          options={optionsForRetryDelay}
          value={formik.values.attempt_retry_delay}
          onChange={handleChange}
        />
        <NumericSelect
          id="attempt_timeout"
          name="attempt_timeout"
          label={
            <span>
              Timeout for abandoned 3DS authentications{' '}
              <LegacyHelpIcon>
                A payment may be stuck as pending if the customer doesn't proceed with 3DS
                authentication. This setting will mark it as failed after the specified amount of
                time, in order to retry it or fail the subscription
              </LegacyHelpIcon>
            </span>
          }
          options={optionsForTimeOut}
          value={formik.values.attempt_timeout}
          onChange={handleChange}
        />
        <LegacyStack.Item fill>
          <VerticalStack gap="2">
            {!failedPaymentWorkflowsEnabled && (
              <Banner status="warning">
                <Text as="p">
                  Please{' '}
                  <InternalLink url={urls.pricingPlansUrl()} target={'_blank'}>
                    upgrade
                  </InternalLink>{' '}
                  to our <Text as="strong">Traction Plan</Text> to access the failed payments action
                  feature and enjoy benefits like automatic order skipping or pausing after the last
                  failed retry attempt.
                </Text>
              </Banner>
            )}
            <Select
              id="failed_dunning_action"
              name="failed_dunning_action"
              disabled={!failedPaymentWorkflowsEnabled}
              label={
                <Text as="span" color="subdued">
                  Last failed attempt action
                  <LegacyHelpIcon>
                    The action to take after the last failed retry attempt
                  </LegacyHelpIcon>
                </Text>
              }
              options={[
                {
                  label: 'Cancel subscription',
                  value: failedDunningActionCancelSubscription,
                },
                {
                  label: 'Skip order',
                  value: failedDunningActionSkipOrder,
                },
                {
                  label: 'Pause subscription',
                  value: failedDunningActionPauseSubscription,
                },
              ]}
              value={
                failedPaymentWorkflowsEnabled
                  ? formik.values.failed_dunning_action
                  : failedDunningActionCancelSubscription
              }
              onChange={handleChange}
            />
            {!!failedPaymentWorkflowsEnabled && (
              <Banner status="info">
                <Text as="p">
                  You can edit the email template for this action in the{' '}
                  <Text as="strong">Customer Notifications</Text> section below.
                </Text>
              </Banner>
            )}
          </VerticalStack>
        </LegacyStack.Item>
      </FormLayout>
    </LegacyCard>
  )
}
