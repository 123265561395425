import { Action, Badge, Button, DataTable, LegacyCard, LegacyStack, Text } from '@shopify/polaris'
import { CaretDownMinor, CaretUpMinor } from '@shopify/polaris-icons'
import React from 'react'
import { mutate } from 'swr'

import { SubscriptionContractSubscriptionStatus } from '../common/api'
import { ApiActionDialog } from '../common/ApiActionDialog'
import { ShortDate, useDialogActivator, useToggle } from '../common/helpers'
import { BillingAttempt, SubscriptionContract } from '../common/subscription-models'
import { PaymentDetails } from './PaymentDetails'

type BadgeStatus = 'success' | 'info' | 'attention' | 'critical' | 'warning' | 'new'

const statusBadgeStatus = (ba: BillingAttempt): BadgeStatus =>
  ba.status === 'Pending' ? 'new' : ba.status === 'Success' ? 'success' : 'critical'

const StatusBadge = ({ attempt }: { attempt: BillingAttempt }) => {
  const status = statusBadgeStatus(attempt)

  return <Badge status={status}>{attempt.status}</Badge>
}

const billingAttemptForFirstOrder = (sc: SubscriptionContract): BillingAttempt => ({
  ready: 1,
  created_at: sc.gql_data.createdAt,
  error_message: undefined,
  status: 'Success',
  gql_data: {
    errorCode: undefined,
    order: sc.origin_order,
  },
})

export const PaymentsCard = ({ sc }: { sc: SubscriptionContract }) => {
  const chargeNowDialogActivator = useDialogActivator()
  const canChargeNow =
    sc.is_editable &&
    (SubscriptionContractSubscriptionStatus.Active === sc.gql_status ||
      SubscriptionContractSubscriptionStatus.Paused === sc.gql_status)

  const attempts: BillingAttempt[] = [...sc.billing_attempts]
  if (sc.origin_order) {
    attempts.push(billingAttemptForFirstOrder(sc))
  }

  const [expanded, toggleExpanded] = useToggle(false)
  const visibleAttempts = expanded ? attempts : attempts.slice(0, 5)
  const rows = visibleAttempts.map((ba: BillingAttempt) => [
    <ShortDate date={ba.created_at} />,
    <StatusBadge attempt={ba} />,
    <PaymentDetails
      subscriptionRestId={sc.rest_id}
      baId={ba.id}
      status={ba.status}
      errorCode={ba.gql_data?.errorCode}
      errorMessage={ba.error_message}
      orderGid={ba.gql_data?.order?.id}
      orderName={ba.gql_data?.order?.name}
      nextActionUrl={ba.gql_data?.nextActionUrl}
    />,
  ])

  let actions: Action[] = []

  if (canChargeNow) {
    actions.push({ content: 'Charge now', onAction: chargeNowDialogActivator.show })
  }

  return (
    <LegacyCard title="Payments" actions={actions}>
      <LegacyCard.Section>
        <DataTable
          columnContentTypes={['text', 'text', 'text', 'text', 'text']}
          headings={['Date', 'Status', 'Details']}
          rows={rows}
        />
        <ApiActionDialog
          activator={chargeNowDialogActivator}
          url={`/subscriptions/${sc.rest_id}/attempt-billing`}
          method="POST"
          message="Are you sure to charge now for the next order?"
          primaryButtonTitle="Charge"
          onSuccess={(res) => mutate(['subscription-contract', `${sc.rest_id}`], res.data, false)}
        />
      </LegacyCard.Section>

      <LegacyCard.Section subdued>
        <LegacyStack distribution="equalSpacing">
          <span>
            {attempts.length > 5 && (
              <Button
                plain
                onClick={toggleExpanded}
                icon={expanded ? CaretUpMinor : CaretDownMinor}
              >
                {expanded ? 'Hide all' : `View all (${attempts.length})`}
              </Button>
            )}
          </span>
          <Text as="span" color="subdued">
            Successful: {sc.processed_installments}
            {sc.remaining_installments !== null && `, Remaining: ${sc.remaining_installments}`}
          </Text>
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  )
}
