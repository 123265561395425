import { Layout, LinkAction, Loading, Page, SkeletonBodyText } from '@shopify/polaris'
import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'

import { Result } from '../common/api'
import { ApiActionDialog } from '../common/ApiActionDialog'
import { Footer } from '../common/Footer'
import { ResultToastOrBanner, showIntercom, useDialogActivator, useQuery } from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { SellingPlansContext, useSellingPlansSWR } from '../workflow-page/api'
import { workflowLabels } from '../workflows-index-page/WorkflowCreateCard'
import {
  convertWorkflowGroupToSubscriptionFlowFormData,
  createOrUpdateWorkflowGroup,
  fetchWorkflowGroupFormData,
  initialSubscriptionFlowFormData,
  SubscriptionFlowFormData,
  SubscriptionFlowInitialParams,
} from './api'
import { SubscriptionFlowForm } from './SubscriptionFlowForm'
import { SubscriptionFlowInfoModal } from './SubscriptionFlowInfoModal'

const useWorkflowGroupFormData = (id?: string, initialParams: SubscriptionFlowInitialParams = {}) =>
  useSWR<SubscriptionFlowFormData>(
    ['workflow-group', id],
    (_: any, id?: string) =>
      id ? fetchWorkflowGroupFormData(_, id) : initialSubscriptionFlowFormData(initialParams),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
    }
  )

const Skeleton = () => (
  <Layout>
    <Layout.Section>
      <SkeletonBodyText lines={10} />
      <br />
      <SkeletonBodyText lines={10} />
    </Layout.Section>
    <Layout.Section oneThird>
      <SkeletonBodyText lines={7} />
    </Layout.Section>
  </Layout>
)

export const SubscriptionFlowPage = () => {
  const history = useHistory()
  const deleteDialogActivator = useDialogActivator()
  const infoDialogActivator = useDialogActivator()
  const { id } = useParams<{ id?: string }>()
  const query = useQuery()

  const createForSellingPlanGroupId = query.get('selling_plan_group_id') ?? undefined
  const createForSellingPlanId = query.get('selling_plan_id') ?? undefined

  const sellingPlansSWR = useSellingPlansSWR()
  const workflowGroupFormSWR = useWorkflowGroupFormData(id, {
    sellingPlanGroupId: createForSellingPlanGroupId,
    sellingPlanId: createForSellingPlanId,
  })
  const workflowGroupFormData = workflowGroupFormSWR.data

  const [isSaving, setSaving] = useState(false)
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const backAction: LinkAction = { content: 'Workflows', url: urls.workflowsUrl }

  const onSave = useCallback(
    async (data: SubscriptionFlowFormData) => {
      setSaving(true)

      const res = await createOrUpdateWorkflowGroup(data, id)

      setSaving(false)

      setSaveResult(res)

      if (res.status !== 'success' || !res.data) {
        return
      }

      if (id) {
        mutate(
          ['workflow-group', id],
          convertWorkflowGroupToSubscriptionFlowFormData(res.data),
          false
        )
      } else {
        history.replace(urls.subscriptionFlowsUrl(res.data.id))
        infoDialogActivator.show()
      }
    },
    [id, history, infoDialogActivator]
  )

  const afterDelete = useCallback(
    () => setTimeout(() => history.replace(urls.workflowsUrl), 10),
    [history]
  )

  const primaryAction =
    id && workflowGroupFormData
      ? {
          content: 'Delete',
          onAction: deleteDialogActivator.show,
          disabled: isSaving,
          destructive: true,
        }
      : undefined

  const secondaryActions =
    id && workflowGroupFormData
      ? [
          {
            content: 'Chat with us',
            onAction: () =>
              showIntercom('I have questions about editing subscription automation workflows.'),
          },
          {
            content: workflowGroupFormData.is_active ? 'Disable' : 'Activate',
            onAction: () =>
              onSave({ ...workflowGroupFormData, is_active: !workflowGroupFormData.is_active }),
            disabled: isSaving,
          },
        ]
      : [
          {
            content: 'Chat with us',
            onAction: () =>
              showIntercom('I have a question about creating subscription automation workflows.'),
          },
        ]

  return (
    <>
      {(workflowGroupFormSWR.isValidating || isSaving) && <Loading />}
      <Tabs selected={TabIndex.Workflows} />
      {workflowGroupFormSWR.error && <LoadErrorPage />}
      {!workflowGroupFormSWR.error && (
        <Page
          title={
            id
              ? `Edit ${workflowLabels['subscription'].title.toLowerCase()}`
              : `Create ${workflowLabels['subscription'].title.toLowerCase()}`
          }
          backAction={backAction}
          primaryAction={primaryAction}
          secondaryActions={secondaryActions}
        >
          {(!workflowGroupFormData || !sellingPlansSWR.data) && <Skeleton />}

          {workflowGroupFormData && sellingPlansSWR.data && (
            <SellingPlansContext.Provider value={sellingPlansSWR.data ?? []}>
              <ResultToastOrBanner result={saveResult} setResult={setSaveResult} />
              <SubscriptionFlowForm
                initialData={workflowGroupFormData}
                onSave={onSave}
                isSaving={isSaving}
                saveResult={saveResult}
                isNew={!id}
              />
            </SellingPlansContext.Provider>
          )}
          <Layout>
            <Layout.Section>
              <Footer />
            </Layout.Section>
          </Layout>
        </Page>
      )}
      {id && (
        <ApiActionDialog
          activator={deleteDialogActivator}
          onSuccess={afterDelete}
          url={`/workflow-groups/${id}`}
          method="DELETE"
          message="This action cannot be reverted"
          destructive={true}
          primaryButtonTitle="Delete"
        />
      )}
      <SubscriptionFlowInfoModal activator={infoDialogActivator} />
    </>
  )
}
