import { Icon, LegacyStack, Text } from '@shopify/polaris'
import { ArrowRightMinor, CaretDownMinor, CaretUpMinor } from '@shopify/polaris-icons'
import { DateStyle, useI18n } from '@shopify/react-i18n'

import { Activity, ActivityDiffLine, ActivityHeaderLine, ActivityLine } from './activity-models'
import { daysEqual, FormattedDate, FormattedDateFormat, useSystemData, useToggle } from './helpers'
import styles from './Timeline.module.css'

const TimelineDiffLineContent = ({ text }: { text: string }) => {
  const [i18n] = useI18n()

  const iso8601Regex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/g

  const dateFormat: FormattedDateFormat = {
    style: DateStyle.Short,
    hour: 'numeric',
    minute: 'numeric',
  }

  const formatDate = (dt: string) =>
    i18n.formatDate(new Date(dt), dateFormat).replace(/ /g, '\u00A0')

  return <div className={styles.diffLineContent}>{text.replace(iso8601Regex, formatDate)}</div>
}

const TimelineHeaderLine = ({ line }: { line: ActivityHeaderLine }) => (
  <div className={styles.headerLine}>
    <Text as="span" fontWeight="semibold">
      {line.content}
    </Text>
  </div>
)

const TimelineDiffLine = ({ line }: { line: ActivityDiffLine }) => (
  <div className={styles.diffLine}>
    <TimelineDiffLineContent text={line.old || '(New item)'} />
    <div className={styles.diffLineArrow}>
      <Icon source={ArrowRightMinor} color="subdued" />
    </div>
    <TimelineDiffLineContent text={line.new || '(Removed)'} />
  </div>
)
const TimelineDataLine = ({ line }: { line: ActivityLine }) =>
  line.type === 'header' ? <TimelineHeaderLine line={line} /> : <TimelineDiffLine line={line} />

const TimelineItem = ({
  activity,
  showDateHeader,
}: {
  activity: Activity
  showDateHeader: boolean
}) => {
  const [detailsOpen, detailsToggle] = useToggle(false)
  const hasDetails = activity.data.length > 0

  return (
    <div className={styles.entry}>
      {showDateHeader && (
        <div className={styles.dateLine}>
          <Text variant="headingSm" as="h3">
            <FormattedDate
              date={new Date(activity.created_at)}
              format={{ month: 'long', year: 'numeric', day: 'numeric' }}
            />
          </Text>
        </div>
      )}
      <div className={styles.entryBasic}>
        <div className={styles.entryBullet}></div>
        <div
          className={`${styles.entryMessage} ${hasDetails ? styles.entryMessageWithDetails : ''}`}
          onClick={hasDetails ? detailsToggle : undefined}
        >
          {activity.message}{' '}
          {hasDetails && <Icon source={detailsOpen ? CaretUpMinor : CaretDownMinor} color="base" />}
        </div>
        <div className={styles.entryTime}>
          <FormattedDate
            date={new Date(activity.created_at)}
            format={{ hour: 'numeric', minute: 'numeric' }}
            titleFormat={{ style: DateStyle.Long, hour: 'numeric', minute: 'numeric' }}
          />
        </div>
      </div>
      {hasDetails && detailsOpen && (
        <div className={styles.entryDetails}>
          {activity.data.map((line, index) => (
            <TimelineDataLine key={index} line={line} />
          ))}
        </div>
      )}
    </div>
  )
}

interface TimelineProps {
  activities: Activity[]
  createdAt: string
  recordingSince: string
  secondaryActions?: React.ReactNode
}

export const Timeline = ({
  activities,
  createdAt,
  recordingSince,
  secondaryActions,
}: TimelineProps) => {
  const entriesMissing = createdAt < recordingSince

  const tz = useSystemData()?.shopData.iana_timezone

  let prevDate = new Date(0)
  const entries = activities.map((ac) => {
    const curDate = new Date(ac.created_at)
    const showDateHeader = !daysEqual(prevDate, curDate, tz)
    prevDate = curDate
    return <TimelineItem activity={ac} key={ac.id} showDateHeader={showDateHeader} />
  })

  return (
    <>
      <div className={styles.header}>
        <LegacyStack>
          <LegacyStack.Item fill>
            <Text variant="headingMd" as="h2">
              Timeline
            </Text>
          </LegacyStack.Item>
          {secondaryActions && <LegacyStack.Item>{secondaryActions}</LegacyStack.Item>}
        </LegacyStack>
      </div>

      {activities.length > 0 && <div className={styles.entries}>{entries}</div>}

      {entriesMissing && (
        <LegacyStack distribution="center">
          <Text variant="bodySm" as="p" color="subdued">
            <br />
            Only events recorded after{' '}
            <FormattedDate
              date={new Date(recordingSince)}
              format={{ style: DateStyle.Short, hour: 'numeric', minute: 'numeric' }}
            />{' '}
            are available
            <br />
            <br />
          </Text>
        </LegacyStack>
      )}
    </>
  )
}
