import { Checkbox, FormLayout, LegacyCard, Select, Text } from '@shopify/polaris'
import { useFormikContext } from 'formik'

import {
  ExternalLink,
  ShowIntercomLink,
  useFormikHandleChangeCallback,
  useSystemData,
} from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { UserSettings } from './api'

export const CustomerPortalCard = () => {
  const systemData = useSystemData()

  const formik = useFormikContext<UserSettings>()
  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)

  const editOptionEnabled =
    formik.values.customers_can_add_products ||
    formik.values.customers_can_add_products_from_widget ||
    formik.values.customers_can_remove_products ||
    formik.values.customers_can_change_quantity !== 'off' ||
    formik.values.customers_can_swap_products !== 'off' ||
    formik.values.customers_can_change_selling_plan ||
    formik.values.customers_can_edit_discounts

  const warnAboutStylesheets =
    editOptionEnabled && systemData && systemData.installedAt < '2022-01-27'

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <FormLayout>
          <Checkbox
            id="customers_can_cancel_subscriptions"
            name="customers_can_cancel_subscriptions"
            label={
              <span>
                Show Cancel button{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to cancel subscriptions (recommended). If this
                  setting is disabled, customers will have to contact you.
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_cancel_subscriptions}
            onChange={handleChange}
          />
          <Checkbox
            id="customers_can_pause_subscriptions"
            name="customers_can_pause_subscriptions"
            label={
              <span>
                Show Pause / Resume buttons{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to pause and resume their subscriptions
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_pause_subscriptions}
            onChange={handleChange}
          />
          <Checkbox
            id="customers_can_attempt_billings"
            name="customers_can_attempt_billings"
            label={
              <span>
                Show Charge Now button{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to process their next payment manually
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_attempt_billings}
            onChange={handleChange}
          />
          <Checkbox
            id="customers_can_skip_installments"
            name="customers_can_skip_installments"
            label={
              <span>
                Show Skip Payment button{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to skip their next payment
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_skip_installments}
            onChange={handleChange}
          />
          <Checkbox
            id="customers_can_reactivate_subscriptions"
            name="customers_can_reactivate_subscriptions"
            label={
              <span>
                Show Reactivate subscription button{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to reactivate their subscription within 1 month
                  after it ends. After this period, they must contact you directly for reactivation.
                  Only cancelled and failed subscriptions can be reactivated by Customer.
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_reactivate_subscriptions}
            onChange={handleChange}
          />
        </FormLayout>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <FormLayout>
          {warnAboutStylesheets && (
            <Text as="span" color="subdued">
              Note: editing subscriptions requires up-to-date stylesheets of the customer portal. In
              case you notice any styling problems, please check PayWhirl{' '}
              <ExternalLink href="https://docs.paywhirl.com/PayWhirl/en/articles/5918237-how-to-migrate-custom-css-to-the-new-customer-portal-files-on-shopify-2020-version">
                migration docs
              </ExternalLink>
              , or <ShowIntercomLink>contact us</ShowIntercomLink> for support
            </Text>
          )}
          <Checkbox
            id="customers_can_edit_discounts"
            name="customers_can_edit_discounts"
            label={
              <span>
                Allow adding and removing discounts{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to add and remove discounts from their
                  subscriptions
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_edit_discounts}
            onChange={handleChange}
          />
          <Checkbox
            id="customers_can_change_selling_plan"
            name="customers_can_change_selling_plan"
            label={
              <span>
                Allow changing selling plans{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to change selling plan within the same selling
                  plan group
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_change_selling_plan}
            onChange={handleChange}
          />
          <Checkbox
            id="customers_can_add_products"
            name="customers_can_add_products"
            label={
              <span>
                Allow adding products{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to add products, assigned to the same selling
                  plan group, to their subscription
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_add_products}
            onChange={handleChange}
          />
          <Checkbox
            id="customers_can_add_products_from_widget"
            name="customers_can_add_products_from_widget"
            label={
              <span>
                Allow adding products to an existing subscription from the product page{' '}
                <LegacyHelpIcon>
                  This setting will add a button to the selling plan widget, allowing customers to
                  add products directly to an existing subscription. The button will only appear if
                  they have an active subscription matching at least one of the selling plans
                  assigned to the selected product variant.
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_add_products_from_widget}
            onChange={handleChange}
          />
          <Checkbox
            id="customers_can_remove_products"
            name="customers_can_remove_products"
            label={
              <span>
                Allow removing products{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to remove products from their subscriptions
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.customers_can_remove_products}
            onChange={handleChange}
          />
          <Select
            id="customers_can_swap_products"
            name="customers_can_swap_products"
            label={
              <span>
                Allow swapping products{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to swap products in their subscriptions
                </LegacyHelpIcon>
              </span>
            }
            options={[
              { label: 'Disabled', value: 'off' },
              { label: 'Only to variants of the same product', value: 'within-product' },
              {
                label: 'To any products/variants assigned to the current selling plan',
                value: 'any-product',
              },
            ]}
            value={formik.values.customers_can_swap_products}
            onChange={handleChange}
          />
          <Select
            id="customers_can_change_quantity"
            name="customers_can_change_quantity"
            label={
              <span>
                Allow changing product quantity{' '}
                <LegacyHelpIcon>
                  This setting will allow customers to change quantity of products in their
                  subscriptions
                </LegacyHelpIcon>
              </span>
            }
            options={[
              { label: 'Disabled', value: 'off' },
              { label: 'Only increase', value: 'only-increase' },
              { label: 'Increase and decrease', value: 'increase-decrease' },
            ]}
            value={formik.values.customers_can_change_quantity}
            onChange={handleChange}
          />
        </FormLayout>
      </LegacyCard.Section>
      {formik.initialValues.show_link_to_customer_portal_after_checkout && (
        <LegacyCard.Section>
          <FormLayout>
            <Checkbox
              id="show_link_to_customer_portal_after_checkout"
              name="show_link_to_customer_portal_after_checkout"
              label={
                <span>
                  Show link to the customer portal after checkout{' '}
                  <LegacyHelpIcon>
                    Enable button pointing to the customer portal on the Thank You page after
                    checkout
                  </LegacyHelpIcon>
                </span>
              }
              checked={formik.values.show_link_to_customer_portal_after_checkout}
              onChange={handleChange}
            />
          </FormLayout>
        </LegacyCard.Section>
      )}
    </LegacyCard>
  )
}
