import { FormLayout, Modal, TextField } from '@shopify/polaris'

import { ApplyDiscountCodePayload } from './common'

export const ApplyDiscountCodeSection = ({
  payload,
  setPayload,
}: {
  payload: ApplyDiscountCodePayload
  setPayload: (payload: ApplyDiscountCodePayload) => any
}) => (
  <Modal.Section>
    <FormLayout>
      <TextField
        label="Discount code"
        value={payload.code}
        onChange={(code) => setPayload({ code })}
        autoComplete="off"
      />
    </FormLayout>
  </Modal.Section>
)
