import { LegacyCard, LegacyStack, Tag, VerticalStack } from '@shopify/polaris'
import React from 'react'

import {
  anchorMonthNames,
  anchorShortMonthNames,
  anchorWeekDayNames,
  SellingPlanAnchor,
} from '../common/anchor-models'
import { formatOrdinal, pluralize, sortAnchors, useDialogActivator } from '../common/helpers'
import { HelpTitle } from '../common/HelpIcon'
import {
  defaultFinishingBehavior,
  finishingBehaviorLabels,
  SubscriptionContract,
} from '../common/subscription-models'
import { DetailsItem } from './DetailsItem'
import { ScheduleEditDialog } from './ScheduleEditDialog'

export const anchorLabel = (anchor: SellingPlanAnchor, short?: boolean) => {
  if (anchor.type === 'WEEKDAY') {
    return `${anchorWeekDayNames[anchor.day]}`
  }

  if (anchor.type === 'MONTHDAY') {
    return `${formatOrdinal(anchor.day)} of the month`
  }

  const monthLabel = short
    ? anchorShortMonthNames[anchor.month ?? 0]
    : anchorMonthNames[anchor.month ?? 0]

  return `${monthLabel} ${anchor.day}`
}

export const anchorDeliveryLabel = (anchor: SellingPlanAnchor) => {
  if (anchor.type === 'WEEKDAY') {
    return `Every ${anchorWeekDayNames[anchor.day]}`
  }

  if (anchor.type === 'MONTHDAY') {
    return `Every ${formatOrdinal(anchor.day)} of the month`
  }

  const monthLabel = anchorMonthNames[anchor.month ?? 0]

  return `Every ${monthLabel} ${anchor.day}`
}

export const ScheduleCard = ({ sc }: { sc: SubscriptionContract }) => {
  const editDialogActivator = useDialogActivator()

  const scheduleTitle = (
    <HelpTitle title="Schedule">
      Current delivery schedule details including frequency and length of subscription term payments
      if applicable.
    </HelpTitle>
  )

  const actions = sc.is_editable
    ? [
        {
          content: 'Edit',
          onAction: editDialogActivator.show,
        },
      ]
    : []

  const anchors = sortAnchors(sc.gql_data.deliveryPolicy.anchors ?? [])
  const deliveryFrequency = () => {
    if (anchors.length > 1 && sc.gql_data.deliveryPolicy.intervalCount === 1) {
      return 'On anchors'
    }

    return `${sc.gql_data.deliveryPolicy.intervalCount} ${pluralize(
      sc.gql_data.deliveryPolicy.intervalCount,
      sc.gql_data.deliveryPolicy.interval.toLowerCase()
    )}`
  }

  return (
    <LegacyCard title={scheduleTitle} sectioned actions={actions}>
      <LegacyStack vertical spacing="extraTight">
        <DetailsItem label="Delivery frequency">{deliveryFrequency()}</DetailsItem>

        {anchors.length > 0 && (
          <DetailsItem label="Delivery anchors">
            <VerticalStack gap="1">
              {anchors.map((anchor, i) => (
                <Tag key={i}>{anchorLabel(anchor)}</Tag>
              ))}
            </VerticalStack>
          </DetailsItem>
        )}

        <DetailsItem
          label="Bill every"
          helpIcon="Bill every is the frequency of billing charges in relation to the frequency of deliveries."
        >
          {sc.pre_paid_deliveries} {pluralize(sc.pre_paid_deliveries, 'delivery', 'deliveries')}
        </DetailsItem>

        <DetailsItem
          label="Min payments"
          helpIcon="The minimum number of payments required before the customer has the option to cancel if allowed in the app settings."
        >
          {sc.gql_data.billingPolicy.minCycles || 'not set'}
        </DetailsItem>
        <DetailsItem
          label="Max payments"
          helpIcon="The maximum number of payments before the subscription expires due to completion."
        >
          {sc.gql_data.billingPolicy.maxCycles || 'not set'}
        </DetailsItem>
        <DetailsItem
          label="Finishing behavior"
          helpIcon="Finish subscriptions either immediately on the last payment / cancellation request, or at the end of the billing cycle."
        >
          {finishingBehaviorLabels[sc.pw_data.finishingBehavior ?? defaultFinishingBehavior]}
        </DetailsItem>
      </LegacyStack>
      <ScheduleEditDialog sc={sc} activator={editDialogActivator} />
    </LegacyCard>
  )
}
