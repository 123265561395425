import {
  Banner,
  Layout,
  LegacyCard,
  LegacyStack,
  Loading,
  Page,
  TextContainer,
} from '@shopify/polaris'
import { useState } from 'react'

import { CodeSnippet } from '../common/CodeSnippet'
import { Footer } from '../common/Footer'
import { ExternalLink, ShowIntercomLink } from '../common/helpers'
import { LoadErrorBanner } from '../common/LoadErrorPage'
import { SkeletonCards } from '../common/SkeletonCards'
import { TabIndex, Tabs } from '../common/Tabs'
import { Theme, ThemeLink, ThemeSelect, useThemesSWR } from '../common/themes'
import * as urls from '../common/urls'

const knownBaseThemes: Record<number, string> = {
  380: 'minimal',
  578: 'simple',
  679: 'supply',
  730: 'brooklyn',
  766: 'boundless',
  775: 'venture',
  796: 'debut',
  829: 'narrative',
  885: 'express',
  887: 'dawn',
}

const getBaseTheme = (theme: Theme) => knownBaseThemes[theme.theme_store_id ?? 0] ?? 'custom'

const Content = ({ themes }: { themes: Theme[] }) => {
  const [theme, setTheme] = useState<Theme>(themes[0])

  return (
    <LegacyStack vertical>
      <Banner status="warning">
        <p>
          Installing the selling plan widget in themes not supporting App Blocks requires manually
          editing your theme templates. If you have any doubts how to proceed, please don't hesitate
          to <ShowIntercomLink>contact us</ShowIntercomLink>. The PayWhirl support team will be
          happy to complete the whole process for you!
        </p>
      </Banner>

      <LegacyCard title="Select your theme" sectioned>
        <LegacyStack vertical spacing="loose">
          <ThemeSelect
            label="Select theme to install the selling plan widget in:"
            themes={themes}
            onChange={setTheme}
            value={theme}
          />
        </LegacyStack>
      </LegacyCard>

      <LegacyCard title="Install prerequisites" sectioned>
        <LegacyStack vertical spacing="loose">
          <TextContainer>
            <p>
              In the{' '}
              <ThemeLink id={theme.id} path="">
                Snippets
              </ThemeLink>{' '}
              folder, click <b>Add a new snippet</b>, create a file named{' '}
              <b>paywhirl-main.liquid</b>, and copy-paste its content{' '}
              <ExternalLink href={`/snippets/paywhirl-main.liquid?theme=${getBaseTheme(theme)}`}>
                from&nbsp;here
              </ExternalLink>
              .
            </p>
            <p>
              Next, put the following snippet before the closing <b>{'</body>'}</b> tag in{' '}
              <ThemeLink id={theme.id} path="layout/theme.liquid">
                Layout/theme.liquid
              </ThemeLink>
              :
            </p>
          </TextContainer>
          <CodeSnippet code={`{% render 'paywhirl-main' %}`} />
        </LegacyStack>
      </LegacyCard>
      <LegacyCard title="Add widget to the product page" sectioned>
        <LegacyStack vertical spacing="loose">
          <TextContainer>
            <p>
              In the{' '}
              <ThemeLink id={theme.id} path="">
                Snippets
              </ThemeLink>{' '}
              folder, click <b>Add a new snippet</b>, create a file named{' '}
              <b>paywhirl-plan-selector.liquid</b>, and copy-paste its content{' '}
              <ExternalLink href="/snippets/paywhirl-plan-selector.liquid">
                from&nbsp;here
              </ExternalLink>
              .
            </p>
            <p>
              Next, find your product template, usually it's either{' '}
              <ThemeLink id={theme.id} path="sections/product-template.liquid">
                Sections/product-template.liquid
              </ThemeLink>{' '}
              or{' '}
              <ThemeLink id={theme.id} path="snippets/product-form.liquid">
                Snippets/product-form.liquid
              </ThemeLink>
              . Put the following snippet wherever you want to display the widget. If not sure,
              paste it AFTER the entire <b>{'<select name="id" ...>...</select>'}</b> block:
            </p>
          </TextContainer>
          <CodeSnippet code={`{% render 'paywhirl-plan-selector', product: product %}`} />
        </LegacyStack>
      </LegacyCard>
      <LegacyCard title="Optional: add widget to featured products" sectioned>
        <LegacyStack vertical spacing="loose">
          <p>
            Many themes have a separate template for featured products:{' '}
            <ThemeLink id={theme.id} path="sections/featured-product.liquid">
              Sections/featured-product.liquid
            </ThemeLink>
            . If you have this file and you'd like to add plan widdget to featured products, put the
            following snippet wherever you want to display it. If not sure, paste it AFTER the
            entire <b>{'<select name="id" ...>...</select>'}</b> block:
          </p>
          <CodeSnippet code={`{% render 'paywhirl-plan-selector', product: product %}`} />
        </LegacyStack>
      </LegacyCard>
    </LegacyStack>
  )
}

export const LegacyPlanSelectorInstallPage = () => {
  const { data: themes, error, isValidating: loading } = useThemesSWR()

  return (
    <>
      {loading && <Loading />}
      <Tabs selected={TabIndex.GettingStarted} />

      <Page
        title="Install selling plan widget in legacy themes"
        backAction={{ content: 'Getting Started', url: urls.gettingStartedUrl }}
      >
        <Layout>
          <Layout.Section>
            {error && <LoadErrorBanner />}
            {!error && !themes && <SkeletonCards schema={[4, 5, 9, 10, 9]} />}
            {!error && themes && themes.length > 0 && <Content themes={themes} />}
          </Layout.Section>
          <Layout.Section>
            <Footer />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  )
}
