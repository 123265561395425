import { FooterHelp, LegacyStack, Link } from '@shopify/polaris'
import React from 'react'

const color = 'var(--p-text-subdued, #637381)'

const MyLink = ({ url, children }: { url: string; children: any }) => (
  <a style={{ color }} target="_blank" rel="noopener noreferrer" href={url} children={children} />
)

export const Footer = () => (
  <LegacyStack vertical spacing="extraTight" alignment="center">
    <FooterHelp>
      To learn more, visit <Link url="https://shop.paywhirl.com">shop.PayWhirl.com</Link>
    </FooterHelp>
    <p style={{ color, marginTop: '-1rem', marginBottom: '2rem', fontSize: '0.9em' }}>
      <MyLink url="https://app.paywhirl.com/terms">Terms of Use</MyLink>
      {'   •   '}
      <MyLink url="https://app.paywhirl.com/privacy">Privacy</MyLink>
      {'   •   '}
      <MyLink url="https://app.paywhirl.com/data-protection">Data Protection</MyLink>
    </p>
  </LegacyStack>
)
