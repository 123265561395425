import { Button, InlineError, LegacyCard, LegacyStack, Text } from '@shopify/polaris'
import React, { useCallback, useEffect } from 'react'

import { Result } from '../common/api'
import {
  formatOrdinal,
  resultErrorField,
  resultErrorFieldStartsWith,
  useDialogActivator,
} from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { WorkflowAction, WorkflowFilter } from '../common/workflow-models'
import { FiltersLine, WorkflowDescriptionActions } from '../workflow-page/WorkflowDescription'
import { SubscriptionFlowWorkflowFormData, subscriptionFlowWorkflowName } from './api'
import { SubscriptionFlowTriggerFiltersModal } from './SubscriptionFlowTriggerFiltersModal'
import { SubscriptionFlowTriggerModal } from './SubscriptionFlowTriggerModal'

export interface SubscriptionFlowTriggerCardProps {
  data: SubscriptionFlowWorkflowFormData
  onChange: (workflow: SubscriptionFlowWorkflowFormData) => any
  saveResult: Result<any> | null
  onDelete: () => any
  index: number
}

export const SubscriptionFlowTriggerCard = ({
  data,
  onChange,
  saveResult,
  onDelete,
  index,
}: SubscriptionFlowTriggerCardProps) => {
  const actionsModalActivator = useDialogActivator()
  const filtersModalActivator = useDialogActivator()

  const error = resultErrorField(saveResult, `workflows.${index}.actions`)
  const actionsError = resultErrorFieldStartsWith(saveResult, `workflows.${index}.actions`)

  const updateWorkflowActions = useCallback(
    (actions: WorkflowAction[]) => {
      const workflow = { ...data }
      workflow['actions'] = [...actions]
      onChange(workflow)
    },
    [data, onChange]
  )

  const updateWorkflowFilters = useCallback(
    (filters: WorkflowFilter[]) => {
      const workflow = { ...data }
      workflow['extraFilters'] = [...filters]
      onChange(workflow)
    },
    [data, onChange]
  )

  useEffect(() => {
    if (data._newlyAdded) {
      actionsModalActivator.show()
      const workflow = { ...data, _newlyAdded: false }
      onChange(workflow)
    }
  }, [onChange, data, actionsModalActivator])

  return (
    <>
      <LegacyCard
        title={
          <LegacyStack>
            <LegacyStack.Item fill>
              <Text variant="headingMd" as="h2">
                {subscriptionFlowWorkflowName(data)}
                <LegacyHelpIcon>
                  These changes will be made right after the {formatOrdinal(data.afterOrderNumber)}{' '}
                  subscription order
                </LegacyHelpIcon>
              </Text>
            </LegacyStack.Item>
            <LegacyStack>
              <Button plain destructive onClick={onDelete}>
                Delete
              </Button>
              <Button onClick={filtersModalActivator.show} plain>
                {data.extraFilters.length > 0 ? 'Edit extra conditions' : 'Add extra conditions'}
              </Button>
              <Button onClick={actionsModalActivator.show} plain>
                {data.actions.length > 0 ? 'Edit actions' : 'Add actions'}
              </Button>
            </LegacyStack>
          </LegacyStack>
        }
      >
        <LegacyCard.Section>
          <LegacyStack vertical>
            {data.extraFilters.length > 0 && (
              <FiltersLine rootFilterType={'and'} filters={data.extraFilters} />
            )}
            <WorkflowDescriptionActions
              actions={data.actions}
              triggers={data.triggers}
              tightSpacing
            />
            {error && <InlineError message={error} fieldID={'actions'} />}
            {!error && actionsError && (
              <InlineError
                message="One of the actions could not be saved due to an error. Please edit the action to fix it."
                fieldID="actions"
              />
            )}
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <SubscriptionFlowTriggerModal
        open={actionsModalActivator.open}
        onClose={actionsModalActivator.close}
        title={subscriptionFlowWorkflowName(data)}
        actions={data.actions}
        triggers={data.triggers}
        onChange={updateWorkflowActions}
        saveResult={saveResult}
        index={index}
      />
      <SubscriptionFlowTriggerFiltersModal
        open={filtersModalActivator.open}
        onClose={filtersModalActivator.close}
        filters={data.extraFilters}
        onChange={updateWorkflowFilters}
      />
    </>
  )
}
