import {
  Icon,
  LegacyCard,
  LegacyStack,
  Loading,
  ResourceItem,
  ResourceList,
  Select,
  TextField,
  Thumbnail,
} from '@shopify/polaris'
import { SearchMinor } from '@shopify/polaris-icons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { backendFetchResult, Result } from '../common/api'
import { CardPagination } from '../common/CardPagination'
import { Collection } from '../common/collections'
import { queryString, useDebounced, useQuery } from '../common/helpers'
import { LoadErrorBanner } from '../common/LoadErrorPage'
import { urlWithParams } from '../common/urls'
import { collectionIdOptions, PageData, ProductResult, useProductData } from './api'
import { ProductResourceItem } from './ProductResourceItem'

const attachProducts = async (spgRestId: string, productGids: string[]): Promise<Result<any>> => {
  const body = JSON.stringify({ productGids })
  return await backendFetchResult('POST', `/plans/${spgRestId}/attach-products`, { body })
}

const detachProducts = async (spgRestId: string, productGids: string[]): Promise<Result<any>> => {
  const body = JSON.stringify({ productGids })
  return await backendFetchResult('POST', `/plans/${spgRestId}/detach`, { body })
}

interface ProductListQuery {
  filter?: string
  before?: string
  after?: string
  collection?: string
}

const useProductListQuery = (): ProductListQuery => {
  const query = useQuery()

  const filter = query.get('filter') ?? undefined
  const before = query.get('before') ?? undefined
  const after = query.get('after') ?? undefined
  const collection = query.get('collection') ?? undefined

  return useMemo(
    () => ({
      filter,
      before,
      after,
      collection,
    }),
    [filter, before, after, collection]
  )
}

export const ProductList = ({
  spgRestId,
  pagePath,
  pageData,
  collections,
  title,
  onProductAttached,
}: {
  spgRestId: string
  pagePath: string
  pageData: PageData
  collections: Collection[]
  title?: string
  onProductAttached?: () => void
}) => {
  const pageQuery = useProductListQuery()
  const query = queryString({
    spg: spgRestId,
    query: pageQuery.filter,
    before: decodeURIComponent(pageQuery.before ?? ''),
    after: decodeURIComponent(pageQuery.after ?? ''),
    collection: pageQuery.collection,
  })

  const productData = useProductData(query, pageData)

  const history = useHistory()

  const [selectedProducts, setSelectedProducts] = useState<string[] | 'All'>([])
  const selectedProductsList = useMemo(
    () => (selectedProducts === 'All' ? productData.products.map((p) => p.id) : selectedProducts),
    [productData.products, selectedProducts]
  )
  const hasSelectedProducts = selectedProductsList.length > 0

  const [filter, setFilter] = useState('')
  const debouncedFilter = useDebounced(filter, 500)

  const [collectionId, setCollectionId] = useState(pageQuery.collection ?? '')

  const [busyAttaching, setBusyAttaching] = useState(false)

  const myAttachProducts = useCallback(
    async (productGids: string[]) => {
      if (busyAttaching) {
        return
      }

      setBusyAttaching(true)
      const res = await attachProducts(spgRestId, productGids)

      if (res.status === 'success' && res.data) {
        productData.update(res.data.attachedProductGids, [])
        onProductAttached?.()
      }

      setBusyAttaching(false)
    },
    [spgRestId, productData, busyAttaching, onProductAttached]
  )

  const myDetachProducts = useCallback(
    async (productGids: string[]) => {
      if (busyAttaching) {
        return
      }

      setBusyAttaching(true)
      const res = await detachProducts(spgRestId, productGids)

      if (res.status === 'success' && res.data) {
        productData.update([], res.data.detachedProductGids)
      }

      setBusyAttaching(false)
    },
    [spgRestId, productData, busyAttaching]
  )

  const attachSelectedProducts = useCallback(async () => {
    await myAttachProducts(selectedProductsList)
    setSelectedProducts([])
  }, [myAttachProducts, selectedProductsList])

  const detachSelectedProducts = useCallback(async () => {
    await myDetachProducts(selectedProductsList)
    setSelectedProducts([])
  }, [myDetachProducts, selectedProductsList])

  const renderProduct = useCallback(
    (product: ProductResult) => (
      <ResourceItem
        verticalAlignment="center"
        id={product.id}
        onClick={() => null}
        media={<Thumbnail source={product.image || ''} alt="" size="small" />}
      >
        <ProductResourceItem
          product={product}
          disableButtons={hasSelectedProducts}
          attachProducts={myAttachProducts}
          detachProducts={myDetachProducts}
        />
      </ResourceItem>
    ),
    [myAttachProducts, myDetachProducts, hasSelectedProducts]
  )

  // Used to prevent removing cursors on the initial load
  const isFirstRun = useRef(true)

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    const url = urlWithParams(pagePath, {
      before: undefined,
      after: undefined,
      filter: debouncedFilter,
      collection: collectionId,
    })
    history.replace(url)
  }, [spgRestId, debouncedFilter, collectionId, history, isFirstRun, pagePath])

  return (
    <>
      {(productData.loading || busyAttaching) && <Loading />}
      {productData.error && <LoadErrorBanner />}
      {!productData.error && (
        <LegacyCard title={title}>
          <ResourceList
            loading={productData.loading}
            items={productData.products}
            renderItem={renderProduct}
            selectable={true}
            selectedItems={selectedProducts}
            onSelectionChange={setSelectedProducts}
            filterControl={
              <LegacyStack distribution="fillEvenly">
                <Select
                  label="Collection:"
                  labelInline
                  value={collectionId}
                  onChange={setCollectionId}
                  options={collectionIdOptions(collections)}
                />
                <TextField
                  label=""
                  value={filter}
                  onChange={setFilter}
                  placeholder="Filter products"
                  prefix={<Icon source={SearchMinor} />}
                  disabled={collectionId !== ''}
                  autoComplete="off"
                />
              </LegacyStack>
            }
            isFiltered={debouncedFilter !== ''}
            promotedBulkActions={[
              { content: 'Add', onAction: attachSelectedProducts, disabled: busyAttaching },
              { content: 'Remove', onAction: detachSelectedProducts, disabled: busyAttaching },
            ]}
          />
          {(productData.page.hasPreviousPage || productData.page.hasNextPage) && (
            <CardPagination
              hasPrevious={productData.page.hasPreviousPage}
              previousURL={urlWithParams(pagePath, {
                ...pageQuery,
                after: undefined,
                before: encodeURIComponent(productData.page.firstCursor ?? ''),
              })}
              hasNext={productData.page.hasNextPage}
              nextURL={urlWithParams(pagePath, {
                ...pageQuery,
                before: undefined,
                after: encodeURIComponent(productData.page.lastCursor ?? ''),
              })}
            />
          )}
        </LegacyCard>
      )}
    </>
  )
}
