// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RetentionCohortReport_retentionChart__irAsZ {
  width: 100%;
  font-size: smaller;
  padding-bottom: 0.5rem;
  white-space: nowrap;
}

.RetentionCohortReport_retentionChart__irAsZ th {
  text-align: center;
}

.RetentionCohortReport_retentionChart__irAsZ td, .RetentionCohortReport_retentionChart__irAsZ th {
  width: 7%;
  padding: 0.1rem 0.7rem;
}

.RetentionCohortReport_retentionChart__irAsZ td.RetentionCohortReport_startDate__uZLbQ, .RetentionCohortReport_retentionChart__irAsZ td.RetentionCohortReport_initialCount__vcjbY {
  text-align: right;
}

.RetentionCohortReport_retentionChart__irAsZ td.RetentionCohortReport_rate__qEeOm {
  border-radius: 3px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard-page/RetentionCohortReport.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".retentionChart {\n  width: 100%;\n  font-size: smaller;\n  padding-bottom: 0.5rem;\n  white-space: nowrap;\n}\n\n.retentionChart th {\n  text-align: center;\n}\n\n.retentionChart td, .retentionChart th {\n  width: 7%;\n  padding: 0.1rem 0.7rem;\n}\n\n.retentionChart td.startDate, .retentionChart td.initialCount {\n  text-align: right;\n}\n\n.retentionChart td.rate {\n  border-radius: 3px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"retentionChart": `RetentionCohortReport_retentionChart__irAsZ`,
	"startDate": `RetentionCohortReport_startDate__uZLbQ`,
	"initialCount": `RetentionCohortReport_initialCount__vcjbY`,
	"rate": `RetentionCohortReport_rate__qEeOm`
};
export default ___CSS_LOADER_EXPORT___;
