import {
  EmptyState,
  Icon,
  LegacyCard,
  LegacyStack,
  Loading,
  ResourceItem,
  ResourceList,
  Select,
  Text,
  TextField,
} from '@shopify/polaris'
import { SearchMinor } from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import useSWR from 'swr'

import { CardPagination } from '../common/CardPagination'
import { useDebounced } from '../common/helpers'
import { LoadErrorBanner } from '../common/LoadErrorPage'
import * as urls from '../common/urls'
import { fetchSellingPlanGroups, SellingPlanGroup } from './api'
import styles from './SellingPlanGroupList.module.css'

const EmptySellingPlanGroupCard = (
  <div className={styles.wrapper}>
    <EmptyState
      heading="Create a new selling plan to get started"
      action={{ content: 'Create Plan', url: urls.newPlanUrl }}
      image="/images/setup-plans.svg"
    >
      <p>You can also create plans directly on your product pages</p>
    </EmptyState>
  </div>
)

const Item = (group: SellingPlanGroup) => {
  return (
    <ResourceItem id={group.id!} url={urls.planUrl(group.id!)}>
      <h3>
        <Text as="span" fontWeight="semibold">
          {group.merchantCode}
        </Text>
      </h3>
      {group.summary}
    </ResourceItem>
  )
}

export const SellingPlanGroupList = () => {
  const [sortKey, setSortKey] = useState('CREATED_AT_DESC')
  const [beforeCursor, setBeforeCursor] = useState('')
  const [afterCursor, setAfterCursor] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounced(searchTerm, 500)

  useEffect(() => {
    setBeforeCursor('')
    setAfterCursor('')
  }, [debouncedSearchTerm, sortKey])

  const { data, error, isValidating } = useSWR(
    ['selling-plan-groups', sortKey, beforeCursor, afterCursor, debouncedSearchTerm],
    fetchSellingPlanGroups
  )
  const items = data?.nodes || []

  const sortOptions = [
    { label: 'Created (oldest first)', value: 'CREATED_AT_ASC' },
    { label: 'Created (newest first)', value: 'CREATED_AT_DESC' },
    { label: 'Updated (oldest first)', value: 'UPDATED_AT_ASC' },
    { label: 'Updated (newest first)', value: 'UPDATED_AT_DESC' },
  ]

  return (
    <>
      {isValidating && <Loading />}

      {error && <LoadErrorBanner />}
      {!error && (
        <LegacyCard>
          <ResourceList
            resourceName={{ singular: 'selling plan group', plural: 'selling plan groups' }}
            emptyState={!isValidating && debouncedSearchTerm ? null : EmptySellingPlanGroupCard}
            items={items}
            renderItem={Item}
            sortValue={sortKey}
            showHeader={true}
            loading={isValidating && !data}
            filterControl={
              <LegacyStack alignment="center" spacing="tight">
                <LegacyStack.Item fill>
                  <TextField
                    label=""
                    placeholder="Search selling plans"
                    value={searchTerm}
                    onChange={setSearchTerm}
                    clearButton
                    onClearButtonClick={() => setSearchTerm('')}
                    prefix={<Icon source={SearchMinor} />}
                    autoComplete="off"
                  />
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <Select
                    label="Sort by:"
                    labelInline
                    options={sortOptions}
                    value={sortKey}
                    onChange={setSortKey}
                  />
                </LegacyStack.Item>
              </LegacyStack>
            }
          />
          {items.length > 0 && (
            <CardPagination
              hasPrevious={data?.page.hasPreviousPage}
              hasNext={data?.page.hasNextPage}
              onPrevious={() => {
                setAfterCursor('')
                setBeforeCursor(data?.page?.firstCursor || '')
              }}
              onNext={() => {
                setBeforeCursor('')
                setAfterCursor(data?.page?.lastCursor || '')
              }}
            />
          )}
        </LegacyCard>
      )}
    </>
  )
}
