import { Badge, LegacyStack, Text, TextContainer } from '@shopify/polaris'
import { Status } from '@shopify/polaris/build/ts/src/components/Badge'
import React from 'react'

import {
  CommaList,
  formatMoney,
  formatOrdinal,
  fragmentList,
  gidToId,
  pluralize,
} from '../common/helpers'
import { useShopCurrency } from '../common/Price'
import { WorkflowGroup } from '../common/workflow-group-models'
import {
  actionIsSupportedByTriggers,
  WorkflowAction,
  workflowActionLabels,
  WorkflowFilter,
  workflowFilterLabels,
  WorkflowFilterType,
  WorkflowProductDataType,
  WorkflowRootFilterType,
  WorkflowTagsDataType,
  workflowTriggerLabels,
  WorkflowTriggerType,
} from '../common/workflow-models'
import { subscriptionFlowWorkflowAfterOrderNumber } from '../subscription-flow-page/api'
import { useSellingPlanNames } from './api'
import { PaymentNumberFilterParams } from './PaymentNumberFilter'
import { operatorLabels, ProductFieldFilterParams } from './ProductFieldFilter'

const ParamsList = ({ children }: { children: React.ReactNode[] }) => (
  <>
    <> (</>
    <CommaList>
      {children.map((node, index) => (
        <Text as="span" color="subdued" key={index}>
          {node}
        </Text>
      ))}
    </CommaList>
    <>)</>
  </>
)

const TriggersLine = ({ triggers }: { triggers: WorkflowTriggerType[] }) => {
  let items: React.ReactNode[] = []

  triggers.forEach((trigger, index) => {
    items.push(index === 0 ? <b>When </b> : <b> or </b>)
    items.push(workflowTriggerLabels[trigger])
  })

  return <p>{fragmentList(items)}</p>
}

const filterLineItems = (
  [type, params]: WorkflowFilter,
  spNames: Record<string, string>
): React.ReactNode[] => {
  const hasSpNames = Object.keys(spNames).length > 0
  let items: React.ReactNode[] = []

  const typesWithProductTitles: WorkflowFilterType[] = [
    'subscription-contains-all-selected-product-variants',
    'subscription-contains-any-of-selected-product-variants',
    'subscription-does-not-contain-any-of-selected-product-variants',
    'subscription-contains-all-selected-products',
    'subscription-contains-any-of-selected-products',
    'subscription-does-not-contain-any-of-selected-products',
  ]

  const descriptionLabels = {
    ...workflowFilterLabels,
    'subscription-contains-any-matching-product-title': 'Subscription contains ANY product where',
    'subscription-contains-any-matching-product-variant-title':
      'Subscription contains ANY product where',
    'after-nth-order': '',
  }

  items.push(descriptionLabels[type])

  if (type === 'after-nth-order') {
    const filterParams = params as PaymentNumberFilterParams
    items.push(`Subscription has `)
    items.push(<b>{filterParams[0]}</b>)
    items.push(` ${pluralize(parseInt(filterParams[0]), 'order')}`)
  }

  if (typesWithProductTitles.includes(type)) {
    items.push(<ParamsList>{params.map((p) => p[1])}</ParamsList>)
  }

  if (type === 'subscription-contains-any-matching-product-title') {
    const filterParams = params as ProductFieldFilterParams
    items.push(` title ${operatorLabels[filterParams[0]]} '${filterParams[1]}'`)
  }

  if (type === 'subscription-contains-any-matching-product-variant-title') {
    const filterParams = params as ProductFieldFilterParams
    items.push(` variant title ${operatorLabels[filterParams[0]]} '${filterParams[1]}'`)
  }

  if (hasSpNames && type === 'subscription-contains-any-of-selected-selling-plans') {
    items.push(
      <ParamsList>{params.map((gid) => spNames[gid] || `#${gidToId(gid)} (deleted)`)}</ParamsList>
    )
  }

  if (hasSpNames && type === 'subscription-contains-any-of-selected-selling-plan-groups') {
    items.push(
      <ParamsList>{params.map((gid) => spNames[gid] || `#${gidToId(gid)} (deleted)`)}</ParamsList>
    )
  }

  return fragmentList(items)
}

export const FiltersLine = ({
  rootFilterType,
  filters,
  prefix,
}: {
  rootFilterType: WorkflowRootFilterType
  filters: WorkflowFilter[]
  prefix?: string
}) => {
  const spNames = useSellingPlanNames()

  let items: React.ReactNode[] = []
  const conjunction = () => (rootFilterType === 'and' ? <b> and </b> : <b> or </b>)

  filters.forEach((filter, index) => {
    items.push(index === 0 ? <b>{prefix || 'If'} </b> : conjunction())
    items = [...items, ...filterLineItems(filter, spNames)]
  })

  return <p>{fragmentList(items)}</p>
}

export const AppliesToOrdersLine = ({ workflowGroup }: { workflowGroup: WorkflowGroup }) => {
  const orderNumbers = workflowGroup.workflows.map((workflow) =>
    subscriptionFlowWorkflowAfterOrderNumber(workflow)
  )

  if (orderNumbers.length === 0) {
    return (
      <Text as="span" color="subdued">
        No orders specified
      </Text>
    )
  }

  return (
    <p>
      <Text as="span" fontWeight="semibold">
        Applies to orders:
      </Text>{' '}
      {orderNumbers.map((orderNumber) => (
        <>
          <Badge key={orderNumber} status="success">
            {formatOrdinal(orderNumber + 1)}
          </Badge>{' '}
        </>
      ))}
    </p>
  )
}

export const ProductBadge = ({
  productData,
  status,
}: {
  productData: WorkflowProductDataType
  status: Status
}) => {
  if (productData.length > 0) {
    return <Badge status={status}>{productData[1]}</Badge>
  }

  return <Badge status="new">No variant selected</Badge>
}

const ActionLine = ({ action }: { action: WorkflowAction }) => {
  const currencyCode = useShopCurrency()

  let items: React.ReactNode[] = []
  items.push(<b>{workflowActionLabels[action[0]]}</b>)
  items.push(<b>:</b>)

  if (['add-customer-tags', 'remove-customer-tags', 'add-order-tags'].includes(action[0])) {
    const tags = action[1] as WorkflowTagsDataType
    tags.forEach((param, index) => {
      items.push(
        <React.Fragment key={`tag-${index}`}>
          {' '}
          <Badge status={action[0] === 'remove-customer-tags' ? 'critical' : 'success'}>
            {param}
          </Badge>
        </React.Fragment>
      )
    })
    tags.length === 0 &&
      items.push(
        <>
          {' '}
          <Text as="span" color="subdued">
            No tags selected
          </Text>
        </>
      )
  }

  if (
    ['add-one-time-gift-product', 'remove-product', 'remove-product-variant'].includes(action[0]) &&
    action[1].length
  ) {
    const params = action[1] as WorkflowProductDataType
    items.push(
      <>
        {' '}
        <ProductBadge productData={params} status="success" />
      </>
    )
  }

  if (action[0] === 'add-product' && action[1].length) {
    const [productData, quantity, currentPrice, recurringCycleLimit] = action[1]
    items.push(
      <>
        {' '}
        <ProductBadge productData={productData} status="success" /> x <b>{quantity}</b>
        {currentPrice !== '' && (
          <>
            {' '}
            at <b>{formatMoney(parseFloat(currentPrice) * 100, currencyCode)}</b> per unit
          </>
        )}
        , that applies to <b>{recurringCycleLimit ?? 'all upcoming'}</b>{' '}
        {recurringCycleLimit ? pluralize(recurringCycleLimit, 'order') : 'orders'}
      </>
    )
  }

  if (action[0] === 'swap-product' && action[1].length) {
    const [sourceProductData, targetProductData, quantity, currentPrice] = action[1]
    items.push(
      <>
        {' '}
        <ProductBadge productData={sourceProductData} status="critical" />
        <b>{' with '}</b>
        <ProductBadge productData={targetProductData} status="success" />
        {quantity && (
          <>
            {' '}
            x <b>{quantity}</b>
          </>
        )}
        {currentPrice && (
          <>
            {' '}
            at <b>{formatMoney(parseFloat(currentPrice) * 100, currencyCode)}</b> per unit
          </>
        )}
      </>
    )
  }
  if (action[0] === 'add-discount') {
    const [discountCodeName, recurringCycleLimit, discountType, discountValue, appliesOnEachItem] =
      action[1]

    items.push(
      <>
        {' '}
        {discountCodeName && <Badge status="success">{discountCodeName}</Badge>}
        {!discountCodeName && <Badge status="new">No discount name</Badge>}
        <span>
          {' '}
          {discountValue !== '' && (
            <>
              at{' '}
              <b>
                {discountType === 'percentage'
                  ? `${discountValue}%`
                  : formatMoney(parseFloat(discountValue) * 100, currencyCode)}
              </b>{' '}
            </>
          )}
          that applies to
          {discountType === 'fixedAmount' && appliesOnEachItem === 1 ? ' each item and ' : ' '}
          <b>{recurringCycleLimit ? recurringCycleLimit : 'all upcoming'}</b>{' '}
          {recurringCycleLimit ? pluralize(parseInt(recurringCycleLimit), 'order') : 'orders'}
        </span>
      </>
    )
  }

  return <TextContainer spacing="tight">{fragmentList(items)}</TextContainer>
}

export const isValidAction = (action: WorkflowAction): boolean => {
  if (
    ['add-discount', 'remove-one-time-gift-products', 'remove-expired-discount'].includes(action[0])
  ) {
    return true
  }

  if (
    action[0] === 'swap-product' &&
    action[1].length >= 2 &&
    action[1][0]?.length === 2 &&
    action[1][1]?.length === 2
  ) {
    return true
  }

  if (
    [
      'add-one-time-gift-product',
      'remove-product',
      'remove-product-variant',
      'add-customer-tags',
      'remove-customer-tags',
      'add-order-tags',
    ].includes(action[0]) &&
    action[1].length > 0
  ) {
    return true
  }

  return false
}

export const WorkflowDescriptionTriggers = ({ triggers }: { triggers: WorkflowTriggerType[] }) => (
  <>
    {triggers.length > 0 && <TriggersLine triggers={triggers} />}
    {triggers.length === 0 && (
      <Text as="span" color="subdued">
        No events selected
      </Text>
    )}
  </>
)

export const WorkflowDescriptionActions = ({
  actions,
  triggers,
  tightSpacing,
}: {
  actions: WorkflowAction[]
  triggers: WorkflowTriggerType[]
  tightSpacing?: boolean
}) => {
  const filteredActions = actions.filter((action) =>
    actionIsSupportedByTriggers(action[0], triggers)
  )

  return (
    <LegacyStack spacing={tightSpacing ? 'extraTight' : 'baseTight'} vertical>
      {filteredActions.map((action, index) => (
        <ActionLine action={action} key={`action-line-${index}`} />
      ))}
      {actions.length === 0 && (
        <Text as="span" color="subdued">
          No action specified
        </Text>
      )}
    </LegacyStack>
  )
}

export const WorkflowDescription = ({
  triggers,
  filters,
  rootFilterType,
  actions,
  tightSpacing,
}: {
  triggers: WorkflowTriggerType[]
  filters: WorkflowFilter[]
  rootFilterType: WorkflowRootFilterType
  actions: WorkflowAction[]
  tightSpacing?: boolean
}) => (
  <LegacyStack vertical spacing={tightSpacing ? 'extraTight' : 'baseTight'}>
    <WorkflowDescriptionTriggers triggers={triggers} />
    {filters.length > 0 && <FiltersLine rootFilterType={rootFilterType} filters={filters} />}
    <WorkflowDescriptionActions actions={actions} triggers={triggers} tightSpacing={tightSpacing} />
  </LegacyStack>
)
