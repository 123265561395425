import { LegacyStack, TextField } from '@shopify/polaris'
import { useEffect, useState } from 'react'
import { mutate } from 'swr'

import { Result } from '../common/api'
import { ApiActionDialog } from '../common/ApiActionDialog'
import { DialogActivator } from '../common/helpers'
import { LegacyHelpLabel } from '../common/HelpIcon'
import { SubscriptionContract } from '../common/subscription-models'

export const customerNotesText = `
  This note is populated from the cart note and will be carried through to the
  generated orders. It may be visible to the customer if your theme supports it.`

export const merchantNotesText = `
  This note is internal only and won't be carried through to the generated orders. It
  is not visible to the customer.
`

export const NotesEditDialog = ({
  activator,
  sc,
}: {
  activator: DialogActivator
  sc: SubscriptionContract
}) => {
  const [customerNote, setCustomerNote] = useState(sc.gql_data.note ?? '')
  const [merchantNote, setMerchantNote] = useState(sc.merchant_note ?? '')

  useEffect(() => {
    setCustomerNote(sc.gql_data.note ?? '')
    setMerchantNote(sc.merchant_note ?? '')
  }, [activator, sc.gql_data.note, sc.merchant_note])

  return (
    <ApiActionDialog
      activator={activator}
      title="Edit notes"
      url={`/subscriptions/${sc.rest_id}`}
      opts={{
        body: JSON.stringify({ input: { note: customerNote }, pwData: { merchantNote } }),
      }}
      onSuccess={(res: Result<SubscriptionContract>) =>
        mutate(['subscription-contract', `${sc.rest_id}`], res.data, false)
      }
      method="PUT"
      message={
        <LegacyStack vertical>
          <TextField
            label={<LegacyHelpLabel title="Customer note">{customerNotesText}</LegacyHelpLabel>}
            value={customerNote}
            onChange={setCustomerNote}
            autoComplete="off"
            multiline
          />
          <TextField
            label={<LegacyHelpLabel title="Merchant note">{merchantNotesText}</LegacyHelpLabel>}
            value={merchantNote}
            onChange={setMerchantNote}
            autoComplete="off"
            multiline
          />
        </LegacyStack>
      }
      primaryButtonTitle="Save"
      cancelButtonTitle="Cancel"
    />
  )
}
