import { ChoiceList, DatePicker, LegacyStack, Modal, Range, TextField } from '@shopify/polaris'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { DialogActivator, pluralize, useShopTimeZone } from '../common/helpers'
import { LegacyHelpLabel } from '../common/HelpIcon'
import { SubscriptionFilter } from '../subscriptions-index-page/api'

enum ExportScope {
  ALL = 'all',
  CURRENT_PAGE = 'current_page',
  MATCHING_FILTER = 'matching_filter',
  SELECTED = 'selected',
  UPDATED_BETWEEN = 'updated_between',
}

const getInitialDate = (zone?: string): { month: number; year: number } => {
  const date = DateTime.now().setZone(zone).minus({ month: 2 })

  return {
    month: date.month,
    year: date.year,
  }
}

const getInitialDateRange = (zone?: string): Range => {
  const end = DateTime.now().setZone(zone)

  return {
    start: new Date(end.minus({ months: 3 }).toISODate()),
    end: new Date(end.toISODate()),
  }
}

export const DateRangePicker = ({
  range,
  onChange,
  zone,
}: {
  range: Range
  onChange: (range: Range) => any
  zone?: string
}) => {
  const [date, setDate] = useState(getInitialDate(zone))

  const onlyStartDateSelected = range.start.valueOf() === range.end.valueOf()
  const disableDatesBefore = onlyStartDateSelected ? range.start : undefined

  const handleMonthChange = useCallback(
    (month: number, year: number) => setDate({ month, year }),
    []
  )

  return (
    <DatePicker
      month={date.month}
      year={date.year}
      selected={range}
      onChange={onChange}
      onMonthChange={handleMonthChange}
      multiMonth
      allowRange
      disableDatesBefore={disableDatesBefore}
    />
  )
}

export const SubscriptionExportModal = ({
  filter,
  activator,
}: {
  filter: SubscriptionFilter
  activator: DialogActivator
}) => {
  const tz = useShopTimeZone()

  const [exportScope, setExportScope] = useState(ExportScope.CURRENT_PAGE)
  const [dateRange, setDateRange] = useState(getInitialDateRange(tz))
  const [to, setTo] = useState('')

  useEffect(() => {
    setExportScope(ExportScope.CURRENT_PAGE)
    setDateRange(getInitialDateRange(tz))
  }, [activator.showCounter, tz])

  const hasFilters =
    Object.values({ ...filter, sort: undefined, page: undefined, ids: undefined }).filter(
      (val) => val
    ).length > 0

  const filterOptions = useMemo(() => {
    if (exportScope === ExportScope.CURRENT_PAGE) {
      return { ...filter, ids: undefined }
    }

    if (exportScope === ExportScope.MATCHING_FILTER) {
      return { ...filter, ids: undefined, page: undefined }
    }

    if (exportScope === ExportScope.SELECTED) {
      return { ids: filter.ids }
    }

    if (exportScope === ExportScope.UPDATED_BETWEEN) {
      // WHen only start is selected updated_max is not being set
      const updated_max =
        dateRange.start.valueOf() === dateRange.end.valueOf()
          ? undefined
          : DateTime.fromJSDate(dateRange.end).toISODate()
      return {
        updated_min: DateTime.fromJSDate(dateRange.start).toISODate(),
        updated_max: updated_max,
        sort: 'updated_desc',
      }
    }

    return {}
  }, [exportScope, filter, dateRange])

  const exportActionState = useApiActionState({
    method: 'POST',
    url: '/subscriptions/export',
    opts: {
      body: JSON.stringify({ to, ...filterOptions }),
    },
    onSuccess: activator.close,
    bannerPosition: 'external',
  })

  const exportScopeOptions = [
    { label: 'Current page', value: ExportScope.CURRENT_PAGE },
    { label: 'All subscriptions', value: ExportScope.ALL },
    {
      label: 'All subscriptions matching your search',
      value: ExportScope.MATCHING_FILTER,
      disabled: !hasFilters,
    },
    {
      label: filter.ids?.length
        ? `Selected: ${filter.ids?.length} ${pluralize(filter.ids?.length, 'subscription')}`
        : 'Selected subscriptions',
      value: ExportScope.SELECTED,
      disabled: !filter.ids?.length,
    },
    {
      label: 'Subscriptions updated between dates',
      value: ExportScope.UPDATED_BETWEEN,
    },
  ]

  return (
    <>
      <Modal
        sectioned
        open={activator.open}
        onClose={activator.close}
        title="Export subscriptions"
        primaryAction={{
          content: 'Export',
          onAction: exportActionState.run,
          loading: exportActionState.loading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: activator.close,
          },
        ]}
      >
        <ApiActionBanner state={exportActionState} />
        <LegacyStack vertical>
          <ChoiceList
            title="Export options"
            titleHidden
            choices={exportScopeOptions}
            selected={[exportScope]}
            onChange={(selected: ExportScope[]) => setExportScope(selected[0])}
          />
          {exportScope === ExportScope.UPDATED_BETWEEN && (
            <DateRangePicker range={dateRange} onChange={setDateRange} />
          )}
          <TextField
            label={
              <LegacyHelpLabel title="Send to">
                Use only to override the default recipients. You can provide multiple emails
                separated by commas.
              </LegacyHelpLabel>
            }
            placeholder="Default recipients"
            helpText="By default, the export will be sent to notification recipients specified in settings"
            value={to}
            onChange={setTo}
            autoComplete="email"
          />
        </LegacyStack>
      </Modal>
      <ApiAction state={exportActionState} />
    </>
  )
}
