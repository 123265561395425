import { LegacyStack, Modal } from '@shopify/polaris'
import React from 'react'

const basicWorkflowTagVariables = [
  'subscription_id',
  'subscription_created_at',
  'subscription_created_at_day',
  'subscription_created_at_month',
  'subscription_created_at_year',
  'subscription_initial_order_number',
  'billing_interval_type',
  'billing_interval_count',
  'billing_anchor',
  'billing_anchor_weekday',
  'billing_anchor_day_of_month',
  'billing_anchor_month',
  'delivery_interval_count',
]
const orderWorkflowTagVariables = ['payment_number']

const VariablesStack = ({ variables }: { variables: string[] }) => (
  <LegacyStack vertical spacing="extraTight">
    {variables.map((variable, index) => (
      <code
        key={`variable-${index}`}
        style={{ background: '#ebeef0', padding: '0.1em' }}
      >{`{{${variable}}}`}</code>
    ))}
  </LegacyStack>
)

export const WorkflowVariablesModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => any
}) => (
  <Modal open={open} onClose={onClose} sectioned title="Variables">
    <LegacyStack vertical>
      <p>You can use the following variables in your tags:</p>
      <VariablesStack variables={basicWorkflowTagVariables} />
      <p>
        The following ones are available only on <b>Subscription is created</b> and{' '}
        <b>Subscription payment succeeds</b> events:
      </p>
      <VariablesStack variables={orderWorkflowTagVariables} />
    </LegacyStack>
  </Modal>
)
