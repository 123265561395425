import {
  AppliedFilterInterface,
  ChoiceList,
  ChoiceListProps,
  FilterInterface,
  LegacyStack,
} from '@shopify/polaris'
import { I18n, useI18n } from '@shopify/react-i18n'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { DateRangePicker } from '../../common/DateRangePicker'
import { formatShortDate, useShopTimeZone } from '../../common/helpers'
import { SubscriptionDateFilter, SubscriptionFilter } from '../api'

type SubscriptionDateFilterField =
  | 'created'
  | 'updated'
  | 'paused'
  | 'finished'
  | 'next_order'
  | 'finishing'
  | 'next_attempt'

const anytimeDateChoices: ChoiceListProps['choices'] = [{ value: 'anytime', label: 'Anytime' }]

const pastDateChoices: ChoiceListProps['choices'] = [
  { value: 'today', label: 'Today' },
  { value: 'last_7_days', label: 'Last 7 days' },
  { value: 'last_30_days', label: 'Last 30 days' },
  { value: 'last_90_days', label: 'Last 90 days' },
  { value: 'custom', label: 'Custom' },
]

const futureDateChoices: ChoiceListProps['choices'] = [
  { value: 'today', label: 'Today' },
  { value: 'next_7_days', label: 'Next 7 days' },
  { value: 'next_30_days', label: 'Next 30 days' },
  { value: 'next_90_days', label: 'Next 90 days' },
  { value: 'custom', label: 'Custom' },
]

const filterChoices: Record<SubscriptionDateFilterField, ChoiceListProps['choices']> = {
  created: pastDateChoices,
  updated: pastDateChoices,
  paused: pastDateChoices,
  next_order: anytimeDateChoices.concat(futureDateChoices),
  next_attempt: anytimeDateChoices.concat(futureDateChoices),
  finishing: anytimeDateChoices.concat(futureDateChoices),
  finished: anytimeDateChoices.concat(pastDateChoices),
}

const filterLabels: Record<SubscriptionDateFilterField, string> = {
  created: 'Created',
  updated: 'Updated',
  paused: 'Paused',
  next_order: 'Next scheduled order',
  next_attempt: 'Next payment attempt',
  finishing: 'Finishing',
  finished: 'Finished',
}

const dateChoiceLabels = Object.fromEntries(
  [...anytimeDateChoices, ...pastDateChoices, ...futureDateChoices].map((choice) => [
    choice.value,
    choice.label,
  ])
)

const getFilterBadgeValue = (
  filter: SubscriptionDateFilter,
  field: SubscriptionDateFilterField,
  i18n: I18n,
  zone: string
) => {
  const minDate = filter[`${field}_min` as keyof SubscriptionDateFilter]
  const maxDate = filter[`${field}_max` as keyof SubscriptionDateFilter]
  const filterType = minDate || maxDate ? 'custom' : filter[field]

  const minDateObj = minDate ? DateTime.fromISO(minDate, { zone }).toJSDate() : null
  const maxDateObj = maxDate ? DateTime.fromISO(maxDate, { zone }).toJSDate() : null

  if (!filterType) {
    return undefined
  } else if (filterType !== 'custom') {
    return dateChoiceLabels[filterType]
  } else if (minDateObj && maxDateObj) {
    return `${formatShortDate(minDateObj, i18n)} - ${formatShortDate(maxDateObj, i18n)}`
  } else if (minDateObj) {
    return `Starting ${formatShortDate(minDateObj, i18n)}`
  } else if (maxDateObj) {
    return `Ending ${formatShortDate(maxDateObj, i18n)}`
  } else {
    return dateChoiceLabels['anytime']
  }
}

const DateFilter = ({
  field,
  filter,
  updateFilter,
}: {
  field: SubscriptionDateFilterField
  filter: SubscriptionDateFilter
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any
}) => {
  const fieldMin = `${field}_min` as keyof SubscriptionDateFilter
  const fieldMax = `${field}_max` as keyof SubscriptionDateFilter

  const selectedOption =
    filter[fieldMin] || filter[fieldMax]
      ? ['custom']
      : filter[field]
      ? [filter[field] as string]
      : []

  const handleChoiceChange = useCallback(
    (value: string[]) => {
      if (value[0] === 'custom') {
        updateFilter({ [field]: value[0] })
      } else {
        updateFilter({
          [field]: value[0],
          [fieldMin]: undefined,
          [fieldMax]: undefined,
        })
      }
    },
    [updateFilter, field, fieldMin, fieldMax]
  )

  return (
    <LegacyStack vertical>
      <ChoiceList
        title="Date"
        titleHidden
        choices={filterChoices[field]}
        selected={selectedOption}
        onChange={handleChoiceChange}
      />
      {selectedOption?.[0] === 'custom' && (
        <DateRangePicker
          startDate={filter[fieldMin]}
          setStartDate={(date) => updateFilter({ [fieldMin]: date })}
          endDate={filter[fieldMax]}
          setEndDate={(date) => updateFilter({ [fieldMax]: date })}
        />
      )}
    </LegacyStack>
  )
}

export const useAddDateFilter = (
  field: SubscriptionDateFilterField,
  filter: SubscriptionDateFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any,
  filters: FilterInterface[],
  appliedFilters: AppliedFilterInterface[]
) => {
  const fieldMin = `${field}_min` as keyof SubscriptionDateFilter
  const fieldMax = `${field}_max` as keyof SubscriptionDateFilter
  const zone = useShopTimeZone() ?? 'UTC'

  const [i18n] = useI18n()

  const clearDates = useCallback(
    () =>
      updateFilter({
        [field]: undefined,
        [fieldMin]: undefined,
        [fieldMax]: undefined,
      }),
    [updateFilter, field, fieldMin, fieldMax]
  )

  const label = filterLabels[field]

  filters.push({
    key: field,
    label: label,
    filter: <DateFilter field={field} filter={filter} updateFilter={updateFilter} />,
  })

  if (filter[field] || filter[fieldMin] || filter[fieldMax]) {
    appliedFilters.push({
      key: field,
      label: `${label}: ${getFilterBadgeValue(filter, field, i18n, zone)}`,
      onRemove: clearDates,
    })
  }
}
