import { Box, DatePicker, LegacyStack, Range, TextField } from '@shopify/polaris'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'

import { DatePickerPopover } from './DateInputWithSelector'

export const dateRex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/

const buildMonthDate = (date?: string) => {
  const dateObj = date ? new Date(date) : new Date()

  return {
    month: dateObj.getMonth(),
    year: dateObj.getFullYear(),
  }
}

const formatter = new Intl.DateTimeFormat('en-CA', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
})

export const DatePickerWithInput = ({
  label,
  date,
  updateDate,
  setError,
  datePickerPopover,
}: {
  label: React.ReactNode
  date?: string
  updateDate: (date?: string) => void
  setError?: (error: string) => void
  datePickerPopover?: boolean
}) => {
  const [monthDate, setMonthDate] = useState<{ month: number; year: number }>(buildMonthDate(date))
  const updateMonthDate = useCallback(
    (month: number, year: number) => setMonthDate({ month, year }),
    []
  )

  const [dateInput, setDateInput] = useState(date ?? '')
  const [dateError, setDateError] = useState('')

  useEffect(() => {
    if (setError) {
      setError(dateError)
    }
  }, [dateError, setError])

  const handleDateInputBlur = useCallback(() => {
    if (dateInput !== '' && !dateRex.test(dateInput)) {
      setDateError('Must match YYYY-MM-DD format')
    } else {
      setDateError('')
      updateDate(dateInput === '' ? undefined : dateInput)
      setMonthDate(buildMonthDate(dateInput))
    }
  }, [dateInput, setDateError, updateDate])

  const handleSelectedRange = useCallback(
    (range: Range) => {
      const dateStr = DateTime.fromJSDate(range.start).toISODate()
      updateDate(dateStr)
      setDateInput(dateStr)
    },
    [updateDate, setDateInput]
  )

  const handleSelectedDate = useCallback(
    (date: Date) => {
      setDateError('')
      updateDate(formatter.format(date))
      setDateInput(formatter.format(date))
    },
    [updateDate, setDateInput]
  )

  return (
    <Box width="250px">
      <LegacyStack vertical>
        <TextField
          value={dateInput}
          placeholder="YYYY-MM-DD"
          label={label}
          autoComplete="off"
          onChange={setDateInput}
          onBlur={handleDateInputBlur}
          error={dateError}
          connectedRight={
            datePickerPopover && (
              <DatePickerPopover
                date={date ?? new Date().toISOString()}
                onSelect={handleSelectedDate}
              />
            )
          }
        />
        {!datePickerPopover && (
          <DatePicker
            month={monthDate.month}
            year={monthDate.year}
            onMonthChange={updateMonthDate}
            onChange={handleSelectedRange}
            selected={date ? new Date(date) : undefined}
          />
        )}
      </LegacyStack>
    </Box>
  )
}
