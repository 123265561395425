import { ActionList, ActionListItemDescriptor, Button, Modal, Popover } from '@shopify/polaris'
import { CalendarMajor } from '@shopify/polaris-icons'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'

import { DateRangePicker } from '../common/DateRangePicker'
import { useDialogActivator, useToggle } from '../common/helpers'

export type Period =
  | 'last_7_days'
  | 'last_30_days'
  | 'last_90_days'
  | 'last_12_months'
  | 'last_month'
  | 'last_week'
  | 'last_year'
  | 'week_to_date'
  | 'month_to_date'
  | 'year_to_date'
  | 'custom'

const periodLabels: Record<Period, string> = {
  last_7_days: 'Last 7 days',
  last_30_days: 'Last 30 days',
  last_90_days: 'Last 90 days',
  last_12_months: 'Last 12 months',
  last_month: 'Last Month',
  last_week: 'Last Week',
  last_year: 'Last Year',
  week_to_date: 'Week to date',
  month_to_date: 'Month to date',
  year_to_date: 'Year to date',
  custom: 'Custom',
}

export const periodLabel = (
  period: Period,
  periodStart: string | null,
  periodEnd: string | null
) => {
  if (period !== 'custom' || !periodStart || !periodEnd) {
    return periodLabels[period]
  }

  return `Custom: ${periodStart} - ${periodEnd}`
}

interface PeriodDateRange {
  start: DateTime
  end: DateTime
}

interface PeriodISODateRange {
  start?: string
  end?: string
}

const periodDateRange = (period: Period, zone?: string): PeriodDateRange => {
  const now = DateTime.now().setZone(zone, { keepLocalTime: true })

  switch (period) {
    case 'last_7_days':
      return { start: now.minus({ days: 7 }), end: now }
    case 'last_30_days':
      return { start: now.minus({ days: 30 }), end: now }
    case 'last_90_days':
      return { start: now.minus({ days: 90 }), end: now }
    case 'last_12_months':
      return { start: now.startOf('month').minus({ months: 12 }), end: now.startOf('month') }
    case 'last_month':
      return { start: now.startOf('month').minus({ months: 1 }), end: now.startOf('month') }
    case 'last_week':
      return { start: now.startOf('week').minus({ weeks: 1 }), end: now.startOf('week') }
    case 'last_year':
      return { start: now.startOf('year').minus({ years: 1 }), end: now.startOf('year') }
    case 'week_to_date':
      return { start: now.startOf('week'), end: now.plus({ days: 1 }) }
    case 'month_to_date':
      return { start: now.startOf('month'), end: now.plus({ days: 1 }) }
    case 'year_to_date':
      return { start: now.startOf('year'), end: now.plus({ days: 1 }) }
    case 'custom':
      return { start: now.minus({ days: 7 }), end: now }
  }
}

export const periodISODateRange = (
  period: Period,
  period_start: string | null,
  period_end: string | null,
  zone?: string
) => {
  const range = periodDateRange(period, zone)

  const start = period === 'custom' && period_start ? period_start : range.start.toISODate()
  const end = period === 'custom' && period_end ? period_end : range.end.toISODate()

  return { start, end }
}

export const PeriodSelector = ({
  period,
  periodStart,
  periodEnd,
  onChange,
}: {
  period: Period
  periodStart: string | null
  periodEnd: string | null
  onChange: (period: Period, period_start?: string, period_end?: string) => any
}) => {
  const [active, toggleActive] = useToggle(false)
  const customDateRangeModalActivator = useDialogActivator()

  const periodISODateRange = (period: Period) =>
    ({
      start: periodDateRange(period).start.toISODate(),
      end: periodDateRange(period).end.toISODate(),
    } as PeriodISODateRange)

  const defaultCustomISODateRange =
    period === 'custom' && periodStart && periodEnd
      ? ({
          start: periodStart,
          end: periodEnd,
        } as PeriodISODateRange)
      : periodISODateRange(period)

  const [customRange, setCustomRange] = useState(defaultCustomISODateRange)

  const activator = (
    <Button disclosure onClick={toggleActive} icon={CalendarMajor} size="slim">
      {periodLabel(period, periodStart, periodEnd)}
    </Button>
  )

  const actions = useMemo(
    (): ActionListItemDescriptor[] =>
      (Object.keys(periodLabels) as Period[]).map((period: Period) => ({
        content: periodLabel(period, periodStart, periodEnd),
        onAction: () => {
          if (period !== 'custom') {
            setCustomRange(periodISODateRange(period))
            onChange(period)
          }
          if (period === 'custom') {
            customDateRangeModalActivator.show()
          }
          toggleActive()
        },
      })),
    [onChange, toggleActive, customDateRangeModalActivator, setCustomRange, periodStart, periodEnd]
  )

  return (
    <>
      <Popover active={active} activator={activator} onClose={toggleActive}>
        <ActionList items={actions} />
      </Popover>
      <Modal
        sectioned
        title="Custom date range"
        open={customDateRangeModalActivator.open}
        onClose={customDateRangeModalActivator.close}
        primaryAction={{
          content: 'Apply',
          onAction: () => {
            onChange('custom', customRange.start, customRange.end)
            customDateRangeModalActivator.close()
          },
          disabled: !customRange.start || !customRange.end,
        }}
      >
        <DateRangePicker
          startDate={customRange.start}
          setStartDate={(date) => {
            setCustomRange({ start: date, end: customRange.end })
          }}
          endDate={customRange.end}
          setEndDate={(date) => setCustomRange({ start: customRange.start, end: date })}
        />
      </Modal>
    </>
  )
}
