import { Button, FormLayout, Modal, TextField } from '@shopify/polaris'

import { DialogActivator } from '../common/helpers'
import { LegacyHelpLabel } from '../common/HelpIcon'
import { ProductQuantityField } from '../common/ProductQuantityField'
import { SellingPlanField } from '../common/SellingPlanField'
import { AddProductPayload } from './common'

export const AddProductSection = ({
  payload,
  setPayload,
  variantTitle,
  variantActivator,
}: {
  payload: AddProductPayload
  setPayload: (payload: AddProductPayload) => any
  variantTitle: string
  variantActivator: DialogActivator
}) => (
  <Modal.Section>
    <FormLayout>
      <TextField
        label="Product variant to add"
        readOnly={true}
        value={variantTitle}
        connectedRight={<Button onClick={variantActivator.show}>Select</Button>}
        autoComplete="off"
      />

      <ProductQuantityField
        value={payload.quantity}
        onChange={(quantity) => setPayload({ ...payload, quantity })}
      />

      <TextField
        label={
          <LegacyHelpLabel title="Price">
            The price will be set numerically as-is in the current subscription's currency.
            <br />
            <br />
            For pre-paid subscriptions, you need to manually multiply it by the amount of pre-paid
            deliveries.
          </LegacyHelpLabel>
        }
        type="number"
        min={0}
        step={0.01}
        value={payload.currentPrice}
        onChange={(currentPrice: string) => setPayload({ ...payload, currentPrice })}
        autoComplete="off"
      />

      <SellingPlanField
        plan={payload.sellingPlan}
        onChange={(sellingPlan) => setPayload({ ...payload, sellingPlan })}
      />
    </FormLayout>
  </Modal.Section>
)
