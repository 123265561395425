import {
  Action,
  Badge,
  Button,
  ButtonGroup,
  DataTable,
  LegacyCard,
  LegacyStack,
  Text,
  Thumbnail,
} from '@shopify/polaris'
import { DeleteMajor, EditMinor } from '@shopify/polaris-icons'
import React from 'react'
import { mutate } from 'swr'

import { ApiActionDialog } from '../common/ApiActionDialog'
import { LineItemProductLink, pluralize, useDialogActivator } from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { getRemainingInstallmentsNumber, isAddedByWorkflow } from '../common/line-item'
import { FormattedPrice, Price } from '../common/Price'
import {
  DeliveryMethod,
  Discount,
  LineItem,
  SubscriptionContract,
} from '../common/subscription-models'
import { DeliveryDetailsEditDialog } from '../delivery-details-edit-dialog/DeliveryDetailsEditDialog'
import { DiscountCodeDialog } from './DiscountCodeDialog'
import { LineEditDialog } from './LineEditDialog'
import styles from './LinesCard.module.css'

export const LineItemButtons = ({ sc, item }: { sc: SubscriptionContract; item: LineItem }) => {
  const removeDialogActivator = useDialogActivator()
  const editDialogActivator = useDialogActivator()
  const remainingInstallments = getRemainingInstallmentsNumber(
    item.customAttributes ?? [],
    sc.processed_installments
  )

  if (!item.id) {
    return null
  }

  return (
    <>
      <ButtonGroup segmented>
        {remainingInstallments !== 0 && (
          <Button
            icon={EditMinor}
            onClick={editDialogActivator.show}
            size="slim"
            disabled={!sc.is_editable}
          />
        )}
        <Button
          icon={DeleteMajor}
          onClick={removeDialogActivator.show}
          size="slim"
          disabled={!sc.is_editable}
        />
      </ButtonGroup>

      <LineEditDialog
        sc={sc}
        item={item}
        open={editDialogActivator.open}
        showCounter={editDialogActivator.showCounter}
        onClose={editDialogActivator.close}
      />

      <ApiActionDialog
        activator={removeDialogActivator}
        message={`Do you want to remove "${
          item.displayData?.title || item.title
        }" from this subscription?`}
        primaryButtonTitle="Remove"
        destructive={true}
        url={`/subscriptions/${sc.rest_id}`}
        method="PUT"
        opts={{ body: JSON.stringify({ lineToRemove: item.id }) }}
        onSuccess={(res) => mutate(['subscription-contract', `${sc.rest_id}`], res.data)}
      />
    </>
  )
}

const discountForItem = (item: LineItem): Price | null => {
  const amount =
    parseFloat(item.currentPrice.amount) * item.quantity -
    parseFloat(item.lineDiscountedPrice.amount)

  if (amount < 0.01) {
    return null
  }

  return { amount: (-amount).toString(), currencyCode: item.currentPrice.currencyCode }
}

const TaxableBadge = ({ taxable }: { taxable?: boolean }) => {
  if (taxable === undefined) {
    return null
  }

  return (
    <Text as="span" color="subdued" key="taxable">
      <Badge status="info">{taxable ? 'Taxable' : 'Not Taxable'}</Badge>
      <LegacyHelpIcon>
        Whether or not the specific product is taxable based on the product settings. This is
        separate from your overall Shopify tax settings. Tax settings and calculations are managed
        by Shopify.
      </LegacyHelpIcon>
    </Text>
  )
}

const buildLineRows = (sc: SubscriptionContract) =>
  sc.gql_data.lines.map((item: LineItem) => {
    const discount = discountForItem(item)
    const addedByWorkflow = isAddedByWorkflow(item)
    const remainingInstallments = getRemainingInstallmentsNumber(
      item.customAttributes ?? [],
      sc.processed_installments
    )

    return [
      <Thumbnail size="small" source={item.displayData?.imageUrl || ''} alt="" />,
      <LegacyStack vertical spacing="extraTight">
        <LineItemProductLink item={item} />
        {item.sellingPlanName && (
          <Text as="span" color="subdued" key="selling-plan-name">
            {item.sellingPlanName}
          </Text>
        )}
        {item.sku && (
          <Text as="span" color="subdued" key="sku">
            SKU: {item.sku}
          </Text>
        )}
        <TaxableBadge taxable={item.taxable} />
        {remainingInstallments && remainingInstallments > 0 && (
          <Text as="span" color="subdued" key="itemType">
            <Badge status="info">{`Remaining cycles: ${remainingInstallments}`}</Badge>
            <LegacyHelpIcon>
              This line item is added for fixed number of cycles and will be removed automatically
              after <Text as="strong">{remainingInstallments}</Text> successful{' '}
              {pluralize(remainingInstallments, 'installment')}.
            </LegacyHelpIcon>
          </Text>
        )}
        <Text as="span" color="subdued" key="workflow">
          {addedByWorkflow && <span>Added by workflow</span>}
          {remainingInstallments === 0 && (
            <>
              <span>Scheduled for removal</span>
              <LegacyHelpIcon>
                This line item will be removed automatically before next installment.
              </LegacyHelpIcon>
            </>
          )}
        </Text>
      </LegacyStack>,
      <LegacyStack vertical spacing="extraTight">
        <span>
          <FormattedPrice price={item.currentPrice} /> × {item.quantity}
        </span>
        {discount && (
          <Text as="span" color="subdued">
            <FormattedPrice price={discount} />
          </Text>
        )}
      </LegacyStack>,
      <FormattedPrice price={item.lineDiscountedPrice} />,
      <LineItemButtons sc={sc} item={item} />,
    ]
  })

const ShipppingLineButtons = ({ sc }: { sc: SubscriptionContract }) => {
  const deliveryDetailsDialogActivator = useDialogActivator()

  return (
    <>
      <Button
        icon={EditMinor}
        onClick={deliveryDetailsDialogActivator.show}
        size="slim"
        disabled={!sc.is_editable}
      />
      <DeliveryDetailsEditDialog sc={sc} activator={deliveryDetailsDialogActivator} />
    </>
  )
}

const discountForShipping = (sc: SubscriptionContract): Price | null => {
  const amount =
    parseFloat(sc.gql_data.deliveryPrice.amount) - parseFloat(sc.discountedDeliveryPrice.amount)

  if (amount < 0.01) {
    return null
  }

  return { amount: (-amount).toString(), currencyCode: sc.discountedDeliveryPrice.currencyCode }
}

export const deliveryMethodTitle = (deliveryMethod: DeliveryMethod | null): string | undefined => {
  if (!deliveryMethod) {
    return undefined
  }

  if (deliveryMethod.shippingOption) {
    return deliveryMethod.shippingOption.title ?? 'Shipping'
  }

  if (deliveryMethod.localDeliveryOption) {
    return deliveryMethod.localDeliveryOption.title ?? 'Local delivery'
  }

  if (deliveryMethod.pickupOption) {
    return deliveryMethod.pickupOption.title ?? 'Pickup'
  }
}

const buildShippingRows = (sc: SubscriptionContract) => {
  const discount = discountForShipping(sc)

  return [
    [
      <span />,
      <span>
        Delivery{' '}
        {sc.gql_data.deliveryMethod && <>({deliveryMethodTitle(sc.gql_data.deliveryMethod)})</>}
      </span>,
      <LegacyStack vertical spacing="extraTight">
        <span>
          <FormattedPrice price={sc.gql_data.deliveryPrice} /> × 1
        </span>
        {discount && (
          <Text as="span" color="subdued">
            <FormattedPrice price={discount} />
          </Text>
        )}
      </LegacyStack>,
      <FormattedPrice price={sc.discountedDeliveryPrice} />,
      <ShipppingLineButtons sc={sc} />,
    ],
  ]
}

const DiscountDetails = ({ discount }: { discount: Discount }) => (
  <Text as="span" color="subdued">
    {discount.value.amount && <FormattedPrice price={discount.value.amount} />}
    {!discount.value.amount && `${discount.value.percentage ?? 0}%`}
    {' off '}
    {discount.targetType === 'SHIPPING_LINE' && 'the shipping price'}
    {discount.targetType === 'LINE_ITEM' && discount.entitledLines.all && 'all product lines'}
    {discount.targetType === 'LINE_ITEM' &&
      !discount.entitledLines.all &&
      'specified product lines'}
    <br />
    {discount.recurringCycleLimit !== null &&
      discount.usageCount >= discount.recurringCycleLimit &&
      'Expired'}
    {discount.recurringCycleLimit !== null &&
      discount.usageCount < discount.recurringCycleLimit &&
      `Active (used ${discount.usageCount} of ${discount.recurringCycleLimit} ${pluralize(
        discount.recurringCycleLimit,
        'time'
      )})`}
    {discount.recurringCycleLimit === null && 'Active'}
  </Text>
)

const DiscountLineButtons = ({
  sc,
  discount,
}: {
  sc: SubscriptionContract
  discount: Discount
}) => {
  const removeDialogActivator = useDialogActivator()

  return (
    <>
      <Button
        icon={DeleteMajor}
        onClick={removeDialogActivator.show}
        size="slim"
        disabled={!sc.is_editable}
      />
      <ApiActionDialog
        activator={removeDialogActivator}
        message={`Do you want to remove "${discount.title}" from this subscription?`}
        primaryButtonTitle="Remove"
        destructive={true}
        url={`/subscriptions/${sc.rest_id}`}
        method="PUT"
        opts={{ body: JSON.stringify({ discountToRemove: discount.id }) }}
        onSuccess={(res) => mutate(['subscription-contract', `${sc.rest_id}`], res.data)}
      />
    </>
  )
}

const buildDiscountRows = (sc: SubscriptionContract) => {
  const discounts = sc.gql_data.discounts ?? []

  return discounts.map((discount: Discount) => [
    null,
    <LegacyStack vertical spacing="extraTight">
      <span>Discount: {discount.title}</span>
      <DiscountDetails discount={discount} />
    </LegacyStack>,
    null,
    null,
    <DiscountLineButtons sc={sc} discount={discount} />,
  ])
}

export const LinesCard = ({ sc }: { sc: SubscriptionContract }) => {
  const lineDialogActivator = useDialogActivator()
  const discountDialogActivator = useDialogActivator()

  const lineRows = buildLineRows(sc)
  const discountRows = buildDiscountRows(sc)
  const shippingRows = buildShippingRows(sc)
  const rows = [...lineRows, ...shippingRows, ...discountRows]

  let actions: Action[] = []

  if (sc.is_editable) {
    actions.push({ content: 'Add product', onAction: lineDialogActivator.show })
    actions.push({ content: 'Apply discount', onAction: discountDialogActivator.show })
  }

  return (
    <LegacyCard title="Line items" actions={actions}>
      <br />
      <div className={styles.tableWrap}>
        <DataTable
          columnContentTypes={['text', 'text', 'numeric', 'numeric', 'numeric']}
          headings={[]}
          verticalAlign="middle"
          rows={rows}
        />
      </div>
      <LineEditDialog
        sc={sc}
        open={lineDialogActivator.open}
        showCounter={lineDialogActivator.showCounter}
        onClose={lineDialogActivator.close}
      />
      <DiscountCodeDialog
        open={discountDialogActivator.open}
        close={discountDialogActivator.close}
        sc={sc}
      />
    </LegacyCard>
  )
}
