import {
  Button,
  ColorPicker,
  hsbToHex,
  LegacyStack,
  Modal,
  rgbToHsb,
  TextField,
} from '@shopify/polaris'
import { useState } from 'react'

import { DialogActivator } from '../common/helpers'
import { ColorCircle } from './ColorCircle'
import { hexToRgb } from './colors'

export const Content = ({
  activator,
  initialColor,
  onSelect,
}: {
  activator: DialogActivator
  initialColor: string
  onSelect: (color: string) => any
}) => {
  const [color, setColor] = useState(rgbToHsb(hexToRgb(initialColor)))
  const [hexColor, setHexColor] = useState(initialColor)

  return (
    <>
      <Modal.Section>
        <LegacyStack vertical>
          <LegacyStack alignment="center">
            <ColorCircle color={hsbToHex(color)} />
            <TextField
              value={hexColor}
              label="Color"
              labelHidden
              onChange={(value: string, name: string) => {
                let adjustedColorValue = value
                if (value !== '') {
                  adjustedColorValue = value.startsWith('#') ? value : '#' + value
                }
                setColor(rgbToHsb(hexToRgb(adjustedColorValue)))
                setHexColor(adjustedColorValue)
              }}
              autoComplete=""
            />
          </LegacyStack>
          <ColorPicker
            onChange={(color) => {
              setColor(color)
              setHexColor(hsbToHex(color))
            }}
            color={color}
            fullWidth
          />
        </LegacyStack>
      </Modal.Section>
      <Modal.Section>
        <LegacyStack distribution="trailing" spacing="tight">
          <Button onClick={activator.close}>Cancel</Button>
          <Button
            primary
            onClick={() => {
              onSelect(hexColor)
              activator.close()
            }}
          >
            Select
          </Button>
        </LegacyStack>
      </Modal.Section>
    </>
  )
}

export const ColorPickerModal = ({
  title,
  activator,
  color,
  onSelect,
}: {
  title: string
  activator: DialogActivator
  color: string
  onSelect: (color: string) => any
}) => (
  <Modal title={title} open={activator.open} onClose={activator.close}>
    <Content activator={activator} initialColor={color} onSelect={onSelect} />
  </Modal>
)
