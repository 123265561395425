// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WorkflowsIndexPage_wrapper__QbfOM .Polaris-Box > .Polaris-VerticalStack > img {
  max-width: 12rem;
  margin: 2rem 0;
}

.WorkflowsIndexPage_workflowCards__9wAw\\+ .Polaris-HorizontalGrid .Polaris-LegacyCard + .Polaris-LegacyCard {
  margin-top: 0;
}

.WorkflowsIndexPage_workflowCards__9wAw\\+ .Polaris-HorizontalGrid .Polaris-LegacyCard {
  height: 100%;
  position: relative;
  margin-bottom: 65px;
}

.WorkflowsIndexPage_workflowCards__9wAw\\+ .Polaris-HorizontalGrid .Polaris-LegacyCard > .Polaris-LegacyCard__Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.WorkflowsIndexPage_workflowCards__9wAw\\+ .Polaris-HorizontalGrid .Polaris-LegacyCard > .Polaris-LegacyCard__Footer * {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/workflows-index-page/WorkflowsIndexPage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".wrapper :global(.Polaris-Box > .Polaris-VerticalStack > img) {\n  max-width: 12rem;\n  margin: 2rem 0;\n}\n\n.workflowCards :global(.Polaris-HorizontalGrid .Polaris-LegacyCard + .Polaris-LegacyCard) {\n  margin-top: 0;\n}\n\n.workflowCards :global(.Polaris-HorizontalGrid .Polaris-LegacyCard) {\n  height: 100%;\n  position: relative;\n  margin-bottom: 65px;\n}\n\n.workflowCards :global(.Polaris-HorizontalGrid .Polaris-LegacyCard > .Polaris-LegacyCard__Footer) {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n}\n\n.workflowCards :global(.Polaris-HorizontalGrid .Polaris-LegacyCard > .Polaris-LegacyCard__Footer *) {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `WorkflowsIndexPage_wrapper__QbfOM`,
	"workflowCards": `WorkflowsIndexPage_workflowCards__9wAw+`
};
export default ___CSS_LOADER_EXPORT___;
