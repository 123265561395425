import { Button, LegacyStack, TextContainer } from '@shopify/polaris'
import React from 'react'
import { mutate } from 'swr'

import { ApiActionDialog } from '../common/ApiActionDialog'
import { ExternalLink, OrderLink, useDialogActivator } from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'

export interface PaymentDetailsProps {
  subscriptionRestId: number
  baId?: number
  status: string
  errorCode?: string
  errorMessage?: string
  orderGid?: string
  orderName?: string
  nextActionUrl?: string
}

const errorCodeMapping: Record<string, string> = {
  AMOUNT_TOO_SMALL: 'The amount is too small.',
  AUTHENTICATION_ERROR: 'There was an error during the authentication.',
  BUYER_CANCELED_PAYMENT_METHOD: 'Payment method was canceled by buyer.',
  CUSTOMER_INVALID: 'Customer is invalid.',
  CUSTOMER_NOT_FOUND: 'Customer was not found.',
  EXPIRED_PAYMENT_METHOD: 'Payment method is expired.',
  INVALID_CUSTOMER_BILLING_AGREEMENT:
    "The billing agreement ID or the transaction ID for the customer's payment method is invalid.",
  INVALID_PAYMENT_METHOD:
    'Payment method is invalid. Please update or create a new payment method.',
  INVALID_SHIPPING_ADDRESS: 'The shipping address is either missing or invalid.',
  INVENTORY_ALLOCATIONS_NOT_FOUND: 'No inventory location found or enabled.',
  INVOICE_ALREADY_PAID: 'A payment has already been made for this invoice.',
  PAYMENT_METHOD_DECLINED: 'Payment method was declined by processor.',
  PAYMENT_METHOD_INCOMPATIBLE_WITH_GATEWAY_CONFIG:
    'Payment method cannot be used with the current payment gateway test mode configuration.',
  PAYMENT_METHOD_NOT_FOUND: 'Payment method was not found.',
  PAYMENT_PROVIDER_IS_NOT_ENABLED: 'Payment provider is not enabled.',
  TEST_MODE: 'Gateway is in test mode and attempted to bill a live payment method.',
  TRANSIENT_ERROR: 'Transient error, try again later.',
  UNEXPECTED_ERROR: 'There was an unexpected error during the billing attempt.',
}

export const PaymentDetails = (props: PaymentDetailsProps) => {
  const retryDialogActivator = useDialogActivator()
  const errorCodeDescription = props.errorCode ? errorCodeMapping[props.errorCode] ?? null : null

  if (props.errorMessage) {
    return (
      <p>
        {props.errorMessage}
        {errorCodeDescription ? <LegacyHelpIcon>{errorCodeDescription}</LegacyHelpIcon> : null} (
        <ExternalLink href="https://docs.paywhirl.com/en/articles/8602708-how-to-manage-failed-payments-and-declines-in-shopify">
          learn more
        </ExternalLink>
        )
      </p>
    )
  }

  if (props.orderGid && props.orderName) {
    return <OrderLink gid={props.orderGid}>Order {props.orderName}</OrderLink>
  }

  if (props.nextActionUrl) {
    return <ExternalLink href={props.nextActionUrl}>Requires confirmation</ExternalLink>
  }

  if (props.baId && props.status === 'Pending') {
    return (
      <>
        <Button plain onClick={retryDialogActivator.show}>
          Retry
        </Button>
        <ApiActionDialog
          activator={retryDialogActivator}
          url={`/subscriptions/${props.subscriptionRestId}/retry-billing-attempt/${props.baId}`}
          method="POST"
          message={
            <LegacyStack>
              <TextContainer>
                <p>
                  The status of this payment is currently unknown. Do you want to retry? The
                  customer will NOT be charged twice."
                </p>
                <p>Note: Please refresh this page a few minutes after retry to check the status.</p>
              </TextContainer>
            </LegacyStack>
          }
          primaryButtonTitle="Retry"
          onSuccess={(res) =>
            mutate(['subscription-contract', `${props.subscriptionRestId}`], res.data, false)
          }
        />
      </>
    )
  }

  return null
}
