import { Button, ChoiceList, FormLayout, Popover } from '@shopify/polaris'

import { NonEmptyArray, useToggle } from './helpers'

export type TableColumnChoices = { label: string; value: string; disabled?: boolean }

export const TableColumnSelector = ({
  columns,
  onChange,
  choices,
}: {
  columns: string[]
  onChange: (columns: NonEmptyArray<string>) => any
  choices: TableColumnChoices[]
}) => {
  const [active, toggleActive] = useToggle(false)

  return (
    <Popover
      active={active}
      activator={
        <Button onClick={toggleActive} disclosure>
          Columns
        </Button>
      }
      autofocusTarget="first-node"
      onClose={toggleActive}
      sectioned
    >
      <FormLayout>
        <ChoiceList
          title="Columns"
          allowMultiple
          choices={choices}
          selected={columns}
          onChange={onChange}
        />
      </FormLayout>
    </Popover>
  )
}
