import {
  Icon,
  LegacyStack,
  Modal,
  ResourceItem,
  ResourceList,
  Tag,
  Text,
  TextContainer,
} from '@shopify/polaris'
import { ChevronRightMinor } from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import { mutate } from 'swr'

import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import {
  WorkflowTemplateID,
  workflowTemplateIDs,
  workflowTemplateLabels,
} from '../common/workflow-models'

const basicDescriptions: Record<WorkflowTemplateID, React.ReactNode> = {
  'tag-active-subscribers': (
    <p>
      Tag customers with active subscriptions as <Tag>Active Subscriber</Tag>
    </p>
  ),
}

const detailedDescriptions: Record<WorkflowTemplateID, React.ReactNode> = {
  'tag-active-subscribers': (
    <p>
      Customers with active subscriptions will be tagged with <Tag>Active Subscriber</Tag>. When
      their subscription gets cancelled, expires or fails, and they have no other active
      subscriptions, the <Tag>Active Subscriber</Tag> tag will be removed
    </p>
  ),
}

interface WorkflowsWizardModalProps {
  open: boolean
  close: () => any
  showCounter: number
}

interface WorkflowTemplateItem {
  id: WorkflowTemplateID
  onClick: () => any
}

const renderWorkflowTemplateItem = (item: WorkflowTemplateItem) => (
  <ResourceItem id={item.id} onClick={item.onClick}>
    <LegacyStack alignment="center">
      <LegacyStack.Item fill>
        <LegacyStack vertical>
          <Text variant="headingMd" as="h2">
            {workflowTemplateLabels[item.id]}
          </Text>
          <TextContainer>{basicDescriptions[item.id]}</TextContainer>
        </LegacyStack>
      </LegacyStack.Item>
      <Icon source={ChevronRightMinor} />
    </LegacyStack>
  </ResourceItem>
)

export const WorkflowsWizardModal = ({ open, close, showCounter }: WorkflowsWizardModalProps) => {
  const [templateId, setTemplateId] = useState<WorkflowTemplateID | null>(null)

  useEffect(() => {
    setTemplateId(null)
  }, [showCounter])

  const workflowTemplateItems = workflowTemplateIDs.map(
    (id: WorkflowTemplateID): WorkflowTemplateItem => ({
      id,
      onClick: () => setTemplateId(id),
    })
  )

  const createActionState = useApiActionState({
    url: '/workflows/create-from-template',
    opts: {
      body: JSON.stringify({ id: templateId }),
    },
    onSuccess: () => {
      mutate(['workflows'])
      close()
    },
    bannerPosition: 'external',
  })

  const primaryAction = templateId
    ? {
        content: 'Create and activate workflows',
        onAction: createActionState.run,
        loading: createActionState.loading,
      }
    : undefined

  return (
    <>
      <Modal
        open={open}
        onClose={close}
        title="Create workflows"
        primaryAction={primaryAction}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: close,
            disabled: createActionState.loading,
          },
        ]}
      >
        {!templateId && (
          <ResourceList items={workflowTemplateItems} renderItem={renderWorkflowTemplateItem} />
        )}

        {templateId && (
          <Modal.Section>
            <ApiActionBanner state={createActionState} />
            {detailedDescriptions[templateId]}
          </Modal.Section>
        )}
      </Modal>

      <ApiAction state={createActionState} />
    </>
  )
}
