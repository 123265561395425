// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.LinesCard_tableWrap__-3RBA th:nth-child(1), .LinesCard_tableWrap__-3RBA td:nth-child(3), .LinesCard_tableWrap__-3RBA td:nth-child(4), .LinesCard_tableWrap__-3RBA td:nth-child(5) {
  width: 1%;
  white-space: nowrap;
}

.LinesCard_tableWrap__-3RBA td:nth-child(2) {
  white-space: break-spaces;
}

@media (max-width: 40em) {
  .LinesCard_tableWrap__-3RBA th:nth-child(1), .LinesCard_tableWrap__-3RBA td:nth-child(3) {
    display: none;
  }
}

.LinesCard_tableWrap__-3RBA tbody tr:last-child .Polaris-DataTable__Cell {
  border-bottom: none;
}
`, "",{"version":3,"sources":["webpack://./src/subscription-page/LinesCard.module.css"],"names":[],"mappings":";AACA;EACE,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["\n.tableWrap th:nth-child(1), .tableWrap td:nth-child(3), .tableWrap td:nth-child(4), .tableWrap td:nth-child(5) {\n  width: 1%;\n  white-space: nowrap;\n}\n\n.tableWrap td:nth-child(2) {\n  white-space: break-spaces;\n}\n\n@media (max-width: 40em) {\n  .tableWrap th:nth-child(1), .tableWrap td:nth-child(3) {\n    display: none;\n  }\n}\n\n.tableWrap tbody tr:last-child :global(.Polaris-DataTable__Cell) {\n  border-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrap": `LinesCard_tableWrap__-3RBA`
};
export default ___CSS_LOADER_EXPORT___;
