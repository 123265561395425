import { SectionDescriptor } from '@shopify/polaris/build/ts/src/types'
import React, { useContext } from 'react'
import useSWR from 'swr'

import { backendFetchResult, backendFetchResultData } from '../common/api'
import {
  Workflow,
  WorkflowAction,
  WorkflowAddDiscountDataType,
  WorkflowFilter,
  WorkflowRootFilter,
  WorkflowRootFilterType,
  WorkflowTriggerType,
} from '../common/workflow-models'

export interface WorkflowFormData {
  id?: number
  name: string
  is_active: boolean
  rootFilterType: WorkflowRootFilterType
  triggers: WorkflowTriggerType[]
  filters: WorkflowFilter[]
  actions: WorkflowAction[]
  _random?: number
}

export type WorkflowInput = Omit<Workflow, 'id'>

const initialWorkflowFormData = (): WorkflowFormData => ({
  name: '',
  is_active: true,
  rootFilterType: 'and',
  triggers: [],
  filters: [],
  actions: [],
})

export const convertWorkflowToFormData = (workflow: Workflow): WorkflowFormData => {
  const rootFilterType = workflow.filter.length > 1 ? workflow.filter[0] : 'and'
  const filters = workflow.filter.length > 1 ? workflow.filter[1] : []

  return {
    id: workflow.id,
    name: workflow.name || '',
    is_active: workflow.is_active,
    triggers: workflow.triggers,
    filters,
    actions: workflow.actions,
    rootFilterType,
    _random: Math.random(),
  }
}

const convertFormDataToWorkflowInput = (data: WorkflowFormData): WorkflowInput => {
  const filter: WorkflowRootFilter = [
    data.filters.length > 1 ? data.rootFilterType : 'and',
    data.filters,
  ]

  return {
    name: data.name,
    is_active: data.is_active,
    triggers: data.triggers,
    filter,
    actions: data.actions.map(convertActionFormDataToActionInput),
  }
}

const convertActionFormDataToActionInput = (action: WorkflowAction): WorkflowAction => {
  if (action[0] === 'add-discount' && action[1][2] === 'fixedAmount') {
    let actionDataInput = [...action[1]] as WorkflowAddDiscountDataType
    actionDataInput[3] = (Math.round(parseFloat(action[1][3]) * 100) / 100).toString()
    return [action[0], actionDataInput]
  }
  return action
}

export const fetchWorkflowFormData = async (_: any, id?: string) => {
  if (!id) {
    return initialWorkflowFormData()
  }

  const workflow = await backendFetchResultData<Workflow>('GET', `/workflows/${id}`)
  const formData = convertWorkflowToFormData(workflow)

  return formData
}

export const createOrUpdateWorkflow = async (data: WorkflowFormData, id?: string) => {
  const input = convertFormDataToWorkflowInput(data)
  const method = id ? 'PATCH' : 'POST'
  const url = id ? `/workflows/${id}` : '/workflows'
  const body = JSON.stringify(input)

  return backendFetchResult<Workflow>(method, url, { body })
}

const fetchWorkflows = () => backendFetchResultData<Workflow[]>('GET', '/workflows')

export const useWorkflowsSWR = () => useSWR<Workflow[]>(['workflows'], fetchWorkflows)

interface SellingPlanOption {
  name: string
  gid: string
}

interface SellingPlanGroupOption {
  gid: string
  name: string
  sellingPlans: SellingPlanOption[]
}

export const sellingPlanOptionListSections = (
  data: SellingPlanGroupOption[],
  disabled?: boolean
): SectionDescriptor[] =>
  data.map((spg: SellingPlanGroupOption) => ({
    title: spg.name,
    options: spg.sellingPlans.map((sp: SellingPlanOption) => ({
      label: sp.name,
      value: sp.gid,
      disabled,
    })),
  }))

export const sellingPlanGroupOptions = (data: SellingPlanGroupOption[]) =>
  data.map((spg: SellingPlanGroupOption) => ({
    label: spg.name,
    value: spg.gid,
  }))

export const sellingPlanNames = (spgData: SellingPlanGroupOption[]) => {
  const ret: Record<string, string> = {}

  spgData.forEach((spg: SellingPlanGroupOption) => {
    ret[spg.gid] = spg.name

    spg.sellingPlans.forEach((sp: SellingPlanOption) => (ret[sp.gid] = `${spg.name}: ${sp.name}`))
  })

  return ret
}

const fetchSellingPlanGroupsData = (_: string): Promise<SellingPlanGroupOption[]> =>
  backendFetchResultData('GET', `/plans/workflows-page-data`)

export const useSellingPlansSWR = () => useSWR(['selling-plans-data'], fetchSellingPlanGroupsData)

export const SellingPlansContext = React.createContext<SellingPlanGroupOption[]>([])

export const useSellingPlans = () => useContext(SellingPlansContext)

export const useSellingPlanNames = () => {
  const data = useSellingPlans()
  return sellingPlanNames(data)
}
