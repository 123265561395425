import {
  Badge,
  HorizontalStack,
  Icon,
  LegacyStack,
  Modal,
  ResourceItem,
  ResourceList,
  Text,
  VerticalStack,
} from '@shopify/polaris'
import { ChevronRightMinor } from '@shopify/polaris-icons'

import { DialogActivator } from '../common/helpers'
import * as urls from '../common/urls'
import { WorkflowCreateItemType, workflowDescriptions, workflowLabels } from './WorkflowCreateCard'

interface BaseWorkflowCreateItemProps {
  id: WorkflowCreateItemType
}

interface WorkflowCreateItemWithUrl extends BaseWorkflowCreateItemProps {
  url: string
  onClick?(id?: string): void
}
interface WorkflowCreateItemWithClick extends BaseWorkflowCreateItemProps {
  url?: string
  onClick(id?: string): void
}

type WorkflowItemProps = WorkflowCreateItemWithUrl | WorkflowCreateItemWithClick

const renderWorkflowCreateItem = (item: WorkflowItemProps) => (
  <ResourceItem {...item}>
    <HorizontalStack wrap={false} align="space-between" gap="2">
      <LegacyStack.Item fill>
        <VerticalStack gap="2">
          <HorizontalStack wrap={false} blockAlign="baseline" align="start" gap="2">
            <Text variant="headingMd" as="h2">
              {workflowLabels[item.id].title}
            </Text>
            {workflowLabels[item.id].badgeLabel && (
              <Badge status="attention">{workflowLabels[item.id].badgeLabel}</Badge>
            )}
          </HorizontalStack>
          <Text as="p">{workflowDescriptions[item.id]}</Text>
        </VerticalStack>
      </LegacyStack.Item>
      <Icon source={ChevronRightMinor} />
    </HorizontalStack>
  </ResourceItem>
)

export const WorkflowCreateModal = ({
  activator,
  wizardActivator,
}: {
  activator: DialogActivator
  wizardActivator: DialogActivator
}) => {
  const workflowCreateItems = (): WorkflowItemProps[] => {
    const taggingItem = {
      id: 'tagging',
      onClick: () => {
        activator.close()
        wizardActivator.show()
      },
    } as WorkflowItemProps

    const subscriptionItem = {
      id: 'subscription',
      url: urls.subscriptionFlowsCreateUrl(),
    } as WorkflowItemProps

    const singleItem = {
      id: 'single',
      url: urls.workflowCreateUrl,
    } as WorkflowItemProps

    return [taggingItem, subscriptionItem, singleItem]
  }

  return (
    <Modal
      open={activator.open}
      onClose={activator.close}
      title="Create workflow"
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: activator.close,
        },
      ]}
    >
      <ResourceList items={workflowCreateItems()} renderItem={renderWorkflowCreateItem} />
    </Modal>
  )
}
