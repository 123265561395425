import { backendFetchResult, Result } from '../common/api'
import { gidToId } from '../common/helpers'
import { SellingPlanGroup, SellingPlanGroupPWData } from './models-common'
import { SellingPlanGroupInput } from './models-input'

// #region Internal types

export const fetchSellingPlanGroup = async (id: string): Promise<SellingPlanGroup> => {
  const res = await backendFetchResult<SellingPlanGroup>('GET', `/plans/${id}`)

  if (res.status !== 'success' || !res.data) {
    return Promise.reject(`Unknown error`)
  }

  return res.data
}

export const createSellingPlanGroup = async (
  input: SellingPlanGroupInput,
  pwData: SellingPlanGroupPWData
): Promise<Result<SellingPlanGroup>> => {
  const body = JSON.stringify({ input, pwData })

  return await backendFetchResult('POST', `/plans`, { body })
}

export const updateSellingPlanGroup = async (
  gid: string,
  input: SellingPlanGroupInput,
  pwData: SellingPlanGroupPWData
): Promise<Result<SellingPlanGroup>> => {
  const id = gidToId(gid)
  const body = JSON.stringify({ input, pwData })

  return await backendFetchResult('PATCH', `/plans/${id}`, { body })
}
