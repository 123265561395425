import {
  Button,
  ButtonGroup,
  ComplexAction,
  Icon,
  Layout,
  LegacyCard,
  LegacyStack,
  LinkAction,
  Page,
  Text,
  TextField,
} from '@shopify/polaris'
import { PlayMinor } from '@shopify/polaris-icons'
import { useState } from 'react'

import { Result } from '../common/api'
import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { useCollections } from '../common/collections'
import { Footer } from '../common/Footer'
import { gidToId, InternalLink, useDialogActivator } from '../common/helpers'
import { SellingPlanInterval, SellingPlanPricingPolicyAdjustmentType } from '../common/plan-models'
import {
  getPriceAdjustmentValueError,
  PriceAdjustmentTypeField,
  PriceAdjustmentValueField,
} from '../common/PriceAdjustmentFields'
import { IntervalCountField, IntervalField, MaxCyclesField } from '../common/ScheduleFields'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { VideoModal } from '../common/VideoModal'
import { ProductList } from '../selling-plan-products-page/ProductList'
import { SellingPlanGroup } from './models-common'
import {
  defaultSellingPlanGroupName,
  defaultSellingPlanGroupOption,
  initialSellingPlanFormData,
  sellingPlanGroupFormDataWithDefaultValues,
} from './models-form'
import { sellingPlanGroupFormDataToInput, sellingPlanGroupFormDataToPWData } from './models-input'

const buildSellingPlanCreateBody = ({
  name,
  interval,
  intervalCount,
  maxCycles,
  adjustmentType,
  adjustmentValue,
}: {
  name: string
  interval: SellingPlanInterval
  intervalCount: number
  maxCycles: number | null
  adjustmentType: SellingPlanPricingPolicyAdjustmentType
  adjustmentValue: string
}) => {
  const planData = initialSellingPlanFormData('basic')

  planData.deliveryPolicy.recurring.interval = interval
  planData.deliveryPolicy.recurring.intervalCount = intervalCount
  planData.billingPolicy.recurring.maxCycles = maxCycles
  planData.fixedPricingPolicy.adjustmentType = adjustmentType
  planData.fixedPricingPolicy.adjustmentValue = adjustmentValue

  const groupData = sellingPlanGroupFormDataWithDefaultValues({
    name: name,
    merchantCode: name,
    planSelectorTitle: '',
    summary: '',
    sellingPlans: [planData],
    activities: [],
  })

  const input = sellingPlanGroupFormDataToInput(groupData)
  const pwData = sellingPlanGroupFormDataToPWData(groupData)

  return { input, pwData }
}

const PlanCard = ({
  spg,
  setSpg,
}: {
  spg?: SellingPlanGroup
  setSpg: (spg: SellingPlanGroup) => void
}) => {
  const [name, setName] = useState<string>('Subscribe and Save')
  const [interval, setInterval] = useState<SellingPlanInterval>(SellingPlanInterval.MONTH)
  const [intervalCount, setIntervalCount] = useState<number>(1)
  const [maxCycles, setMaxCycles] = useState<number | null>(null)
  const [adjustmentType, setAdjustmentType] = useState<SellingPlanPricingPolicyAdjustmentType>(
    SellingPlanPricingPolicyAdjustmentType.NONE
  )
  const [adjustmentValue, setAdjustmentValue] = useState<string>('0')
  const priceAdjustmentValueError = getPriceAdjustmentValueError(adjustmentType, adjustmentValue)

  const disabled = spg !== undefined

  const createActionState = useApiActionState({
    url: '/plans',
    opts: {
      body: JSON.stringify(
        buildSellingPlanCreateBody({
          name,
          interval,
          intervalCount,
          maxCycles,
          adjustmentType,
          adjustmentValue,
        })
      ),
    },
    onSuccess: (res: Result<SellingPlanGroup>) => setSpg(res.data!),
    bannerPosition: 'external',
  })

  const createAction: ComplexAction = {
    content: 'Create plan',
    onAction: createActionState.run,
    loading: createActionState.loading,
    disabled: !!priceAdjustmentValueError,
  }

  return (
    <LegacyCard
      title={
        <LegacyStack vertical spacing="extraTight">
          <Text variant="headingMd" as="h2">
            1. Create your first selling plan
          </Text>
          <Text as="span" color="subdued">
            To access more advanced features, use the full editor of selling plans in the{' '}
            <InternalLink url={urls.plansUrl}>Plans</InternalLink> section of the app.
          </Text>
        </LegacyStack>
      }
      sectioned
      primaryFooterAction={disabled ? undefined : createAction}
    >
      <LegacyStack vertical spacing="loose">
        {createActionState.banner && createActionState.message && (
          <ApiActionBanner state={createActionState} bottomMargin={false} />
        )}

        <TextField
          label="Name"
          value={name}
          onChange={setName}
          disabled={disabled}
          autoComplete="off"
          placeholder={defaultSellingPlanGroupName}
        />

        <LegacyStack alignment="trailing" distribution="fill">
          <IntervalCountField
            label="Delivery frequency"
            value={intervalCount}
            onChange={setIntervalCount}
            disabled={disabled}
            placeholder={defaultSellingPlanGroupOption}
          />
          <IntervalField label=" " value={interval} onChange={setInterval} disabled={disabled} />
        </LegacyStack>

        <MaxCyclesField value={maxCycles} onChange={setMaxCycles} disabled={disabled} />

        <LegacyStack alignment="leading" distribution="fillEvenly">
          <PriceAdjustmentTypeField
            value={adjustmentType}
            onChange={setAdjustmentType}
            disabled={disabled}
          />
          <PriceAdjustmentValueField
            value={adjustmentValue}
            onChange={setAdjustmentValue}
            disabled={disabled}
            prePaidDeliveries={1}
            currencySymbol="$"
            adjustmentType={adjustmentType}
            error={priceAdjustmentValueError}
          />
        </LegacyStack>
      </LegacyStack>
      <ApiAction state={createActionState} />
    </LegacyCard>
  )
}

const ProductsForm = ({
  spg,
  onProductAttached,
}: {
  spg: SellingPlanGroup
  onProductAttached: () => void
}) => {
  const collections = useCollections()

  return (
    <ProductList
      spgRestId={gidToId(spg.gid)}
      pageData={{ merchantCode: spg.gql_data.merchantCode, bulkAction: null }}
      collections={collections}
      pagePath={urls.firstSellingPlanUrl}
      title="2. Select products for your selling plan"
      onProductAttached={onProductAttached}
    />
  )
}

const CongratulationsCard = () => (
  <LegacyCard title="3. Congratulations!" sectioned>
    <LegacyStack vertical spacing="loose">
      <p>
        Congratulations, you've created your first selling plan! Now, in order to show it to your
        customers, you need to install PayWhirl's selling plan widget on the product page.
      </p>
      <ButtonGroup>
        <Button url={urls.gettingStartedUrl}>Install selling plan widget</Button>
      </ButtonGroup>
    </LegacyStack>
  </LegacyCard>
)

export const FirstSellingPlanPage = () => {
  const backAction: LinkAction = { content: 'Getting started', url: urls.gettingStartedUrl }
  const videoActivator = useDialogActivator()

  const [spg, setSpg] = useState<SellingPlanGroup | undefined>(undefined)
  const [productAttached, setProductAttached] = useState(false)

  return (
    <>
      <Tabs selected={TabIndex.GettingStarted} />
      <Page
        title={'Create a new selling plan'}
        backAction={backAction}
        primaryAction={
          <Button
            onClick={videoActivator.show}
            icon={<Icon source={PlayMinor} />}
            outline
            monochrome
          >
            Watch video
          </Button>
        }
      >
        <Layout>
          <Layout.Section>
            <PlanCard spg={spg} setSpg={setSpg} />
            {spg && <ProductsForm spg={spg} onProductAttached={() => setProductAttached(true)} />}
            {productAttached && <CongratulationsCard />}
          </Layout.Section>
          <Layout.Section>
            <Footer />
          </Layout.Section>
        </Layout>
      </Page>
      <VideoModal
        src="https://www.youtube.com/embed/jwGwLyVsEYc"
        title="How to Setup Subscribe & Save"
        activator={videoActivator}
      />
    </>
  )
}
