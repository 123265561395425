import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

import { backendFetch, backendFetchResult, backendFetchResultData } from '../common/api'

export const lowInventoryActionNone = 'none'
export const lowInventoryActionSkipOrder = 'skip-order'
export const lowInventoryActionPauseSubscription = 'pause-subscription'
export type LowInventoryAction =
  | typeof lowInventoryActionNone
  | typeof lowInventoryActionSkipOrder
  | typeof lowInventoryActionPauseSubscription

export const failedDunningActionCancelSubscription = 'cancel-subscription'
export const failedDunningActionSkipOrder = 'skip-order'
export const failedDunningActionPauseSubscription = 'pause-subscription'
export type FailedDunningAction =
  | typeof failedDunningActionCancelSubscription
  | typeof failedDunningActionSkipOrder
  | typeof failedDunningActionPauseSubscription

export interface UserSettings {
  customers_can_skip_installments: boolean
  customers_can_attempt_billings: boolean
  customers_can_cancel_subscriptions: boolean
  customers_can_pause_subscriptions: boolean
  customers_can_reactivate_subscriptions: boolean
  customers_can_change_quantity: string
  customers_can_add_products: boolean
  customers_can_add_products_from_widget: boolean
  customers_can_remove_products: boolean
  customers_can_swap_products: string
  customers_can_edit_discounts: boolean
  customers_can_change_selling_plan: boolean
  show_link_to_customer_portal_after_checkout: boolean
  send_new_subscription_emails: boolean
  send_subscription_expired_emails: boolean
  send_subscription_paused_emails: boolean
  send_subscription_resumed_emails: boolean
  send_subscription_reactivation_emails: boolean
  send_subscription_edited_emails: boolean
  send_subscription_retrying_emails: boolean
  send_subscription_failed_emails: boolean
  send_annual_reminder_emails: boolean
  annual_reminder_emails_days: number
  send_payment_notification_emails: boolean
  send_address_changed_emails: boolean
  send_invite_emails: boolean
  attempt_retry_count: number
  attempt_retry_delay: number
  payment_notification_timing: number
  attempt_timeout: number
  low_inventory_action: LowInventoryAction
  low_inventory_threshold: number
  failed_dunning_action: FailedDunningAction
  send_user_emails_to: string
  send_user_subscription_emails: boolean
  callback_url: string
  callback_topics: string[]
  cancellation_flow_show_survey: boolean
  cancellation_flow_offer_pause: boolean
  cancellation_flow_offer_skip_order: boolean
  cancellation_flow_offer_discount: boolean
  cancellation_flow_discount_code: string
  cancellation_flow_offer_edit: boolean
  customer_portal_theme: string
  standalone_customer_portal: boolean
  klaviyo_api_key: string
  klaviyo_enabled: boolean
}

export interface ThemeColors {
  [key: string]: string
}

export interface KlaviyoAuthorizationStatus {
  isAuthorized: boolean
  organizationName: string | null
}

export type KlaviyoBulkProfileImportStatus = 'Dispatching' | 'InProgress' | 'Completed' | null

export const useUserSettings = () =>
  useSWR<UserSettings>(['user-settings'], () =>
    backendFetch('GET', '/settings/user-settings').then((resp: any) => resp.data)
  )

export const patchUserSettings = async (settings: UserSettings) => {
  const body = JSON.stringify({ settings })
  return await backendFetchResult('PATCH', '/settings/user-settings', { body })
}

export const useCustomerPortalCustomStyles = () =>
  useSWRImmutable<string>(['customer-portal-custom-styles'], () =>
    backendFetchResultData<string>('GET', '/settings/customer-portal-custom-styles')
  )

export const useCustomerPortalCustomScriptUrl = () =>
  useSWRImmutable<string>(['customer-portal-custom-script-url'], () =>
    backendFetchResultData<string>('GET', '/settings/customer-portal-custom-script-url')
  )

export const useCustomerPortalCustomColors = () =>
  useSWRImmutable<ThemeColors>(['customer-portal-custom-colors'], () =>
    backendFetchResultData<ThemeColors>('GET', '/settings/customer-portal-custom-colors')
  )

export const fetchKlaviyoAuthorizationStatus = (_: string) =>
  backendFetchResultData<KlaviyoAuthorizationStatus>('GET', '/klaviyo/authorization-status')

export const useKlaviyoAuthorizationStatus = () =>
  useSWR<KlaviyoAuthorizationStatus>(
    ['klaviyo/authorization-status'],
    fetchKlaviyoAuthorizationStatus
  )

export const fetchKlaviyoBulkProfileImportStatus = (_: string) =>
  backendFetchResultData<KlaviyoBulkProfileImportStatus>(
    'GET',
    '/klaviyo/bulk-profile-import-status'
  )

export const useKlaviyoBulkProfileImportStatus = (polling: boolean) =>
  useSWR<KlaviyoBulkProfileImportStatus>(
    ['klaviyo/bulk-profile-import-status'],
    fetchKlaviyoBulkProfileImportStatus,
    {
      refreshInterval: polling ? 5000 : undefined,
    }
  )
