import { LegacyCard, SkeletonBodyText, TextContainer } from '@shopify/polaris'

export const SkeletonCards = ({ schema }: { schema: number[] }) => (
  <>
    {schema.map((lines, i) => (
      <LegacyCard sectioned key={i}>
        <TextContainer>
          <SkeletonBodyText lines={lines} />
        </TextContainer>
      </LegacyCard>
    ))}
  </>
)
