import { Button, InlineError, LegacyCard, LegacyStack, OptionList, Popover } from '@shopify/polaris'
import React from 'react'

import { Result } from '../common/api'
import { resultErrorField, useToggle } from '../common/helpers'
import { SellingPlanInterval } from '../common/plan-models'
import {
  WorkflowAction,
  workflowActionLabels,
  WorkflowActionType,
  workflowActionTypes,
} from '../common/workflow-models'
import { ActionsForm } from './ActionsForm'
import { WorkflowFormData } from './api'

export type ActionOption = {
  label: string
  value: WorkflowActionType
}

export const actionOptions = workflowActionTypes
  .filter(
    (action) => !['remove-one-time-gift-products', 'remove-expired-discount'].includes(action)
  )
  .map((action) => ({
    label: workflowActionLabels[action],
    value: action,
  }))

export const workflowActionDefaults: Record<
  Exclude<WorkflowActionType, 'remove-one-time-gift-products' | 'remove-expired-discount'>,
  any
> = {
  'add-customer-tags': [],
  'remove-customer-tags': [],
  'add-order-tags': [],
  'add-one-time-gift-product': [],
  'add-product': [[], 1, '0.00', 1],
  'swap-product': [],
  'remove-product': [],
  'remove-product-variant': [],
  'add-discount': ['', '1', 'percentage', '0'],
  'edit-delivery-frequency': [1, SellingPlanInterval.MONTH, 1],
}

export interface ActionsCardProps {
  data: WorkflowFormData
  onChange: (actions: WorkflowAction[]) => any
  saveResult: Result<any> | null
}

export const ActionsCard = ({ data, onChange, saveResult }: ActionsCardProps) => {
  const [actionPopoverActive, toggleActionPopover] = useToggle(false)
  const error = resultErrorField(saveResult, `actions`)

  return (
    <LegacyCard title="Actions">
      <ActionsForm
        actions={data.actions}
        triggers={data.triggers}
        onChange={onChange}
        saveResult={saveResult}
      />
      <LegacyCard.Section>
        <LegacyStack vertical>
          <Popover
            active={actionPopoverActive}
            activator={
              <Button onClick={toggleActionPopover} disclosure>
                Add action
              </Button>
            }
            onClose={toggleActionPopover}
            fluidContent
          >
            <OptionList
              onChange={(
                selected: Exclude<
                  WorkflowActionType,
                  'remove-expired-discount' | 'remove-one-time-gift-products'
                >[]
              ) => {
                onChange([...data.actions, [selected[0], workflowActionDefaults[selected[0]]]])
                toggleActionPopover()
              }}
              options={actionOptions}
              selected={[]}
            />
          </Popover>
          {error && <InlineError message={error} fieldID={'actions'} />}
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  )
}
