import { Modal, Text, VerticalStack } from '@shopify/polaris'

import { DialogActivator } from '../common/helpers'

export const SubscriptionFlowInfoModal = ({ activator }: { activator: DialogActivator }) => (
  <Modal
    primaryAction={{ content: 'Close', onAction: activator.close }}
    sectioned
    title="Important information about workflow rules"
    open={activator.open}
    onClose={activator.close}
  >
    <Modal.Section>
      <VerticalStack gap="4">
        <Text as="p">
          Hello! As you set up a new workflow rule, it's important to understand its timing in
          relation to order payments.
        </Text>
        <Text as="p">
          <Text as="span" fontWeight="bold">
            Workflow Execution:
          </Text>{' '}
          Our system triggers workflow rules{' '}
          <Text as="span" fontWeight="bold">
            only
          </Text>{' '}
          after an order is paid. Customers who match the new workflow criteria but have already
          completed payment at the time of the workflow's implementation will not be retroactively
          adjusted by the system. To apply the new rule to such orders, manual adjustments are
          necessary.
        </Text>
        <Text as="p">
          <Text as="span" fontWeight="bold">
            Example:
          </Text>{' '}
          If you implement a rule today to offer a bonus on a customer's 5th order, it will not
          affect customers who have already paid for their 4th order. These customers, who meet the
          rule criteria but have completed payment prior to the rule's setup, will not automatically
          receive the bonus on their 5th order.
        </Text>
      </VerticalStack>
    </Modal.Section>
  </Modal>
)
