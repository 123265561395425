import {
  Banner,
  Button,
  Checkbox,
  FormLayout,
  HorizontalStack,
  LegacyCard,
  Text,
  TextField,
  VerticalStack,
} from '@shopify/polaris'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { ApiActionDialog } from '../common/ApiActionDialog'
import {
  ExternalRedirect,
  InternalLink,
  ShowIntercomLink,
  useDialogActivator,
  useFormikHandleChangeCallback,
  useShopFlag,
  useShopPermission,
} from '../common/helpers'
import { FeatureAccessFlag } from '../common/SystemData'
import * as urls from '../common/urls'
import {
  useKlaviyoAuthorizationStatus,
  useKlaviyoBulkProfileImportStatus,
  UserSettings,
} from './api'
import { KlaviyoBulkProfileImportDialog } from './KlaviyoBulkProfileImportDialog'

const KlaviyoBulkImport = () => {
  const bulkProfileImportActivator = useDialogActivator()
  const [importStatusPolling, setImportStatusPolling] = useState(false)

  const { data: importStatus, mutate: mutateImportStatus } =
    useKlaviyoBulkProfileImportStatus(importStatusPolling)

  const isImportInProgress = !!importStatus && ['InProgress', 'Dispatching'].includes(importStatus)

  useEffect(() => {
    setImportStatusPolling(isImportInProgress)
  }, [setImportStatusPolling, isImportInProgress])

  if (importStatus === undefined) {
    return null
  }

  return (
    <>
      <HorizontalStack blockAlign="center" gap="3">
        {!isImportInProgress && (
          <Button onClick={bulkProfileImportActivator.show}>Import profiles</Button>
        )}
        <Text as="span" color="subdued">
          {importStatus && !isImportInProgress && <>Profile import completed.</>}
          {isImportInProgress && <>Profile import in progress...</>}
        </Text>
      </HorizontalStack>
      <KlaviyoBulkProfileImportDialog
        activator={bulkProfileImportActivator}
        onStart={() => mutateImportStatus('InProgress')}
      />
    </>
  )
}

export const KlaviyoCard = ({ userSettings }: { userSettings: UserSettings }) => {
  const klaviyoIntegrationAccess = useShopFlag('klaviyo_integration') as FeatureAccessFlag
  const klaviyoAllowed = useShopPermission('klaviyoIntegrationEnabled')
  const {
    data: klaviyoAuthorizationStatus,
    error: klaviyotAuthorizationError,
    mutate: mutateAuthorizationStatus,
  } = useKlaviyoAuthorizationStatus()
  const [klaviyoAuthorizationUrl, setKlaviyoAuthorizationUrl] = useState<string | null>(null)

  const authorizeKlaviyoAction = useApiActionState({
    method: 'GET',
    url: '/klaviyo/authorization-url',
    onSuccess: (res) => {
      setKlaviyoAuthorizationUrl(res.data.url)
    },
  })

  const formik = useFormikContext<UserSettings>()
  const klaviyoDisconnectDialogActivator = useDialogActivator()

  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)

  const sendTestAction = useApiActionState({
    url: '/shop/send-klaviyo-test-event',
    opts: {
      body: JSON.stringify({
        api_key: klaviyoAuthorizationStatus?.isAuthorized ? '' : formik.values.klaviyo_api_key,
      }),
    },
    bannerPosition: 'external',
  })

  const legacyKlaviyoEnabledAndSettingsUnchanged =
    formik.initialValues.klaviyo_api_key &&
    formik.initialValues.klaviyo_api_key === formik.values.klaviyo_api_key &&
    formik.initialValues.klaviyo_enabled &&
    formik.initialValues.klaviyo_enabled === formik.values.klaviyo_enabled

  if (klaviyoIntegrationAccess === 'disabled') {
    return <></>
  }

  if (klaviyoIntegrationAccess === 'by-plan' && !klaviyoAllowed) {
    return (
      <LegacyCard sectioned>
        <Banner status="warning">
          <Text as="p">
            Please{' '}
            <InternalLink url={urls.pricingPlansUrl()} target={'_blank'}>
              upgrade
            </InternalLink>{' '}
            to our <Text as="strong">Traction Plan</Text> to access enhanced email and SMS marketing
            tools through our Klaviyo integration, enabling personalized customer communication
            based on their subscription behaviors.
          </Text>
        </Banner>
      </LegacyCard>
    )
  }

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <ApiActionBanner state={sendTestAction} />
        <ApiAction state={sendTestAction} />

        {userSettings.klaviyo_enabled && (
          <>
            <FormLayout>
              <Checkbox
                id="klaviyo_enabled"
                name="klaviyo_enabled"
                label="Enable Klaviyo integration"
                checked={formik.values.klaviyo_enabled}
                onChange={handleChange}
              />
              <TextField
                id="klaviyo_api_key"
                name="klaviyo_api_key"
                label="Klaviyo private API Key"
                value={formik.values.klaviyo_api_key}
                onChange={handleChange}
                disabled={!formik.values.klaviyo_enabled}
                autoComplete="off"
                connectedRight={
                  <Button
                    loading={sendTestAction.loading}
                    onClick={sendTestAction.run}
                    disabled={
                      formik.values.klaviyo_api_key === '' || !formik.values.klaviyo_enabled
                    }
                  >
                    Send test event
                  </Button>
                }
              />
            </FormLayout>
          </>
        )}
        {!userSettings.klaviyo_enabled && (
          <VerticalStack gap="4" inlineAlign="start">
            {klaviyoAuthorizationUrl && <ExternalRedirect url={klaviyoAuthorizationUrl} active />}
            {klaviyotAuthorizationError && (
              <Banner status="critical">
                <Text as="p">
                  There was an error while getting the Klaviyo authorization status. Please refresh
                  this page or try to{' '}
                  <Button onClick={authorizeKlaviyoAction.run} plain>
                    reconnect your Klaviyo account
                  </Button>
                  . Please don't hesitate to <ShowIntercomLink>contact us</ShowIntercomLink> if this
                  error persists.
                </Text>
              </Banner>
            )}
            {klaviyoAuthorizationStatus?.isAuthorized && (
              <>
                <Text as="p">
                  Connected to{' '}
                  <Text as="strong">{klaviyoAuthorizationStatus?.organizationName}</Text> Klaviyo
                  account (
                  <Button onClick={klaviyoDisconnectDialogActivator.show} plain destructive>
                    disconnect
                  </Button>
                  )
                </Text>
                <Button loading={sendTestAction.loading} onClick={sendTestAction.run}>
                  Send test event
                </Button>
              </>
            )}
            {klaviyoAuthorizationStatus?.isAuthorized === false && (
              <>
                <Button onClick={authorizeKlaviyoAction.run} primary>
                  Connect Klaviyo account
                </Button>
              </>
            )}
          </VerticalStack>
        )}
      </LegacyCard.Section>
      {(legacyKlaviyoEnabledAndSettingsUnchanged || klaviyoAuthorizationStatus?.isAuthorized) && (
        <LegacyCard.Section>
          <KlaviyoBulkImport />
        </LegacyCard.Section>
      )}
      <ApiActionDialog
        activator={klaviyoDisconnectDialogActivator}
        url="/klaviyo/revoke"
        onSuccess={() => {
          mutateAuthorizationStatus()
        }}
        method="POST"
        title="Disconnect Klaviyo account"
        message="Are you sure you want to disconnect your Klaviyo account?"
        destructive
        primaryButtonTitle="Disconnect"
        cancelButtonTitle="Cancel"
      />
    </LegacyCard>
  )
}
