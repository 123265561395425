import { Button, ButtonGroup, LegacyCard, LegacyStack, Link, ProgressBar } from '@shopify/polaris'

import { ApiAction, ApiActionBanner, useApiActionState } from './ApiAction'
import { ApiActionDialog } from './ApiActionDialog'
import { BulkAction } from './bulk-actions'
import { useDialogActivator } from './helpers'

const BulkActionCard = ({ blka, mutate }: { blka: BulkAction; mutate: () => any }) => {
  const resumeActivator = useDialogActivator()
  const cancelActivator = useDialogActivator()
  const reportActivator = useDialogActivator()

  const pauseActionState = useApiActionState({
    method: 'POST',
    url: `/bulk-actions/${blka.id}/pause`,
    onSuccess: mutate,
    bannerPosition: 'external',
  })

  return (
    <>
      <LegacyCard sectioned title="Bulk action">
        <ApiActionBanner state={pauseActionState} />
        <LegacyStack vertical>
          <p>
            A bulk action is in progress, you can close this page while it's running. When it
            finishes, we'll send you a <Link onClick={reportActivator.show}>CSV report</Link>.
          </p>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              <ProgressBar progress={(blka.lines_finished * 100) / blka.lines_total} />
            </LegacyStack.Item>
            <span>
              {blka.lines_finished} / {blka.lines_total}
            </span>
          </LegacyStack>
          <ButtonGroup>
            {blka.status === 'Active' && (
              <Button onClick={pauseActionState.run} loading={pauseActionState.loading}>
                Pause
              </Button>
            )}
            {blka.status === 'Paused' && <Button onClick={resumeActivator.show}>Resume</Button>}
            {(blka.status === 'Active' || blka.status === 'Paused') && (
              <Button onClick={cancelActivator.show} destructive plain>
                Cancel
              </Button>
            )}
          </ButtonGroup>
        </LegacyStack>
      </LegacyCard>
      <br />
      <ApiActionDialog
        activator={resumeActivator}
        url={`/bulk-actions/${blka.id}/resume`}
        method="POST"
        message="Are you sure to resume the bulk action?"
        primaryButtonTitle="Resume bulk action"
        cancelButtonTitle="Keep paused"
        onSuccess={mutate}
      />
      <ApiActionDialog
        activator={cancelActivator}
        url={`/bulk-actions/${blka.id}/cancel`}
        method="POST"
        message="Are you sure to cancel the bulk action?"
        primaryButtonTitle="Cancel bulk action"
        cancelButtonTitle={`Keep ${blka.status === 'Active' ? 'running' : 'paused'}`}
        onSuccess={mutate}
        destructive
      />
      <ApiActionDialog
        activator={reportActivator}
        url={`/bulk-actions/${blka.id}/send-report`}
        method="POST"
        title="Bulk action report"
        message="The report contains a detailed list of the action results on all selected subscriptions"
        primaryButtonTitle="Send now"
        cancelButtonTitle="Close"
      />
      <ApiAction state={pauseActionState} />
    </>
  )
}

export const BulkActionCards = ({ blkas, mutate }: { blkas: BulkAction[]; mutate: () => any }) => (
  <>
    {blkas.map((blka) => (
      <BulkActionCard blka={blka} mutate={mutate} key={blka.id} />
    ))}
  </>
)
