import { LegacyStack, Text, VerticalStack } from '@shopify/polaris'
import { useContext } from 'react'
import useSWR from 'swr'

import { backendFetchResultData } from '../common/api'
import { SettingsContext } from '../settings-page/api'
import { BasicStatCard } from './BasicStats'

interface AverageLengthResult {
  avg: number | null
  count: number
}

interface AverageLengthStatsResponse {
  averageLength: AverageLengthResult
  averageLengthForChurn: AverageLengthResult
}

const fetchAverageLengthStats = (_: any, query: string) =>
  backendFetchResultData<AverageLengthStatsResponse>(
    'GET',
    `/dashboard/average-length-stats?${query}`
  )

export const AverageLengthReport = ({
  filterQuery,
  filterLabel,
}: {
  filterQuery: string
  filterLabel: string
}) => {
  const userSettings = useContext(SettingsContext)

  const { data: averageLengthReportData } = useSWR<AverageLengthStatsResponse>(
    ['average-length-stats', filterQuery, userSettings?.analytics_start_date],
    fetchAverageLengthStats,
    {
      revalidateOnFocus: false,
    }
  )

  const loading = !averageLengthReportData

  return (
    <LegacyStack distribution="fillEvenly">
      <BasicStatCard
        title="Average subscription length"
        filterLabel={filterLabel}
        tooltip="The average duration of all subscriptions. This metric includes the period from activation (creation) to the next planned payment date for active and paused subscriptions. For completed subscriptions (cancelled, expired, failed), it includes the period from activation to the end of the last billing cycle."
        loading={loading}
      >
        {averageLengthReportData && (
          <>
            {averageLengthReportData.averageLength.avg && (
              <VerticalStack gap="1">
                {averageLengthReportData.averageLength.avg} months
                <Text as="p" color="subdued" variant="bodyMd">
                  Based on <Text as="strong">{averageLengthReportData.averageLength.count}</Text>{' '}
                  subscriptions.
                </Text>
              </VerticalStack>
            )}
            {!averageLengthReportData.averageLength.avg && (
              <Text color="subdued" as="p">
                &ndash;
              </Text>
            )}
          </>
        )}
      </BasicStatCard>
      <BasicStatCard
        title="Average completed subscription length"
        filterLabel={filterLabel}
        tooltip="The average duration of subscriptions that have ended, including cancelled, expired, and failed subscriptions. This metric calculates the period from activation to the end of the last billing cycle, not necessarily the date when the subscription was cancelled or expired."
        loading={loading}
      >
        {averageLengthReportData && (
          <>
            {averageLengthReportData.averageLengthForChurn.avg && (
              <VerticalStack gap="1">
                {averageLengthReportData.averageLengthForChurn.avg} months
                <Text as="p" color="subdued" variant="bodyMd">
                  Based on{' '}
                  <Text as="strong">{averageLengthReportData.averageLengthForChurn.count}</Text>{' '}
                  subscriptions.
                </Text>
              </VerticalStack>
            )}
            {!averageLengthReportData.averageLengthForChurn.avg && (
              <Text color="subdued" as="p">
                &ndash;
              </Text>
            )}
          </>
        )}
      </BasicStatCard>
    </LegacyStack>
  )
}
