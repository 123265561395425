import {
  Banner,
  Button,
  HorizontalStack,
  Icon,
  InlineError,
  LegacyStack,
  RadioButton,
  Text,
  VerticalStack,
} from '@shopify/polaris'
import { InfoMinor } from '@shopify/polaris-icons'
import React, { useCallback } from 'react'

import { gidToId, MemoedTextField, useDialogActivator } from '../common/helpers'
import { LegacyHelpLabel } from '../common/HelpIcon'
import { useShopCurrency, useShopCurrencySymbol } from '../common/Price'
import { ProductVariantModal, ProductVariantResult } from '../common/ProductVariantModal'
import {
  WorkflowAction,
  workflowActionLabels,
  WorkflowAddProductDataType,
  WorkflowProductDataType,
} from '../common/workflow-models'
import { ProductBadge } from './WorkflowDescription'

export const AddProductAction = ({
  params,
  onChange,
  index,
  productError,
  currentPriceError,
  quantityError,
}: {
  params: WorkflowAddProductDataType
  onChange: (action: WorkflowAction, index: number) => any
  index: number
  productError: string | null
  currentPriceError: string | null
  quantityError: string | null
}) => {
  const currencySymbol = useShopCurrencySymbol()
  const shopCurrency = useShopCurrency()
  const productModalActivator = useDialogActivator()

  const [productData, quantity, currentPrice, recurringCycleLimit] = [
    (params[0] ?? []) as WorkflowProductDataType,
    params[1] ?? 1,
    params[2] ?? '0.00',
    params[3] === undefined ? null : params[3],
  ]

  const handleUpdate = useCallback(
    (params: WorkflowAddProductDataType) => {
      onChange(['add-product', params], index)
    },
    [index, onChange]
  )

  return (
    <LegacyStack vertical>
      <Text variant="headingSm" as="h3">
        {workflowActionLabels['add-product']}
      </Text>

      {productData.length === 0 && (
        <Text as="span" color="subdued">
          No variant selected
        </Text>
      )}

      {productData.length > 0 && (
        <VerticalStack gap="1" inlineAlign="start">
          <ProductBadge productData={productData} status="success" />
          <HorizontalStack gap="1" wrap={false} align="start" blockAlign="start">
            <Icon source={InfoMinor} color="warning" />
            <Text as="p" color="warning">
              To prevent duplicate entries, this workflow action will NOT add this variant to
              subscription if it has already been added by any workflow.
            </Text>
          </HorizontalStack>
        </VerticalStack>
      )}

      <Button onClick={productModalActivator.show} size="slim">
        Select product variant
      </Button>
      {productError && <InlineError message={productError} fieldID="" />}

      <ProductVariantModal
        currency={shopCurrency}
        open={productModalActivator.open}
        onClose={productModalActivator.close}
        onSelect={(selected: ProductVariantResult) => {
          handleUpdate([
            [gidToId(selected.id), selected.title],
            quantity,
            selected.price,
            recurringCycleLimit,
          ])
          productModalActivator.close()
        }}
      />

      <MemoedTextField
        label={
          <LegacyHelpLabel title="Unit price">
            For pre-paid subscriptions, you need to manually multiply it by the amount of pre-paid
            deliveries.
          </LegacyHelpLabel>
        }
        type="number"
        prefix={currencySymbol}
        autoComplete="off"
        value={currentPrice}
        min="0"
        step={0.01}
        onChange={(val: string) => handleUpdate([productData, quantity, val, recurringCycleLimit])}
        error={currentPriceError ?? undefined}
        placeholder="0.00"
      />

      {parseFloat(currentPrice) !== 0.0 && !isNaN(parseFloat(currentPrice)) && (
        <Banner status="warning">
          <Text as="p" color="warning">
            This product will be added only to subscriptions using native shop currency
          </Text>
        </Banner>
      )}

      <MemoedTextField
        label="Quantity"
        type="number"
        autoComplete="off"
        value={quantity.toString()}
        min="1"
        step={1}
        onChange={(val: string) =>
          handleUpdate([productData, parseInt(val), currentPrice, recurringCycleLimit])
        }
        error={quantityError ?? undefined}
      />

      <LegacyStack vertical spacing="extraTight">
        <RadioButton
          label="Applies to this order only (removed automatically)"
          checked={recurringCycleLimit === 1}
          name={`recurringCycleLimit-${index}`}
          onChange={() => handleUpdate([productData, quantity, currentPrice, 1])}
        />
        <RadioButton
          label="Applies to this and all upcoming orders"
          checked={recurringCycleLimit === null}
          name={`recurringCycleLimit-${index}`}
          onChange={() => handleUpdate([productData, quantity, currentPrice, null])}
        />
      </LegacyStack>
    </LegacyStack>
  )
}
