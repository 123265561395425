import { Button, ButtonGroup, LegacyCard, LegacyStack, ProgressBar } from '@shopify/polaris'
import React from 'react'
import { mutate } from 'swr'

import { ApiActionDialog } from '../common/ApiActionDialog'
import { useDialogActivator } from '../common/helpers'
import { BulkAction } from './api'

export const BulkActionCard = ({
  spgRestId,
  bulkAction,
}: {
  spgRestId: string
  bulkAction: BulkAction
}) => {
  const swrKey = ['products-page-data', spgRestId]
  const bulkCancelModalActivator = useDialogActivator()

  return (
    <>
      <LegacyCard sectioned title="Bulk action">
        <LegacyStack vertical>
          <p>
            A bulk action is in progress. All products
            {bulkAction.collectionTitle
              ? ` from collection "${bulkAction.collectionTitle}" `
              : ' in your store '}
            are being
            {bulkAction.type === 'attach' ? ' added to ' : ' removed from '}
            this selling plan group...
          </p>
          <p>
            <LegacyStack alignment="center">
              <LegacyStack.Item fill>
                <ProgressBar progress={(bulkAction.processedCount * 100) / bulkAction.totalCount} />
              </LegacyStack.Item>
              <span>
                {bulkAction.processedCount} / {bulkAction.totalCount}
              </span>
            </LegacyStack>
          </p>
          <ButtonGroup>
            <Button onClick={bulkCancelModalActivator.show} destructive>
              Cancel
            </Button>
          </ButtonGroup>
        </LegacyStack>
      </LegacyCard>
      <ApiActionDialog
        activator={bulkCancelModalActivator}
        onClose={() => {
          mutate(swrKey)
        }}
        url={`/plans/${spgRestId}/cancel-bulk-action`}
        method="POST"
        message="Are you sure to cancel current bulk action?"
        primaryButtonTitle="Cancel bulk action"
        cancelButtonTitle="Keep running"
        destructive
      />
    </>
  )
}
