import useSWR, { mutate } from 'swr'

import { backendFetchResult, backendFetchResultData, Result } from '../common/api'

export interface MailSettings {
  subject: string
  html: string
}

export const useMailSettings = (mailType: string) =>
  useSWR<MailSettings>(['mail-settings', mailType], () =>
    backendFetchResultData<MailSettings>('GET', `/settings/mail-settings/${mailType}`)
  )

export const useMailSample = (mailType: string) =>
  useSWR<MailSettings>(['mail-sample', mailType], () =>
    backendFetchResultData<MailSettings>('GET', `/settings/mail-settings/${mailType}/sample`)
  )

export const putMailSettings = async (
  mailType: string,
  settings: MailSettings
): Promise<Result<MailSettings>> => {
  const body = JSON.stringify({ settings })
  const res = await backendFetchResult<MailSettings>('PUT', `/settings/mail-settings/${mailType}`, {
    body,
  })

  if (res.status === 'success') {
    mutate(['mail-settings', mailType], res.data)
    mutate(['mail-sample', mailType])
  }

  return res
}
