import { NavMenu } from '@shopify/app-bridge-react'
import { Tabs as PolarisTabs } from '@shopify/polaris'
import { Link } from 'react-router-dom'

import { anyWindow, useSystemData } from './helpers'
import { MaintenanceBanner } from './MaintenanceBanner'
import { PricingPlanBanner } from './PricingPlanBanner'
import { SellingPlanPageWarningBanner } from './SellingPlanPageWarningBanner'
import * as urls from './urls'

export enum TabIndex {
  Dashboard,
  GettingStarted,
  Plans,
  Subscriptions,
  Workflows,
  Settings,
}

const tabs = [
  { id: 'home', content: 'PayWhirl', url: '/' },
  { id: 'dashboard', content: 'Dashboard', url: urls.dashboardUrl() },
  { id: 'getting-started', content: 'Getting Started', url: urls.gettingStartedUrl },
  { id: 'plans', content: 'Plans', url: urls.plansUrl },
  { id: 'subscriptions', content: 'Subscriptions', url: urls.subscriptionsUrl() },
  { id: 'workflows', content: 'Workflows', url: urls.workflowsUrl },
  { id: 'settings', content: 'Settings', url: urls.settingsUrl() },
]

export const Tabs = ({ selected }: { selected: TabIndex }) => {
  return (
    <>
      {anyWindow.adminMode && (
        <PolarisTabs tabs={tabs.filter(({ id }) => id !== 'home')} selected={selected} />
      )}
      <MaintenanceBanner />
      <PricingPlanBanner />
      {tabs[selected + 1].id === 'plans' && <SellingPlanPageWarningBanner />}
    </>
  )
}

export const NativeNavigationMenu = () => {
  const systemData = useSystemData()
  const hasSubscriptions = systemData?.flags.has_subscriptions ?? false

  return (
    <NavMenu>
      {tabs
        .filter(({ id }) => id !== (hasSubscriptions ? 'dashboard' : 'getting-started'))
        .map(({ id, content, url }, index) => (
          <Link key={`nav-${id}`} to={url} rel={id === 'home' ? id : ''}>
            {content}
          </Link>
        ))}
    </NavMenu>
  )
}
