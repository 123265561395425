type LabelFormatter = (val: any) => string

const defaultYAxisFormatter: LabelFormatter = (val: any) => Number(val).toFixed(0)

export const buildChartOptions = (
  data: number[],
  categories: string[],
  yAxisFormatter: LabelFormatter = defaultYAxisFormatter,
  fullWidth?: boolean
): ApexCharts.ApexOptions => {
  const maxY = Math.max(...data)

  return {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#00a19f'],
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    title: {
      text: '',
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      strokeDashArray: 4,
      borderColor: '#DFE3E8',
      xaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 10,
      },
    },
    markers: {
      radius: 4,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      horizontalAlign: 'right',
      onItemClick: {
        toggleDataSeries: false,
      },
      markers: {
        radius: 4,
      },
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      y: {
        title: {
          formatter: (seriesName: string): string => '',
        },
      },
    },
    xaxis: {
      tickAmount: fullWidth ? undefined : 3,
      tooltip: {
        enabled: false,
      },
      crosshairs: {
        width: 6,
        opacity: 0.2,
        stroke: {
          width: 0,
        },
      },
      labels: {
        rotate: 0,
        style: {
          colors: ['#fff', '#637381'],
        },
      },
      categories,
    },
    yaxis: {
      title: {
        text: '',
      },
      tickAmount: 5,
      max: maxY < 5 ? 5 : maxY + 1,
      min: 0,
      labels: {
        formatter: yAxisFormatter,
        style: {
          colors: '#637381',
        },
      },
    },
  }
}
