import useSWR from 'swr'

import { backendFetchResultData } from './api'

export interface Collection {
  id: string
  title: string
  productsCount: number
}

export const fetchCollections = async (_: string): Promise<Collection[]> =>
  await backendFetchResultData('GET', '/products/collections')

export const useCollectionsSWR = () =>
  useSWR<Collection[]>('collections', fetchCollections, {
    revalidateOnFocus: false,
  })

export const useCollections = () => useCollectionsSWR().data ?? []
