import { AppliedFilterInterface, FilterInterface } from '@shopify/polaris'
import { useCallback } from 'react'

import { gidToNumericId, idToGid, pwCache } from '../../common/helpers'
import { SellingPlanDescriptor, SellingPlanField } from '../../common/SellingPlanField'
import { SubscriptionFilter } from '../api'

const formatSellingPlanId = (id?: number): string | undefined => {
  if (!id) {
    return undefined
  }

  const name = pwCache.sellingPlanNames[id]

  return name ? `${name} (#${id})` : `#${id}`
}

export const useAddSellingPlanIdFilter = (
  filter: SubscriptionFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any,
  filters: FilterInterface[],
  appliedFilters: AppliedFilterInterface[]
) => {
  const onRemove = useCallback(() => updateFilter({ selling_plan_id: undefined }), [updateFilter])

  const onSelect = useCallback(
    (plan: SellingPlanDescriptor) => {
      const selling_plan_id = plan.id ? gidToNumericId(plan.id) : undefined

      if (selling_plan_id && plan.name !== null) {
        pwCache.sellingPlanNames[selling_plan_id] = plan.name
      }

      updateFilter({ selling_plan_id })
    },
    [updateFilter]
  )

  const formattedSellingPlan = formatSellingPlanId(filter.selling_plan_id)

  filters.push({
    key: 'selling_plan_id',
    label: 'Selling plan',
    filter: (
      <>
        <SellingPlanField
          labelHidden
          label=""
          emptyLabel="Any"
          onChange={onSelect}
          plan={{
            id: filter.selling_plan_id ? idToGid(filter.selling_plan_id, 'SellingPlan') : null,
            name: formattedSellingPlan ?? '',
          }}
        />
      </>
    ),
  })

  if (formattedSellingPlan) {
    appliedFilters.push({
      key: 'selling_plan_id',
      label: `Selling plan: ${formattedSellingPlan}`,
      onRemove: onRemove,
    })
  }
}
