import { Filters, FormLayout, LegacyCard, LegacyStack, Tabs, Text } from '@shopify/polaris'
import { useFormikContext } from 'formik'
import { useCallback, useState } from 'react'

import { capitalizeHyphens, MemoedTextField, useDebounced } from '../common/helpers'
import { filterDefaults, translationGroups, translationItems, TranslationSet } from './api'

interface Props {
  language: string
  defaults: TranslationSet
}

const TranslationItemFields = ({
  language,
  group,
  defaults,
  handleChange,
}: {
  language: string
  group: string
  defaults: TranslationSet
  handleChange: (value: any, id: string) => any
}) => {
  const formik = useFormikContext<TranslationSet>()

  return (
    <LegacyStack vertical spacing="loose">
      {group === 'plan-selector' && (
        <p>
          <Text as="span" color="subdued">
            The following translations only apply to the plan selector widget installed as an App
            Block. Note, you can use HTML tags in them, for example to include links.
          </Text>
        </p>
      )}
      <FormLayout>
        {translationItems(language, group, defaults).map((item, j) => (
          <MemoedTextField
            key={j}
            id={item.key}
            name={item.key}
            label={item.label}
            type="text"
            placeholder={item.placeholder}
            value={formik.values[item.key] || ''}
            onChange={handleChange}
            autoComplete="off"
            multiline
          />
        ))}
      </FormLayout>
    </LegacyStack>
  )
}

export const TranslationGroupCards = (props: Props) => {
  const formik = useFormikContext<TranslationSet>()
  const handleChange = useCallback(
    (value: any, id: string) => formik.setFieldValue(id, value || undefined),
    [formik]
  )

  const [filter, setFilter] = useState('')
  const debouncedFilter = useDebounced(filter, 500)

  const handleFilterUpdate = (val: string) => {
    if (val !== '') {
      setSelectedTab(0)
    }
    setFilter(val)
  }

  const filteredDefaults =
    debouncedFilter !== '' ? filterDefaults(props.defaults, debouncedFilter) : props.defaults
  const filteredGroup = translationGroups(filteredDefaults)

  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabSelect = useCallback(
    (tabIndex: number) => {
      if (filter !== '' && tabIndex > 0) {
        setFilter('')
        tabIndex = tabIndex - 1
      }
      setSelectedTab(tabIndex)
    },
    [filter]
  )

  const tabs = (
    filter !== ''
      ? [
          {
            id: 'search-results',
            content: 'Search results',
            accessibilityLabel: 'Search results',
            panelId: 'search-results',
          },
        ]
      : []
  ).concat(
    translationGroups(props.defaults).map((group, i) => ({
      id: group,
      content: capitalizeHyphens(group),
      accessibilityLabel: capitalizeHyphens(group),
      panelId: group,
    }))
  )

  const selectedPanel = tabs[selectedTab].panelId

  return (
    <LegacyCard sectioned>
      <Filters
        queryValue={filter}
        filters={[]}
        appliedFilters={[]}
        onQueryChange={handleFilterUpdate}
        onQueryClear={() => setFilter('')}
        onClearAll={() => setFilter('')}
      />
      <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabSelect}>
        <LegacyCard.Section>
          {selectedPanel !== 'search-results' && (
            <TranslationItemFields
              language={props.language}
              group={selectedPanel}
              defaults={props.defaults}
              handleChange={handleChange}
            />
          )}

          {selectedPanel === 'search-results' && Object.keys(filteredDefaults).length === 0 && (
            <Text variant="headingMd" as="h2">
              No translations found
            </Text>
          )}

          {selectedPanel === 'search-results' && Object.keys(filteredDefaults).length > 0 && (
            <LegacyStack vertical spacing="extraLoose">
              {filteredGroup.map((group) => (
                <LegacyStack key={group} vertical>
                  <Text variant="headingMd" as="h2">
                    {capitalizeHyphens(group)}
                  </Text>
                  <TranslationItemFields
                    language={props.language}
                    group={group}
                    defaults={filteredDefaults}
                    handleChange={handleChange}
                  />
                </LegacyStack>
              ))}
            </LegacyStack>
          )}
        </LegacyCard.Section>
      </Tabs>
    </LegacyCard>
  )
}
