import { HorizontalStack, LegacyStack, Text, VerticalStack } from '@shopify/polaris'

import { InternalLink, queryString } from '../common/helpers'
import * as urls from '../common/urls'
import { UpgradeToAccessCard } from '../settings-page/UpgradeToAccessCard'
import { useAdvancedDashboardFilters } from './advanced-filters'
import { AdvancedDasbordFilterSelectors } from './AdvancedDasbordFilterSelectors'
import { AdvancedDashboardFilterLabels } from './AdvancedDashboardFilterLabels'
import { AverageLengthReport } from './AverageLengthReport'
import { CustomerValueAndChurnReport } from './CustomerValueAndChurnReport'
import { RetentionCohortReport } from './RetentionCohortReport'

export const CustomerRententionAndValueStatsPlaceholder = () => {
  const advancedFilters = useAdvancedDashboardFilters()

  return (
    <VerticalStack gap="4">
      <LegacyStack alignment="baseline">
        <LegacyStack.Item fill>
          <Text variant="headingMd" as="h1">
            Customer retention and value
          </Text>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <HorizontalStack gap="2">
            <AdvancedDasbordFilterSelectors filter={advancedFilters} disabled={true} />
          </HorizontalStack>
        </LegacyStack.Item>
      </LegacyStack>
      <UpgradeToAccessCard>
        <Text as="p">
          Unlock additional stats and filters by{' '}
          <InternalLink url={urls.pricingPlansUrl()} target={'_blank'}>
            upgrading
          </InternalLink>{' '}
          to our <Text as="strong">Traction Plan</Text> or higher.
        </Text>
      </UpgradeToAccessCard>
      <img
        style={{ width: '100%' }}
        src="/images/enhanced_reports_placeholder.png"
        alt="Customer retention and value"
      />
    </VerticalStack>
  )
}

export const CustomerRetentionAndValueStats = () => {
  const advancedFilters = useAdvancedDashboardFilters()
  const filterQueryString = queryString(advancedFilters.filterParams)

  return (
    <LegacyStack vertical wrap={false}>
      <LegacyStack alignment="baseline">
        <LegacyStack.Item fill>
          <Text variant="headingMd" as="h1">
            Customer retention and value
          </Text>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <HorizontalStack gap="2">
            <AdvancedDasbordFilterSelectors filter={advancedFilters} />
          </HorizontalStack>
        </LegacyStack.Item>
      </LegacyStack>
      {advancedFilters.isFiltered && <AdvancedDashboardFilterLabels filter={advancedFilters} />}
      <AverageLengthReport
        filterQuery={filterQueryString}
        filterLabel={advancedFilters.isFiltered ? 'Filtered' : ''}
      />
      <CustomerValueAndChurnReport
        filterLabel={advancedFilters.isFiltered ? 'Filtered' : ''}
        filterQuery={filterQueryString}
      />
      <RetentionCohortReport
        filterLabel={advancedFilters.isFiltered ? 'Filtered' : ''}
        filterQuery={filterQueryString}
      />
    </LegacyStack>
  )
}
