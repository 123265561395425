import {
  ActionList,
  Button,
  ButtonGroup,
  LegacyCard,
  Popover,
  TextContainer,
} from '@shopify/polaris'
import { DateStyle } from '@shopify/react-i18n'
import { useContext, useState } from 'react'

import { ApiActionDialog } from '../common/ApiActionDialog'
import { DetailsRow } from '../common/DetailsRow'
import { ExternalRedirect, FormattedDate, useDialogActivator, useToggle } from '../common/helpers'
import { CentPrice } from '../common/Price'
import { SystemContext, SystemPricingPlanData } from '../common/SystemData'
import * as urls from '../common/urls'
import { PricingPlanCapDialog } from './PricingPlanCapDialog'

const PricingPlanDetails = ({ plan }: { plan: SystemPricingPlanData }) => {
  return (
    <>
      <DetailsRow label="Name">{plan.name}</DetailsRow>
      <DetailsRow label="Started">
        <FormattedDate
          date={new Date(plan.createdAt)}
          format={{ style: DateStyle.Short, hour: 'numeric', minute: 'numeric' }}
          titleFormat={{ style: DateStyle.Long, hour: 'numeric', minute: 'numeric' }}
        />
      </DetailsRow>
      <DetailsRow label="Monthly price">
        <CentPrice cents={plan.price} />
      </DetailsRow>
      <DetailsRow label="Transaction fee">{plan.formattedFee}</DetailsRow>
      {plan.totalRevenue && (
        <DetailsRow label="Total processing">
          <CentPrice cents={plan.totalRevenue} />
        </DetailsRow>
      )}
      <DetailsRow label="Usage in current period">
        <CentPrice cents={plan.balanceUsed} /> of <CentPrice cents={plan.cappedAmount} /> (
        {Math.round((plan.balanceUsed * 100) / plan.cappedAmount)}%)
      </DetailsRow>
      <DetailsRow label="End of current period" last>
        <FormattedDate
          date={new Date(plan.currentPeriodEnd)}
          format={{ style: DateStyle.Short, hour: 'numeric', minute: 'numeric' }}
          titleFormat={{ style: DateStyle.Long, hour: 'numeric', minute: 'numeric' }}
        />
      </DetailsRow>
    </>
  )
}

export const PricingPlanCard = () => {
  const systemData = useContext(SystemContext)
  const plan = systemData?.pricingPlan
  const cancelDialogActivator = useDialogActivator()
  const [updateCapDialogVisible, setUpdateCapDialogVisible] = useState(false)
  const [redirectActive, setRedirectActive] = useState(false)
  const [popoverActive, togglePopoverActive] = useToggle(false)

  return (
    <LegacyCard>
      <LegacyCard.Section>
        {plan && <PricingPlanDetails plan={plan} />}
        {!plan && <TextContainer>You don't have an active plan.</TextContainer>}
      </LegacyCard.Section>
      <LegacyCard.Section>
        {plan && (
          <ButtonGroup>
            <Popover
              active={popoverActive}
              activator={
                <Button onClick={togglePopoverActive} disclosure>
                  Change plan
                </Button>
              }
              onClose={togglePopoverActive}
            >
              <ActionList
                actionRole="menuitem"
                items={[
                  { content: 'Select different plan...', url: urls.pricingPlansUrl() },
                  {
                    content: 'Cancel your plan',
                    onAction: cancelDialogActivator.show,
                    destructive: true,
                  },
                ]}
              />
            </Popover>
            <Button plain onClick={() => setUpdateCapDialogVisible(true)}>
              Update usage cap
            </Button>
            <ApiActionDialog
              activator={cancelDialogActivator}
              onSuccess={() => setRedirectActive(true)}
              url={`/pricing-plans/cancel`}
              title="Cancel your pricing plan?"
              message="Live subscriptions won't be processed without an active plan. Are you sure you want to cancel your current one?"
              primaryButtonTitle="Cancel plan"
              cancelButtonTitle="Keep plan"
              destructive={true}
            />

            <ExternalRedirect path="/settings" active={redirectActive} />

            <PricingPlanCapDialog
              open={updateCapDialogVisible}
              onClose={() => setUpdateCapDialogVisible(false)}
              onSuccess={() => null}
              previousCap={Math.round(plan.cappedAmount / 100)}
            />
          </ButtonGroup>
        )}
        {!plan && (
          <Button primary url={urls.pricingPlansUrl()}>
            Select plan
          </Button>
        )}
      </LegacyCard.Section>
    </LegacyCard>
  )
}
