import { FormLayout, Modal, Select, Text } from '@shopify/polaris'

import { DateTimePicker } from '../common/DateTimePicker'
import { NextAction, nextActionOptions } from '../common/subscription-models'
import { UpdateNextActionPayload } from './common'

export const UpdateNextActionSection = ({
  payload,
  setPayload,
}: {
  payload: UpdateNextActionPayload
  setPayload: (payload: UpdateNextActionPayload) => any
}) => {
  const { action, date } = payload

  return (
    <Modal.Section>
      <FormLayout>
        <Select
          label="Next scheduled action"
          options={nextActionOptions}
          value={action}
          onChange={(val: string) => setPayload({ ...payload, action: val as NextAction })}
        />

        <DateTimePicker
          label="Date"
          initial={date}
          onChange={(val: string) => setPayload({ ...payload, date: val })}
        />

        {action === 'payment' && (
          <p>
            <Text as="span" color="subdued">
              Note: Payment times will be distributed every 5 seconds to avoid exceeding Shopify
              usage limits
            </Text>
          </p>
        )}
      </FormLayout>
    </Modal.Section>
  )
}
