// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateTimePicker_wrap__sPPSk .Polaris-Label {
  margin-bottom: 0.25rem;
}

.DateTimePicker_fieldsWrap__scVud {
  display: flex;
  column-gap: 2rem;
  row-gap: 0.625rem;
  align-items: center;
  flex-wrap: wrap;
}

.DateTimePicker_dateWrap__ikbVS {
  display: flex;
  column-gap: 0.625rem;
  align-items: center;
  flex-grow: 1;
}

.DateTimePicker_dateWrap__ikbVS > :nth-child(1) {
  flex-grow: 1;
}

.DateTimePicker_dateWrap__ikbVS > :nth-child(2) {
  min-width: 70px;
}

.DateTimePicker_dateWrap__ikbVS > :nth-child(3) {
  min-width: 100px;
}

.DateTimePicker_hourWrap__JlJpk {
  display: flex;
  column-gap: 0.625rem;
  align-items: center;
  flex-grow: 1;
}

.DateTimePicker_hourWrap__JlJpk > :nth-child(1), .DateTimePicker_hourWrap__JlJpk > :nth-child(3) {
  min-width: 70px;
  flex-grow: 1;
}

`, "",{"version":3,"sources":["webpack://./src/common/DateTimePicker.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":[".wrap :global(.Polaris-Label) {\n  margin-bottom: 0.25rem;\n}\n\n.fieldsWrap {\n  display: flex;\n  column-gap: 2rem;\n  row-gap: 0.625rem;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.dateWrap {\n  display: flex;\n  column-gap: 0.625rem;\n  align-items: center;\n  flex-grow: 1;\n}\n\n.dateWrap > :nth-child(1) {\n  flex-grow: 1;\n}\n\n.dateWrap > :nth-child(2) {\n  min-width: 70px;\n}\n\n.dateWrap > :nth-child(3) {\n  min-width: 100px;\n}\n\n.hourWrap {\n  display: flex;\n  column-gap: 0.625rem;\n  align-items: center;\n  flex-grow: 1;\n}\n\n.hourWrap > :nth-child(1), .hourWrap > :nth-child(3) {\n  min-width: 70px;\n  flex-grow: 1;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `DateTimePicker_wrap__sPPSk`,
	"fieldsWrap": `DateTimePicker_fieldsWrap__scVud`,
	"dateWrap": `DateTimePicker_dateWrap__ikbVS`,
	"hourWrap": `DateTimePicker_hourWrap__JlJpk`
};
export default ___CSS_LOADER_EXPORT___;
