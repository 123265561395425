import {
  Banner,
  Button,
  ButtonGroup,
  DataTable,
  LegacyCard,
  LegacyStack,
  Modal,
  Text,
  TextField,
} from '@shopify/polaris'
import { DeleteMinor, ViewMinor } from '@shopify/polaris-icons'
import { useState } from 'react'
import useSWR, { mutate } from 'swr'

import { backendFetchResultData, Result } from '../common/api'
import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { ApiActionDialog } from '../common/ApiActionDialog'
import { CodeSnippet } from '../common/CodeSnippet'
import {
  CenteredSpinner,
  DialogActivator,
  InternalLink,
  ShortDate,
  ShowIntercomLink,
  useDialogActivator,
  useShopFlag,
  useShopPermission,
} from '../common/helpers'
import { FeatureAccessFlag } from '../common/SystemData'
import * as urls from '../common/urls'
import styles from './MerchantApiTokensCard.module.css'

interface MerchantApiToken {
  id: number
  title: string
  value: string | null
  createdAt: string
}

const useToken = (id: number) =>
  useSWR<MerchantApiToken>(['merchant-api-token', id], () =>
    backendFetchResultData('GET', `/merchant-api-tokens/${id}`)
  )

const useTokens = () =>
  useSWR<MerchantApiToken[]>(['merchant-api-tokens'], () =>
    backendFetchResultData('GET', '/merchant-api-tokens')
  )

const mutateTokens = (res: Result<MerchantApiToken[]>) =>
  res.data && mutate(['merchant-api-tokens'], res.data, { revalidate: false })

const TokenCreateModal = ({ activator }: { activator: DialogActivator }) => {
  const [title, setTitle] = useState('')

  const actionState = useApiActionState({
    method: 'POST',
    url: '/merchant-api-tokens',
    opts: { body: JSON.stringify({ title }) },
    onSuccess: (res: Result<MerchantApiToken[]>) => {
      mutateTokens(res)
      activator.close()
    },
    bannerPosition: 'external',
  })

  return (
    <>
      <Modal
        title="Create API token"
        open={activator.open}
        onClose={activator.close}
        sectioned
        primaryAction={{
          content: 'Create',
          disabled: !title,
          onAction: actionState.run,
          loading: actionState.loading,
        }}
        secondaryActions={[{ content: 'Cancel', onAction: activator.close }]}
      >
        <ApiActionBanner state={actionState} />
        <TextField
          id="title"
          name="title"
          label="Title"
          value={title}
          onChange={setTitle}
          autoComplete="off"
          maxLength={12}
          requiredIndicator={true}
        />
      </Modal>
      <ApiAction state={actionState} />
    </>
  )
}

const TokenValueModal = ({ id, onClose }: { id: number; onClose: () => any }) => {
  const { isValidating: loading, data: token } = useToken(id)

  return (
    <Modal
      title={`API token${token ? `: ${token.title}` : ''}`}
      open={true}
      onClose={onClose}
      sectioned
      secondaryActions={[{ content: 'Close', onAction: onClose }]}
    >
      {loading && <CenteredSpinner />}
      {!loading && <CodeSnippet code={token?.value ?? ''} />}
    </Modal>
  )
}

const TokenList = () => {
  const { data: tokens } = useTokens()
  const [tokenToRemove, setTokenToRemove] = useState<MerchantApiToken | null>()
  const [tokenToShow, setTokenToShow] = useState<MerchantApiToken | null>()
  const tokenRemoveActivator = useDialogActivator()

  if (tokens === undefined) {
    return <CenteredSpinner />
  }

  return (
    <>
      {tokens.length > 0 && (
        <DataTable
          headings={['Created at', 'Title', 'Actions']}
          columnContentTypes={['text', 'text', 'numeric']}
          verticalAlign="middle"
          rows={tokens.map((token) => [
            <ShortDate date={token.createdAt} />,
            <LegacyStack.Item fill>{token.title}</LegacyStack.Item>,
            <ButtonGroup segmented>
              <Button icon={ViewMinor} onClick={() => setTokenToShow(token)} />
              <Button
                icon={DeleteMinor}
                destructive
                onClick={() => {
                  setTokenToRemove(token)
                  tokenRemoveActivator.show()
                }}
              />
            </ButtonGroup>,
          ])}
        ></DataTable>
      )}
      {tokenToShow && <TokenValueModal id={tokenToShow.id} onClose={() => setTokenToShow(null)} />}
      {tokenToRemove && (
        <ApiActionDialog
          activator={tokenRemoveActivator}
          message={`Do you want to permanently remove "${tokenToRemove.title}" API token?`}
          primaryButtonTitle="Remove"
          destructive={true}
          url={`/merchant-api-tokens/${tokenToRemove.id}`}
          method="DELETE"
          onClose={() => setTokenToRemove(null)}
          onSuccess={mutateTokens}
        />
      )}
    </>
  )
}

const UpgradeToAccess = () => (
  <>
    Please{' '}
    <InternalLink url={urls.pricingPlansUrl()} target={'_blank'}>
      upgrade
    </InternalLink>{' '}
    to experience the advanced capabilities of the PayWhirl for Shopify API, available for merchants
    on our <Text as="strong">Traction Plan</Text> or higher.{' '}
  </>
)

const RequestAccessCard = ({ apiAllowed }: { apiAllowed: boolean }) => (
  <LegacyCard sectioned>
    <Banner status="warning">
      <Text as="p">
        {!apiAllowed && <UpgradeToAccess />}
        To activate your API access, kindly <ShowIntercomLink>get in touch</ShowIntercomLink> with
        the PayWhirl support team. Share your use case with us, and we'll set up your API access,
        opening up new possibilities for your e-commerce strategy.
      </Text>
    </Banner>
  </LegacyCard>
)

export const MerchantApiTokensCard = () => {
  const createActivator = useDialogActivator()
  const merchantApiAccess = useShopFlag('merchant_api_access') as FeatureAccessFlag
  const apiAllowed = useShopPermission('apiAllowed')

  if (merchantApiAccess === 'disabled') {
    return <RequestAccessCard apiAllowed={apiAllowed} />
  }

  if (merchantApiAccess === 'by-plan' && !apiAllowed) {
    return (
      <LegacyCard sectioned>
        <Banner status="warning">
          <Text as="p">
            <UpgradeToAccess />
          </Text>
        </Banner>
      </LegacyCard>
    )
  }

  return (
    <div className={styles.wrapper}>
      <LegacyCard>
        <TokenList />
        <LegacyCard.Section>
          <Button onClick={createActivator.show}>Create API token</Button>
        </LegacyCard.Section>
        <TokenCreateModal activator={createActivator} />
      </LegacyCard>
    </div>
  )
}
