import { Banner, Layout, LinkAction, Page } from '@shopify/polaris'
import { useContext } from 'react'
import { mutate } from 'swr'

import { SubscriptionContractSubscriptionStatus } from '../common/api'
import { ApiActionDialog } from '../common/ApiActionDialog'
import { Footer } from '../common/Footer'
import {
  gidToId,
  InternalLink,
  showIntercom,
  useDialogActivator,
  useShopPermission,
} from '../common/helpers'
import { SubscriptionContract } from '../common/subscription-models'
import { SystemContext } from '../common/SystemData'
import { Timeline } from '../common/Timeline'
import * as urls from '../common/urls'
import { DeliveryDetailsCard } from './DeliveryDetailsCard'
import { LinesCard } from './LinesCard'
import { NextActionCard } from './NextActionCard'
import { NotesCard } from './NotesCard'
import { OverviewCard } from './OverviewCard'
import { PaymentMethodCard } from './PaymentMethodCard'
import { PaymentsCard } from './PaymentsCard'
import { ReactivateDialog } from './ReactivateDialog'
import { ScheduleCard } from './ScheduleCard'
import { ScheduledFulfillmentsCard } from './ScheduledFulfillmentsCard'

const ManualSubscriptionsDisabledWarning = ({ sc }: { sc: SubscriptionContract }) => {
  const systemData = useContext(SystemContext)

  if (
    !systemData ||
    systemData.flags.live_subscriptions_status !== 'disabled' ||
    sc.origin_order !== null
  ) {
    return null
  }

  return (
    <Layout.Section>
      <Banner status="warning">
        <p>
          Manually created subscriptions require purchasing a plan to be processed. You can continue
          testing the app by placing test subscriptions through checkout.
        </p>
      </Banner>
    </Layout.Section>
  )
}

export const PageContent = ({ sub }: { sub: SubscriptionContract }) => {
  const backAction: LinkAction = { content: 'Subscriptions', url: urls.subscriptionsUrl() }
  const workflowsEnabled = useShopPermission('workflowsEnabled')

  const cancelDialogActivator = useDialogActivator()
  const pauseDialogActivator = useDialogActivator()
  const resumeDialogActivator = useDialogActivator()
  const activateDialogActivator = useDialogActivator()
  const reactivateDialogActivator = useDialogActivator()

  const cancelAction = {
    content: 'Cancel',
    disabled:
      sub.gql_status !== SubscriptionContractSubscriptionStatus.Active &&
      sub.gql_status !== SubscriptionContractSubscriptionStatus.Paused,
    destructive: true,
    onAction: cancelDialogActivator.show,
  }

  const reactivateAction = {
    content: 'Reactivate',
    onAction: reactivateDialogActivator.show,
  }

  let secondaryActions = [
    {
      content: 'Chat with us',
      onAction: () => showIntercom('I have a question about editing subscriptions.'),
    },
  ]

  if (sub.gql_status === SubscriptionContractSubscriptionStatus.Active) {
    secondaryActions.push({
      content: 'Pause',
      onAction: pauseDialogActivator.show,
    })
  }

  if (sub.gql_status === SubscriptionContractSubscriptionStatus.Paused && sub.activated_at) {
    secondaryActions.push({
      content: 'Resume',
      onAction: resumeDialogActivator.show,
    })
  }

  if (sub.gql_status === SubscriptionContractSubscriptionStatus.Paused && !sub.activated_at) {
    secondaryActions.push({
      content: 'Activate',
      onAction: activateDialogActivator.show,
    })
  }

  return (
    <Page
      title={`Subscription #${gidToId(sub.gid)}`}
      backAction={backAction}
      primaryAction={sub.finished_at ? reactivateAction : cancelAction}
      secondaryActions={secondaryActions}
    >
      <ApiActionDialog
        activator={cancelDialogActivator}
        url={`/subscriptions/${gidToId(sub.gid)}/cancel`}
        onSuccess={() => mutate(['subscription-contract', `${gidToId(sub.gid)}`])}
        title="Are you sure?"
        message="Do you want to cancel this subscription immediately?"
        primaryButtonTitle="Cancel subscription"
        cancelButtonTitle="Keep subscription"
        destructive
      />

      <ApiActionDialog
        activator={pauseDialogActivator}
        url={`/subscriptions/${gidToId(sub.gid)}/pause`}
        onSuccess={() => mutate(['subscription-contract', `${gidToId(sub.gid)}`])}
        title="Are you sure?"
        message="Do you want to pause this subscription? You can resume it later"
        primaryButtonTitle="Pause"
        cancelButtonTitle="Cancel"
      />

      <ApiActionDialog
        activator={resumeDialogActivator}
        url={`/subscriptions/${gidToId(sub.gid)}/resume`}
        onSuccess={() => mutate(['subscription-contract', `${gidToId(sub.gid)}`])}
        title="Are you sure?"
        message="Do you want to resume this subscription?"
        primaryButtonTitle="Resume"
        cancelButtonTitle="Cancel"
      />

      <ApiActionDialog
        activator={activateDialogActivator}
        url={`/subscriptions/${gidToId(sub.gid)}/activate`}
        onSuccess={() => mutate(['subscription-contract', `${gidToId(sub.gid)}`])}
        title="Are you sure?"
        message="Do you want to activate this subscription? Activating will make it permanently visible to the customer"
        primaryButtonTitle="Activate"
        cancelButtonTitle="Cancel"
      />

      <ReactivateDialog sc={sub} activator={reactivateDialogActivator} />

      <Layout>
        <ManualSubscriptionsDisabledWarning sc={sub} />
        <Layout.Section>
          <LinesCard sc={sub} />
          <PaymentsCard sc={sub} />
          <ScheduledFulfillmentsCard sc={sub} />
          <Timeline
            activities={sub.activities}
            createdAt={sub.created_at}
            recordingSince={'2022-02-01T15:00:00Z'}
            secondaryActions={
              workflowsEnabled ? (
                <InternalLink url={urls.workflowLogsUrl({ subscription_id: sub.rest_id })}>
                  Workflow logs
                </InternalLink>
              ) : null
            }
          />
        </Layout.Section>
        <Layout.Section secondary>
          <OverviewCard sc={sub} />
          <NotesCard sc={sub} />
          <NextActionCard sc={sub} />
          <ScheduleCard sc={sub} />
          <DeliveryDetailsCard sc={sub} />
          <PaymentMethodCard sc={sub} />
        </Layout.Section>
        <Layout.Section>
          <Footer />
        </Layout.Section>
      </Layout>
    </Page>
  )
}
