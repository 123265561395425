import { HorizontalStack } from '@shopify/polaris'

import { DatePickerWithInput } from './DatePickerWithInput'

export const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  startDate?: string
  endDate?: string
  setStartDate: (date?: string) => void
  setEndDate: (date?: string) => void
}) => (
  <HorizontalStack align="space-evenly" gap="4">
    <DatePickerWithInput label="Starting" date={startDate} updateDate={setStartDate} />
    <DatePickerWithInput label="Ending" date={endDate} updateDate={setEndDate} />
  </HorizontalStack>
)
