import { Button, Icon, LegacyCard, LegacyStack, Text } from '@shopify/polaris'
import { DeleteMajor } from '@shopify/polaris-icons'
import React, { useCallback } from 'react'

import { Result } from '../common/api'
import { resultErrorField } from '../common/helpers'
import {
  actionIsSupportedByTriggers,
  WorkflowAction,
  WorkflowTriggerType,
} from '../common/workflow-models'
import { AddDiscountAction } from './AddDiscountAction'
import { AddOneTimeGiftProductAction } from './AddOneTimeGiftProductAction'
import { AddProductAction } from './AddProductAction'
import { EditDeliveryFrequencyAction } from './EditDeliveryFrequencyAction'
import { RemoveProductAction } from './RemoveProductAction'
import { RemoveProductVariantAction } from './RemoveProductVariantAction'
import { SwapProductAction } from './SwapProductAction'
import { TagsAction } from './TagsAction'

export interface ActionsFormProps {
  actions: WorkflowAction[]
  triggers: WorkflowTriggerType[]
  onChange: (actions: WorkflowAction[]) => any
  saveResult: Result<any> | null
  errorPrefix?: string
}

export const ActionsForm = ({
  actions,
  triggers,
  onChange,
  saveResult,
  errorPrefix = '',
}: ActionsFormProps) => {
  const removeAction = useCallback(
    (index: number) => {
      const newActions = [...actions]
      newActions.splice(index, 1)
      onChange(newActions)
    },
    [actions, onChange]
  )

  const updateAction = useCallback(
    (action: WorkflowAction, index: number) => {
      const newActions = [...actions]
      newActions[index] = action
      onChange(newActions)
    },
    [actions, onChange]
  )

  return (
    <>
      {actions.map(([type, params]: WorkflowAction, index: number) => (
        <LegacyCard.Section key={index}>
          <LegacyStack wrap={false} spacing="extraLoose">
            <LegacyStack.Item fill>
              {(type === 'add-customer-tags' ||
                type === 'add-order-tags' ||
                type === 'remove-customer-tags') && (
                <LegacyStack vertical>
                  <TagsAction
                    actionType={type}
                    tags={params}
                    onChange={updateAction}
                    index={index}
                    error={resultErrorField(saveResult, `${errorPrefix}actions.${index}`)}
                  />
                  {!actionIsSupportedByTriggers(type, triggers) && (
                    <Text as="span" color="critical">
                      This action is supported only for <b>Subscription is created</b> and{' '}
                      <b>Subscription payment succeeds</b> events.
                    </Text>
                  )}
                </LegacyStack>
              )}
              {type === 'add-one-time-gift-product' && (
                <AddOneTimeGiftProductAction
                  params={params}
                  onChange={updateAction}
                  index={index}
                  error={resultErrorField(saveResult, `${errorPrefix}actions.${index}.1`)}
                />
              )}
              {type === 'add-product' && (
                <AddProductAction
                  params={params}
                  onChange={updateAction}
                  index={index}
                  productError={resultErrorField(saveResult, `${errorPrefix}actions.${index}.1.0`)}
                  quantityError={resultErrorField(saveResult, `${errorPrefix}actions.${index}.1.1`)}
                  currentPriceError={resultErrorField(
                    saveResult,
                    `${errorPrefix}actions.${index}.1.2`
                  )}
                />
              )}
              {type === 'swap-product' && (
                <SwapProductAction
                  params={params}
                  onChange={updateAction}
                  index={index}
                  sourceProductError={
                    resultErrorField(saveResult, `${errorPrefix}actions.${index}.1.0`) ??
                    resultErrorField(saveResult, `${errorPrefix}actions.${index}.1`)
                  }
                  targetProductError={
                    resultErrorField(saveResult, `${errorPrefix}actions.${index}.1.1`) ??
                    resultErrorField(saveResult, `${errorPrefix}actions.${index}.1`)
                  }
                  quantityError={resultErrorField(saveResult, `${errorPrefix}actions.${index}.1.2`)}
                  currentPriceError={resultErrorField(
                    saveResult,
                    `${errorPrefix}actions.${index}.1.3`
                  )}
                />
              )}
              {type === 'remove-product' && (
                <RemoveProductAction
                  params={params}
                  onChange={updateAction}
                  index={index}
                  error={resultErrorField(saveResult, `actions.${index}.1`)}
                />
              )}
              {type === 'remove-product-variant' && (
                <RemoveProductVariantAction
                  params={params}
                  onChange={updateAction}
                  index={index}
                  error={resultErrorField(saveResult, `actions.${index}.1`)}
                />
              )}
              {type === 'add-discount' && (
                <AddDiscountAction
                  params={params}
                  onChange={updateAction}
                  index={index}
                  discountNameError={resultErrorField(
                    saveResult,
                    `${errorPrefix}actions.${index}.1.0`
                  )}
                  adjustmentValueError={resultErrorField(
                    saveResult,
                    `${errorPrefix}actions.${index}.1.3`
                  )}
                />
              )}
              {type === 'edit-delivery-frequency' && (
                <EditDeliveryFrequencyAction
                  params={params}
                  onChange={updateAction}
                  index={index}
                />
              )}
            </LegacyStack.Item>
            <LegacyStack.Item>
              <Button
                onClick={() => removeAction(index)}
                icon={<Icon source={DeleteMajor} color="base" />}
              />
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
      ))}
    </>
  )
}
