import { Layout, Page } from '@shopify/polaris'

import { Footer } from '../common/Footer'
import { ReferralCodeBanner } from '../common/ReferralCodeBanner'
import { TabIndex, Tabs } from '../common/Tabs'
import { Content } from './Content'

export const GettingStartedPage = () => {
  return (
    <>
      <Tabs selected={TabIndex.GettingStarted} />
      <ReferralCodeBanner />
      <Page title="Getting Started">
        <Layout>
          <Layout.Section>
            <Content />
          </Layout.Section>
          <Layout.Section>
            <Footer />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  )
}
