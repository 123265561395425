import { Layout, Page } from '@shopify/polaris'
import React from 'react'

import { Footer } from '../common/Footer'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { SellingPlanGroupList } from './SellingPlanGroupList'

export const SellingPlansPage = () => (
  <>
    <Tabs selected={TabIndex.Plans} />
    <Page
      title="Manage Plans"
      secondaryActions={[
        { content: "What's New", url: urls.newsUrl({ returnUrl: urls.plansUrl }) },
      ]}
      primaryAction={{ content: 'Create', url: urls.newPlanUrl }}
    >
      <Layout>
        <Layout.Section>
          <SellingPlanGroupList />
        </Layout.Section>

        <Layout.Section>
          <Footer />
        </Layout.Section>
      </Layout>
    </Page>
  </>
)
