import {
  LegacyCard,
  LegacyStack,
  Loading,
  Pagination,
  Spinner,
  useIndexResourceState,
} from '@shopify/polaris'
import { useCallback, useEffect, useState } from 'react'

import { Paginator, useMatchMutate } from '../common/api'
import { BulkActionCards } from '../common/BulkActionCards'
import { DialogActivator, NonEmptyArray, useDialogActivator } from '../common/helpers'
import { LoadErrorBanner } from '../common/LoadErrorPage'
import { useLocalStorage } from '../common/local-storage'
import { SubscriptionContract } from '../common/subscription-models'
import { SubscriptionExportModal } from '../subscription-export-modal/SubscriptionExportModal'
import { SubscriptionsBulkUpdateModal } from '../subscriptions-bulk-update-modal/SubscriptionsBulkUpdateModal'
import { SubscriptionFilter, useSubscriptionListSWR } from './api'
import { SubscriptionFilterForm } from './SubscriptionFilterForm'
import {
  SubscriptionTable,
  SubscriptionTableColumn,
  subscriptionTableDefaultColumns,
} from './SubscriptionTable'

const subscriptionContractIdResolver = (sc: any) => sc.rest_id.toString()

const useSelection = (scs?: SubscriptionContract[]) => {
  const {
    selectedResources: ids,
    allResourcesSelected: all,
    handleSelectionChange: handleChange,
    clearSelection,
  } = useIndexResourceState((scs ?? []) as any[], {
    resourceIDResolver: subscriptionContractIdResolver,
  })

  return { ids, all, handleChange, clearSelection }
}

const useListData = (filter: SubscriptionFilter) => {
  const [polling, setPolling] = useState(false)

  const { data: response, error, isValidating: loading } = useSubscriptionListSWR(filter, polling)

  const paginator = response?.paginator
  const bulkActions = response?.bulkActions

  // When bulk actions are present, keep polling for new data
  useEffect(() => {
    setPolling(bulkActions ? bulkActions.length > 0 : false)
  }, [bulkActions])

  // Always return the last loaded paginator, even while waiting for the a new one
  const [loadedPaginator, setLoadedPaginator] = useState<Paginator<SubscriptionContract> | null>(
    null
  )
  useEffect(() => {
    if (paginator) {
      setLoadedPaginator(paginator)
    }
  }, [paginator])

  return {
    error,
    loading,
    paginator: loadedPaginator,
    bulkActions: bulkActions,
  }
}

const useSubscriptionMutate = () => {
  const matchMutate = useMatchMutate()
  return useCallback(() => matchMutate(/subscriptions/), [matchMutate])
}

const LoadingSpinner = () => (
  <LegacyStack vertical spacing="loose">
    <p />
    <LegacyStack alignment="center" distribution="center" vertical>
      <Spinner />
    </LegacyStack>
    <p />
  </LegacyStack>
)

export const SubscriptionList = ({
  filter,
  exportModalActivator,
  updateFilter,
  clearFilter,
}: {
  filter: SubscriptionFilter
  exportModalActivator: DialogActivator
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any
  clearFilter: () => any
}) => {
  const { error, loading, paginator, bulkActions } = useListData(filter)

  const page = filter.page ?? 1
  const { clearSelection, ...selection } = useSelection(paginator?.data)
  const mutateSubscriptions = useSubscriptionMutate()

  const blkaActivator = useDialogActivator()
  const blkaFilter: SubscriptionFilter = selection.all
    ? { ...filter, ids: undefined, page: undefined }
    : {
        ids: selection.ids,
      }

  const exportFilter: SubscriptionFilter = {
    ...filter,
    ids: selection.all ? undefined : selection.ids,
  }

  const [columns, setColumns] = useLocalStorage<NonEmptyArray<SubscriptionTableColumn>>(
    'subscription-table-columns',
    subscriptionTableDefaultColumns
  )

  useEffect(() => clearSelection(), [filter, clearSelection])

  return (
    <>
      {loading && <Loading />}
      {bulkActions && <BulkActionCards blkas={bulkActions} mutate={mutateSubscriptions} />}
      {error && <LoadErrorBanner />}
      {!error && (
        <LegacyCard>
          <LegacyCard.Section>
            <SubscriptionFilterForm
              filter={filter}
              updateFilter={updateFilter}
              clearFilter={clearFilter}
              columns={columns}
              onColumnsChange={setColumns}
            />
          </LegacyCard.Section>
          {!paginator && <LoadingSpinner />}
          {paginator && (
            <LegacyStack vertical>
              <SubscriptionTable
                paginator={paginator}
                loading={loading}
                onBulkUpdate={blkaActivator.show}
                hasUnfinishedBulkActions={!!bulkActions && bulkActions.length > 0}
                selectedIds={selection.ids}
                selectedAll={selection.all}
                handleSelectionChange={selection.handleChange}
                columns={columns}
              />
              <LegacyStack distribution="center">
                <Pagination
                  label={`Page ${page} of ${paginator.last_page}`}
                  hasPrevious={!!paginator.prev_page_url}
                  onPrevious={() => updateFilter({ page: page - 1 })}
                  hasNext={!!paginator.next_page_url}
                  onNext={() => updateFilter({ page: page + 1 })}
                />
              </LegacyStack>
              <p />
              <SubscriptionsBulkUpdateModal
                activator={blkaActivator}
                filter={blkaFilter}
                total={selection.all ? paginator.total : selection.ids.length}
                onCreated={() => {
                  mutateSubscriptions()
                  clearSelection()
                }}
              />
              <SubscriptionExportModal filter={exportFilter} activator={exportModalActivator} />
            </LegacyStack>
          )}
        </LegacyCard>
      )}
    </>
  )
}
