import {
  Banner,
  Button,
  ButtonGroup,
  ChoiceList,
  FormLayout,
  LegacyCard,
  Modal,
  Text,
  TextField,
} from '@shopify/polaris'
import { useFormikContext } from 'formik'
import useSWR from 'swr'

import { backendFetchResultData, mutateWithResult } from '../common/api'
import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { ApiActionDialog } from '../common/ApiActionDialog'
import { CodeSnippet } from '../common/CodeSnippet'
import {
  CenteredSpinner,
  DialogActivator,
  InternalLink,
  ShowIntercomLink,
  useDialogActivator,
  useFormikHandleChangeCallback,
  useShopFlag,
  useShopPermission,
} from '../common/helpers'
import { FeatureAccessFlag } from '../common/SystemData'
import { callbackDeliveriesUrl } from '../common/urls'
import * as urls from '../common/urls'
import { UserSettings } from './api'

const useCallbackSecret = () =>
  useSWR<string>(['callback-secret'], () =>
    backendFetchResultData<string>('GET', '/callbacks/secret')
  )

const SecretModal = ({ activator }: { activator: DialogActivator }) => {
  const { isValidating: loading, data: token } = useCallbackSecret()
  const secretResetActivator = useDialogActivator()

  return (
    <>
      <Modal
        title="Webhook token"
        open={activator.open && !secretResetActivator.open}
        onClose={activator.close}
        sectioned
        secondaryActions={[
          {
            content: 'Reset',
            onAction: secretResetActivator.show,
            destructive: true,
          },
          { content: 'Close', onAction: activator.close },
        ]}
      >
        {loading && <CenteredSpinner />}
        {!loading && <CodeSnippet code={token ?? 'Unknown error'} />}
      </Modal>
      <ApiActionDialog
        activator={secretResetActivator}
        method="DELETE"
        url="/callbacks/secret"
        title="Reset webhook token"
        message="Are you sure? This action is irreversible"
        primaryButtonTitle="Reset token"
        destructive
        onSuccess={(res) => {
          mutateWithResult(['callback-secret'], res)
        }}
      />
    </>
  )
}

const eventChoices = [
  { value: 'subscription/created', label: 'Subscription is created' },
  { value: 'subscription/updated', label: 'Subscription is updated' },
  { value: 'subscription/payment-succeeded', label: 'Subscription payment succeeded' },
  { value: 'subscription/payment-failed', label: 'Subscription payment failed' },
]

const UpgradeToAccess = () => (
  <>
    Please{' '}
    <InternalLink url={urls.pricingPlansUrl()} target={'_blank'}>
      upgrade
    </InternalLink>{' '}
    to access this exclusive feature for merchants on our <Text as="strong">Traction Plan</Text> or
    higher.{' '}
  </>
)

const RequestAccessCard = ({ callbacksAllowed }: { callbacksAllowed: boolean }) => (
  <LegacyCard sectioned>
    <Banner status="warning">
      <Text as="p">
        {!callbacksAllowed && <UpgradeToAccess />}To gain access, please reach out to the PayWhirl
        support team. Simply <ShowIntercomLink>inform us</ShowIntercomLink> about your use case, and
        we'll enable Webhooks for your account, empowering you to expand your business capabilities.
      </Text>
    </Banner>
  </LegacyCard>
)

export const CallbacksCard = () => {
  const formik = useFormikContext<UserSettings>()
  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)
  const sendTestAction = useApiActionState({
    url: '/callbacks/send-test',
    opts: { body: JSON.stringify({ url: formik.values.callback_url }) },
    bannerPosition: 'external',
  })

  const secretActivator = useDialogActivator()

  const callbacksAccess = useShopFlag('callbacks_access') as FeatureAccessFlag
  const callbacksAllowed = useShopPermission('callbacksAllowed')

  if (callbacksAccess === 'disabled') {
    return <RequestAccessCard callbacksAllowed={callbacksAllowed} />
  }

  if (callbacksAccess === 'by-plan' && !callbacksAllowed) {
    return (
      <LegacyCard sectioned>
        <Banner status="warning">
          <Text as="p">
            <UpgradeToAccess />
          </Text>
        </Banner>
      </LegacyCard>
    )
  }

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <ApiActionBanner state={sendTestAction} />
        <ApiAction state={sendTestAction} />
        <FormLayout>
          <TextField
            id="callback_url"
            name="callback_url"
            label="Webhook URL"
            placeholder="https://example.com/webhook"
            value={formik.values.callback_url}
            onChange={handleChange}
            autoComplete="off"
            connectedRight={
              <Button loading={sendTestAction.loading} onClick={sendTestAction.run}>
                Send test
              </Button>
            }
          />
          <ChoiceList
            name="callback_topics"
            title="Send webhooks when"
            allowMultiple
            choices={eventChoices}
            selected={formik.values.callback_topics}
            onChange={handleChange}
          />
        </FormLayout>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <ButtonGroup>
          <Button onClick={secretActivator.show}>Show webhook token</Button>
          <Button url={callbackDeliveriesUrl()} plain>
            Recent deliveries
          </Button>
        </ButtonGroup>
      </LegacyCard.Section>

      <SecretModal activator={secretActivator} />
    </LegacyCard>
  )
}
