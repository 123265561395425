import { Select, SelectProps } from '@shopify/polaris'
import React from 'react'

export interface NumericSelectOption {
  label: string
  value: number
}

export interface NumericSelectProps extends Omit<SelectProps, 'options' | 'value' | 'onChange'> {
  options: NumericSelectOption[]
  value: number
  onChange?(selected: number, id: string): void
}

export const NumericSelect = (props: NumericSelectProps) => {
  const { onChange } = props

  const selectProps: SelectProps = {
    ...props,
    options: props.options.map((o) => ({ label: o.label, value: o.value.toString() })),
    value: props.value.toString(),
    onChange: (val: string, id: string) => onChange?.(parseInt(val), id),
  }

  return <Select {...selectProps} />
}

export const MemoedNumericSelect = React.memo(NumericSelect)

export interface OptionalNumericSelectOption {
  label: string
  value: number | null
}

export interface OptionalNumericSelectProps
  extends Omit<SelectProps, 'options' | 'value' | 'onChange'> {
  options: OptionalNumericSelectOption[]
  value: number | null
  onChange?(selected: number | null, id: string): void
}

export const OptionalNumericSelect = (props: OptionalNumericSelectProps) => {
  const { onChange } = props

  const selectProps: SelectProps = {
    ...props,
    options: props.options.map((o) => ({ label: o.label, value: o.value?.toString() ?? '' })),
    value: props.value?.toString() ?? '',
    onChange: (val: string, id: string) => onChange?.(val === '' ? null : parseInt(val), id),
  }

  return <Select {...selectProps} />
}

export const MemoedOptionalNumericSelect = React.memo(OptionalNumericSelect)

export const numericRangeOptions = (
  start: number,
  end: number,
  zeroPad: number = 0
): NumericSelectOption[] => {
  let ret: NumericSelectOption[] = []

  for (let i = start; i <= end; ++i) {
    ret.push({
      label: i.toString().padStart(zeroPad, '0'),
      value: i,
    })
  }

  return ret
}

export const numericIndexOptions = (labels: string[]): NumericSelectOption[] => {
  return labels.map((label: string, idx: number) => ({
    label: label,
    value: idx,
  }))
}
