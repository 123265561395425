import { Badge } from '@shopify/polaris'
import React from 'react'

import { formatPaymentMethodBrand } from '../common/helpers'
import iconAmericanExpress from './icons/american-express.svg'
import iconApplePay from './icons/apple-pay.svg'
import iconDinersClub from './icons/diners-club.svg'
import iconDiscover from './icons/discover.svg'
import iconElo from './icons/elo.svg'
import iconGooglePay from './icons/google-pay.svg'
import iconJcb from './icons/jcb.svg'
import iconMastercard from './icons/mastercard.svg'
import iconPayPal from './icons/paypal.svg'
import iconShopPay from './icons/shop-pay.svg'
import iconVisa from './icons/visa.svg'
import styles from './PaymentMethodIcon.module.css'

const icons: Record<string, string> = {
  american_express: iconAmericanExpress,
  apple_pay: iconApplePay,
  diners_club: iconDinersClub,
  discover: iconDiscover,
  elo: iconElo,
  google_pay: iconGooglePay,
  jcb: iconJcb,
  mastercard: iconMastercard,
  payPal: iconPayPal,
  shop_pay: iconShopPay,
  visa: iconVisa,
}

export const PaymentMethodIcon = ({ brand }: { brand: string }) => {
  const icon = icons[brand]
  const title = formatPaymentMethodBrand(brand)

  return icon ? (
    <img src={icon} alt={title} title={title} className={styles.img} />
  ) : (
    <Badge>{title}</Badge>
  )
}
