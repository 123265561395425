import { formatMoneyAmount } from '../common/helpers'
import { Price } from '../common/Price'
import { DeliveryMethod } from '../common/subscription-models'
import { Address } from './models'

export type DeliveryMethodType = 'none' | 'shipping' | 'local-delivery' | 'pickup'

export interface DeliveryDetailsForm {
  type: DeliveryMethodType
  address: Address
  title: string
  code: string
  phone: string
  instructions: string
  locationId: string
  price: string
}

const getDeliveryMethodType = (deliveryMethod: DeliveryMethod | null): DeliveryMethodType => {
  if (!deliveryMethod) {
    return 'none'
  }

  if (deliveryMethod.shippingOption) {
    return 'shipping'
  }

  if (deliveryMethod.localDeliveryOption) {
    return 'local-delivery'
  }

  return 'pickup'
}

export const deliveryDetailsToFormData = (
  deliveryMethod: DeliveryMethod | null,
  deliveryPrice: Price
): DeliveryDetailsForm => ({
  type: getDeliveryMethodType(deliveryMethod),
  address: deliveryMethod?.address ?? {},
  title:
    deliveryMethod?.shippingOption?.title ??
    deliveryMethod?.localDeliveryOption?.title ??
    deliveryMethod?.pickupOption?.title ??
    '',
  code:
    deliveryMethod?.shippingOption?.code ??
    deliveryMethod?.localDeliveryOption?.code ??
    deliveryMethod?.pickupOption?.code ??
    '',
  instructions: deliveryMethod?.localDeliveryOption?.instructions ?? '',
  phone: deliveryMethod?.localDeliveryOption?.phone ?? '',
  locationId: deliveryMethod?.pickupOption?.location.id ?? '',
  price: formatMoneyAmount(deliveryPrice.amount),
})
