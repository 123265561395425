import { useCallback, useState } from 'react'

import { ProductResult } from '../common/ProductModal'
import { ProductVariantResult } from '../common/ProductVariantModal'
import { sellingPlanNames, useSellingPlans } from '../workflow-page/api'

export interface AdvancedDashboardFilter {
  onProductVariantSelect: (selected: ProductVariantResult) => void
  onProductVariantRemove: (variantId: string) => void
  onProductSelect: (selected: ProductResult) => void
  onProductRemove: (productId: string) => void
  setSellingPlans: (plans: string[]) => void
  filterParams: Record<string, string>
  isFiltered: boolean
  products: ProductResult[]
  productVariants: ProductVariantResult[]
  sellingPlans: string[]
  sellingPlanIds: string[]
  sellingPlanNames: Record<string, string>
}

export const useAdvancedDashboardFilters = (): AdvancedDashboardFilter => {
  const spData = useSellingPlans()

  const [sellingPlans, setSellingPlans] = useState<string[]>(['any'])
  const [productVariants, setProductVariants] = useState<ProductVariantResult[]>([])
  const [products, setProducts] = useState<ProductResult[]>([])
  const sellingPlanIds = sellingPlans.filter((sp) => sp !== 'any')

  const spNames = sellingPlanNames(spData)

  const handleProductVariantSelect = useCallback(
    (selected: ProductVariantResult) => {
      setProductVariants([...productVariants, selected])
    },
    [productVariants, setProductVariants]
  )

  const handleRemoveProductVariant = useCallback(
    (variantId: string) => {
      const newVariants = productVariants.filter((variant) => variant.id !== variantId)
      setProductVariants(newVariants)
    },
    [productVariants, setProductVariants]
  )

  const handleProductSelect = useCallback(
    (selected: ProductResult) => {
      setProducts([...products, selected])
    },
    [products, setProducts]
  )

  const handleRemoveProduct = useCallback(
    (productId: string) => {
      const newProducts = products.filter((product) => product.id !== productId)
      setProducts(newProducts)
    },
    [products, setProducts]
  )

  const filterParams = {
    sellingPlanIds: sellingPlanIds.join(','),
    productIds: products.map((p) => p.restId).join(','),
    productVariantIds: productVariants.map((v) => v.restId).join(','),
  }

  const isFiltered = !!products.length || !!productVariants.length || !!sellingPlanIds.length

  return {
    onProductVariantSelect: handleProductVariantSelect,
    onProductVariantRemove: handleRemoveProductVariant,
    onProductSelect: handleProductSelect,
    onProductRemove: handleRemoveProduct,
    setSellingPlans,
    filterParams,
    isFiltered,
    products,
    productVariants,
    sellingPlans,
    sellingPlanIds,
    sellingPlanNames: spNames,
  }
}
