import { Banner, Page, Text } from '@shopify/polaris'

import { InternalLink, pwCache, useShopFlag, useSystemData } from './helpers'
import { referralCodeAppCreditValue } from './ReferralCode'
import { settingsUrl } from './urls'

export const ReferralCodeBanner = () => {
  const hasReferralPartnerId = useShopFlag('has_referral_partner_id')
  const isEligibleForReferralProgram = useShopFlag('eligible_for_referral_program')
  const createdAt = useSystemData()?.shopData.installed_shopwhirl_at
  const timestampNow = Math.floor(Date.now() / 1000)
  const recentlyCreated = createdAt > timestampNow - 60 * 60 * 24 * 3

  if (!recentlyCreated) {
    return null
  }

  if (!isEligibleForReferralProgram) {
    return null
  }

  if (hasReferralPartnerId || pwCache.referralCodeSubmitted) {
    return null
  }

  return (
    <Page title="Referral code banner" titleHidden>
      <Banner status="info">
        <Text as="p">
          Have a referral code? Enter it <InternalLink url={settingsUrl()}>here</InternalLink> to
          receive a <Text as="strong">{referralCodeAppCreditValue}</Text> in app credits.
        </Text>
      </Banner>
    </Page>
  )
}
