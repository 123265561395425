import { Select, TextField } from '@shopify/polaris'
import useSWR from 'swr'

import { backendFetchResultData } from './api'
import { LegacyHelpLabel } from './HelpIcon'

interface SellingPlanOption {
  label: string
  value: string
}

interface SellingPlanOptionGroup {
  title: string
  options: SellingPlanOption[]
}

const fetchSellingPlansData = (_: string): Promise<SellingPlanOptionGroup[]> =>
  backendFetchResultData('GET', `/plans/select-data`)

const useSellingPlansSWR = () => useSWR(['selling-plans-select-data'], fetchSellingPlansData)

export interface SellingPlanDescriptor {
  id: string | null
  name: string | null
}

interface SellingPlanFieldProps {
  plan: SellingPlanDescriptor
  label?: React.ReactNode
  labelHidden?: boolean
  emptyLabel?: string
  disabled?: boolean
  onChange: (plan: SellingPlanDescriptor) => any
}

export const SellingPlanField = ({
  plan,
  label,
  labelHidden = undefined,
  emptyLabel = 'None',
  disabled = false,
  onChange,
}: SellingPlanFieldProps) => {
  const { data } = useSellingPlansSWR()

  label = label ?? (
    <LegacyHelpLabel title="Selling plan label">
      This field only controls the displayed label. It does not affect schedule or any other fields
      in the subscription
    </LegacyHelpLabel>
  )

  if (!data) {
    return (
      <TextField
        disabled
        label={label}
        labelHidden={labelHidden}
        value={plan.name ?? ''}
        onChange={() => null}
        autoComplete="off"
      />
    )
  }

  const options = [{ label: emptyLabel, value: '' }, ...data]

  const namesById = data
    .flatMap((g) => g.options)
    .reduce((map, opt) => {
      map[opt.value] = opt.label
      return map
    }, {} as Record<string, string>)

  const isRemovedOrModified = (plan: SellingPlanDescriptor) =>
    (plan.id && !namesById[plan.id]) || (plan.name && namesById[plan.id ?? ''] !== plan.name)

  if (isRemovedOrModified(plan)) {
    namesById[`current-${plan.id}`] = plan.name ?? ''
    options.splice(0, 0, {
      label: `${plan.name} (original plan modified or removed)`,
      value: `current-${plan.id}`,
    })
  }

  const planForValue = (val: string): SellingPlanDescriptor => {
    if (val === '') {
      return { id: null, name: null }
    }
    const id = val.startsWith('current-') ? val.slice(7) : val
    return { id: id, name: namesById[val] ?? '' }
  }

  const valueForPlan = (plan: SellingPlanDescriptor): string =>
    isRemovedOrModified(plan) ? `current-${plan.id}` : plan.id ?? ''

  return (
    <Select
      label={label}
      labelHidden={labelHidden}
      options={options}
      value={valueForPlan(plan)}
      onChange={(val) => onChange(planForValue(val))}
      disabled={disabled}
    />
  )
}
