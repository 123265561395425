import { AppliedFilterInterface, ChoiceList, FilterInterface } from '@shopify/polaris'
import { useCallback } from 'react'

import { splitOptionalString } from '../../common/helpers'
import { useUserSettings } from '../../settings-page/api'
import { SubscriptionFilter } from '../api'

type Retrying = 'not_retrying' | 'all' | 'specific'

const retryingLables = (dunningRetries: string[]): Record<Retrying, string> => ({
  not_retrying: 'not retrying',
  all: 'all currently retrying',
  specific: dunningRetries.join(','),
})

export const useAddDunningRetriesFilter = (
  filter: SubscriptionFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any,
  filters: FilterInterface[],
  appliedFilters: AppliedFilterInterface[]
) => {
  const userSettingsRetryCount = useUserSettings().data?.attempt_retry_count ?? 4
  const dunningRetries = splitOptionalString(filter.dunning_retries).sort(
    (a, b) => parseInt(a) - parseInt(b)
  )
  const retrying = filter.retrying as Retrying
  const specificRetryOptions = Array.from({ length: userSettingsRetryCount }, (_, i) => ({
    value: `${i}`,
    label: i === 0 ? 'Initial decline' : `${i} Automatic ${i === 1 ? 'retry' : 'retries'}`,
  }))

  const setRetryChoices = useCallback(
    (sel: string[]) => {
      updateFilter({
        dunning_retries:
          sel.includes('not_retrying') || sel.includes('all')
            ? undefined
            : dunningRetries.join(','),
        retrying: sel[0],
      })
    },
    [updateFilter, dunningRetries]
  )

  const setSpecificRetryChoices = useCallback(
    (sel: string[]) => {
      updateFilter({
        retrying: 'specific',
        dunning_retries: sel.length ? sel.join(',') : undefined,
      })
    },
    [updateFilter]
  )

  const renderSpecificRetryOptions = useCallback(
    (selected: boolean) => {
      return (
        selected && (
          <ChoiceList
            title="Specific number of retries"
            titleHidden
            choices={specificRetryOptions}
            selected={dunningRetries}
            onChange={setSpecificRetryChoices}
            allowMultiple
          />
        )
      )
    },
    [dunningRetries, specificRetryOptions, setSpecificRetryChoices]
  )

  const paymentRetriesFilterOptions = [
    { value: 'not_retrying', label: 'Not Retrying' },
    { value: 'all', label: 'All Currently Retrying' },
    {
      value: 'specific',
      label: 'Specific Number of Retries',
      renderChildren: renderSpecificRetryOptions,
    },
  ]

  filters.push({
    key: 'retrying',
    label: 'Payment retries',
    filter: (
      <ChoiceList
        title="Payment retries"
        titleHidden
        choices={paymentRetriesFilterOptions}
        selected={retrying ? [retrying] : []}
        onChange={setRetryChoices}
      />
    ),
  })

  if (retrying) {
    appliedFilters.push({
      key: 'retrying',
      label: `Payment retries: ${retryingLables(dunningRetries)[retrying]}`,
      onRemove: () => updateFilter({ dunning_retries: undefined, retrying: undefined }),
    })
  }
}
