import {
  Icon,
  LegacyStack,
  Modal,
  ResourceList,
  Text,
  TextField,
  Thumbnail,
} from '@shopify/polaris'
import { SearchMinor } from '@shopify/polaris-icons'
import React, { useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'

import { backendFetchResult } from './api'
import { GQLPaginator } from './gql-models-common'
import { queryString, useDebounced } from './helpers'

export interface ProductResult {
  id: string
  restId: number
  title: string
  image: string | null
}

interface ProductQuery {
  search: string
  results: ProductResult[]
}

const searchProducts = async (_: any, search: string): Promise<ProductQuery> => {
  const query = queryString({ query: search, limit: 8 })
  const res = await backendFetchResult<GQLPaginator<ProductResult>>(
    'GET',
    `/products/search-products?${query}`
  )
  const results = res.status === 'success' && res.data ? res.data.nodes : []

  return { search, results }
}

const renderProductResult = (result: ProductResult, onSelect: (result: ProductResult) => any) => {
  return (
    <ResourceList.Item
      id={result.id}
      media={<Thumbnail source={result.image || ''} alt="" size="small" />}
      onClick={() => onSelect(result)}
      verticalAlignment="center"
    >
      <h3>
        <Text as="span" fontWeight="semibold">
          {result.title}
        </Text>
      </h3>
    </ResourceList.Item>
  )
}

interface ProductModalContentProps {
  onSelect: (result: ProductResult) => any
}

const ProductModalContent = ({ onSelect }: ProductModalContentProps) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounced(search, 500)

  const [query, setQuery] = useState<ProductQuery>({ search: '___', results: [] })
  const swr = useSWR(['product-variants', debouncedSearch], searchProducts)

  const myRenderProductResult = useCallback(
    (result: ProductResult) => renderProductResult(result, onSelect),
    [onSelect]
  )

  useEffect(() => {
    if (swr.data && swr.data.search === search) {
      setQuery(swr.data)
    }
  }, [swr.data, search, setQuery])

  return (
    <>
      <div
        style={{ padding: '1.25rem', borderBottom: '0.0625rem solid var(--p-divider, #dfe3e8)' }}
      >
        <TextField
          label=""
          placeholder="Search"
          prefix={<Icon source={SearchMinor} />}
          value={search}
          onChange={setSearch}
          autoComplete="off"
        />
      </div>

      <ResourceList
        items={query.results}
        renderItem={myRenderProductResult}
        loading={query.search !== search}
        emptyState={
          <LegacyStack distribution="center">
            <p style={{ padding: '2rem' }}>No products found</p>
          </LegacyStack>
        }
      />
    </>
  )
}

interface Props {
  open: boolean
  onClose: () => any
  onSelect: (result: ProductResult) => any
}

export const ProductModal = (props: Props) => (
  <Modal title="Select product" open={props.open} onClose={props.onClose}>
    <ProductModalContent onSelect={props.onSelect} />
  </Modal>
)
