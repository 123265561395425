import { useCallback, useEffect, useState } from 'react'

import { useDebounced } from '../../common/helpers'
import { SubscriptionFilter } from '../api'

export const useSearchState = (
  filter: SubscriptionFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any
) => {
  const [previousSearch, setPreviousSearch] = useState<string | undefined>(undefined)
  const [search, setSearch] = useState(filter.search)
  const debouncedSearch = useDebounced(search, 500)
  const clearSearch = useCallback(() => {
    setSearch(undefined)
    updateFilter({ search: undefined, sort: undefined })
  }, [updateFilter])

  const updateSearch = useCallback(
    (queryValue: string) => (queryValue ? setSearch(queryValue) : clearSearch()),
    [clearSearch]
  )

  // Monitor debouncedSearch, and aside from updating the URL, reset page to 1 if it changes
  useEffect(() => {
    if (previousSearch !== null && debouncedSearch !== previousSearch) {
      updateFilter({
        search: debouncedSearch,
        sort: !previousSearch && !!debouncedSearch ? 'relevance' : filter.sort,
      })
    }

    setPreviousSearch(debouncedSearch)
  }, [debouncedSearch, updateFilter, previousSearch, filter.sort])

  return { search, updateSearch, clearSearch }
}
