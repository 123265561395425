import {
  Layout,
  LegacyCard,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
} from '@shopify/polaris'
import React, { useMemo, useState } from 'react'

import { Footer } from '../common/Footer'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { TranslationSet, useTranslationSettings } from './api'
import languages from './languages.json'
import { TranslationsForm } from './TranslationsForm'

const MySkeletonPage = () => (
  <>
    <SkeletonPage title="Translations" backAction={true}>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </LegacyCard>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </LegacyCard>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  </>
)

interface LoadedPageProps {
  defaults: TranslationSet
  initialValues: TranslationSet
}

const languageTitles: Record<string, string> = (() => {
  let ret: Record<string, string> = {}
  languages.forEach((lang) => (ret[lang.code] = lang.title))
  return ret
})()

const LoadedPage = ({ initialValues, defaults }: LoadedPageProps) => {
  const [language, setLanguage] = useState<string>('default')

  const actions = useMemo(
    () =>
      languages.map((language) => ({
        content: language.title,
        onAction: () => setLanguage(language.code),
      })),
    [setLanguage]
  )

  return (
    <>
      <Page
        title="Translations"
        backAction={{ content: 'Settings', url: urls.settingsUrl() }}
        actionGroups={[
          {
            title: languageTitles[language],
            actions: actions,
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            <TranslationsForm
              defaults={defaults}
              initialValues={initialValues}
              language={language}
            />
          </Layout.Section>
          <Layout.Section>
            <Footer />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  )
}

export const TranslationsPage = () => {
  const { data, error, isValidating } = useTranslationSettings()

  return (
    <>
      {isValidating && <Loading />}
      <Tabs selected={TabIndex.Settings} />

      {error && <LoadErrorPage />}
      {!error && !data && <MySkeletonPage />}
      {!error && data && <LoadedPage initialValues={data.translations} defaults={data.defaults} />}
    </>
  )
}
