import {
  Button,
  InlineError,
  LegacyCard,
  LegacyStack,
  Modal,
  OptionList,
  Popover,
  Text,
} from '@shopify/polaris'

import { Result } from '../common/api'
import { ExternalLink, resultErrorField, useToggle } from '../common/helpers'
import { WorkflowAction, WorkflowActionType, WorkflowTriggerType } from '../common/workflow-models'
import { actionOptions, workflowActionDefaults } from '../workflow-page/ActionsCard'
import { ActionsForm } from '../workflow-page/ActionsForm'

export interface SubscriptionFlowTriggerModalProps {
  title: string
  open: boolean
  onClose: () => any
  actions: WorkflowAction[]
  triggers: WorkflowTriggerType[]
  onChange: (actions: WorkflowAction[]) => any
  saveResult: Result<any> | null
  index: number
}

export const SubscriptionFlowTriggerModal = (props: SubscriptionFlowTriggerModalProps) => {
  const [actionPopoverActive, toggleActionPopover] = useToggle(false)
  const error = resultErrorField(props.saveResult, `workflows.${props.index}.actions`)

  const hasAddOrSwapProductActions = props.actions.some((action) =>
    ['add-product', 'swap-product'].includes(action[0])
  )

  return (
    <Modal
      title={props.title}
      open={props.open}
      onClose={props.onClose}
      primaryAction={{ content: 'Done', onAction: props.onClose }}
      footer={
        hasAddOrSwapProductActions && (
          <Text as="p">
            Please make sure your subscription policy is up to date (
            <ExternalLink href="https://help.shopify.com/en/manual/checkout-settings/refund-privacy-tos">
              learn more
            </ExternalLink>
            ).
          </Text>
        )
      }
    >
      <ActionsForm
        actions={props.actions}
        triggers={props.triggers}
        onChange={props.onChange}
        saveResult={props.saveResult}
        errorPrefix={`workflows.${props.index}.`}
      />
      <LegacyCard.Section>
        <LegacyStack vertical>
          <Popover
            active={actionPopoverActive}
            activator={
              <Button onClick={toggleActionPopover} disclosure>
                Add action
              </Button>
            }
            onClose={toggleActionPopover}
            fluidContent
          >
            <OptionList
              onChange={(
                selected: Exclude<
                  WorkflowActionType,
                  'remove-expired-discount' | 'remove-one-time-gift-products'
                >[]
              ) => {
                props.onChange([
                  ...props.actions,
                  [selected[0], workflowActionDefaults[selected[0]]],
                ])
                toggleActionPopover()
              }}
              options={actionOptions}
              selected={[]}
            />
          </Popover>
          {error && <InlineError message={error} fieldID={'actions'} />}
        </LegacyStack>
      </LegacyCard.Section>
    </Modal>
  )
}
