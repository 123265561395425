import { useCallback, useState } from 'react'

export const readLocalStorage = <T>(key: string, defaultValue: T): T => {
  try {
    const val = localStorage.getItem(key)
    return val ? JSON.parse(val) : defaultValue
  } catch (e) {
    return defaultValue
  }
}

export const writeLocalStorage = <T>(key: string, value: T) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {}
}

export const useLocalStorage = <T>(key: string, defaultData: T): [T, (value: T) => any] => {
  const [data, setData] = useState<T>(() => readLocalStorage(key, defaultData))

  const updateData = useCallback(
    (value: T) => {
      setData(value)
      writeLocalStorage(key, value)
    },
    [key]
  )

  return [data, updateData]
}
