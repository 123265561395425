import { InlineError, Label, LegacyStack } from '@shopify/polaris'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

import styles from './DateTimePicker.module.css'
import { useShopTimeZone } from './helpers'
import { numericIndexOptions, numericRangeOptions, NumericSelect } from './NumericSelect'

interface Props {
  label: string
  initial?: string | null
  error?: string | null
  onChange: (date: string) => any
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const daysInMonth = (year: number, month: number) => new Date(year, month + 1, 0).getDate()

export const DateTimePicker = (props: Props) => {
  const { initial, onChange } = props
  const zone = useShopTimeZone()
  const initialDateUTC = props.initial ? DateTime.fromISO(props.initial) : DateTime.utc()
  const initialDate = initialDateUTC.setZone(zone)

  const [year, setYear] = useState(initialDate.year)
  const [month, setMonth] = useState(initialDate.month - 1)
  const [day, setDay] = useState(initialDate.day as number)
  const [hour, setHour] = useState(initialDate.hour as number)
  const [minute, setMinute] = useState(initialDate.minute as number)

  useEffect(() => {
    const days = daysInMonth(year, month)

    if (day > days) {
      setDay(days)
    }

    const date = DateTime.fromObject({ year, month: month + 1, day, hour, minute }, { zone })
      .toUTC()
      .toISO()

    if (date !== initial) {
      onChange(date)
    }
  }, [year, month, day, hour, minute, zone, initial, onChange])

  return (
    <div className={styles.wrap}>
      <Label id="year">{props.label}</Label>
      <LegacyStack vertical spacing="extraTight">
        <div className={styles.fieldsWrap}>
          <div className={styles.dateWrap}>
            <NumericSelect
              id="year"
              label=""
              options={numericIndexOptions(monthNames)}
              value={month}
              onChange={setMonth}
              error={!!props.error}
            />
            <NumericSelect
              label=""
              options={numericRangeOptions(1, daysInMonth(year, month))}
              value={day}
              onChange={setDay}
              error={!!props.error}
            />
            <NumericSelect
              label=""
              options={numericRangeOptions(2000, 2100)}
              value={year}
              onChange={setYear}
              error={!!props.error}
            />
          </div>
          <div className={styles.hourWrap}>
            <NumericSelect
              label=""
              options={numericRangeOptions(0, 23)}
              value={hour}
              onChange={setHour}
              error={!!props.error}
            />
            <span>:</span>
            <NumericSelect
              label=""
              options={numericRangeOptions(0, 59, 2)}
              value={minute}
              onChange={setMinute}
              error={!!props.error}
            />
          </div>
        </div>
        {props.error && <InlineError message={props.error} fieldID="date" />}
      </LegacyStack>
    </div>
  )
}
