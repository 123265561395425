// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GettingStarted_wrapper__084pb .Polaris-CalloutCard__Image {
  width: 10rem;
}

.GettingStarted_wrapper__084pb .Polaris-CalloutCard__Buttons {
  display: none;
}

.GettingStarted_legacyWrapper__Gy2hD .Polaris-CalloutCard__Image {
  width: 13rem;
}
`, "",{"version":3,"sources":["webpack://./src/getting-started-page/GettingStarted.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd","sourcesContent":[".wrapper :global(.Polaris-CalloutCard__Image) {\n  width: 10rem;\n}\n\n.wrapper :global(.Polaris-CalloutCard__Buttons) {\n  display: none;\n}\n\n.legacyWrapper :global(.Polaris-CalloutCard__Image) {\n  width: 13rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `GettingStarted_wrapper__084pb`,
	"legacyWrapper": `GettingStarted_legacyWrapper__Gy2hD`
};
export default ___CSS_LOADER_EXPORT___;
