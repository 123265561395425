import { SelectOption } from '@shopify/polaris'

import { Address } from '../delivery-details-edit-dialog/models'
import { Activity } from './activity-models'
import { SellingPlanAnchor } from './anchor-models'
import { SubscriptionContractSubscriptionStatus } from './api'
import { SellingPlanInterval } from './plan-models'
import { Price } from './Price'

export type BillingAttemptStatus = 'Pending' | 'Success' | 'Failed'

export interface BillingAttempt {
  id?: number
  ready: number
  status: BillingAttemptStatus
  error_message: string | undefined
  gql_data: {
    errorCode: string | undefined
    order: {
      id: string
      name: string
    } | null
    nextActionUrl?: string
  } | null
  created_at: string
}

export interface FulfillmentOrder {
  id: string
  fulfillAt: string
  orderId: string
  orderName: string
}

export interface Attribute {
  key: string
  value?: string
}

export interface LineItem {
  id?: string
  sku: string
  title: string
  quantity: number
  productId: string
  variantId?: string
  sellingPlanId: string | null
  sellingPlanName: string | null
  currentPrice: Price
  lineDiscountedPrice: Price
  taxable?: boolean
  displayData?: {
    title: string
    adminUrl: string
    customerUrl: string
    imageUrl?: string
  }
  customAttributes?: Attribute[]
}

export interface Discount {
  id: string
  title: string | null
  recurringCycleLimit: number | null
  usageCount: number
  entitledLines: {
    all: boolean
  }
  targetType: 'LINE_ITEM' | 'SHIPPING_LINE'
  value: {
    __typename: string
    amount?: Price
    percentage?: number
  }
}

export interface CustomerCreditCard {
  __typename?: string
  brand: string
  expiryMonth: number
  expiryYear: number
  inactive?: boolean // used for casting CustomerShopPayAgreements
  maskedNumber?: string
  lastDigits?: string
  name: string
  source?: string
  virtual_last_digits?: string
}

export interface CustomerPaypalBillingAgreement {
  __typename: string
  inactive?: boolean
  paypalAccountEmail?: string
}

export interface CustomerShopPayAgreement {
  __typename?: string
  expiryMonth: number
  expiryYear: number
  inactive: boolean
  lastDigits?: string
  name: string
}

export interface PaymentMethod {
  id: string
  revokedAt?: string
  instrument: CustomerCreditCard | CustomerPaypalBillingAgreement | CustomerShopPayAgreement | null
}

export type FinishingBehavior = 'immediately' | 'end-of-cycle'

export const defaultFinishingBehavior: FinishingBehavior = 'immediately'

export const finishingBehaviorLabels: Record<FinishingBehavior, string> = {
  immediately: 'Immediately',
  'end-of-cycle': 'End of cycle',
}

export interface Location {
  id: string
  name: string
  address: Address
}

export interface ShippingOption {
  code: string
  title: string
  presentmentTitle: string | null
  description: string | null
}

export interface LocalDeliveryOption {
  code: string
  title: string
  presentmentTitle: string | null
  description: string | null
  instructions: string | null
  phone: string
}

export interface PickupOption {
  code: string
  title: string
  presentmentTitle: string | null
  description: string | null
  location: Location
}

export interface DeliveryMethod {
  address?: Address
  shippingOption?: ShippingOption
  localDeliveryOption?: LocalDeliveryOption
  pickupOption?: PickupOption
}

export interface SubscriptionContract {
  gid: string
  rest_id: number
  current_billing_attempt_id: number | null
  activities: Activity[]
  billing_attempts: BillingAttempt[]
  fulfillment_orders: FulfillmentOrder[]
  gql_status: SubscriptionContractSubscriptionStatus
  gql_customer_gid: string
  gql_data: {
    id: string
    billingPolicy: {
      anchors?: SellingPlanAnchor[]
      interval: SellingPlanInterval
      intervalCount: number
      minCycles: number | null
      maxCycles: number | null
    }
    currencyCode: string
    customer: {
      id: string
      displayName: string
      email: string | null
    } | null
    customerPaymentMethod: PaymentMethod | null
    deliveryAddress: string
    deliveryPolicy: {
      anchors?: SellingPlanAnchor[]
      interval: SellingPlanInterval
      intervalCount: number
      minCycles: number | null
      maxCycles: number | null
    }
    deliveryMethod: DeliveryMethod | null
    deliveryPrice: Price
    lines: LineItem[]
    discounts: Discount[]
    nextBillingDate: string
    note?: string
    createdAt: string
    updatedAt: string
  }
  origin_order: {
    createdAt: string
    id: string
    name: string
    statusPageUrl: string
    test: boolean
    totalPriceSet: {
      shopMoney: Price
    }
  } | null
  discountedDeliveryPrice: Price
  pw_data: {
    finishingBehavior?: FinishingBehavior
    cancellationReason?: string
    cancellationFeedback?: string
    pausedAt?: string | null
    pauseReason?: PauseReason | null
    dunningRetries?: number
  }
  next_attempt_at: string | null
  next_order_date: string | null
  retrying_at: string | null
  next_action: NextAction
  next_action_date: string | null
  next_next_order_date: string | null
  finish_at: string | null
  finish_status: SubscriptionContractSubscriptionStatus | null
  processed_installments: number
  remaining_installments: number
  pre_paid_deliveries: number
  created_at: string
  updated_at: string
  activated_at: string | null
  finished_at: string | null
  merchant_note: string
  is_editable: boolean
}

export type NextAction = 'payment' | 'expire' | 'cancel' | 'none'

export const nextActionOptions: SelectOption[] = [
  { label: 'Collect payment and create an order', value: 'payment' },
  { label: 'Expire subscription', value: 'expire' },
  { label: 'Cancel subscription', value: 'cancel' },
]

export type PauseReason =
  | 'default'
  | 'low_inventory'
  | 'shop_disabled'
  | 'contract_under_review'
  | 'invalid_line_items'
  | 'failed_payments'

export type PauseReasonFilter = '' | PauseReason

export const pauseReasonLabels: Record<PauseReasonFilter, string> = {
  '': 'Any reason',
  default: 'Paused manually',
  low_inventory: 'Low inventory',
  contract_under_review: 'Contract under review',
  shop_disabled: "Merchant's pricing plan deactivated",
  invalid_line_items: 'Invalid line items',
  failed_payments: 'Failed payments',
}
