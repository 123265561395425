import { Banner, Button, LegacyStack, Modal, TextField } from '@shopify/polaris'
import { useEffect, useState } from 'react'

import { mutateWithResult, Result } from '../common/api'
import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { CenteredSpinner, DialogActivator } from '../common/helpers'
import { useCustomerPortalCustomScriptUrl } from './api'

export const Content = ({ activator }: { activator: DialogActivator }) => {
  const { data: initialScriptUrl, isValidating: loading } = useCustomerPortalCustomScriptUrl()

  const [scriptUrl, setScriptUrl] = useState(initialScriptUrl ?? '')

  useEffect(() => {
    setScriptUrl(initialScriptUrl ?? '')
  }, [initialScriptUrl, activator.showCounter])

  const saveAction = useApiActionState({
    method: 'PATCH',
    url: '/settings/customer-portal-custom-script-url',
    opts: {
      body: JSON.stringify({ scriptUrl: scriptUrl }),
    },
    onSuccess: (res: Result<string>) => {
      mutateWithResult(['customer-portal-custom-script-url'], res)
    },
    bannerPosition: 'external',
  })

  return (
    <>
      <Modal.Section>
        <ApiActionBanner state={saveAction} />
        {loading && initialScriptUrl === undefined && <CenteredSpinner />}
        {!loading && initialScriptUrl === undefined && (
          <Banner status="critical">Script URL couldn't be loaded, please try again later</Banner>
        )}
        {initialScriptUrl !== undefined && (
          <TextField
            label="Custom Script URL"
            labelHidden
            value={scriptUrl}
            onChange={setScriptUrl}
            autoComplete="off"
            autoFocus
            monospaced
            spellCheck={false}
          />
        )}
      </Modal.Section>
      <Modal.Section>
        <LegacyStack distribution="trailing" spacing="tight">
          <Button onClick={activator.close}>Close</Button>
          <Button
            primary
            onClick={saveAction.run}
            loading={saveAction.loading}
            disabled={initialScriptUrl === undefined || scriptUrl === initialScriptUrl}
          >
            Save
          </Button>
        </LegacyStack>
      </Modal.Section>
      <ApiAction state={saveAction} />
    </>
  )
}

export const CustomScriptUrlModal = ({ activator }: { activator: DialogActivator }) => (
  <Modal title="Custom Script URL" open={activator.open} onClose={activator.close}>
    <Content activator={activator} />
  </Modal>
)
