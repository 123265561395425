import { Layout, Loading, Page, SkeletonBodyText, SkeletonPage } from '@shopify/polaris'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useSWR, { mutate } from 'swr'

import { backendFetchResult, Result } from '../common/api'
import { Collection, useCollectionsSWR } from '../common/collections'
import { Footer } from '../common/Footer'
import { useDialogActivator } from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { PageData } from './api'
import { BulkActionCard } from './BulkActionCard'
import { BulkActionModal } from './BulkActionModal'
import { ProductList } from './ProductList'

const fetchPageData = async (_: string, spgRestId: string): Promise<Result<PageData>> =>
  await backendFetchResult('GET', `/plans/${spgRestId}/products-page-data`)

const Skeleton = () => (
  <SkeletonPage title="Add products to selling plan group" backAction>
    <Layout>
      <Layout.Section>
        <SkeletonBodyText />
        <SkeletonBodyText />
        <SkeletonBodyText />
        <SkeletonBodyText />
      </Layout.Section>
    </Layout>
  </SkeletonPage>
)

const Loaded = ({
  spgRestId,
  pageData,
  collections,
}: {
  spgRestId: string
  pageData: PageData
  collections: Collection[]
}) => {
  const swrKey = ['products-page-data', spgRestId]

  const bulkAttachModalActivator = useDialogActivator()
  const bulkDetachModalActivator = useDialogActivator()

  return (
    <Page
      title={`Add products to ${pageData.merchantCode}`}
      subtitle="You can assign individual variants directly from product variant pages"
      backAction={{ content: 'Plan', url: urls.planUrl(spgRestId) }}
      primaryAction={
        pageData.bulkAction === null
          ? { content: 'Add in bulk', onAction: bulkAttachModalActivator.show }
          : undefined
      }
      secondaryActions={
        pageData.bulkAction === null
          ? [{ content: 'Remove in bulk', onAction: bulkDetachModalActivator.show }]
          : undefined
      }
    >
      <Layout>
        {pageData.bulkAction && (
          <Layout.Section>
            <BulkActionCard spgRestId={spgRestId} bulkAction={pageData.bulkAction} />
          </Layout.Section>
        )}
        <Layout.Section>
          <ProductList
            spgRestId={spgRestId}
            pagePath={urls.planProductsUrl(spgRestId)}
            pageData={pageData}
            collections={collections}
          />
        </Layout.Section>

        <Layout.Section>
          <Footer />
        </Layout.Section>
      </Layout>
      <BulkActionModal
        actionType="attach"
        open={bulkAttachModalActivator.open}
        onClose={() => {
          mutate(swrKey)
          bulkAttachModalActivator.close()
        }}
        spgRestId={spgRestId}
        collections={collections}
      />
      <BulkActionModal
        actionType="detach"
        open={bulkDetachModalActivator.open}
        onClose={() => {
          mutate(swrKey)
          bulkDetachModalActivator.close()
        }}
        spgRestId={spgRestId}
        collections={collections}
      />
    </Page>
  )
}

export const SellingPlansProductsPage = () => {
  const params = useParams<{ id: string }>()

  const [pollingInterval, setPollingInterval] = useState<number | undefined>(undefined)
  const pageDataSwrKey = ['products-page-data', params.id]
  const pageDataSwr = useSWR<Result<PageData>>(pageDataSwrKey, fetchPageData, {
    refreshInterval: pollingInterval,
  })
  const pageDataError =
    pageDataSwr.error || (pageDataSwr.data && pageDataSwr.data.status !== 'success')
  const pageData = pageDataSwr.data?.status === 'success' ? pageDataSwr.data.data! : null

  const colsSwr = useCollectionsSWR()
  const colsSwrError = colsSwr.error
  const cols: Collection[] = colsSwr.data ?? []

  const error = pageDataError || colsSwrError
  const loading = pageDataSwr.isValidating || colsSwr.isValidating

  useEffect(() => {
    setPollingInterval(pageData?.bulkAction ? 8000 : undefined)
  }, [pageData])

  return (
    <>
      {loading && <Loading />}
      <Tabs selected={TabIndex.Plans} />
      {error && <LoadErrorPage />}
      {!error && !pageData && <Skeleton />}
      {!error && pageData && (
        <Loaded spgRestId={params.id} pageData={pageData} collections={cols} />
      )}
    </>
  )
}
