import { Checkbox, FormLayout, LegacyCard, TextField } from '@shopify/polaris'
import { useFormikContext } from 'formik'

import { useFormikHandleChangeCallback } from '../common/helpers'
import { LegacyHelpLabel } from '../common/HelpIcon'
import { UserSettings } from './api'

export const CancellationFlowCard = () => {
  const formik = useFormikContext<UserSettings>()
  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <FormLayout>
          <Checkbox
            id="cancellation_flow_show_survey"
            name="cancellation_flow_show_survey"
            label={
              <LegacyHelpLabel title="Ask for the reason of cancellation and feedback">
                You can edit the predefined reasons together with other translations
              </LegacyHelpLabel>
            }
            checked={formik.values.cancellation_flow_show_survey}
            onChange={handleChange}
          />
          <Checkbox
            id="cancellation_flow_offer_pause"
            name="cancellation_flow_offer_pause"
            label="Offer to pause the subscription instead of cancelling"
            checked={formik.values.cancellation_flow_offer_pause}
            onChange={handleChange}
          />
          <Checkbox
            id="cancellation_flow_offer_skip_order"
            name="cancellation_flow_offer_skip_order"
            label="Offer to skip the next order instead of cancelling"
            checked={formik.values.cancellation_flow_offer_skip_order}
            onChange={handleChange}
          />
          <Checkbox
            id="cancellation_flow_offer_edit"
            name="cancellation_flow_offer_edit"
            label="Offer to edit subscription instead of cancelling"
            checked={formik.values.cancellation_flow_offer_edit}
            onChange={handleChange}
          />
          <Checkbox
            id="cancellation_flow_offer_discount"
            name="cancellation_flow_offer_discount"
            label={
              <LegacyHelpLabel title="Offer a discount instead of cancelling">
                The option will only be presented if the customer doesn't already have any active
                discounts in their subscription
              </LegacyHelpLabel>
            }
            checked={formik.values.cancellation_flow_offer_discount}
            onChange={handleChange}
          />
          {formik.values.cancellation_flow_offer_discount && (
            <TextField
              id="cancellation_flow_discount_code"
              name="cancellation_flow_discount_code"
              label="Discount code to apply if the customer accepts"
              placeholder="e.g. 'FREESHIPPING' "
              value={formik.values.cancellation_flow_discount_code}
              onChange={handleChange}
              autoComplete="off"
            />
          )}
        </FormLayout>
      </LegacyCard.Section>
    </LegacyCard>
  )
}
