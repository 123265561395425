import {
  Badge,
  DataTable,
  Layout,
  LegacyCard,
  LegacyStack,
  LinkAction,
  Page,
  Pagination,
  Select,
  Text,
} from '@shopify/polaris'
import { Status } from '@shopify/polaris/build/ts/src/components/Badge'
import { DateStyle } from '@shopify/react-i18n'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { DatePickerField } from '../common/DatePickerField'
import { Footer } from '../common/Footer'
import { FormattedDate, InternalLink, OrderLink } from '../common/helpers'
import * as urls from '../common/urls'
import { usePagination } from '../dashboard-payments-page/PageContent'
import {
  FulfillmentEvent,
  FulfillmentOrderStatus,
  PageQuery,
  usePageQuery,
} from './DashboardFulfillmentOrdersPage'

interface TableProps {
  items: FulfillmentEvent[]
  status: FulfillmentOrderStatus
  footerContent: React.ReactNode
}

const fulfillmentStatusToBadgeStatusMapping: Record<FulfillmentOrderStatus, Status> = {
  Scheduled: 'info',
  Open: 'attention',
  Closed: 'success',
}

const fulfillmentStatusLabels: Record<FulfillmentOrderStatus, string> = {
  Scheduled: 'Scheduled',
  Open: 'Unfulfilled',
  Closed: 'Fulfilled',
}

const Table = (props: TableProps) => {
  const rows = props.items.map((item) => [
    <InternalLink url={urls.subscriptionUrl(item.subscriptionRestId)}>
      #{item.subscriptionRestId}
    </InternalLink>,
    item.orderGid ? (
      <OrderLink gid={item.orderGid}>Order {item.orderName}</OrderLink>
    ) : (
      <Text as="span" color="subdued">
        Upcoming
      </Text>
    ),
    item.customerName,
    <Badge status={fulfillmentStatusToBadgeStatusMapping[item.status as FulfillmentOrderStatus]}>
      {fulfillmentStatusLabels[item.status as FulfillmentOrderStatus]}
    </Badge>,
    <FormattedDate
      date={new Date(item.date)}
      format={{ style: DateStyle.Short, hour: 'numeric', minute: 'numeric' }}
      titleFormat={{ style: DateStyle.Long, hour: 'numeric', minute: 'numeric' }}
    />,
  ])

  return (
    <DataTable
      columnContentTypes={['text', 'text', 'text', 'text', 'text']}
      headings={['Subscription ID', 'Order', 'Customer', 'Status', 'Date']}
      rows={rows}
      verticalAlign="middle"
      footerContent={props.footerContent}
    />
  )
}

export const PageContent = ({ fulfillments }: { fulfillments: FulfillmentEvent[] }) => {
  const history = useHistory()
  const pageQuery = usePageQuery()
  const pagination = usePagination(fulfillments, pageQuery.page)

  const updateParams = useCallback(
    (newParams: Partial<PageQuery>) => {
      history.replace(urls.dashboardFulfillmentsUrl({ ...pageQuery, ...newParams }))
    },
    [pageQuery, history]
  )

  const backAction: LinkAction = {
    content: 'Dashboard',
    url: urls.dashboardUrl({ date: pageQuery.date, event_types: pageQuery.event_types }),
  }

  return (
    <Page
      title="Fulfillments schedule"
      backAction={backAction}
      primaryAction={
        <LegacyStack>
          <Select
            label="Status: "
            labelInline
            options={Object.entries(fulfillmentStatusLabels).map(([value, label]) => ({
              value,
              label,
            }))}
            value={pageQuery.status}
            onChange={(status) =>
              updateParams({ status: status as FulfillmentOrderStatus, page: 1 })
            }
          />
          <DatePickerField
            date={pageQuery.date}
            onChange={(date) => updateParams({ date, page: 1 })}
          />
        </LegacyStack>
      }
    >
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <Table
              items={pagination.pageItems}
              status={pageQuery.status}
              footerContent={
                <Pagination
                  label={`Page: ${pageQuery.page} of ${pagination.maxPages + 1}`}
                  hasPrevious={pagination.hasPrevious}
                  onPrevious={() => updateParams({ page: pageQuery.page - 1 })}
                  hasNext={pagination.hasNext}
                  onNext={() => updateParams({ page: pageQuery.page + 1 })}
                />
              }
            />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <Footer />
        </Layout.Section>
      </Layout>
    </Page>
  )
}
