import {
  Layout,
  LegacyCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
} from '@shopify/polaris'
import React from 'react'

export const PageSkeleton = () => (
  <>
    <SkeletonPage backAction primaryAction title="Subscription">
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </LegacyCard>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
      <Layout.Section secondary>
        <LegacyCard>
          <LegacyCard.Section>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </TextContainer>
          </LegacyCard.Section>
        </LegacyCard>
        <LegacyCard>
          <LegacyCard.Section>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </TextContainer>
          </LegacyCard.Section>
        </LegacyCard>
      </Layout.Section>
    </SkeletonPage>
  </>
)
