import {
  Banner,
  Button,
  ContextualSaveBar,
  Layout,
  LegacyCard,
  LegacyStack,
  OptionList,
  Popover,
  Text,
} from '@shopify/polaris'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Result } from '../common/api'
import {
  formatOrdinal,
  MemoedTextField,
  range,
  resultErrorField,
  ShowIntercomButton,
  useDialogActivator,
  useToggle,
} from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { FiltersLine } from '../workflow-page/WorkflowDescription'
import {
  initialSubscriptionFlowWorkflowFormData,
  SubscriptionFlowFormData,
  SubscriptionFlowWorkflowFormData,
} from './api'
import { SubscriptionFlowFiltersModal } from './SubscriptionFlowFiltersModal'
import { SubscriptionFlowTriggerCard } from './SubscriptionFlowTriggerCard'
interface SubscriptionFlowFormProps {
  initialData: SubscriptionFlowFormData
  onSave: (data: SubscriptionFlowFormData) => any
  isSaving: boolean
  saveResult: Result<any> | null
  isNew: boolean
}

export const SubscriptionFlowForm = (props: SubscriptionFlowFormProps) => {
  const filtersModalActivator = useDialogActivator()

  const { initialData } = props
  const [triggerPopoverActive, toggleTriggerPopover] = useToggle(false)

  const [data, setData] = useState<SubscriptionFlowFormData>(
    JSON.parse(JSON.stringify(initialData))
  )
  useEffect(() => setData(JSON.parse(JSON.stringify(initialData))), [initialData])

  const [saveResult, setSaveResult] = useState<Result<any> | null>(props.saveResult)
  useEffect(() => setSaveResult(props.saveResult), [props.saveResult])

  const isDirty = useMemo(
    () => JSON.stringify(data) !== JSON.stringify(initialData),
    [data, initialData]
  )

  const addedTriggerOrderNumbers = useMemo(
    () => data.workflows.map((w) => w.afterOrderNumber),
    [data]
  )

  const triggerOptions = useMemo(
    () =>
      [{ label: 'Checkout (1st order)', value: '1', disabled: true }].concat(
        range(1, 100).map((i) => ({
          label: `Applies to ${formatOrdinal(i + 1)} order`,
          value: `${i}`,
          disabled: addedTriggerOrderNumbers.includes(i),
        }))
      ),
    [addedTriggerOrderNumbers]
  )

  const updateWorkflow = useCallback(
    (workflow: SubscriptionFlowWorkflowFormData, index: number) => {
      const newWorkflows = [...data.workflows]
      newWorkflows[index] = workflow
      setData({ ...data, workflows: newWorkflows })
    },
    [data, setData]
  )

  const addTrigger = useCallback(
    (afterOrderNumber: string) => {
      const newWorkflows = [...data.workflows]
      const insertIndex = newWorkflows.findIndex(
        (workflow) => workflow.afterOrderNumber > parseInt(afterOrderNumber)
      )
      const newWorkflow = initialSubscriptionFlowWorkflowFormData(parseInt(afterOrderNumber))
      insertIndex === -1
        ? newWorkflows.push(newWorkflow)
        : newWorkflows.splice(insertIndex, 0, newWorkflow)
      setData({ ...data, workflows: newWorkflows })
      setSaveResult(null)
    },
    [data, setData, setSaveResult]
  )

  const removeTrigger = useCallback(
    (indexed: number) => {
      const newWorkflows = [...data.workflows]
      newWorkflows.splice(indexed, 1)
      setData({ ...data, workflows: newWorkflows })
      setSaveResult(null)
    },
    [data, setData, setSaveResult]
  )

  return (
    <Layout>
      <Layout.Section>
        <LegacyCard title="Name">
          <LegacyCard.Section>
            <MemoedTextField
              name="name"
              type="text"
              label="Name"
              labelHidden
              value={data.name}
              onChange={(val) => setData({ ...data, name: val })}
              autoComplete="off"
              error={resultErrorField(saveResult, 'name') ?? undefined}
            />
          </LegacyCard.Section>
        </LegacyCard>

        <LegacyCard
          title={
            <LegacyStack>
              <LegacyStack.Item fill>
                <LegacyStack alignment="center">
                  <Text variant="headingMd" as="h2">
                    Subscription conditions
                  </Text>
                  <LegacyHelpIcon>
                    These conditions are being checked for each trigger. If they are not met, the
                    workflow trigger will not run.
                  </LegacyHelpIcon>
                </LegacyStack>
              </LegacyStack.Item>
              <Button onClick={filtersModalActivator.show} plain>
                Edit
              </Button>
            </LegacyStack>
          }
        >
          <LegacyCard.Section>
            {data.filters.length === 0 && (
              <>
                <Banner
                  title="No Conditions Set: This workflow will apply to ALL new and existing subscriptions."
                  status="warning"
                  action={{
                    content: 'Set conditions',
                    onAction: filtersModalActivator.show,
                  }}
                >
                  <Text as="p">
                    Click <b>Set conditions</b> to define which subscriptions this workflow will
                    affect. Without conditions, it will affect all new and existing subscriptions.
                  </Text>
                </Banner>
              </>
            )}
            {data.filters.length > 0 && (
              <FiltersLine rootFilterType={data.rootFilterType} filters={data.filters} />
            )}
          </LegacyCard.Section>
        </LegacyCard>

        <SubscriptionFlowFiltersModal
          open={filtersModalActivator.open}
          onClose={filtersModalActivator.close}
          filters={data.filters}
          onChange={(filters) => setData({ ...data, filters })}
          rootFilterType={data.rootFilterType}
          onRootFilterTypeChange={(rootFilterType) => setData({ ...data, rootFilterType })}
        />
        <LegacyCard title="Checkout (1st order)" sectioned>
          <Text as="p">
            Original order created after checkout (or first order for manually created
            subscriptions) won't be modified by the workflow.{' '}
          </Text>
        </LegacyCard>
        {data.workflows.map((workflow, index) => (
          <SubscriptionFlowTriggerCard
            key={index}
            data={workflow}
            onChange={(workflow) => updateWorkflow(workflow, index)}
            onDelete={() => removeTrigger(index)}
            saveResult={saveResult}
            index={index}
          />
        ))}
      </Layout.Section>
      <Layout.Section>
        <LegacyStack distribution="trailing">
          <Popover
            active={triggerPopoverActive}
            activator={
              <Button onClick={toggleTriggerPopover} disclosure primary>
                Add trigger
              </Button>
            }
            onClose={toggleTriggerPopover}
          >
            <OptionList
              onChange={(selected) => {
                addTrigger(selected[0])
                toggleTriggerPopover()
              }}
              options={triggerOptions}
              selected={[]}
            />
          </Popover>
        </LegacyStack>
      </Layout.Section>

      {isDirty && (
        <ContextualSaveBar
          message="Unsaved changes"
          alignContentFlush={true}
          saveAction={{
            onAction: () => props.onSave(data),
            loading: props.isSaving,
            disabled: false,
          }}
          discardAction={{
            onAction: () => setData(initialData),
          }}
          secondaryMenu={
            <ShowIntercomButton
              message={
                props.isNew
                  ? 'I have a question about creating subscription automation workflows.'
                  : 'I have questions about editing subscription automation workflows.'
              }
            >
              Chat with us
            </ShowIntercomButton>
          }
        />
      )}
    </Layout>
  )
}
