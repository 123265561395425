import { Banner, Modal, TextContainer } from '@shopify/polaris'
import { useCallback, useState } from 'react'
import useSWR from 'swr'

import { backendFetchResult, backendFetchResultData, Result } from '../common/api'
import { pluralize, ResultBanner, ResultToast, useShopFlag } from '../common/helpers'
import { CapSelect } from './CapSelect'

const NeedingAttemptBanner = ({ count }: { count: number }) =>
  count === 0 ? null : (
    <>
      <Banner status="warning">
        <p>
          You have {count} pending {pluralize(count, 'subscription')} that will resume processing
          immediately. To avoid creating orders for all missed cycles, make sure to reschedule{' '}
          {pluralize(count, 'it', 'them')} to a future date.
        </p>
      </Banner>
      <br />
    </>
  )

const FeatureCheckBanner = ({ planId }: { planId: string }) => {
  const { data: missingPremiumFeatures } = useSWR<string[]>(
    ['pricing-plan-missing-premium-features', planId],
    () => backendFetchResultData('GET', `/pricing-plans/missing-features/${planId}`)
  )

  return missingPremiumFeatures && missingPremiumFeatures.length > 0 ? (
    <>
      <Banner status="warning">
        <p>
          Please note, that switching to this plan will restrict access to the following features:
        </p>
        <ul>
          {missingPremiumFeatures.map((f) => (
            <li>{f}</li>
          ))}
        </ul>
      </Banner>
      <br />
    </>
  ) : null
}

export const ActivateDialog = (props: {
  open: boolean
  onClose: () => any
  planId: string
  defaultCap: number
}) => {
  const [primaryButtonEnabled, setPrimaryButtonEnabled] = useState(true)
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)
  const [cap, setCap] = useState<number>(props.defaultCap)
  const { planId } = props
  const needingAttemptCount = useShopFlag('subscriptions_needing_attempt_when_enabled') ?? 0

  const action = useCallback(async () => {
    setPrimaryButtonEnabled(false)

    const body = JSON.stringify({ planId, cap })
    const res = await backendFetchResult<string>('POST', '/pricing-plans/activate', { body })

    setSaveResult(res)

    if (res.status === 'success' && res.data) {
      window.open(res.data, '_top')
    }

    setPrimaryButtonEnabled(true)
  }, [planId, cap, setPrimaryButtonEnabled])

  const primaryAction = {
    content: 'Activate',
    loading: !primaryButtonEnabled,
    onAction: action,
  }

  const cancelAction = { content: 'Cancel', onAction: props.onClose }

  return (
    <>
      <Modal
        open={props.open}
        title={'Activate pricing plan'}
        onClose={props.onClose}
        primaryAction={primaryAction}
        secondaryActions={[cancelAction]}
        sectioned
      >
        <ResultBanner result={saveResult} setResult={setSaveResult} />
        <NeedingAttemptBanner count={needingAttemptCount} />
        <FeatureCheckBanner planId={planId} />
        <TextContainer>
          <p>You'll be redirected to Shopify page to confirm the purchase</p>
          <CapSelect default={props.defaultCap} newPlanId={props.planId} onChange={setCap} />
        </TextContainer>
      </Modal>
      <ResultToast result={saveResult} setResult={setSaveResult} />
    </>
  )
}
