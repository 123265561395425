import { LegacyCard, LegacyStack, Text } from '@shopify/polaris'
import React from 'react'
import useSWR from 'swr'

import { backendFetchResultData, SubscriptionContractSubscriptionStatus } from '../common/api'
import { CenteredSpinner } from '../common/helpers'
import { HelpTitle } from '../common/HelpIcon'

interface BasicStatCardProps {
  title: string
  tooltip: string
  loading: boolean
  children?: any
  filterLabel?: string
}

export const BasicStatCard = (props: BasicStatCardProps) => (
  <LegacyCard
    title={<HelpTitle title={props.title} children={props.tooltip}></HelpTitle>}
    sectioned
    actions={[{ content: props.filterLabel, disabled: true }]}
  >
    {props.loading && <CenteredSpinner />}
    {!props.loading && (
      <Text variant="heading2xl" as="p">
        {props.children}
      </Text>
    )}
  </LegacyCard>
)

interface BasicStatsResponse {
  totalSubscriptions: number
  subscriptionCountByStatus: { [key in SubscriptionContractSubscriptionStatus]: number }
  totalOrders: number
  totalCustomers: number
  activeCustomers: number
}

const fetchBasicStats = () =>
  backendFetchResultData<BasicStatsResponse>('GET', '/dashboard/basic-stats')

export const BasicStats = () => {
  const { data } = useSWR<BasicStatsResponse>(['dashboard-stats'], fetchBasicStats, {
    revalidateOnFocus: false,
  })

  const loading = !data

  return (
    <LegacyStack vertical>
      <LegacyStack distribution="fillEvenly">
        <BasicStatCard
          title="Total subscriptions"
          tooltip="Number of all subscription contracts across various statuses."
          loading={loading}
        >
          {data?.totalSubscriptions}
        </BasicStatCard>
        <BasicStatCard
          title="Active subscriptions"
          tooltip="Number of subscriptions currently in an active status. This includes subscriptions retrying failed payments."
          loading={loading}
        >
          {data?.subscriptionCountByStatus[SubscriptionContractSubscriptionStatus.Active] ?? 0}
        </BasicStatCard>
        <BasicStatCard
          title="Paused subscriptions"
          tooltip="Number of subscriptions temporarily on hold, marked as paused."
          loading={loading}
        >
          {data?.subscriptionCountByStatus[SubscriptionContractSubscriptionStatus.Paused] ?? 0}
        </BasicStatCard>
      </LegacyStack>
      <LegacyStack distribution="fillEvenly">
        <BasicStatCard
          title="Cancelled subscriptions"
          tooltip="Number of subscriptions that have been terminated or cancelled by a customer or admin."
          loading={loading}
        >
          {data?.subscriptionCountByStatus[SubscriptionContractSubscriptionStatus.Cancelled] ?? 0}
        </BasicStatCard>
        <BasicStatCard
          title="Expired subscriptions"
          tooltip="Number of subscriptions that have reached the end of their term successfully."
          loading={loading}
        >
          {data?.subscriptionCountByStatus[SubscriptionContractSubscriptionStatus.Expired] ?? 0}
        </BasicStatCard>
        <BasicStatCard
          title="Failed subscriptions"
          tooltip="Number of subscriptions that were cancelled due to failing the maximum retry attempts set in your configuration. Excludes subscriptions currently undergoing the dunning process."
          loading={loading}
        >
          {data?.subscriptionCountByStatus[SubscriptionContractSubscriptionStatus.Failed] ?? 0}
        </BasicStatCard>
      </LegacyStack>
      <LegacyStack distribution="fillEvenly">
        <BasicStatCard
          title="Total orders"
          tooltip="The total number of orders associated with subscription plans, including the initial checkout order. Please note that our reporting includes initial orders, which may differ from Shopify sales channel reporting for our app."
          loading={loading}
        >
          {data?.totalOrders}
        </BasicStatCard>
        <BasicStatCard
          title="Total customers"
          tooltip="Number of all subscription customers, including those with active and inactive subscriptions."
          loading={loading}
        >
          {data?.totalCustomers}
        </BasicStatCard>
        <BasicStatCard
          title="Active customers"
          tooltip="Number of customers with one or more active subscriptions."
          loading={loading}
        >
          {data?.activeCustomers}
        </BasicStatCard>
      </LegacyStack>
    </LegacyStack>
  )
}
