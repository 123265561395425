import {
  Badge,
  Button,
  DataTable,
  Icon,
  Layout,
  LegacyCard,
  LegacyStack,
  Loading,
  Modal,
  Page,
  Pagination,
  Select,
  SelectOption,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  TextContainer,
} from '@shopify/polaris'
import { ViewMinor } from '@shopify/polaris-icons'
import { DateStyle } from '@shopify/react-i18n'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'

import { backendFetchResultData, SimplePaginator } from '../common/api'
import { Footer } from '../common/Footer'
import {
  FormattedDate,
  fragmentList,
  InternalLink,
  truncateString,
  useQuery,
} from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { WorkflowExecution, WorkflowExecutionStatus } from '../common/workflow-execution-model'
import {
  WorkflowAction,
  workflowActionLabels,
  workflowTriggerLabels,
  WorkflowTriggerType,
} from '../common/workflow-models'
import { SellingPlansContext, useSellingPlansSWR } from '../workflow-page/api'
import { FiltersLine, WorkflowDescription } from '../workflow-page/WorkflowDescription'

const statusOptions: SelectOption[] = [
  { value: '', label: 'All' },
  { value: 'Queued', label: 'Queued' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Retrying', label: 'Retrying' },
  { value: 'Failed', label: 'Failed' },
]

interface WorkflowLogsPageParams {
  page: number
  status: WorkflowExecutionStatus
  subscription_id?: number
}

const useWorkflowLogsPageParams = (): WorkflowLogsPageParams => {
  const query = useQuery()
  const page = parseInt(query.get('page') || '1') || 1
  const status = (query.get('status') as WorkflowExecutionStatus | null) || ''
  const subscription_id = query.get('subscription_id')
    ? parseInt(query.get('subscription_id') ?? '0')
    : undefined

  return useMemo(() => ({ page, status, subscription_id }), [page, status, subscription_id])
}

export const useWorkflowLogs = (params: WorkflowLogsPageParams) => {
  const url = urls.urlWithParams('/workflows/logs', params)

  return useSWR<SimplePaginator<WorkflowExecution>>(['workflow-logs', params], () =>
    backendFetchResultData<SimplePaginator<WorkflowExecution>>('GET', url)
  )
}

const MySkeletonPage = () => (
  <SkeletonPage title="Recent workflow logs" backAction={true}>
    <Layout>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
)

export const TriggersList = ({ triggers }: { triggers: WorkflowTriggerType[] }) => {
  let items: React.ReactNode[] = []

  triggers.forEach((trigger, index) => {
    items.push(workflowTriggerLabels[trigger])
  })

  return (
    <LegacyStack vertical spacing="extraTight">
      {fragmentList(items)}
    </LegacyStack>
  )
}

const StatusBadge = ({ workflowExecution }: { workflowExecution: WorkflowExecution }) => {
  const statuses: Record<
    WorkflowExecutionStatus,
    'success' | 'critical' | 'warning' | 'info' | undefined
  > = {
    Completed: 'success',
    Retrying: 'warning',
    Failed: 'critical',
    Queued: undefined,
    '': undefined,
  }

  const status = statuses[workflowExecution.status] ?? undefined

  return <Badge status={status}>{workflowExecution.status}</Badge>
}

const WorkflowActionNames = ({ actions }: { actions: WorkflowAction[] }) => (
  <LegacyStack vertical spacing="extraTight">
    {actions.map((action, index) => (
      <span>{workflowActionLabels[action[0]]}</span>
    ))}
  </LegacyStack>
)

const workflowLink = (we: WorkflowExecution): string => {
  if (we.workflow_group_id) {
    return urls.subscriptionFlowsUrl(we.workflow_group_id)
  }
  if (we.workflow_id) {
    return urls.workflowUrl(we.workflow_id)
  }

  return '#'
}

interface TableProps {
  items: WorkflowExecution[]
}

const Table = (props: TableProps) => {
  const [openedLog, setOpenedLog] = useState<WorkflowExecution | null>(null)
  const closeDetails = () => setOpenedLog(null)

  const rows = props.items.map((item: WorkflowExecution) => [
    <TriggersList triggers={item.triggers} />,
    <>
      {item.workflow_id && (
        <>
          {item.workflow && (
            <InternalLink url={workflowLink(item)} style={{ whiteSpace: 'break-spaces' }}>
              {item.workflow.name
                ? truncateString(item.workflow.name, 100)
                : `#${item.workflow_id}`}
            </InternalLink>
          )}
          {!item.workflow && <Badge>Deleted</Badge>}
        </>
      )}
      {!item.workflow_id && (
        <Text as="span" color="subdued">
          <WorkflowActionNames actions={item.workflow_actions} />
        </Text>
      )}
    </>,
    <InternalLink url={urls.subscriptionUrl(item.subscription_contract_rest_id)}>
      #{item.subscription_contract_rest_id}
    </InternalLink>,
    <StatusBadge workflowExecution={item} />,
    <FormattedDate
      date={new Date(item.created_at)}
      format={{ day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }}
    />,
    <Button onClick={() => setOpenedLog(item)} icon={<Icon source={ViewMinor} color="base" />} />,
  ])

  return (
    <>
      <DataTable
        columnContentTypes={['text', 'text', 'text', 'text', 'numeric', 'text', 'text']}
        headings={['Event', 'Workflow', 'Subscription ID', 'Status', 'Time', '']}
        rows={rows}
        verticalAlign="middle"
      />
      <Modal
        open={!!openedLog}
        title={
          <>
            <span>Execution log for workflow: </span>
            {openedLog?.workflow &&
              (openedLog.workflow.name
                ? truncateString(openedLog.workflow.name, 50)
                : `#${openedLog?.workflow_id}`)}
          </>
        }
        large
        onClose={closeDetails}
        secondaryActions={[{ content: 'Close', onAction: closeDetails }]}
        sectioned
      >
        {openedLog && (
          <>
            <Modal.Section>
              <LegacyStack vertical>
                <Text variant="headingSm" as="h3">
                  Event
                </Text>
                <TriggersList triggers={openedLog.triggers} />,
              </LegacyStack>
            </Modal.Section>
            <Modal.Section>
              <LegacyStack vertical>
                <Text variant="headingSm" as="h3">
                  Status
                </Text>
                <StatusBadge workflowExecution={openedLog} />
              </LegacyStack>
            </Modal.Section>
            <Modal.Section>
              <LegacyStack vertical>
                <Text variant="headingSm" as="h3">
                  Time
                </Text>
                <FormattedDate
                  date={new Date(openedLog.created_at)}
                  format={{ day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }}
                />
              </LegacyStack>
            </Modal.Section>
            <Modal.Section>
              <LegacyStack vertical>
                <Text variant="headingSm" as="h3">
                  Subscription ID
                </Text>
                <InternalLink url={urls.subscriptionUrl(openedLog.subscription_contract_rest_id)}>
                  #{openedLog.subscription_contract_rest_id}
                </InternalLink>
              </LegacyStack>
            </Modal.Section>
            {openedLog.workflow_group_id && (
              <Modal.Section>
                <LegacyStack vertical>
                  <Text variant="headingSm" as="h3">
                    Subscription conditions
                  </Text>
                  {openedLog.workflow_group_filter &&
                    openedLog.workflow_group_filter[1]?.length > 0 && (
                      <FiltersLine
                        rootFilterType={openedLog.workflow_group_filter[0]}
                        filters={openedLog.workflow_group_filter[1]}
                      />
                    )}
                  {(!openedLog.workflow_group_filter ||
                    !openedLog.workflow_group_filter[1] ||
                    openedLog.workflow_group_filter[1].length === 0) && (
                    <Text as="span" color="subdued">
                      Run workflow unconditionally
                    </Text>
                  )}
                </LegacyStack>
              </Modal.Section>
            )}
            <Modal.Section>
              <LegacyStack vertical>
                <LegacyStack>
                  <LegacyStack.Item fill>
                    <Text variant="headingSm" as="h3">
                      Workflow details
                    </Text>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    {openedLog.workflow_id && (
                      <>
                        {openedLog?.workflow && (
                          <InternalLink url={workflowLink(openedLog)}>Edit workflow</InternalLink>
                        )}
                        {!openedLog?.workflow && <Badge>Deleted</Badge>}
                      </>
                    )}
                  </LegacyStack.Item>
                </LegacyStack>
                <WorkflowDescription
                  triggers={openedLog.workflow_triggers}
                  actions={openedLog.workflow_actions}
                  filters={openedLog.workflow_filter[1]}
                  rootFilterType={openedLog.workflow_filter[0]}
                />
              </LegacyStack>
            </Modal.Section>
          </>
        )}
      </Modal>
    </>
  )
}

export const WorkflowLogsPage = () => {
  const params = useWorkflowLogsPageParams()
  const history = useHistory()

  const { data, error, isValidating } = useWorkflowLogs(params)
  const sellingPlansSWR = useSellingPlansSWR()

  const updateParams = useCallback(
    (newParams: Partial<WorkflowLogsPageParams>) => {
      history.replace(urls.workflowLogsUrl({ ...params, ...newParams }))
    },
    [params, history]
  )

  const setStatus = useCallback(
    (status: string) => updateParams({ status: status as WorkflowExecutionStatus, page: 1 }),
    [updateParams]
  )

  const pageTitle = useMemo(() => {
    if (params.subscription_id) {
      return `Recent workflow logs for subscription #${params.subscription_id}`
    }
    return 'Recent workflow logs'
  }, [params.subscription_id])

  const backUrl = useMemo(() => {
    if (params.subscription_id) {
      return urls.subscriptionUrl(params.subscription_id)
    }
    return urls.workflowsUrl
  }, [params.subscription_id])

  return (
    <>
      {isValidating && <Loading />}
      <Tabs selected={TabIndex.Settings} />

      {error && <LoadErrorPage />}
      {!error && (!data || !sellingPlansSWR.data) && <MySkeletonPage />}
      {!error && data && sellingPlansSWR.data && (
        <Page title={pageTitle} backAction={{ content: 'Workflows', url: backUrl }}>
          <SellingPlansContext.Provider value={sellingPlansSWR.data}>
            <Layout>
              <Layout.Section>
                <LegacyCard>
                  <LegacyCard.Section>
                    <LegacyStack distribution="trailing">
                      <Select
                        label="Status:"
                        labelInline
                        options={statusOptions}
                        value={params.status}
                        onChange={setStatus}
                      />
                    </LegacyStack>
                  </LegacyCard.Section>
                  <LegacyStack vertical>
                    <Table items={data.data} />
                    <LegacyStack distribution="center">
                      <Pagination
                        label={`Page: ${params.page}`}
                        hasPrevious={!!data.prev_page_url}
                        onPrevious={() => updateParams({ page: params.page - 1 })}
                        hasNext={!!data.next_page_url}
                        onNext={() => updateParams({ page: params.page + 1 })}
                      />
                    </LegacyStack>
                    {!data.next_page_url && (
                      <LegacyStack distribution="center">
                        <Text as="p" color="subdued" variant="bodySm">
                          <br />
                          Only logs recorded after{' '}
                          <FormattedDate
                            date={new Date('2023-06-16T10:00:00Z')}
                            format={{
                              style: DateStyle.Short,
                              hour: 'numeric',
                              minute: 'numeric',
                            }}
                          />{' '}
                          are available
                          <br />
                          <br />
                        </Text>
                      </LegacyStack>
                    )}
                    <p />
                  </LegacyStack>
                </LegacyCard>
              </Layout.Section>
              <Layout.Section>
                <Footer />
              </Layout.Section>
            </Layout>
          </SellingPlansContext.Provider>
        </Page>
      )}
    </>
  )
}
