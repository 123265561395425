import { backendFetch, Result } from '../common/api'
import { GQLPaginator } from '../common/gql-models-common'

export interface SellingPlanGroup {
  id: string
  merchantCode: string
  summary: string
}

export const fetchSellingPlanGroups = (
  _: string,
  sortKey: string,
  beforeCursor: string,
  afterCursor: string
): Promise<Result<GQLPaginator<SellingPlanGroup>>> =>
  backendFetch(
    'GET',
    `/plans?sort-key=${sortKey}&before=${encodeURIComponent(
      beforeCursor
    )}&after=${encodeURIComponent(afterCursor)}`
  )
