import { Button, FormLayout, Modal, Text, TextField } from '@shopify/polaris'

import { DialogActivator } from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { FormattedPrice, useShopCurrency } from '../common/Price'
import { AddOneTimeGiftProductPayload, AddProductPayload } from './common'

export const AddOneTimeGiftProductSection = ({
  payload,
  setPayload,
  variantTitle,
  variantActivator,
}: {
  payload: AddOneTimeGiftProductPayload
  setPayload: (payload: AddProductPayload) => any
  variantTitle: string
  variantActivator: DialogActivator
}) => {
  const shopCurrency = useShopCurrency()
  return (
    <Modal.Section>
      <FormLayout>
        <TextField
          label={
            <Text as="span">
              Product variant to add
              <LegacyHelpIcon>
                The selected product variant will be added to the subscription and will be marked as
                a 'Gift' at <FormattedPrice price={{ amount: '0', currencyCode: shopCurrency }} />.
                It will be automatically removed after the order is created.
              </LegacyHelpIcon>
            </Text>
          }
          readOnly={true}
          value={variantTitle}
          connectedRight={<Button onClick={variantActivator.show}>Select</Button>}
          autoComplete="off"
        />
      </FormLayout>
    </Modal.Section>
  )
}
