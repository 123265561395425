import { Button, FormLayout, Modal, TextField } from '@shopify/polaris'

import { DialogActivator } from '../common/helpers'

export const RemoveProductSection = ({
  variantTitle,
  variantActivator,
}: {
  variantTitle: string
  variantActivator: DialogActivator
}) => (
  <Modal.Section>
    <FormLayout>
      <TextField
        label="Product variant to remove"
        readOnly={true}
        value={variantTitle}
        connectedRight={<Button onClick={variantActivator.show}>Select</Button>}
        autoComplete="off"
      />
    </FormLayout>
  </Modal.Section>
)
