import { Checkbox, FormLayout, LegacyCard, TextField } from '@shopify/polaris'
import { useFormikContext } from 'formik'
import React from 'react'

import { useFormikHandleChangeCallback, useSystemData } from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { UserSettings } from './api'

export const SystemNotificationsCard = () => {
  const formik = useFormikContext<UserSettings>()
  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)
  const systemData = useSystemData()

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <FormLayout>
          <TextField
            id="send_user_emails_to"
            name="send_user_emails_to"
            label="Send system notifications to"
            helpText="Enter comma-separated list of emails"
            placeholder={systemData?.shopData.email}
            value={formik.values.send_user_emails_to}
            onChange={handleChange}
            autoComplete="email"
          />
        </FormLayout>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <FormLayout>
          <Checkbox
            id="send_user_subscription_emails"
            name="send_user_subscription_emails"
            label={
              <span>
                Send system notifications about subscriptions{' '}
                <LegacyHelpIcon>
                  Send emails about new subscriptions, recurring payments, and other important
                  events
                </LegacyHelpIcon>
              </span>
            }
            checked={formik.values.send_user_subscription_emails}
            onChange={handleChange}
          />
        </FormLayout>
      </LegacyCard.Section>
    </LegacyCard>
  )
}
