import { Button, Card, HorizontalGrid, Image, Link, Text, VerticalStack } from '@shopify/polaris'

export const PaywhirlAppsCard = () => {
  return (
    <Card>
      <HorizontalGrid columns={{ xs: 1, sm: 2 }} alignItems="center" gap="8">
        <VerticalStack align="end" inlineAlign="center" gap="3">
          <Image
            alt=""
            source="/images/glow_logo.svg"
            style={{ width: '100%', maxWidth: '90px', height: 'auto' }}
          ></Image>
          <Text as="p" alignment="center">
            Reward your subscribers with points for each purchase and amplify your reach through
            their referrals.
          </Text>
          <Link url="https://apps.shopify.com/glow-loyalty">
            <Button>Start Rewarding Today</Button>
          </Link>
        </VerticalStack>
        <VerticalStack align="end" inlineAlign="center" gap="3">
          <Image
            alt=""
            source="/images/Upsell-logo.svg"
            style={{ width: '100%', maxWidth: '250px', height: 'auto' }}
          ></Image>
          <Text as="p" alignment="center">
            Increase your average order value by presenting customers with enticing one-click
            upsells and cross-sells.
          </Text>
          <Link url="https://apps.shopify.com/upsell-wizard">
            <Button>Create Upsells Now</Button>
          </Link>
        </VerticalStack>
      </HorizontalGrid>
    </Card>
  )
}
