import { FormLayout, Modal, TextField } from '@shopify/polaris'
import { useState } from 'react'

import { mutateWithResult } from '../common/api'
import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { SubscriptionContract } from '../common/subscription-models'

interface Props {
  sc: SubscriptionContract
  open: boolean
  close: () => any
}

export const DiscountCodeDialog = ({ sc, open, close }: Props) => {
  const [code, setCode] = useState('')

  const saveAction = useApiActionState({
    method: 'PUT',
    url: `/subscriptions/${sc.rest_id}`,
    opts: { body: JSON.stringify({ discountCodeToApply: code }) },
    onSuccess: (res) => {
      mutateWithResult(['subscription-contract', `${sc.rest_id}`], res.data)
      close()
    },
    bannerPosition: 'external',
  })

  return (
    <>
      <Modal
        open={open}
        title="Apply discount code"
        onClose={close}
        primaryAction={{ content: 'Save', onAction: saveAction.run, loading: saveAction.loading }}
        secondaryActions={[{ content: 'Cancel', onAction: close }]}
        sectioned
      >
        <ApiActionBanner state={saveAction} />
        <FormLayout>
          <TextField label="Discount code" value={code} onChange={setCode} autoComplete="off" />
        </FormLayout>
      </Modal>

      <ApiAction state={saveAction} />
    </>
  )
}
