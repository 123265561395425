import { Box, DatePicker, LegacyStack, Range, TextField } from '@shopify/polaris'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'

const dateRex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/

const buildMonthDate = (date?: string) => {
  const dateObj = date ? new Date(date) : new Date()

  return {
    month: dateObj.getMonth(),
    year: dateObj.getFullYear(),
  }
}

export const DatePickerWithInput = ({
  label,
  date,
  updateDate,
}: {
  label: string
  date?: string
  updateDate: (date?: string) => void
}) => {
  const [monthDate, setMonthDate] = useState<{ month: number; year: number }>(buildMonthDate(date))
  const updateMonthDate = useCallback(
    (month: number, year: number) => setMonthDate({ month, year }),
    []
  )

  const [dateInput, setDateInput] = useState(date ?? '')
  const [dateError, setDateError] = useState('')

  const handleDateInputBlur = useCallback(() => {
    if (dateInput !== '' && !dateRex.test(dateInput)) {
      setDateError('Must match YYYY-MM-DD format')
    } else {
      setDateError('')
      updateDate(dateInput === '' ? undefined : dateInput)
      setMonthDate(buildMonthDate(dateInput))
    }
  }, [dateInput, setDateError, updateDate])

  const handleSelectedRange = useCallback(
    (range: Range) => {
      const dateStr = DateTime.fromJSDate(range.start).toISODate()
      updateDate(dateStr)
      setDateInput(dateStr)
    },
    [updateDate, setDateInput]
  )

  return (
    <Box width="250px">
      <LegacyStack vertical>
        <TextField
          value={dateInput}
          placeholder="YYYY-MM-DD"
          label={label}
          autoComplete="off"
          onChange={setDateInput}
          onBlur={handleDateInputBlur}
          error={dateError}
        />
        <DatePicker
          month={monthDate.month}
          year={monthDate.year}
          onMonthChange={updateMonthDate}
          onChange={handleSelectedRange}
          selected={date ? new Date(date) : undefined}
        />
      </LegacyStack>
    </Box>
  )
}
