import { LegacyStack } from '@shopify/polaris'
import React from 'react'

import { LegacyHelpIcon } from '../common/HelpIcon'

export const DetailsItem = ({
  label,
  helpIcon,
  children,
}: {
  label: React.ReactNode
  helpIcon?: React.ReactNode
  children: any
}) => (
  <LegacyStack distribution="equalSpacing" spacing="extraTight">
    <span>
      {label}:{helpIcon && <LegacyHelpIcon>{helpIcon}</LegacyHelpIcon>}
    </span>
    <span children={children} />
  </LegacyStack>
)

export const LongDetailsItem = ({
  label,
  content,
}: {
  label: React.ReactNode
  content: string
}) => (
  <div
    style={{
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      columnGap: '8px',
    }}
  >
    <div style={{ whiteSpace: 'nowrap' }}>{label}:</div>
    <div
      style={{
        textAlign: 'right',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      title={content}
    >
      {content}
    </div>
  </div>
)
