import { Button, OptionList, Popover } from '@shopify/polaris'
import { AdjustMinor } from '@shopify/polaris-icons'
import { useCallback } from 'react'

import { gidToId, idToGid, useToggle } from '../common/helpers'
import { sellingPlanOptionListSections, useSellingPlans } from '../workflow-page/api'

export const SellingPlanSelector = ({
  sellingPlans,
  onChange,
  disabled,
}: {
  sellingPlans: string[]
  onChange: (selected: string[]) => void
  disabled?: boolean
}) => {
  const [active, toggleActive] = useToggle(false)
  const spData = useSellingPlans()
  const allGids = spData.map((section) => section.sellingPlans.map((sp) => sp.gid)).flat()

  const optionListSections = [
    {
      options: [{ value: 'any', label: 'All selling plans (including non-existing)' }],
    },
    ...sellingPlanOptionListSections(spData, sellingPlans.includes('any')),
  ]

  const handleChange = useCallback(
    (selected: string[]) => {
      if (selected.includes('any')) {
        onChange(['any'])
        return
      }

      if (sellingPlans.includes('any')) {
        onChange([])
        return
      }

      onChange(selected.map((gid) => gidToId(gid)))
    },
    [onChange, sellingPlans]
  )

  const activator = (
    <Button disclosure onClick={toggleActive} icon={AdjustMinor} size="slim" disabled={disabled}>
      Selling Plan Filter
    </Button>
  )

  return (
    <>
      <Popover active={active} activator={activator} onClose={toggleActive}>
        <OptionList
          allowMultiple={true}
          onChange={handleChange}
          sections={optionListSections}
          selected={
            sellingPlans.includes('any')
              ? ['any', ...allGids]
              : sellingPlans.map((id) => idToGid(id, 'SellingPlan'))
          }
        />
      </Popover>
    </>
  )
}
