import { Button, Icon, Layout, LegacyStack, Page } from '@shopify/polaris'
import { PlayMinor } from '@shopify/polaris-icons'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Footer } from '../common/Footer'
import { pwCache, useDialogActivator, useQuery } from '../common/helpers'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { VideoModal } from '../common/VideoModal'
import { SubscriptionFilter, useSubscriptionFilter } from './api'
import { SubscriptionList } from './SubscriptionList'

export const SubscriptionsIndexPage = () => {
  const query = useQuery()
  const filter = useSubscriptionFilter()
  const history = useHistory()
  const subscriptionExportActivator = useDialogActivator()
  const videoActivator = useDialogActivator()

  const updateFilter = useCallback(
    (newFilter: Partial<SubscriptionFilter>) => {
      pwCache.subscriptionFilter = { ...filter, ...newFilter, page: newFilter.page ?? 1 }
      history.replace(urls.subscriptionsUrl())
    },
    [filter, history]
  )

  const clearFilter = useCallback(() => {
    pwCache.subscriptionFilter = {}
    history.replace(urls.subscriptionsUrl())
  }, [history])

  if (query.get('id')) {
    history.replace(urls.subscriptionUrl(query.get('id')))
    return <div />
  }

  return (
    <>
      <Tabs selected={TabIndex.Subscriptions} />
      <Page
        fullWidth
        title="Subscriptions"
        secondaryActions={[
          { content: "What's New", url: urls.newsUrl({ returnUrl: urls.subscriptionsUrl() }) },
        ]}
        primaryAction={
          <LegacyStack wrap={false}>
            <Button onClick={subscriptionExportActivator.show}>Export</Button>
            <Button url={urls.subscriptionCreateUrl} primary>
              Create
            </Button>
            <Button
              onClick={videoActivator.show}
              icon={<Icon source={PlayMinor} />}
              monochrome
              outline
            >
              Watch video
            </Button>
          </LegacyStack>
        }
      >
        <Layout>
          <Layout.Section>
            <SubscriptionList
              filter={filter}
              exportModalActivator={subscriptionExportActivator}
              updateFilter={updateFilter}
              clearFilter={clearFilter}
            />
          </Layout.Section>
          <Layout.Section>
            <Footer />
          </Layout.Section>
        </Layout>
        <VideoModal
          src="https://www.youtube.com/embed/g5WmRVrujsM"
          title="How to Manage Subscribers"
          activator={videoActivator}
        />
      </Page>
    </>
  )
}
