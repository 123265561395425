import { Button, DatePicker, Popover, Range } from '@shopify/polaris'
import { CalendarMinor } from '@shopify/polaris-icons'
import { DateStyle, useI18n } from '@shopify/react-i18n'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'

import { formatLocalISODate, useShopTimeZone, useToggle } from './helpers'

// Note: DatePickerFields always works with local dates, because
// it only provides generic date strings, not corresponding to
// any specific events

interface Props {
  date: string
  onChange: (date: string) => any
}

export const DatePickerField = ({ date, onChange }: Props) => {
  const tz = useShopTimeZone()
  const [active, toggleActive] = useToggle(false)
  const [i18n] = useI18n()

  const initialDate = DateTime.fromFormat(date, 'yyyy-MM-dd', { zone: tz }).toJSDate()

  const [monthDate, setMonthDate] = useState<{ month: number; year: number }>({
    month: initialDate.getMonth(),
    year: initialDate.getFullYear(),
  })
  const updateMonthDate = useCallback(
    (month: number, year: number) => setMonthDate({ month, year }),
    []
  )

  const setDate = useCallback(
    (range: Range) => onChange(formatLocalISODate(range.start)),
    [onChange]
  )

  return (
    <Popover
      sectioned
      active={active}
      onClose={toggleActive}
      activator={
        <Button icon={CalendarMinor} onClick={toggleActive}>
          {i18n.formatDate(initialDate, { style: DateStyle.Short })}
        </Button>
      }
    >
      <DatePicker
        year={monthDate.year}
        month={monthDate.month}
        onMonthChange={updateMonthDate}
        onChange={setDate}
      />
    </Popover>
  )
}
