import { LegacyStack, Text } from '@shopify/polaris'
import React from 'react'

import styles from './DetailsRow.module.css'

interface Props {
  label: string
  last?: boolean
  children: any
}

export const DetailsRow = ({ label, last, children }: Props) => {
  return (
    <div className={`${styles.row} ${!last ? styles.rowWithBorder : ''}`}>
      <LegacyStack distribution="equalSpacing">
        <Text as="span" fontWeight="semibold">
          {label}:
        </Text>
        <span children={children} />
      </LegacyStack>
    </div>
  )
}
