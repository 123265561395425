import { LegacyCard, LegacyStack, Pagination, PaginationProps } from '@shopify/polaris'

export const CardPagination = (props: PaginationProps) => (
  <div style={{ borderTop: '.0625rem solid var(--p-border-subdued)' }}>
    <LegacyCard.Section>
      <LegacyStack distribution="center">
        <Pagination {...props} />
      </LegacyStack>
    </LegacyCard.Section>
  </div>
)
