import '@shopify/polaris/build/esm/styles.css'

import { Banner, Frame, Page } from '@shopify/polaris'
import { I18nContext, I18nManager } from '@shopify/react-i18n'
import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { backendFetch } from './common/api'
import { ConnectPage } from './common/ConnectPage'
import { DevBar } from './common/DevBar'
import { getShopTimeZone } from './common/helpers'
import { MaintenanceBanner } from './common/MaintenanceBanner'
import { MaintenanceStatus, SystemContext, SystemData } from './common/SystemData'
import { NativeNavigationMenu } from './common/Tabs'
import { AdminRoutePropagator, AppProvider, setupRollbar } from './main/helpers'
import { PageSwitch } from './main/PageSwitch'
import { loadIntercom, setupIntercom } from './vendor/intercom'

const anyWindow = window as any
const urlParams = new URLSearchParams(window.location.search)
const shopOrigin = urlParams.get('shop') || ''
const host = urlParams.get('host') || ''
const locale = urlParams.get('locale') || 'en-US'

setupRollbar(shopOrigin)

// expose to the backend client
anyWindow.adminMode = urlParams.get('admin-mode')
anyWindow.shopOrigin = shopOrigin
anyWindow.shopifyHost = host

const RootComponent = () => {
  const [systemData, setSystemData] = useState<SystemData | undefined>(anyWindow.systemData)
  const shouldWait = !systemData
  const fullMaintenance = systemData?.app_maintenance_status === MaintenanceStatus.Full

  useEffect(() => {
    ;(async () => {
      let data = systemData

      if (!data) {
        data = (await backendFetch('GET', '/system-data')).data
        setSystemData(data)
      }

      if (!anyWindow.adminMode) {
        loadIntercom()
        setupIntercom(data?.shopData)
      }
    })()
    // eslint-disable-next-line
  }, [])

  const i18nManager = new I18nManager({
    locale,
    timezone: getShopTimeZone(systemData),
  })

  return (
    <BrowserRouter>
      <I18nContext.Provider value={i18nManager}>
        <AppProvider>
          <SystemContext.Provider value={systemData}>
            {anyWindow.adminMode && <AdminRoutePropagator />}
            {!anyWindow.adminMode && <NativeNavigationMenu />}
            <Frame
              logo={{
                contextualSaveBarSource: '/images/contextual-save-bar-logo.svg',
                width: 180,
              }}
            >
              {shouldWait && !fullMaintenance && (
                <Page>
                  <Banner status="info">Please wait...</Banner>
                </Page>
              )}
              {!shouldWait && !fullMaintenance && <PageSwitch />}
              {fullMaintenance && <MaintenanceBanner />}
              <DevBar />
            </Frame>
          </SystemContext.Provider>
        </AppProvider>
      </I18nContext.Provider>
    </BrowserRouter>
  )
}

const rootEl = document.getElementById('root') as Element
const root = createRoot(rootEl)

root.render(
  <React.StrictMode>
    {shopOrigin && <RootComponent />}
    {!shopOrigin && <ConnectPage />}
  </React.StrictMode>
)
