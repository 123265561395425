import { Button } from '@shopify/polaris'
import React from 'react'

import { useToggle } from '../common/helpers'
import { ProductModal, ProductResult } from '../common/ProductModal'

interface ProductSelectorProps {
  onSelect: (result: ProductResult) => any
  disabled?: boolean
}

export const ProductSelector = (props: ProductSelectorProps) => {
  const [productModalActive, toggleProductModalActive] = useToggle(false)

  const handleSelect = (result: ProductResult) => {
    props.onSelect(result)
    toggleProductModalActive()
  }

  return (
    <>
      <Button onClick={toggleProductModalActive} size="slim" disabled={props.disabled}>
        Product filter
      </Button>
      <ProductModal
        open={productModalActive}
        onClose={toggleProductModalActive}
        onSelect={handleSelect}
      />
    </>
  )
}
