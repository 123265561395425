import { AppProvider as ShopifyAppProvider } from '@shopify/polaris'
import enTranslations from '@shopify/polaris/locales/en.json'
import React from 'react'
import { Link as ReactRouterLink, RouteComponentProps, withRouter } from 'react-router-dom'
import Rollbar from 'rollbar'

export const setupRollbar = (shopOrigin?: string) => {
  ;(window as any).rollbar = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_APP_ENV,
      person: {
        id: shopOrigin ?? null,
      },
    },
    transform: function (payload: any) {
      let isExternal = false
      const trace = payload.body.trace
      if (trace && trace.frames) {
        for (let i = 0; i < trace.frames.length; i++) {
          const filename = trace.frames[i].filename
          if (filename && !filename.includes('paywhirl')) {
            isExternal = true
          }
        }
        if (isExternal && trace.exception?.description) {
          trace.exception.description = `[external] ${trace.exception.description}`
        }
      }
    },
  })
}

export const AdapterLink = ({ children, onClick, url, ...rest }: any) => {
  const external = url.startsWith('http')
  const newTab = external && !onClick
  return external ? (
    <a
      href={url}
      rel="noopener noreferrer"
      target={newTab ? '_blank' : undefined}
      onClick={onClick}
      children={children}
    />
  ) : (
    <ReactRouterLink to={url!} children={children} {...rest} />
  )
}

export const AppProvider = ({ children }: { children: any }) => (
  <ShopifyAppProvider i18n={enTranslations} linkComponent={AdapterLink} children={children} />
)

export const AdminRoutePropagator = withRouter((props: RouteComponentProps) => {
  window.parent.postMessage({ newPathname: props.location.pathname }, '*')
  return null
})
