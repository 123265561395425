import { Button, Page } from '@shopify/polaris'

import { useToggle } from './helpers'

export const DevBar = () => {
  const [visible, setVisible] = useToggle(false)

  ;(window as any).showDevBar = setVisible

  if (!visible) {
    return null
  }

  return (
    <Page>
      <Button
        onClick={() => {
          throw new Error('Test error')
        }}
      >
        Throw test error
      </Button>
    </Page>
  )
}
