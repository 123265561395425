import { ComplexAction, Modal, TextContainer } from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'

import { backendFetchResult, Result } from '../common/api'
import { DialogActivator, ResultBanner, ResultToast } from './helpers'

interface Props {
  activator: DialogActivator
  onSuccess?: (res: Result<any>) => any
  onClose?: () => any
  url: string
  method?: string
  opts?: any
  title?: string
  message: React.ReactNode
  destructive?: boolean
  primaryButtonTitle: string
  cancelButtonTitle?: string
}

export const ApiActionDialog = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<Result<any> | null>(null)

  useEffect(() => {
    setResult(null)
  }, [props.activator.showCounter])

  const onClose = useCallback(() => {
    props.onClose?.()
    props.activator.close()
  }, [props])

  const action = useCallback(async () => {
    setLoading(true)
    const res = await backendFetchResult(props.method || 'POST', props.url, props.opts || {})
    setLoading(false)

    setResult(res)

    if (res.status === 'success' && props.onSuccess) {
      props.onSuccess(res)
    }

    if (res.status === 'success') {
      onClose()
    }
  }, [props, onClose])

  const primaryAction: ComplexAction = {
    content: props.primaryButtonTitle,
    destructive: props.destructive,
    onAction: action,
    loading: loading,
  }

  const cancelAction = {
    content: props.cancelButtonTitle || 'Cancel',
    onAction: onClose,
    disabled: loading,
  }

  return (
    <>
      <Modal
        open={props.activator.open}
        title={props.title || 'Are you sure?'}
        onClose={onClose}
        primaryAction={primaryAction}
        secondaryActions={[cancelAction]}
        sectioned
      >
        <ResultBanner result={result} setResult={setResult} />
        <TextContainer>{props.message}</TextContainer>
      </Modal>
      <ResultToast result={result} setResult={setResult} />
    </>
  )
}
