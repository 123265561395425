import { Button, Layout, Loading, Page } from '@shopify/polaris'
import React from 'react'

import { Footer } from '../common/Footer'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import { translationsUrl } from '../common/urls'
import { UserSettings, useUserSettings } from './api'
import { SettingsForm } from './SettingsForm'
import { SkeletonPage } from './SkeletonPage'

const LoadedPage = ({ initialValues }: { initialValues: UserSettings }) => (
  <Page
    title="Settings"
    primaryAction={<Button url={translationsUrl()}>Manage translations</Button>}
  >
    <SettingsForm initialValues={initialValues} />
    <Layout.Section>
      <Footer />
    </Layout.Section>
  </Page>
)

export const SettingsPage = () => {
  const { data, error, isValidating } = useUserSettings()

  return (
    <>
      {isValidating && <Loading />}
      <Tabs selected={TabIndex.Settings} />

      {error && <LoadErrorPage />}
      {!error && !data && <SkeletonPage />}
      {!error && data && <LoadedPage initialValues={data} />}
    </>
  )
}
