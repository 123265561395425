import { Button, ButtonGroup, DataTable, LegacyCard } from '@shopify/polaris'
import { CaretDownMinor, CaretUpMinor, EditMinor } from '@shopify/polaris-icons'
import React from 'react'

import { OrderLink, ShortDate, useDialogActivator, useToggle } from '../common/helpers'
import { FulfillmentOrder, SubscriptionContract } from '../common/subscription-models'
import { RescheduleFulfillmentOrderDialog } from './RescheduleFulfillmentOrderDialog'
import styles from './ScheduledFulfillmentsCard.module.css'

interface Props {
  sc: SubscriptionContract
  fo: FulfillmentOrder
}

export const FulfillmentItemActions = ({ sc, fo }: Props) => {
  const rescheduleActivator = useDialogActivator()

  return (
    <>
      <ButtonGroup segmented>
        <Button icon={EditMinor} onClick={rescheduleActivator.show} size="slim" />
      </ButtonGroup>

      <RescheduleFulfillmentOrderDialog
        open={rescheduleActivator.open}
        close={rescheduleActivator.close}
        showCounter={rescheduleActivator.showCounter}
        sc={sc}
        fo={fo}
      />
    </>
  )
}

export const ScheduledFulfillmentsCard = ({ sc }: { sc: SubscriptionContract }) => {
  const [expanded, toggleExpanded] = useToggle(false)

  const fos = sc.fulfillment_orders

  if (fos.length === 0) {
    return null
  }

  const displayedFos = expanded ? fos : fos.slice(0, 5)

  const rows = displayedFos.map((fo: FulfillmentOrder) => [
    <ShortDate date={fo.fulfillAt} />,
    <OrderLink gid={fo.orderId}>Order {fo.orderName}</OrderLink>,
    <FulfillmentItemActions fo={fo} sc={sc} />,
  ])

  return (
    <LegacyCard title="Scheduled fulfillments">
      <LegacyCard.Section>
        <div className={styles.tableWrap}>
          <DataTable
            columnContentTypes={['text', 'text', 'numeric']}
            headings={['Date', 'Order', 'Action']}
            rows={rows}
            verticalAlign="middle"
          />
        </div>
      </LegacyCard.Section>

      {fos.length > 5 && (
        <LegacyCard.Section subdued>
          <Button plain onClick={toggleExpanded} icon={expanded ? CaretUpMinor : CaretDownMinor}>
            {expanded ? 'Hide all' : `View all (${fos.length})`}
          </Button>
        </LegacyCard.Section>
      )}
    </LegacyCard>
  )
}
