import { FormLayout, LegacyStack, Modal } from '@shopify/polaris'
import { useCallback, useEffect, useState } from 'react'
import { mutate } from 'swr'

import { SellingPlanAnchor } from '../common/anchor-models'
import { AnchorField } from '../common/AnchorField'
import { backendFetchResult, Result } from '../common/api'
import { ResultBanner, ResultToast } from '../common/helpers'
import { SellingPlanInterval } from '../common/plan-models'
import {
  IntervalCountField,
  IntervalField,
  MaxCyclesField,
  MinCyclesField,
} from '../common/ScheduleFields'
import { FinishingBehaviorSelect } from '../common/subscription-fields'
import {
  defaultFinishingBehavior,
  FinishingBehavior,
  SubscriptionContract,
} from '../common/subscription-models'
import * as urls from '../common/urls'
import { PrePaidDeliveriesField } from '../selling-plan-page/PrePaidDeliveriesField'

interface Props {
  sc: SubscriptionContract
  open: boolean
  close: () => any
}

export const ScheduleEditDialog = ({ open, close, sc }: Props) => {
  const [saving, setSaving] = useState(false)
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const [deliveryIntervalCount, setDeliveryIntervalCount] = useState<number>(
    sc.gql_data.deliveryPolicy.intervalCount
  )
  const [prePaidDeliveries, setPrePaidDeliveries] = useState<number>(
    Math.round(sc.gql_data.billingPolicy.intervalCount / sc.gql_data.deliveryPolicy.intervalCount)
  )
  const billingIntervalCount = deliveryIntervalCount * prePaidDeliveries

  const [interval, setInterval] = useState<SellingPlanInterval>(sc.gql_data.billingPolicy.interval)
  const [minCycles, setMinCycles] = useState<number | null>(sc.gql_data.billingPolicy.minCycles)
  const [maxCycles, setMaxCycles] = useState<number | null>(sc.gql_data.billingPolicy.maxCycles)

  const [anchor, setAnchor] = useState<SellingPlanAnchor | null>(
    sc.gql_data.deliveryPolicy.anchors?.[0] ?? null
  )

  const [finishingBehavior, setFinishingBehavior] = useState<FinishingBehavior>(
    sc.pw_data.finishingBehavior ?? defaultFinishingBehavior
  )

  // Clear field error on change
  useEffect(() => {
    setSaveResult(null)
  }, [maxCycles])

  const save = useCallback(async () => {
    setSaving(true)

    const opts = {
      body: JSON.stringify({
        input: {
          billingPolicy: {
            anchors: anchor ? [anchor] : [],
            intervalCount: billingIntervalCount,
            interval,
            minCycles,
            maxCycles,
          },
          deliveryPolicy: {
            anchors: anchor ? [anchor] : [],
            intervalCount: deliveryIntervalCount,
            interval,
          },
        },
        pwData: {
          finishingBehavior,
        },
      }),
    }
    const resp = await backendFetchResult('PUT', `/subscriptions/${sc.rest_id}`, opts)

    setSaving(false)
    mutate(['subscription-contract', `${sc.rest_id}`])

    setSaveResult(resp)

    if (resp.status === 'success') {
      close()
    }
  }, [
    close,
    setSaving,
    sc,
    anchor,
    deliveryIntervalCount,
    billingIntervalCount,
    interval,
    minCycles,
    maxCycles,
    finishingBehavior,
  ])

  return (
    <>
      <Modal
        open={open}
        title="Edit schedule"
        onClose={close}
        primaryAction={{ content: 'Save', onAction: save, loading: saving }}
        secondaryActions={[{ content: 'Cancel', onAction: close }]}
        sectioned
      >
        <FormLayout>
          <ResultBanner result={saveResult} setResult={setSaveResult} bottomMargin={false} />
          <LegacyStack distribution="fillEvenly" spacing="loose">
            <LegacyStack distribution="fillEvenly">
              <IntervalCountField
                label="Delivery frequency"
                value={deliveryIntervalCount}
                onChange={setDeliveryIntervalCount}
              />
              <IntervalField
                label="  "
                value={interval}
                onChange={(val: SellingPlanInterval) => {
                  setInterval(val)
                  setAnchor(null)
                }}
              />
            </LegacyStack>

            <PrePaidDeliveriesField
              value={prePaidDeliveries}
              onChange={setPrePaidDeliveries}
              pagePath={urls.subscriptionUrl(sc.rest_id)}
            />
          </LegacyStack>
          <AnchorField
            anchor={anchor}
            interval={interval}
            onChange={setAnchor}
            pagePath={urls.subscriptionUrl(sc.rest_id)}
          />
          <FormLayout.Group>
            <MinCyclesField value={minCycles} onChange={setMinCycles} />
            <MaxCyclesField
              value={maxCycles}
              onChange={setMaxCycles}
              error={saveResult?.field === 'maxCycles' ? saveResult.message : false}
            />
          </FormLayout.Group>
          <FinishingBehaviorSelect onChange={setFinishingBehavior} value={finishingBehavior} />
        </FormLayout>
      </Modal>
      <ResultToast result={saveResult} setResult={setSaveResult} />
    </>
  )
}
