import {
  Layout,
  LegacyCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
} from '@shopify/polaris'
import React from 'react'

export const PageSkeleton = () => (
  <>
    <Layout.Section>
      <LegacyCard sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </LegacyCard>
      <LegacyCard sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </LegacyCard>
      <LegacyCard sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </LegacyCard>
    </Layout.Section>
  </>
)
