import { Button, FormLayout, LegacyCard, LegacyStack } from '@shopify/polaris'
import { useFormikContext } from 'formik'
import React, { useCallback } from 'react'

import { MemoedTextField, useFormikHandleChangeCallback, useToggle } from '../common/helpers'
import { HelpLabel } from '../common/HelpIcon'
import {
  defaultSellingPlanGroupMerchantCode,
  defaultSellingPlanGroupName,
  defaultSellingPlanGroupOption,
  SellingPlanGroupFormData,
} from './models-form'

export const OverviewCard = () => {
  const formik = useFormikContext<SellingPlanGroupFormData>()
  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)
  const handleNameChange = useCallback(
    (value: string, id: string) => {
      if (formik.values.name === formik.values.merchantCode) {
        handleChange(value, 'merchantCode')
      }

      handleChange(value, 'name')
    },
    [handleChange, formik.values.name, formik.values.merchantCode]
  )

  const [advancedSettings, toggleAdvancedSettings] = useToggle(false)

  return (
    <LegacyCard title="Selling Plan Group" sectioned>
      <LegacyStack vertical spacing="loose">
        <FormLayout>
          <MemoedTextField
            id="name"
            name="name"
            label={
              <HelpLabel tooltip="Public name of the group, displayed on the product page">
                Name
              </HelpLabel>
            }
            type="text"
            placeholder={defaultSellingPlanGroupName}
            value={formik.values.name}
            onChange={handleNameChange}
            autoComplete="off"
          />
        </FormLayout>

        <Button plain onClick={toggleAdvancedSettings}>
          {advancedSettings ? 'Hide' : 'Show'} advanced settings
        </Button>

        {advancedSettings && (
          <FormLayout>
            <MemoedTextField
              id="merchantCode"
              name="merchantCode"
              label={
                <HelpLabel tooltip="Internal name of the group, used to identify it in the admin">
                  Internal name
                </HelpLabel>
              }
              type="text"
              placeholder={defaultSellingPlanGroupMerchantCode}
              value={formik.values.merchantCode}
              onChange={handleChange}
              autoComplete="off"
            />

            <MemoedTextField
              name="planSelectorTitle"
              id="planSelectorTitle"
              type="text"
              label={
                <HelpLabel tooltip="Title of the selling plan selector on the product page">
                  Plan selector title
                </HelpLabel>
              }
              placeholder={defaultSellingPlanGroupOption}
              value={formik.values.planSelectorTitle}
              onChange={handleChange}
              autoComplete="off"
            />
          </FormLayout>
        )}
      </LegacyStack>
    </LegacyCard>
  )
}
