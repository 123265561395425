import { Modal, Text } from '@shopify/polaris'

import { WorkflowFilter, WorkflowRootFilterType } from '../common/workflow-models'
import { WorkflowFilters, workflowFilterSections } from '../workflow-page/FiltersCard'

export interface SubscriptionFlowFiltersModalProps {
  open: boolean
  onClose: () => any
  filters: WorkflowFilter[]
  rootFilterType: WorkflowRootFilterType
  onChange: (filters: WorkflowFilter[]) => any
  onRootFilterTypeChange: (rootFilterType: WorkflowRootFilterType) => any
}

export const SubscriptionFlowFiltersModal = (props: SubscriptionFlowFiltersModalProps) => {
  return (
    <Modal
      title="General flow conditions"
      open={props.open}
      onClose={props.onClose}
      primaryAction={{ content: 'Done', onAction: props.onClose }}
    >
      {props.filters.length === 0 && (
        <Modal.Section>
          <Text as="span" color="subdued">
            Run workflow unconditionally
          </Text>
        </Modal.Section>
      )}
      <WorkflowFilters
        filters={props.filters}
        onChange={props.onChange}
        rootFilterType={props.rootFilterType}
        onRootFilterTypeChange={props.onRootFilterTypeChange}
        workflowFilterOptions={workflowFilterSections(true)}
      />
    </Modal>
  )
}
