import { FormLayout, Modal, Select, TextField } from '@shopify/polaris'
import { useCallback, useEffect, useState } from 'react'

import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { BulkActionSubscriptionUpdateMethod } from '../common/bulk-actions'
import { DialogActivator, useDialogActivator } from '../common/helpers'
import { LegacyHelpLabel } from '../common/HelpIcon'
import { useShopCurrency } from '../common/Price'
import { ProductVariantModal, ProductVariantResult } from '../common/ProductVariantModal'
import { SubscriptionFilter } from '../subscriptions-index-page/api'
import { AddOneTimeGiftProductSection } from './AddOneTimeGiftProductSection'
import { AddProductSection } from './AddProductSection'
import { ApplyDiscountCodeSection } from './ApplyDiscountCodeSection'
import { defaultPayload, methodOptions, validatePayload } from './common'
import { LearnMoreModal } from './LearnMoreModal'
import { RemoveProductSection } from './RemoveProductSection'
import { SummarySection } from './SummarySection'
import { SwapProductSection } from './SwapProductSection'
import { UpdateNextActionSection } from './UpdateNextActionSection'
import { UpdateShippingMethodSection } from './UpdateShippingMethodSection'

interface SubscriptionsBulkUpdateModalProps {
  activator: DialogActivator
  filter: SubscriptionFilter
  total: number
  onCreated: () => any
}

export const SubscriptionsBulkUpdateModal = (props: SubscriptionsBulkUpdateModalProps) => {
  const defaultMethod: BulkActionSubscriptionUpdateMethod = 'pause'

  const [method, setMethod] = useState<BulkActionSubscriptionUpdateMethod>(defaultMethod)
  const [payload, setPayload] = useState<any>(defaultPayload(method))
  const [reportEmail, setReportEmail] = useState<string>('')
  const [showingSummary, setShowingSummary] = useState(false)

  const learnMoreActivator = useDialogActivator()

  useEffect(() => {
    setMethod(defaultMethod)
    setPayload(defaultPayload(defaultMethod))
    setShowingSummary(false)
  }, [props.activator.showCounter])

  const currency = useShopCurrency()

  // Nested modals need to be outside of the parent modal
  // TODO: Consider how this can be refactored in the future
  const sourceVariantActivator = useDialogActivator()
  const [sourceVariantTitle, setSourceVariantTitle] = useState('')

  const onSourceVariantSelect = useCallback(
    (res: ProductVariantResult) => {
      setPayload({ ...payload, sourceVariantId: res.restId, sourceVariantTitle: res.title })
      setSourceVariantTitle(res.title)
      sourceVariantActivator.close()
    },
    [payload, setPayload, sourceVariantActivator]
  )

  const variantActivator = useDialogActivator()
  const [variantTitle, setVariantTitle] = useState('')

  const onVariantSelect = useCallback(
    (res: ProductVariantResult) => {
      setPayload({
        ...payload,
        variantId: res.restId,
        variantTitle: res.title,
        // Don't change selected price when swapping product
        currentPrice: method === 'swap-product' ? payload.currentPrice : res.price,
      })
      setVariantTitle(res.title)
      variantActivator.close()
    },
    [payload, setPayload, variantActivator, method]
  )

  const createAction = useApiActionState({
    method: 'POST',
    url: '/subscriptions/bulk-update',
    opts: {
      body: JSON.stringify({
        filter: props.filter,
        method,
        payload,
        reportEmail,
      }),
    },
    onSuccess: () => {
      props.activator.close()
      props.onCreated()
    },
    bannerPosition: 'external',
  })

  return (
    <>
      <Modal
        title={showingSummary ? 'Are you sure?' : 'Bulk update subscriptions'}
        open={
          props.activator.open &&
          !variantActivator.open &&
          !sourceVariantActivator.open &&
          !learnMoreActivator.open
        }
        onClose={props.activator.close}
        primaryAction={{
          content: showingSummary ? 'Start' : 'Next',
          disabled: !validatePayload(method, payload),
          loading: createAction.loading,
          onAction: () => {
            showingSummary ? createAction.run() : setShowingSummary(true)
          },
        }}
        secondaryActions={[
          {
            content: 'Learn more',
            onAction: learnMoreActivator.show,
          },
          showingSummary
            ? { content: 'Back', onAction: () => setShowingSummary(false) }
            : {
                content: 'Cancel',
                onAction: props.activator.close,
              },
        ]}
      >
        {showingSummary && (
          <Modal.Section>
            <ApiActionBanner state={createAction} />
            <SummarySection method={method} payload={payload} total={props.total} />
          </Modal.Section>
        )}
        {!showingSummary && (
          <>
            <Modal.Section>
              <FormLayout>
                <Select
                  label="Action"
                  options={methodOptions}
                  value={method}
                  onChange={(val: string) => {
                    const method = val as BulkActionSubscriptionUpdateMethod
                    setMethod(method)
                    setPayload(defaultPayload(method))
                    setVariantTitle('')
                    setSourceVariantTitle('')
                  }}
                />
              </FormLayout>
            </Modal.Section>
            {method === 'update-next-action' && (
              <UpdateNextActionSection payload={payload} setPayload={setPayload} />
            )}
            {method === 'add-product' && (
              <AddProductSection
                payload={payload}
                setPayload={setPayload}
                variantActivator={variantActivator}
                variantTitle={variantTitle}
              />
            )}
            {method === 'add-one-time-gift-product' && (
              <AddOneTimeGiftProductSection
                payload={payload}
                setPayload={setPayload}
                variantActivator={variantActivator}
                variantTitle={variantTitle}
              />
            )}
            {method === 'swap-product' && (
              <SwapProductSection
                payload={payload}
                setPayload={setPayload}
                variantActivator={variantActivator}
                variantTitle={variantTitle}
                sourceVariantActivator={sourceVariantActivator}
                sourceVariantTitle={sourceVariantTitle}
              />
            )}
            {method === 'remove-product' && (
              <RemoveProductSection
                variantActivator={variantActivator}
                variantTitle={variantTitle}
              />
            )}
            {method === 'apply-discount-code' && (
              <ApplyDiscountCodeSection payload={payload} setPayload={setPayload} />
            )}
            {method === 'update-shipping-method' && (
              <UpdateShippingMethodSection payload={payload} setPayload={setPayload} />
            )}
            <Modal.Section>
              <TextField
                label={
                  <LegacyHelpLabel title="Send report to">
                    When the bulk update is finished, a CSV report will be sent via email. By
                    default, it will be delivered to notification recipients specified in settings.
                    Use this field only to override the default recipients.
                  </LegacyHelpLabel>
                }
                placeholder="Default recipients"
                value={reportEmail}
                onChange={setReportEmail}
                autoComplete="email"
              />
            </Modal.Section>
          </>
        )}
      </Modal>

      <ProductVariantModal
        currency={currency}
        open={variantActivator.open}
        onClose={variantActivator.close}
        onSelect={onVariantSelect}
      />

      <ProductVariantModal
        currency={currency}
        open={sourceVariantActivator.open}
        onClose={sourceVariantActivator.close}
        onSelect={onSourceVariantSelect}
      />

      <LearnMoreModal activator={learnMoreActivator} />

      <ApiAction state={createAction} />
    </>
  )
}
