import {
  Banner,
  Button,
  CalloutCard,
  HorizontalStack,
  Icon,
  LegacyStack,
  List,
} from '@shopify/polaris'
import { PlayMinor } from '@shopify/polaris-icons'

import {
  ExternalAdminLink,
  ExternalLink,
  InternalLink,
  ShowIntercomButton,
  ShowIntercomLink,
  useDialogActivator,
} from '../common/helpers'
import * as urls from '../common/urls'
import { VideoModal } from '../common/VideoModal'
import styles from './GettingStarted.module.css'

const WelcomeMessage = () => (
  <p>
    To start selling subscriptions, you need to complete a simple 3-step setup process. In case you
    need any assistance, please <ShowIntercomLink>reach out</ShowIntercomLink> to the PayWhirl
    support team, we're here to help! You can also find more information in our{' '}
    <ExternalLink href="https://docs.paywhirl.com/en/articles/4554322-getting-started-with-the-paywhirl-subscription-app-for-shopify-2020-version">
      docs
    </ExternalLink>
    .
  </p>
)

const FirstSellingPlanCard = () => {
  const videoActivator = useDialogActivator()

  return (
    <>
      <CalloutCard
        title="Step 1. Create selling plans for your products"
        illustration="/images/setup-plans.svg"
        // Since CalloutCard doesn't support multiple primary actions, we're adding a dummy button that's hidden via CSS
        primaryAction={{
          content: '',
          url: '',
        }}
      >
        <p>
          Selling plans control the schedule, pricing, and other parameters of subscriptions. Start
          by creating your first selling plan and assigning it to a product.
        </p>
        <LegacyStack>
          <Button url={urls.firstSellingPlanUrl}>Create selling plan</Button>
          <Button onClick={videoActivator.show} icon={<Icon source={PlayMinor} color="base" />}>
            Watch video
          </Button>
        </LegacyStack>
        <VideoModal
          src="https://www.youtube.com/embed/jwGwLyVsEYc"
          title="How to Setup Subscribe & Save"
          activator={videoActivator}
        />
      </CalloutCard>
    </>
  )
}

const PlanSelectorCard = () => {
  const videoActivator = useDialogActivator()

  return (
    <CalloutCard
      title="Step 2. Install selling plan widget"
      // Since CalloutCard doesn't support multiple primary actions, we're adding a dummy button that's hidden via CSS
      primaryAction={{ content: '', url: '' }}
      illustration="/images/setup-plan-selector.svg"
    >
      <LegacyStack vertical spacing="loose">
        <p>
          In order to show your selling plans to customers, add the selling plan widget to the
          product page:
        </p>
        <List>
          <List.Item>
            Go to your <ExternalAdminLink url="/themes">themes library</ExternalAdminLink> and click
            the <b>Customize</b> button next to your target theme
          </List.Item>
          <List.Item>
            Navigate to <b>Products → Default product</b>
          </List.Item>
          <List.Item>
            In the <b>Product information</b> section of the side menu, click <b>Add Block</b> and
            select <b>PayWhirl Plan Selector</b>
          </List.Item>
          <List.Item>
            If your theme doesn't support App Blocks,{' '}
            <InternalLink url={urls.legacyPlanSelectorInstallUrl}>click here</InternalLink> for
            instructions for legacy themes (like Debut)
          </List.Item>
        </List>
        <HorizontalStack gap="2">
          <Button onClick={videoActivator.show} icon={<Icon source={PlayMinor} color="base" />}>
            Watch video
          </Button>
          <ShowIntercomButton message="I'd like help setting up PayWhirl with my theme.">
            Chat with us
          </ShowIntercomButton>
        </HorizontalStack>
      </LegacyStack>
      <VideoModal
        src="https://www.youtube.com/embed/iw0pCjDUnDA"
        title="How to Setup Your Shopify Theme"
        activator={videoActivator}
      />
    </CalloutCard>
  )
}

const CustomerPortalCard = () => {
  const videoActivator = useDialogActivator()

  return (
    <CalloutCard
      title="Step 3. Install customer portal"
      illustration="/images/setup-customer-portal.svg"
      // Since CalloutCard doesn't support multiple primary actions, we're adding a dummy button that's hidden via CSS
      primaryAction={{ content: '', url: '' }}
    >
      <LegacyStack vertical spacing="loose">
        <Banner status="warning">
          <p>
            The App embed version of PayWhirl's customer portal is only supported on Shopify's
            'Classic customer accounts'. If you would like to use Shopify's 'New customer accounts',
            please <ShowIntercomLink>get in touch</ShowIntercomLink> with PayWhirl support team, to
            get access to the Beta version of our standalone customer portal.
          </p>
        </Banner>
        <p>
          Finally, to allow your customers to manage their subscriptions in their accounts, install
          the customer portal:
        </p>
        <List>
          <List.Item>
            Go to your <ExternalAdminLink url="/themes">themes library</ExternalAdminLink> and click
            the <b>Customize</b> button next to your target theme
          </List.Item>
          <List.Item>
            In the <b>App embeds</b> section of the side menu, enable{' '}
            <b>PayWhirl Customer Portal</b> and its relevant settings
          </List.Item>
        </List>
        <HorizontalStack gap="2">
          <Button onClick={videoActivator.show} icon={<Icon source={PlayMinor} color="base" />}>
            Watch video
          </Button>
          <ShowIntercomButton message="I'd like help installing the customer portal in my theme.">
            Chat with us
          </ShowIntercomButton>
        </HorizontalStack>
      </LegacyStack>
      <VideoModal
        src="https://www.youtube.com/embed/iw0pCjDUnDA?start=61"
        title="How to Install the Customer Portal"
        activator={videoActivator}
      />
    </CalloutCard>
  )
}
const CongratulationsCard = () => (
  <CalloutCard
    title="Congratulations!"
    illustration="/images/start-selling.svg"
    primaryAction={{ content: 'Go to Dashboard', url: urls.dashboardUrl() }}
  >
    <p>
      You're ready to start selling! At this point, you can go to your product's page and create
      your first subscription.
    </p>
  </CalloutCard>
)

export const Content = () => (
  <div className={styles.wrapper}>
    <LegacyStack vertical spacing="loose">
      <WelcomeMessage />
      <LegacyStack vertical>
        <FirstSellingPlanCard />
        <PlanSelectorCard />
        <CustomerPortalCard />
        <CongratulationsCard />
      </LegacyStack>
    </LegacyStack>
  </div>
)
