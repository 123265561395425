import { Checkbox, DescriptionList, LegacyCard, LegacyStack, TextContainer } from '@shopify/polaris'
import { FormikContextType, useFormikContext } from 'formik'
import React, { useMemo } from 'react'

import {
  ExternalAdminLink,
  InternalLink,
  useFormikHandleChangeCallback,
  useShopPermission,
} from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { NumericSelect, NumericSelectOption } from '../common/NumericSelect'
import * as urls from '../common/urls'
import { UserSettings } from './api'

const daySeconds = 86400

const optionsForPaymentNotification: NumericSelectOption[] = [
  { label: 'Send 5 minutes before (use only for testing)', value: 60 * 5 },
  { label: 'Send 1 day before', value: daySeconds * 1 },
  { label: 'Send 2 days before', value: daySeconds * 2 },
  { label: 'Send 3 days before', value: daySeconds * 3 },
  { label: 'Send 5 days before', value: daySeconds * 5 },
  { label: 'Send 7 days before', value: daySeconds * 7 },
  { label: 'Send 14 days before', value: daySeconds * 14 },
]

export type MailType =
  | 'new-subscription'
  | 'subscription-expired'
  | 'subscription-paused'
  | 'subscription-paused-low-inventory'
  | 'subscription-resumed'
  | 'subscription-cancelled'
  | 'subscription-reactivated'
  | 'subscription-retrying'
  | 'subscription-failed'
  | 'subscription-edited'
  | 'order-skipped'
  | 'order-skipped-low-inventory'
  | 'payment-failed-skip-order'
  | 'payment-failed-pause-subscription'
  | 'payment-notification'
  | 'annual-reminder'
  | 'address-changed'

const mailItems: { type: MailType; title: string; label: React.ReactNode; extra?: any }[] = [
  {
    type: 'new-subscription',
    title: 'New subscription',
    label: 'Sent to the customer when they create a new subscription',
  },
  {
    type: 'subscription-expired',
    title: 'Subscription expired (completed)',
    label: 'Sent to the customer when their subscription expires',
  },
  {
    type: 'subscription-paused',
    title: 'Subscription paused',
    label: 'Sent to the customer when their subscription is paused',
  },
  {
    type: 'subscription-paused-low-inventory',
    title: 'Subscription paused (low inventory)',
    label: 'Sent to the customer when their subscription is paused due to low inventory',
  },
  {
    type: 'subscription-resumed',
    title: 'Subscription resumed',
    label: 'Sent to the customer when their subscription is resumed',
  },
  {
    type: 'subscription-edited',
    title: 'Subscription edited',
    label:
      'Sent to the customer when their subscription is edited. Multiple edits made less than 2 minutes apart will trigger only one e-mail',
  },
  {
    type: 'subscription-cancelled',
    title: 'Subscription cancelled',
    label:
      'Sent to the customer when their subscription is manually cancelled by themselves or by an admin',
  },
  {
    type: 'subscription-reactivated',
    title: 'Subscription reactivated',
    label: 'Sent to the customer when their subscription is reactivated',
  },
  {
    type: 'subscription-retrying',
    title: 'Payment failed (retrying)',
    label: 'Sent to the customer after a failed payment, which is scheduled for automatic retrying',
  },
  {
    type: 'subscription-failed',
    title: 'Payment failed (last attempt - cancel subscription)',
    label:
      "Sent to the customer after the last attempt at processing failed payment if last failed attempt action is set to 'cancel subscription'",
  },
  {
    type: 'payment-failed-skip-order',
    title: 'Payment failed (last attempt - skip order)',
    label:
      "Sent to the customer after the last attempt at processing failed payments if last failed attempt action is set to 'skip order'",
  },
  {
    type: 'payment-failed-pause-subscription',
    title: 'Payment failed (last attempt - pause subscription)',
    label:
      "Sent to the customer after the last attempt at processing failed payments if last failed attempt action is set to 'pause subscription'",
  },
  {
    type: 'order-skipped',
    title: 'Order skipped',
    label: 'Sent to the customer after skipping an order',
  },
  {
    type: 'order-skipped-low-inventory',
    title: 'Order skipped (low inventory)',
    label: 'Sent to the customer after skipping an order due to low inventory',
  },
  {
    type: 'annual-reminder',
    title: 'Annual subscription reminder',
    label:
      'This email is required by certain local jurisdictions, please review and customize accordingly',
    extra: (
      formik: FormikContextType<UserSettings>,
      handleChange: (value: any, id: any) => string
    ) =>
      formik.values.send_annual_reminder_emails ? (
        <NumericSelect
          id="annual_reminder_emails_days"
          name="annual_reminder_emails_days"
          label=""
          options={Array(40)
            .fill(1)
            .map((_, i) => ({
              label: `Send ${i + 1} days before anniversary payment`,
              value: i + 1,
            }))}
          value={formik.values.annual_reminder_emails_days}
          onChange={handleChange}
        />
      ) : null,
  },
  {
    type: 'address-changed',
    title: 'Address changed',
    label: (
      <>
        Sent to the customer after they edit an address in their account settings, to remind them
        that it doesn't automatically update existing subscriptions&nbsp;
        <LegacyHelpIcon>
          The email is sent 30s after editing the address and not more often than one per day
        </LegacyHelpIcon>
      </>
    ),
  },
  {
    type: 'payment-notification',
    title: 'Payment reminder',
    label: 'Sent to the customer before an upcoming payment',
    extra: (
      formik: FormikContextType<UserSettings>,
      handleChange: (value: any, id: any) => string
    ) =>
      formik.values.send_payment_notification_emails ? (
        <NumericSelect
          id="payment_notification_timing"
          name="payment_notification_timing"
          label=""
          options={optionsForPaymentNotification}
          value={formik.values.payment_notification_timing}
          onChange={handleChange}
        />
      ) : null,
  },
]

const buildListItems = (
  formik: FormikContextType<UserSettings>,
  handleChange: (value: any, id: any) => string,
  lowInventoryActionsEnabled: boolean,
  failedPaymentWorkflowsEnabled: boolean
) => {
  const items = mailItems
    .filter((item) => item.type !== 'order-skipped-low-inventory' || lowInventoryActionsEnabled)
    .filter(
      (item) => item.type !== 'subscription-paused-low-inventory' || lowInventoryActionsEnabled
    )
    .filter((item) => item.type !== 'payment-failed-skip-order' || failedPaymentWorkflowsEnabled)
    .filter(
      (item) => item.type !== 'payment-failed-pause-subscription' || failedPaymentWorkflowsEnabled
    )
    .map((item) => ({ ...item, key: `send_${item.type.replace(/-/g, '_')}_emails` }))
    .map((item) => ({
      term: <InternalLink url={urls.emailSettingsUrl(item.type)}>{item.title}</InternalLink>,
      description: (
        <LegacyStack vertical>
          <Checkbox
            id={item.key}
            name={item.key}
            label={item.label || 'Mail label'}
            checked={formik.values[item.key as keyof UserSettings] as boolean}
            onChange={handleChange}
          />
          {item.extra ? item.extra(formik, handleChange) : null}
        </LegacyStack>
      ),
    }))

  items.push({
    term: (
      <ExternalAdminLink url="/email_templates/customer_account_activate/edit">
        Customer account invite
      </ExternalAdminLink>
    ),
    description: (
      <LegacyStack vertical>
        <Checkbox
          id="send_invite_emails"
          name="send_invite_emails"
          label="Send the Shopify account invite email to customers who checkout as guests"
          checked={formik.values.send_invite_emails}
          onChange={handleChange}
        />
      </LegacyStack>
    ),
  })

  items.push({
    term: (
      <InternalLink url={urls.emailSettingsUrl('customer-portal-login')}>
        Customer account login
      </InternalLink>
    ),
    description: (
      <LegacyStack spacing="tight">
        <Checkbox
          id="send_customer_portal_login_emails"
          name="send_customer_portal_login_emails"
          label="Customer portal login email"
          checked={true}
          labelHidden
          disabled
        />
        <TextContainer>Customer portal login email</TextContainer>
      </LegacyStack>
    ),
  })

  return items
}

export const NotificationsCard = () => {
  const formik = useFormikContext<UserSettings>()
  const inventoryManagementEnabled = useShopPermission('inventoryManagementEnabled')
  const failedPaymentWorkflowsEnabled = useShopPermission('failedPaymentWorkflowsEnabled')

  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)
  const listItems = useMemo(
    () =>
      buildListItems(
        formik,
        handleChange,
        inventoryManagementEnabled,
        failedPaymentWorkflowsEnabled
      ),
    [formik, handleChange, inventoryManagementEnabled, failedPaymentWorkflowsEnabled]
  )

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <DescriptionList items={listItems} />
      </LegacyCard.Section>
    </LegacyCard>
  )
}
