import useSWR from 'swr'

import { backendFetchResultData } from './api'

export interface GQLCollection {
  id: string
  title: string
  productsCount: {
    count: number
  }
}

export const fetchCollections = async (_: string): Promise<GQLCollection[]> =>
  await backendFetchResultData('GET', '/products/collections')

export const useCollectionsSWR = () =>
  useSWR<GQLCollection[]>('collections', fetchCollections, {
    revalidateOnFocus: false,
  })

export const useCollections = () => useCollectionsSWR().data ?? []
