import { Banner, LegacyStack, Text } from '@shopify/polaris'
import React, { useCallback } from 'react'

import { SellingPlanInterval } from '../common/plan-models'
import { IntervalCountField, IntervalField } from '../common/ScheduleFields'
import {
  WorkflowAction,
  workflowActionLabels,
  WorkflowEditDeliveryFrequencyDataType,
} from '../common/workflow-models'
import { PrePaidDeliveriesField } from '../selling-plan-page/PrePaidDeliveriesField'

export const EditDeliveryFrequencyAction = ({
  onChange,
  index,
  params,
}: {
  onChange: (action: WorkflowAction, index: number) => void
  index: number
  params: WorkflowEditDeliveryFrequencyDataType
}) => {
  const [intervalCount, interval, prePaidDeliveries] = params

  const handleUpdate = useCallback(
    (updatedParams: WorkflowEditDeliveryFrequencyDataType) => {
      onChange(['edit-delivery-frequency', updatedParams], index)
    },
    [index, onChange]
  )

  return (
    <LegacyStack vertical>
      <Text variant="headingSm" as="h2">
        {workflowActionLabels['edit-delivery-frequency']}
      </Text>
      <Banner status="warning">
        <Text as="p" color="warning">
          This action will remove any anchors for the subscription.
        </Text>
      </Banner>
      <LegacyStack distribution="fillEvenly" alignment="trailing">
        <IntervalCountField
          label="Delivery frequency"
          value={intervalCount}
          onChange={(val: number) => handleUpdate([val, interval, prePaidDeliveries])}
        />
        <IntervalField
          value={interval}
          onChange={(val: SellingPlanInterval) =>
            handleUpdate([intervalCount, val, prePaidDeliveries])
          }
          label=" "
        />
      </LegacyStack>
      <PrePaidDeliveriesField
        value={prePaidDeliveries}
        onChange={(val: number) => handleUpdate([intervalCount, interval, val])}
        pagePath="workflow"
      />
    </LegacyStack>
  )
}
