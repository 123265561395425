import {
  AppProvider,
  Button,
  Form,
  FormLayout,
  Frame,
  LegacyCard,
  LegacyStack,
  Page,
  TextField,
} from '@shopify/polaris'
import enTranslations from '@shopify/polaris/locales/en.json'
import React, { useState } from 'react'

const ConnectForm = () => {
  const [shopName, setShopName] = useState<string>('')
  const subdomain = shopName.replace('.myshopify.com', '')
  const url = `/shopify/connect?shop=${encodeURIComponent(subdomain)}.myshopify.com`

  return (
    <LegacyStack vertical alignment="center">
      <LegacyCard title="Install PayWhirl in your Shopify store" sectioned>
        <Form onSubmit={() => (window.location.href = url)}>
          <FormLayout>
            <TextField
              label="Store name"
              placeholder="my-store"
              suffix=".myshopify.com"
              value={shopName}
              onChange={setShopName}
              autoComplete="off"
            />
            <Button submit primary disabled={!shopName}>
              Install
            </Button>
          </FormLayout>
        </Form>
      </LegacyCard>
    </LegacyStack>
  )
}

export const ConnectPage = () => (
  <AppProvider i18n={enTranslations}>
    <Frame>
      <Page>
        <ConnectForm />
      </Page>
    </Frame>
  </AppProvider>
)
