import { mutate, useSWRConfig } from 'swr'

export enum SubscriptionContractSubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Paused = 'PAUSED',
}

export interface SimplePaginator<T = any> {
  current_page: number
  next_page_url?: string
  prev_page_url?: string
  data: T[]
}

export interface Paginator<T = any> extends SimplePaginator<T> {
  last_page: number
  total: number
}

export interface GQLPaginatorPageInfo {
  firstCursor: string | null
  lastCursor: string | null
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export const emptyGQLPaginatorPageInfo: GQLPaginatorPageInfo = {
  firstCursor: null,
  lastCursor: null,
  hasPreviousPage: false,
  hasNextPage: false,
}

export interface GQLPaginator<T> {
  nodes: T[]
  page: GQLPaginatorPageInfo
}

export interface Result<T> {
  status: string
  code?: string
  data?: T
  message?: string
  temporary: boolean
  field?: string
}

export const backendFetch = async (method: string, path: string, opts: any = {}): Promise<any> => {
  const url = `/api${path}`
  const anyWindow = window as any

  const myOpts = {
    ...opts,
    method,
    headers: {
      ...opts.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Shop-Origin': anyWindow.shopOrigin,
    },
  }

  const resp = await fetch(url, myOpts)

  const json = await resp.json()

  return json
}

export const backendFetchResult = async <T>(
  method: string,
  path: string,
  opts: any = {}
): Promise<Result<T>> => {
  const unknownError: Result<T> = {
    status: 'error',
    message: 'Unknown error',
    temporary: true,
  }

  let json = null

  try {
    json = await backendFetch(method, path, opts)
  } catch (e) {
    console.log(e)
    return unknownError
  }

  if (!json || !json.status || (json.status === 'error' && !json.message)) {
    console.log(json)
    return unknownError
  }

  return json
}

export const backendFetchResultData = async <T>(
  method: string,
  path: string,
  opts: any = {}
): Promise<T> => {
  const url = `/api${path}`
  const anyWindow = window as any

  const myOpts = {
    ...opts,
    method,
    headers: {
      ...opts.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Shop-Origin': anyWindow.shopOrigin,
    },
  }

  const resp = await fetch(url, myOpts)

  if (!resp.ok) {
    return Promise.reject(`Invalid response code: ${resp.status}`)
  }

  const json = await resp.json()

  if (json.status !== 'success') {
    return Promise.reject(`Invalid response status code: ${json.status}`)
  }

  return json.data
}

export const backendFetchGraphQL = async (query: string, variables: object = {}): Promise<any> => {
  const method = 'POST'
  const path = '/graphql'
  const body = JSON.stringify({ query, variables })
  const json = await backendFetch(method, path, { body })

  return json
}

export const mutateWithResult = <T>(key: any, result: Result<T>) => {
  const ok = result.status === 'success'
  const data = ok ? result.data : undefined
  const revalidate = !ok
  mutate(key, data, revalidate)
}

export function useMatchMutate() {
  const { cache, mutate } = useSWRConfig()

  return (matcher: RegExp) => {
    if (!(cache instanceof Map)) {
      throw new Error('matchMutate requires the cache provider to be a Map instance')
    }

    const keys = []

    for (const key of Array.from(cache.keys())) {
      if (matcher.test(key)) {
        keys.push(key)
      }
    }

    const mutations = keys.map((key) => mutate(key))

    return Promise.all(mutations)
  }
}
