import { LegacyCard, LegacyStack, Text, TextContainer } from '@shopify/polaris'
import React from 'react'

import { nl2br, useDialogActivator } from '../common/helpers'
import { HelpTitle } from '../common/HelpIcon'
import { FormattedPrice } from '../common/Price'
import { SubscriptionContract } from '../common/subscription-models'
import { DeliveryDetailsEditDialog } from '../delivery-details-edit-dialog/DeliveryDetailsEditDialog'
import { DetailsItem } from './DetailsItem'

export const DeliveryDetailsCard = ({ sc }: { sc: SubscriptionContract }) => {
  const deliveryDetailsDialogActivator = useDialogActivator()
  const deliveryMethod = sc.gql_data.deliveryMethod
  const deliveryMethodCardTitle = (
    <HelpTitle title="Delivery Method">
      Adjust the delivery method from shipping, local delivery, or pickup and configure the price
      accordingly. Select none for digital products/services. Please note pickup is always free.
    </HelpTitle>
  )

  const deliveryMethodCode =
    deliveryMethod?.shippingOption?.code ??
    deliveryMethod?.localDeliveryOption?.code ??
    deliveryMethod?.pickupOption?.code ??
    ''
  const deliveryMethodTitle =
    deliveryMethod?.shippingOption?.title ??
    deliveryMethod?.localDeliveryOption?.title ??
    deliveryMethod?.pickupOption?.title ??
    ''

  const deliveryMethodType = deliveryMethod?.shippingOption
    ? 'Shipping'
    : deliveryMethod?.localDeliveryOption
    ? 'Local Delivery'
    : deliveryMethod?.pickupOption
    ? 'Pickup'
    : null

  const actions = sc.is_editable
    ? [
        {
          content: 'Edit',
          onAction: deliveryDetailsDialogActivator.show,
        },
      ]
    : []

  return (
    <LegacyCard title={deliveryMethodCardTitle} sectioned actions={actions}>
      <>
        {!deliveryMethod && <TextContainer>No delivery method</TextContainer>}
        {deliveryMethod && (
          <LegacyStack vertical>
            <LegacyStack vertical spacing="extraTight">
              <DetailsItem label="Type">{deliveryMethodType}</DetailsItem>
              <DetailsItem label="Title">{deliveryMethodTitle}</DetailsItem>
              <DetailsItem label="Code">{deliveryMethodCode}</DetailsItem>
              {deliveryMethod.pickupOption && (
                <DetailsItem label="Pickup location">
                  {deliveryMethod.pickupOption.location.name}
                </DetailsItem>
              )}
              {!deliveryMethod.pickupOption && (
                <DetailsItem label="Price">
                  <FormattedPrice price={sc.gql_data.deliveryPrice} />
                </DetailsItem>
              )}
            </LegacyStack>
            <LegacyStack vertical>
              {deliveryMethod.address && (
                <LegacyStack vertical>
                  <HelpTitle title="Delivery Address">
                    Current customer delivery address for this subscription.
                  </HelpTitle>
                  <p>{nl2br(sc.gql_data.deliveryAddress)}</p>
                </LegacyStack>
              )}
              {deliveryMethod.localDeliveryOption && (
                <LegacyStack vertical>
                  <Text variant="headingMd" as="h2">
                    Delivery instructions
                  </Text>
                  <TextContainer>
                    <LegacyStack vertical spacing="extraTight">
                      <Text as="p">{deliveryMethod.localDeliveryOption.phone}</Text>
                      <Text as="p">{deliveryMethod.localDeliveryOption.instructions}</Text>
                    </LegacyStack>
                  </TextContainer>
                </LegacyStack>
              )}
            </LegacyStack>
          </LegacyStack>
        )}
      </>
      <DeliveryDetailsEditDialog sc={sc} activator={deliveryDetailsDialogActivator} />
    </LegacyCard>
  )
}
