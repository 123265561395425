import {
  Badge,
  Button,
  ButtonGroup,
  EmptyState,
  HorizontalGrid,
  HorizontalStack,
  Icon,
  Layout,
  LegacyCard,
  LegacyStack,
  Loading,
  Page,
  ResourceItem,
  ResourceList,
  SkeletonBodyText,
  Text,
  useBreakpoints,
  VerticalStack,
} from '@shopify/polaris'
import { PlayMinor } from '@shopify/polaris-icons'
import React from 'react'

import { Footer } from '../common/Footer'
import {
  DialogActivator,
  ExternalLink,
  InternalLink,
  showIntercom,
  useDialogActivator,
  useShopPermission,
} from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { VideoModal } from '../common/VideoModal'
import { WorkflowGroup } from '../common/workflow-group-models'
import { Workflow } from '../common/workflow-models'
import {
  convertWorkflowGroupToSubscriptionFlowFormData,
  useWorkflowGroupsSWR,
} from '../subscription-flow-page/api'
import {
  convertWorkflowToFormData,
  SellingPlansContext,
  useSellingPlansSWR,
  useWorkflowsSWR,
} from '../workflow-page/api'
import {
  AppliesToOrdersLine,
  FiltersLine,
  WorkflowDescription,
} from '../workflow-page/WorkflowDescription'
import { WorkflowStatusBadge } from '../workflow-page/WorkflowStatusBadge'
import { WorkflowCreateCard, workflowLabels } from './WorkflowCreateCard'
import { WorkflowCreateModal } from './WorkflowCreateModal'
import styles from './WorkflowsIndexPage.module.css'
import { WorkflowsWizardModal } from './WorkflowsWizardModal'

const WorkflowsNotPermittedCard = ({ videoActivator }: { videoActivator: DialogActivator }) => (
  <LegacyCard sectioned>
    <div className={styles.wrapper}>
      <EmptyState
        heading=""
        image="/images/setup-plans.svg"
        footerContent={
          <LegacyStack>
            <ButtonGroup fullWidth={true}>
              <Button icon={PlayMinor} onClick={videoActivator.show}>
                Watch video
              </Button>
              <Button primary url={urls.pricingPlansUrl()}>
                Please upgrade to access this feature
              </Button>
            </ButtonGroup>
          </LegacyStack>
        }
      >
        <VerticalStack gap="1">
          <Text as="p">
            Please{' '}
            <InternalLink url={urls.pricingPlansUrl()} target={'_blank'}>
              upgrade
            </InternalLink>{' '}
            to our <Text as="strong">Traction Plan</Text> to access Workflows, where powerful
            automation effortlessly handles complex subscription scenarios.
          </Text>
          <Text as="p">
            Whether segmenting customers, automating subscription changes, or dynamically responding
            to subscription events, Workflows simplifies these processes, reducing manual efforts
            and enhancing accuracy. (
            <ExternalLink href="https://docs.paywhirl.com/PayWhirl/en/articles/5560718-how-to-add-or-remove-shopify-customer-and-order-tags-on-subscription-events">
              Learn&nbsp;more
            </ExternalLink>
            )
          </Text>
        </VerticalStack>
      </EmptyState>
    </div>
  </LegacyCard>
)

const renderWorkflowItem = (workflow: Workflow) => {
  const data = convertWorkflowToFormData(workflow)

  return (
    <ResourceItem id={workflow.id.toString()} url={urls.workflowUrl(workflow.id)}>
      <br />
      <LegacyStack wrap={false}>
        <LegacyStack.Item fill>
          <LegacyStack vertical spacing="loose">
            <LegacyStack alignment="center">
              <Badge status="info">{workflowLabels['single'].title}</Badge>
              <Text variant="headingMd" as="h3">
                {data.name ? data.name : 'Workflow #' + workflow.id}
              </Text>
            </LegacyStack>
            <WorkflowDescription
              triggers={data.triggers}
              actions={data.actions}
              filters={data.filters}
              rootFilterType={data.rootFilterType}
              tightSpacing
            />
          </LegacyStack>
        </LegacyStack.Item>
        <WorkflowStatusBadge data={data} />
      </LegacyStack>
      <br />
    </ResourceItem>
  )
}

const renderWorkflowGroupItem = (workflowGroup: WorkflowGroup) => {
  const data = convertWorkflowGroupToSubscriptionFlowFormData(workflowGroup)

  const rootFilter = workflowGroup.filter[0] ?? 'and'
  const filters = workflowGroup.filter[1] ?? []

  return (
    <ResourceItem
      id={workflowGroup.id.toString()}
      url={urls.subscriptionFlowsUrl(workflowGroup.id)}
    >
      <br />
      <LegacyStack wrap={false}>
        <LegacyStack.Item fill>
          <VerticalStack gap="3">
            <HorizontalStack blockAlign="center" gap="3">
              <Badge status="info">{workflowLabels['subscription'].title}</Badge>
              <Text variant="headingMd" as="h3">
                {data.name}
              </Text>
            </HorizontalStack>
            {filters.length > 0 && (
              <FiltersLine rootFilterType={rootFilter} filters={filters} prefix="Applies if" />
            )}
            {filters.length === 0 && (
              <Text as="span" color="subdued">
                Run workflow unconditionally
              </Text>
            )}
            <AppliesToOrdersLine workflowGroup={workflowGroup} />
          </VerticalStack>
        </LegacyStack.Item>
        <WorkflowStatusBadge data={data} />
      </LegacyStack>
      <br />
    </ResourceItem>
  )
}

const WorkflowItems = ({ items }: { items: (WorkflowGroup | Workflow)[] }) => (
  <LegacyCard>
    <ResourceList
      items={items}
      emptyState={<></>}
      renderItem={(item) =>
        'workflows' in item ? renderWorkflowGroupItem(item) : renderWorkflowItem(item)
      }
    />
  </LegacyCard>
)

export const WorkflowsIndexPage = () => {
  const workflowsSWR = useWorkflowsSWR()
  const workflowGroupsSWR = useWorkflowGroupsSWR()
  const sellingPlansSWR = useSellingPlansSWR()
  const createModalActivator = useDialogActivator()
  const videoActivator = useDialogActivator()
  const wizardActivator = useDialogActivator()
  const workflowsEnabled = useShopPermission('workflowsEnabled')
  const { mdUp } = useBreakpoints()

  return (
    <SellingPlansContext.Provider value={sellingPlansSWR.data ?? []}>
      {(workflowsSWR.isValidating || sellingPlansSWR.isValidating) && <Loading />}
      <Tabs selected={TabIndex.Workflows} />

      {(workflowsSWR.error || workflowGroupsSWR.error) && <LoadErrorPage />}
      {!workflowsSWR.error && !workflowGroupsSWR.error && (
        <Page
          title="Workflows"
          secondaryActions={[
            {
              content: 'Chat with us',
              onAction: () => showIntercom('I have a question about configuring workflows.'),
            },
          ]}
          primaryAction={
            <>
              {workflowsEnabled && (
                <LegacyStack wrap={false}>
                  <Button url={urls.workflowLogsUrl()}>Logs</Button>
                  <Button
                    onClick={videoActivator.show}
                    icon={<Icon source={PlayMinor} />}
                    monochrome
                    outline
                  >
                    Watch video
                  </Button>
                </LegacyStack>
              )}
            </>
          }
        >
          <Layout>
            <Layout.Section>
              <VerticalStack gap="6">
                <>
                  {!workflowsEnabled && (
                    <WorkflowsNotPermittedCard videoActivator={videoActivator} />
                  )}
                  {workflowsEnabled && (
                    <>
                      <div className={styles.workflowCards}>
                        <WorkflowCreateModal
                          activator={createModalActivator}
                          wizardActivator={wizardActivator}
                        />
                        {!mdUp && (
                          <LegacyCard
                            sectioned
                            primaryFooterAction={{
                              content: 'Create workflow',
                              onAction: createModalActivator.show,
                            }}
                            footerActionAlignment="left"
                          >
                            Workflows allow you to auto-adjust subscriptions based on payment events
                            or various subscription status changes. Easily add discounts, products,
                            manage customer tags and tag orders on certain events.
                          </LegacyCard>
                        )}
                        {mdUp && (
                          <HorizontalGrid alignItems="start" columns={3} gap="3">
                            <WorkflowCreateCard type="tagging" onClick={wizardActivator.show} />
                            <WorkflowCreateCard
                              type="subscription"
                              url={urls.subscriptionFlowsCreateUrl()}
                            />
                            <WorkflowCreateCard type="single" url={urls.workflowCreateUrl} />
                          </HorizontalGrid>
                        )}
                      </div>
                      {(!workflowsSWR.data || !workflowsSWR.data) && (
                        <SkeletonBodyText lines={10} />
                      )}
                      {workflowsSWR.data && workflowGroupsSWR.data && (
                        <LegacyStack vertical spacing="extraLoose">
                          <WorkflowItems
                            items={[...workflowGroupsSWR.data, ...workflowsSWR.data]}
                          />
                          <WorkflowsWizardModal
                            open={wizardActivator.open}
                            close={wizardActivator.close}
                            showCounter={wizardActivator.showCounter}
                          />
                        </LegacyStack>
                      )}
                    </>
                  )}
                </>
              </VerticalStack>
            </Layout.Section>
            <Layout.Section>
              <Footer />
            </Layout.Section>
          </Layout>
          <VideoModal
            src="https://www.youtube.com/embed/OYit7mpQtsM"
            title="How to Automate Your Subscription Business with Workflows"
            activator={videoActivator}
          />
        </Page>
      )}
    </SellingPlansContext.Provider>
  )
}
