import {
  Badge,
  DataTable,
  Layout,
  LegacyCard,
  LegacyStack,
  LinkAction,
  Page,
  Pagination,
  Select,
  SelectOption,
} from '@shopify/polaris'
import { DateStyle } from '@shopify/react-i18n'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import { DatePickerField } from '../common/DatePickerField'
import { Footer } from '../common/Footer'
import { FormattedDate, InternalLink } from '../common/helpers'
import { BadgeStatus } from '../common/SubscriptionStatusBadge'
import * as urls from '../common/urls'
import { PaymentDetails } from '../subscription-page/PaymentDetails'
import { PageQuery, PaymentEvent, PaymentStatus, usePageQuery } from './DashboardPaymentsPage'

interface TableProps {
  items: PaymentEvent[]
  status: PaymentStatus
  footerContent: React.ReactNode
}

const StatusMapping: Record<PaymentStatus, BadgeStatus> = {
  Success: 'success',
  Failed: 'critical',
  Upcoming: 'new',
}

const statusOptions: SelectOption[] = [
  { value: 'Scheduled', label: 'Scheduled' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Success', label: 'Successful' },
  { value: 'Failed', label: 'Failed' },
]

const Table = (props: TableProps) => {
  const rows = props.items.map((item) => [
    <InternalLink url={urls.subscriptionUrl(item.subscriptionRestId)}>
      #{item.subscriptionRestId}
    </InternalLink>,
    item.customerName,
    <Badge status={StatusMapping[item.status as PaymentStatus]}>{item.status}</Badge>,
    props.status === 'Success' ? item.amount : null,
    <FormattedDate
      date={new Date(item.date)}
      format={{ style: DateStyle.Short, hour: 'numeric', minute: 'numeric' }}
      titleFormat={{ style: DateStyle.Long, hour: 'numeric', minute: 'numeric' }}
    />,
    <PaymentDetails {...item} />,
  ])

  return (
    <DataTable
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
      headings={['Subscription ID', 'Customer', 'Status', 'Amount', 'Date', 'Details']}
      rows={rows}
      verticalAlign="middle"
      footerContent={props.footerContent}
    />
  )
}

const usePagination = (items: any, page: number, perPage: number = 20) => {
  const maxPages = Math.floor(items.length / perPage)
  const pageItems = items.slice((page - 1) * perPage, page * perPage)

  return useMemo(
    () => ({
      maxPages,
      pageItems,
      hasPrevious: page > 1,
      hasNext: page <= maxPages,
    }),
    [maxPages, pageItems, page]
  )
}

export const PageContent = ({ payments }: { payments: PaymentEvent[] }) => {
  const history = useHistory()
  const pageQuery = usePageQuery()
  const pagination = usePagination(payments, pageQuery.page)

  const updateParams = useCallback(
    (newParams: Partial<PageQuery>) => {
      history.replace(urls.dashboardPaymentsUrl({ ...pageQuery, ...newParams }))
    },
    [pageQuery, history]
  )

  const backAction: LinkAction = {
    content: 'Dashboard',
    url: urls.dashboardUrl({ date: pageQuery.date }),
  }

  return (
    <Page
      title="Payment schedule"
      backAction={backAction}
      primaryAction={
        <LegacyStack>
          <Select
            label="Status: "
            labelInline
            options={statusOptions}
            value={pageQuery.status}
            onChange={(status) => updateParams({ status: status as PaymentStatus, page: 1 })}
          />
          <DatePickerField
            date={pageQuery.date}
            onChange={(date) => updateParams({ date, page: 1 })}
          />
        </LegacyStack>
      }
    >
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <Table
              items={pagination.pageItems}
              status={pageQuery.status}
              footerContent={
                <Pagination
                  label={`Page: ${pageQuery.page} of ${pagination.maxPages + 1}`}
                  hasPrevious={pagination.hasPrevious}
                  onPrevious={() => updateParams({ page: pageQuery.page - 1 })}
                  hasNext={pagination.hasNext}
                  onNext={() => updateParams({ page: pageQuery.page + 1 })}
                />
              }
            />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <Footer />
        </Layout.Section>
      </Layout>
    </Page>
  )
}
