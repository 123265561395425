import { Button, InlineError, LegacyStack, Tag, Text } from '@shopify/polaris'
import React, { useCallback } from 'react'

import { gidToId, useToggle } from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import { FormattedPrice, useShopCurrency } from '../common/Price'
import { ProductVariantModal, ProductVariantResult } from '../common/ProductVariantModal'
import {
  WorkflowAction,
  workflowActionLabels,
  WorkflowProductDataType,
} from '../common/workflow-models'

export const AddOneTimeGiftProductAction = ({
  params,
  onChange,
  index,
  error,
}: {
  params: WorkflowProductDataType
  onChange: (action: WorkflowAction, index: number) => any
  index: number
  error: string | null
}) => {
  const shopCurrency = useShopCurrency()
  const [productModalActive, toggleProductModalActive] = useToggle(false)

  const handleUpdate = useCallback(
    (selected: ProductVariantResult) => {
      onChange(['add-one-time-gift-product', [gidToId(selected.id), selected.title]], index)
      toggleProductModalActive()
    },
    [index, onChange, toggleProductModalActive]
  )

  return (
    <LegacyStack vertical>
      <Text variant="headingSm" as="h2">
        {workflowActionLabels['add-one-time-gift-product']}
        <LegacyHelpIcon>
          The selected product variant will be added to the subscription and will be marked as
          'One-time' at <FormattedPrice price={{ amount: '0', currencyCode: shopCurrency }} />. It
          will be automatically removed after the order is created.
        </LegacyHelpIcon>
      </Text>

      {params.length === 0 && (
        <Text as="span" color="subdued">
          No variant selected
        </Text>
      )}

      {params[0] && <Tag key={params[0]}>{params[1]}</Tag>}

      <Button onClick={toggleProductModalActive} size="slim">
        Select product variant
      </Button>
      {error && <InlineError message={error} fieldID="" />}

      <ProductVariantModal
        currency={shopCurrency}
        open={productModalActive}
        onClose={toggleProductModalActive}
        onSelect={handleUpdate}
      />
    </LegacyStack>
  )
}
