// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SellingPlanGroupList_wrapper__L5q6Z .Polaris-Box > .Polaris-VerticalStack > img {
    max-width: 12rem;
    margin: 2rem 0;
  }
`, "",{"version":3,"sources":["webpack://./src/selling-plans-index-page/SellingPlanGroupList.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".wrapper :global(.Polaris-Box > .Polaris-VerticalStack > img) {\n    max-width: 12rem;\n    margin: 2rem 0;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `SellingPlanGroupList_wrapper__L5q6Z`
};
export default ___CSS_LOADER_EXPORT___;
