import { Modal, TextContainer } from '@shopify/polaris'
import React, { useState } from 'react'

interface State {
  open: boolean
  show: () => any
  close: () => any
  confirmButtonEnabled: boolean
  toggleConfirmButton: (enabled: boolean) => any
}

interface Props {
  state: State
  destructive?: boolean
  onConfirm: () => any
  title?: string
  message: React.ReactNode
  confirmButtonTitle: string
  cancelButtonTitle?: string
}

export const ConfirmDialog = (props: Props) => {
  const primaryAction = {
    content: props.confirmButtonTitle,
    disabled: !props.state.confirmButtonEnabled,
    destructive: props.destructive,
    onAction: () => {
      props.onConfirm()
      props.state.close()
    },
  }

  const cancelAction = {
    content: props.cancelButtonTitle || 'Cancel',
    onAction: props.state.close,
  }

  return (
    <Modal
      open={props.state.open}
      title={props.title || 'Are you sure?'}
      onClose={props.state.close}
      primaryAction={primaryAction}
      secondaryActions={[cancelAction]}
      sectioned
    >
      <TextContainer>
        <p>{props.message}</p>
      </TextContainer>
    </Modal>
  )
}

export const useConfirmDialogState = (): State => {
  const [open, setOpen] = useState<boolean>(false)
  const [confirmButtonEnabled, toggleConfirmButton] = useState<boolean>(true)
  const show = () => setOpen(true)
  const close = () => setOpen(false)

  return { open, show, close, confirmButtonEnabled, toggleConfirmButton }
}
