import { gidToId, pwCache, queryString } from './helpers'

export const urlWithParams = (path: string, params: any, trim: boolean = false) => {
  const qs = queryString(params, trim)
  const sep = qs ? '?' : ''
  return `${path}${sep}${qs}`
}

export const rootUrl = '/'

export const gettingStartedUrl = '/getting-started'
export const legacyPlanSelectorInstallUrl = '/getting-started/legacy-themes'
export const planSelectorInstallUrl = '/getting-started/plan-selector'
export const customerPortalInstallUrl = '/getting-started/customer-portal'
export const firstSellingPlanUrl = '/getting-started/selling-plan'

export const dashboardUrl = (params: any = {}) => urlWithParams('/dashboard', params)

export const dashboardPaymentsUrl = (params: any = {}) =>
  urlWithParams('/dashboard/payments/', params)

export const plansUrl = '/plans'
export const planUrl = (gidOrId: any) => `/plans/${gidToId(gidOrId)}`
export const newPlanUrl = `/plans/new`
export const planProductsUrl = (gidOrId: string, params: any = {}) =>
  urlWithParams(`/plans/${gidToId(gidOrId)}/products`, params)

export const subscriptionsUrl = (params?: any) =>
  urlWithParams('/subscriptions', params ?? pwCache.subscriptionFilter, true)

export const subscriptionUrl = (gidOrId: any) => `/subscriptions/${gidToId(gidOrId)}`
export const subscriptionCreateUrl = '/subscriptions/create'

export const settingsUrl = () => '/settings'
export const pricingPlansUrl = () => '/settings/pricing-plans'
export const translationsUrl = () => '/settings/translations'
export const callbackDeliveriesUrl = (params: any = {}) =>
  urlWithParams('/settings/webhook-deliveries', params)
export const emailSettingsUrl = (mailType: string) => `/settings/emails/${mailType}`

export const newsUrl = (params?: any) => urlWithParams(`/dashboard/news`, params)

export const workflowsUrl = '/workflows'
export const workflowCreateUrl = '/workflows/create'
export const workflowUrl = (id: number) => `/workflows/${id}`
export const workflowLogsUrl = (params: any = {}) => urlWithParams('/workflows/logs', params)

export const subscriptionFlowsCreateUrl = (params: any = {}) =>
  urlWithParams('/subscription-flows/create', params)
export const subscriptionFlowsUrl = (id: number) => `/subscription-flows/${id}`
