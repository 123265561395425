import useSWR, { mutate } from 'swr'

import { backendFetchResult, backendFetchResultData, Result } from '../common/api'
import { capitalize } from '../common/helpers'

export type TranslationSet = Record<string, string>

export interface TranslationSettings {
  defaults: TranslationSet
  translations: TranslationSet
}

export const filterDefaults = (defaults: TranslationSet, filter: string): TranslationSet =>
  Object.keys(defaults)
    .filter((key) => {
      const val = defaults[key].toLowerCase()
      key = key.toLowerCase().replace(/-/g, ' ')
      filter = filter.toLowerCase()
      return key.includes(filter) || val.includes(filter)
    })
    .reduce((filtered, key) => {
      filtered[key] = defaults[key]
      return filtered
    }, {} as TranslationSet)

export const translationGroups = (defaults: TranslationSet): string[] => {
  const allKeys = Object.keys(defaults).map((key) => key.split(':')[0])
  const keySet = new Set(allKeys)

  const keysOrder = [
    'home',
    'subscription',
    'cancellation-flow',
    'subscription-edit',
    'edit-confirmation',
    'shipping-address',
    'messages',
    'thank-you-page',
    'plan-selector',
    'general',
  ]

  const orderedArray = Array.from(keySet).sort(
    (a, b) => keysOrder.indexOf(a) - keysOrder.indexOf(b)
  )
  return orderedArray
}

export const translationItems = (
  language: string,
  group: string,
  defaults: TranslationSet
): { key: string; label: string; placeholder: string }[] => {
  return Object.keys(defaults)
    .map((key) => key.split(':'))
    .filter((key) => key[0] === group)
    .map((key) => ({
      key: `${language}:${key.join(':')}`,
      label: capitalize(key[1]).replace(/-/g, ' '),
      placeholder: defaults[key.join(':')],
    }))
}

export const useTranslationSettings = () =>
  useSWR<TranslationSettings>(['translation-settings'], () =>
    backendFetchResultData<TranslationSettings>('GET', '/settings/translation-settings')
  )

export const patchTranslationSettings = async (translations: any): Promise<Result<any>> => {
  const body = JSON.stringify({ translations })
  const res = await backendFetchResult('PATCH', '/settings/translation-settings', { body })
  mutate(['translation-settings'])
  return res
}
