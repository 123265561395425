import { Button, TextField } from '@shopify/polaris'
import { ClipboardMinor } from '@shopify/polaris-icons'
import { useCallback, useRef, useState } from 'react'

import { Toast } from './helpers'

interface CodeSnippetProps {
  code: string
  multiline?: boolean
  maxHeight?: string | number
  showCopyButton?: boolean
}

export const CodeSnippet = ({
  code,
  multiline,
  maxHeight,
  showCopyButton = true,
}: CodeSnippetProps) => {
  const ref = useRef(null)
  const [copied, setCopied] = useState(false)

  const copyToClipboard = useCallback(() => {
    ;(ref as any).current.querySelector('input, textarea').select()
    document.execCommand('copy')
    setCopied(true)
  }, [ref, setCopied])

  return (
    <span ref={ref}>
      {copied && <Toast content="Copied to clipboard" onDismiss={() => setCopied(false)} />}

      <TextField
        label=""
        labelHidden
        onChange={() => undefined}
        connectedRight={
          showCopyButton ? <Button icon={ClipboardMinor} onClick={copyToClipboard}></Button> : null
        }
        value={code}
        spellCheck={false}
        multiline={multiline}
        autoComplete="off"
        monospaced={true}
        maxHeight={maxHeight}
      />
    </span>
  )
}
