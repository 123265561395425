import { FormLayout, Modal, Select } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { mutate } from 'swr'

import { Result } from '../common/api'
import { ApiAction, ApiActionBanner, useApiActionState } from '../common/ApiAction'
import { DateTimePicker } from '../common/DateTimePicker'
import { NextAction, nextActionOptions, SubscriptionContract } from '../common/subscription-models'

interface Props {
  sc: SubscriptionContract
  open: boolean
  close: () => any
  showCounter: number
}

export const NextActionDialog = ({ open, close, sc, showCounter }: Props) => {
  const [action, setAction] = useState<NextAction>(sc.next_action)
  const [date, setDate] = useState<string | null>(sc.next_action_date)
  const [requestResultError, setRequestResultError] = useState<Result<any> | null>(null)

  useEffect(() => {
    setAction(sc.next_action)
    setDate(sc.next_action_date)
  }, [showCounter, sc])

  useEffect(() => {
    setRequestResultError(null)
  }, [date])

  const saveAction = useApiActionState({
    method: 'PUT',
    url: `/subscriptions/${sc.rest_id}/next-action`,
    opts: {
      body: JSON.stringify({ action, date }),
    },
    onSuccess: (res) => {
      setRequestResultError(null)
      mutate(['subscription-contract', `${sc.rest_id}`], res.data, false)
      close()
    },
    onError: setRequestResultError,
    bannerPosition: 'external',
  })

  return (
    <>
      <Modal
        open={open}
        title={'Edit next scheduled action'}
        onClose={close}
        primaryAction={{ content: 'Save', onAction: saveAction.run, loading: saveAction.loading }}
        secondaryActions={[{ content: 'Cancel', onAction: close }]}
        sectioned
      >
        <ApiActionBanner state={saveAction} />
        <FormLayout>
          <Select
            label="Next scheduled action"
            options={nextActionOptions}
            value={action}
            onChange={setAction as (val: string) => any}
          />
          {action !== 'none' && (
            <DateTimePicker
              label="Date"
              initial={date}
              onChange={setDate}
              error={requestResultError?.field === 'date' ? requestResultError.message : null}
            />
          )}
        </FormLayout>
      </Modal>

      <ApiAction state={saveAction} />
    </>
  )
}
