// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PricingPlanBanner_wrap__zcaaO .Polaris-Page__Content {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/common/PricingPlanBanner.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","sourcesContent":[".wrap :global(.Polaris-Page__Content) {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `PricingPlanBanner_wrap__zcaaO`
};
export default ___CSS_LOADER_EXPORT___;
