import { Banner, Checkbox, LegacyStack, RadioButton, Text, TextContainer } from '@shopify/polaris'
import React, { useCallback } from 'react'

import { formatMoney, MemoedSelect, MemoedTextField, range } from '../common/helpers'
import { useShopCurrency, useShopCurrencySymbol } from '../common/Price'
import {
  WorkflowAction,
  workflowActionLabels,
  WorkflowAddDiscountDataType,
} from '../common/workflow-models'

const paymentNumberOptions = range(1, 100).map((i) => ({
  label: `${i}`,
  value: `${i}`,
}))

export const AddDiscountAction = ({
  params,
  onChange,
  index,
  discountNameError,
  adjustmentValueError,
}: {
  params: WorkflowAddDiscountDataType
  onChange: (action: WorkflowAction, index: number) => any
  index: number
  discountNameError: string | null
  adjustmentValueError: string | null
}) => {
  const [discountName, discountRecurringLimit, discountType, adjustmentValue, appliesOnEachItem] = [
    params[0],
    params[1],
    params[2],
    params[3],
    params[4] ?? 1,
  ]

  const currencySymbol = useShopCurrencySymbol()
  const currencyCode = useShopCurrency()

  const handleUpdate = useCallback(
    (params: WorkflowAddDiscountDataType) => {
      onChange(['add-discount', params], index)
    },
    [index, onChange]
  )

  const isPercentage = discountType === 'percentage'

  return (
    <LegacyStack vertical>
      <Text variant="headingSm" as="h2">
        {workflowActionLabels['add-discount']}
      </Text>

      <MemoedTextField
        label="Discount name"
        value={discountName}
        onChange={(val: string) =>
          handleUpdate([
            val,
            discountRecurringLimit,
            discountType,
            adjustmentValue,
            appliesOnEachItem ?? 1,
          ])
        }
        autoComplete="off"
        error={discountNameError ?? undefined}
      />
      <LegacyStack vertical spacing="extraTight">
        <RadioButton
          label="Percentage"
          checked={isPercentage}
          name={`discountType-${index}`}
          onChange={() =>
            handleUpdate([
              discountName,
              discountRecurringLimit,
              'percentage',
              '',
              appliesOnEachItem ?? 1,
            ])
          }
        />
        <RadioButton
          label="Fixed Amount"
          checked={!isPercentage}
          name={`discountType-${index}`}
          onChange={() =>
            handleUpdate([
              discountName,
              discountRecurringLimit,
              'fixedAmount',
              '',
              appliesOnEachItem ?? 1,
            ])
          }
        />
        {!isPercentage && (
          <Banner status="warning">
            <Text as="p" color="warning">
              This type of discount will be applied only to subscriptions using native shop currency
            </Text>
          </Banner>
        )}
      </LegacyStack>
      {isPercentage && (
        <MemoedTextField
          label="Adjustment value"
          type="number"
          autoComplete="off"
          suffix="%"
          value={adjustmentValue}
          min="0"
          max="100"
          onChange={(val: string) =>
            handleUpdate([
              discountName,
              discountRecurringLimit,
              discountType,
              val,
              appliesOnEachItem ?? 1,
            ])
          }
          error={adjustmentValueError ?? undefined}
        />
      )}
      {!isPercentage && (
        <LegacyStack vertical>
          <MemoedTextField
            label="Adjustment value"
            type="number"
            autoComplete="off"
            value={adjustmentValue}
            prefix={currencySymbol}
            min="0"
            step={0.01}
            onChange={(val: string) =>
              handleUpdate([
                discountName,
                discountRecurringLimit,
                discountType,
                val,
                appliesOnEachItem ?? 1,
              ])
            }
            error={adjustmentValueError ?? undefined}
            placeholder="0.00"
          />
          <Checkbox
            name={`appliesOnEachItem-${index}`}
            label={
              <LegacyStack vertical spacing="extraTight">
                <TextContainer>Only apply discount once per order</TextContainer>
                <Text as="span" color="subdued">
                  If not selected,{' '}
                  {adjustmentValue
                    ? formatMoney(parseFloat(adjustmentValue) * 100, currencyCode)
                    : 'the amount'}{' '}
                  will be taken off each item in an order.
                </Text>
              </LegacyStack>
            }
            checked={appliesOnEachItem === 0}
            onChange={(val: boolean) =>
              handleUpdate([
                discountName,
                discountRecurringLimit,
                discountType,
                adjustmentValue,
                val ? 0 : 1,
              ])
            }
          />
        </LegacyStack>
      )}
      <LegacyStack vertical spacing="extraTight">
        <RadioButton
          label="Limit discount to this order only"
          checked={discountRecurringLimit === '1'}
          name={`discountRecurringLimit-${index}`}
          onChange={() =>
            handleUpdate([discountName, '1', discountType, adjustmentValue, appliesOnEachItem ?? 1])
          }
        />
        <RadioButton
          label={
            <LegacyStack vertical spacing="extraTight">
              <TextContainer>Limit discount to multiple orders</TextContainer>
              {discountRecurringLimit !== null && parseInt(discountRecurringLimit) > 1 && (
                <MemoedSelect
                  label=""
                  labelHidden
                  value={discountRecurringLimit}
                  onChange={(val: string) =>
                    handleUpdate([
                      discountName,
                      val,
                      discountType,
                      adjustmentValue,
                      appliesOnEachItem ?? 1,
                    ])
                  }
                  options={paymentNumberOptions}
                />
              )}
            </LegacyStack>
          }
          checked={discountRecurringLimit != null && parseInt(discountRecurringLimit) > 1}
          name={`discountRecurringLimit-${index}`}
          onChange={() =>
            handleUpdate([discountName, '2', discountType, adjustmentValue, appliesOnEachItem ?? 1])
          }
        />
        <RadioButton
          label="Discount applies to this and all future orders"
          checked={discountRecurringLimit === null}
          name={`discountRecurringLimit-${index}`}
          onChange={() =>
            handleUpdate([
              discountName,
              null,
              discountType,
              adjustmentValue,
              appliesOnEachItem ?? 1,
            ])
          }
        />
      </LegacyStack>
    </LegacyStack>
  )
}
