import { Checkbox, FormLayout, Modal, TextField } from '@shopify/polaris'

import { LegacyHelpLabel } from '../common/HelpIcon'
import { RemoveDiscountCodePayload } from './common'
export const RemoveDiscountCodeSection = ({
  payload,
  setPayload,
}: {
  payload: RemoveDiscountCodePayload
  setPayload: (payload: RemoveDiscountCodePayload) => any
}) => (
  <Modal.Section>
    <FormLayout>
      <TextField
        label={
          <Checkbox
            checked={payload.code !== undefined}
            onChange={(checked) => setPayload({ code: checked ? '' : undefined })}
            label={
              <LegacyHelpLabel title="Remove specific discount">
                By default, the first discount will be removed. If you want to remove a specific
                discount, check this box and enter the discount code.
              </LegacyHelpLabel>
            }
          />
        }
        value={payload.code}
        onChange={(code) => setPayload({ ...payload, code })}
        autoComplete="off"
        disabled={payload.code === undefined}
      />
    </FormLayout>
  </Modal.Section>
)
