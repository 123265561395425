// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MerchantApiTokensCard_wrapper__rupPj th:first-child, .MerchantApiTokensCard_wrapper__rupPj td:last-child {
  width: 1%;
  white-space: nowrap;
}

.MerchantApiTokensCard_wrapper__rupPj .Polaris-DataTable {
  border-bottom: .0625rem solid var(--p-divider);
}
`, "",{"version":3,"sources":["webpack://./src/settings-page/MerchantApiTokensCard.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,8CAA8C;AAChD","sourcesContent":[".wrapper th:first-child, .wrapper td:last-child {\n  width: 1%;\n  white-space: nowrap;\n}\n\n.wrapper :global(.Polaris-DataTable) {\n  border-bottom: .0625rem solid var(--p-divider);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MerchantApiTokensCard_wrapper__rupPj`
};
export default ___CSS_LOADER_EXPORT___;
