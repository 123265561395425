import { Banner, Loading } from '@shopify/polaris'
import { useCallback, useState } from 'react'

import { backendFetchResult, Result } from '../common/api'
import { Toast } from './helpers'

type ApiActionBannerPosition = 'none' | 'inline' | 'external'

interface ApiActionState {
  loading: boolean
  message: string
  isError: boolean
  banner: boolean
  field: string | null
  bannerPosition: ApiActionBannerPosition
  dismissMessage: () => any
  run: () => any
}

export const ApiAction = ({ state }: { state: ApiActionState }) => (
  <>
    {state.loading && <Loading />}
    {!state.banner && !state.field && state.message && (
      <Toast error={state.isError} content={state.message} onDismiss={state.dismissMessage} />
    )}
  </>
)

export const ApiActionBanner = ({
  state,
  bottomMargin = true,
}: {
  state: ApiActionState
  bottomMargin?: boolean
}) =>
  !state.banner || !state.message ? null : (
    <>
      <Banner status="critical" onDismiss={state.dismissMessage}>
        <p>Error: {state.message}</p>
      </Banner>
      {bottomMargin && <br />}
    </>
  )

export const useApiActionState = <T extends any = any>({
  method = 'POST',
  url,
  opts = {},
  onSuccess,
  onError,
  bannerPosition = 'none',
}: {
  method?: string
  url: string
  opts?: any
  onSuccess?: (res: Result<T>) => any
  onError?: (res: Result<T>) => any
  bannerPosition?: ApiActionBannerPosition
}): ApiActionState => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [isError, setIsError] = useState(false)
  const [banner, setBanner] = useState(false)
  const [field, setField] = useState<null | string>(null)

  const dismissMessage = useCallback(() => {
    setMessage('')
    setBanner(false)
  }, [setMessage, setBanner])

  const run = useCallback(async () => {
    setLoading(true)

    const res = await backendFetchResult<T>(method, url, opts)

    dismissMessage()

    if (res.field) {
      setField(res.field)
    }

    if (
      res.message &&
      res.status !== 'success' &&
      !res.temporary &&
      bannerPosition !== 'none' &&
      !res.field
    ) {
      setBanner(true)
      setMessage(res.message)
    } else if (res.message) {
      setIsError(res.status !== 'success')
      setMessage(res.message)
    }

    setLoading(false)

    if (res.status === 'success' && onSuccess) {
      onSuccess(res)
    }

    if (res.status === 'error' && onError) {
      onError(res)
    }
  }, [method, url, opts, onSuccess, bannerPosition, dismissMessage, onError])

  return {
    loading,
    message,
    isError,
    banner,
    field,
    bannerPosition,
    dismissMessage,
    run,
  }
}
