import { Banner, Layout, Page } from '@shopify/polaris'
import React from 'react'

export const LoadErrorBanner = () => (
  <Banner status="critical">
    <p>This page could not be loaded. Please try again later.</p>
  </Banner>
)

export const LoadErrorPage = () => (
  <Page>
    <Layout>
      <Layout.Section>
        <LoadErrorBanner />
      </Layout.Section>
    </Layout>
  </Page>
)
