import { Banner, Page } from '@shopify/polaris'

import { useLocalStorage } from './local-storage'

const CustomerPricingBanner = ({ onDismiss }: { onDismiss: () => void }) => (
  <Banner status="warning" onDismiss={onDismiss}>
    <p>
      Please note that customers are grandfathered into their pricing at checkout. Changes to
      product pricing, shipping, or selling plans will only affect new subscribers. Please use our
      bulk update tools to make adjustments to existing subscribers.
    </p>
  </Banner>
)

export const SellingPlanPageWarningBanner = () => {
  const [dismissed, setDismissed] = useLocalStorage<boolean>(
    'selling-plan-page-warning-dismissed',
    false
  )

  return (
    <>
      {!dismissed && (
        <Page>
          <CustomerPricingBanner
            onDismiss={() => {
              setDismissed(true)
            }}
          />
        </Page>
      )}
    </>
  )
}
