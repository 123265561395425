import { Banner, Page } from '@shopify/polaris'
import React, { useContext } from 'react'

import { InternalLink, ShowIntercomLink } from '../common/helpers'
import { SystemContext } from '../common/SystemData'
import * as urls from '../common/urls'
import styles from './PricingPlanBanner.module.css'

const PlansLink = (props: { children: any }) => (
  <InternalLink url={urls.pricingPlansUrl()} {...props} />
)

const BetaEnabledBanner = () => (
  <Banner status="warning">
    <p>
      Thank you for being an early user of PayWhirl. Processing live subscriptions will soon require{' '}
      <PlansLink>purchasing a plan</PlansLink>. Not to worry! We have created some{' '}
      <PlansLink>special offers</PlansLink> for you if you'd like to continue using the app.
    </p>
  </Banner>
)

const TrialEnabledBanner = ({ seconds }: { seconds: number }) => {
  const days = Math.round(seconds / 86400)
  const daysString =
    days >= 2 ? `${days} more days` : days >= 1 ? 'one more day' : 'less than a day'

  return (
    <Banner title="Welcome to PayWhirl!" status="warning">
      <p>
        You can use all the features for free for {daysString}. After that, you can continue running
        test subscriptions for as long as you need, but in order to process live payments you'll
        need to <PlansLink>select a pricing plan</PlansLink>.
      </p>
    </Banner>
  )
}

const DisabledBanner = () => (
  <Banner status="warning">
    <p>
      Your trial period has ended. You can keep testing PayWhirl as long as you need using test
      payments, but in order to process live subscriptions you need to{' '}
      <PlansLink>purchase a plan</PlansLink>. Please{' '}
      <ShowIntercomLink>get in touch</ShowIntercomLink> if you need more time to decide!
    </p>
  </Banner>
)

const ManualDisabledBanner = () => (
  <Banner status="warning">
    <p>
      Live subscriptions have been disabled in your store. Please{' '}
      <ShowIntercomLink>get in touch</ShowIntercomLink> to resolve this issue.
    </p>
  </Banner>
)

export const PricingPlanBanner = () => {
  const systemData = useContext(SystemContext)

  if (!systemData) {
    return null
  }

  const status = systemData.flags.live_subscriptions_status

  if (['beta-enabled', 'trial-enabled', 'disabled', 'manual-disabled'].indexOf(status) === -1) {
    return null
  }

  return (
    <div className={styles.wrap}>
      <Page>
        {status === 'beta-enabled' && <BetaEnabledBanner />}
        {status === 'trial-enabled' && (
          <TrialEnabledBanner seconds={systemData.flags.remaining_trial_seconds} />
        )}
        {status === 'disabled' && <DisabledBanner />}
        {status === 'manual-disabled' && <ManualDisabledBanner />}
      </Page>
    </div>
  )
}
