import { Banner, Button, Checkbox, InlineError, LegacyStack, Tag, Text } from '@shopify/polaris'
import React, { useCallback } from 'react'

import { gidToId, MemoedTextField, useDialogActivator } from '../common/helpers'
import { LegacyHelpIcon, LegacyHelpLabel } from '../common/HelpIcon'
import { useShopCurrency, useShopCurrencySymbol } from '../common/Price'
import { ProductQuantityField } from '../common/ProductQuantityField'
import { ProductVariantModal, ProductVariantResult } from '../common/ProductVariantModal'
import {
  WorkflowAction,
  workflowActionLabels,
  WorkflowProductDataType,
  WorkflowSwapProductDataType,
} from '../common/workflow-models'

export const SwapProductAction = ({
  params,
  onChange,
  index,
  sourceProductError,
  targetProductError,
  currentPriceError,
  quantityError,
}: {
  params: WorkflowSwapProductDataType
  onChange: (params: WorkflowAction, index: number) => any
  index: number
  sourceProductError: string | null
  targetProductError: string | null
  currentPriceError: string | null
  quantityError: string | null
}) => {
  const currencySymbol = useShopCurrencySymbol()
  const shopCurrency = useShopCurrency()
  const sourceProductModalActivator = useDialogActivator()
  const targetProductModalActivator = useDialogActivator()

  const [sourceProductData, targetProductData, quantity, currentPrice] = [
    (params[0] ?? []) as WorkflowProductDataType,
    (params[1] ?? []) as WorkflowProductDataType,
    params[2] ?? null,
    params[3] ?? null,
  ]

  const handleUpdate = useCallback(
    (params: WorkflowSwapProductDataType) => {
      onChange(['swap-product', params], index)
    },
    [index, onChange]
  )

  return (
    <LegacyStack vertical>
      <Text variant="headingSm" as="h2">
        {workflowActionLabels['swap-product']}
        <LegacyHelpIcon>
          The selected product variant to swap from will be replaced with the new product variant.
        </LegacyHelpIcon>
      </Text>

      <LegacyStack>
        <Text as="p">Product variant to swap from:</Text>
        {sourceProductData.length === 0 && (
          <Text as="span" color="subdued">
            No variant selected
          </Text>
        )}
        {sourceProductData.length > 0 && (
          <Tag key={sourceProductData[0]}>{sourceProductData[1]}</Tag>
        )}
      </LegacyStack>

      <Button onClick={sourceProductModalActivator.show} size="slim">
        Select product variant
      </Button>
      {sourceProductError && <InlineError message={sourceProductError} fieldID="" />}

      <LegacyStack>
        <Text as="p">Product variant to swap to:</Text>
        {targetProductData.length > 0 && (
          <Tag key={targetProductData[0]}>{targetProductData[1]}</Tag>
        )}
        {targetProductData.length === 0 && (
          <Text as="span" color="subdued">
            No variant selected
          </Text>
        )}
      </LegacyStack>
      <Button onClick={targetProductModalActivator.show} size="slim">
        Select product variant
      </Button>
      {targetProductError && <InlineError message={targetProductError} fieldID="" />}

      <MemoedTextField
        label={
          <Checkbox
            checked={currentPrice !== null}
            onChange={(checked) =>
              handleUpdate([
                sourceProductData,
                targetProductData,
                quantity,
                checked ? '0.00' : null,
              ])
            }
            label={
              <LegacyHelpLabel title="Change unit price">
                For pre-paid subscriptions, you need to manually multiply it by the amount of
                pre-paid deliveries.
              </LegacyHelpLabel>
            }
          />
        }
        type="number"
        autoComplete="off"
        prefix={currencySymbol}
        value={currentPrice ?? ''}
        min="0"
        step={0.01}
        onChange={(val: string) =>
          handleUpdate([sourceProductData, targetProductData, quantity, val])
        }
        error={currentPriceError ?? undefined}
        disabled={currentPrice === null}
      />
      {currentPrice !== null && parseFloat(currentPrice) !== 0 && (
        <Banner status="warning">
          <Text as="p" color="warning">
            This product will be swapped only on subscriptions using native shop currency
          </Text>
        </Banner>
      )}

      <ProductQuantityField
        label={
          <Checkbox
            checked={quantity !== null}
            onChange={(checked) =>
              handleUpdate([sourceProductData, targetProductData, checked ? 1 : null, currentPrice])
            }
            label="Change quantity"
          />
        }
        type="number"
        value={quantity ?? 1}
        min="1"
        onChange={(val: number) =>
          handleUpdate([sourceProductData, targetProductData, val, currentPrice])
        }
        disabled={quantity === null}
        error={quantityError ?? undefined}
      />

      <ProductVariantModal
        currency={shopCurrency}
        open={sourceProductModalActivator.open}
        onClose={sourceProductModalActivator.close}
        onSelect={(selected: ProductVariantResult) => {
          handleUpdate([
            [gidToId(selected.id), selected.title],
            targetProductData,
            quantity,
            currentPrice,
          ])
          sourceProductModalActivator.close()
        }}
      />
      <ProductVariantModal
        currency={shopCurrency}
        open={targetProductModalActivator.open}
        onClose={targetProductModalActivator.close}
        onSelect={(selected: ProductVariantResult) => {
          handleUpdate([
            sourceProductData,
            [gidToId(selected.id), selected.title],
            quantity,
            currentPrice,
          ])
          targetProductModalActivator.close()
        }}
      />
    </LegacyStack>
  )
}
