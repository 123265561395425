import { AppliedFilterInterface, Button, FilterInterface, TextField } from '@shopify/polaris'
import { useCallback, useState } from 'react'

import { SubscriptionFilter } from '../api'

export const useAddProductSkuFilter = (
  filter: SubscriptionFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any,
  filters: FilterInterface[],
  appliedFilters: AppliedFilterInterface[]
) => {
  const [sku, setSku] = useState<string>(filter.product_sku ?? '')

  const onRemove = useCallback(() => updateFilter({ product_sku: undefined }), [updateFilter])
  const onSet = useCallback(() => {
    updateFilter({ product_sku: sku })
  }, [updateFilter, sku])

  filters.push({
    key: 'product_sku',
    label: 'Product SKU',
    filter: (
      <>
        <TextField
          label=""
          labelHidden
          value={sku}
          onChange={setSku}
          connectedRight={
            <Button disabled={!sku} onClick={onSet}>
              Set
            </Button>
          }
          autoComplete="off"
        />
      </>
    ),
  })

  if (filter.product_sku) {
    appliedFilters.push({
      key: 'product_sku',
      label: `Product SKU: ${filter.product_sku}`,
      onRemove: onRemove,
    })
  }
}
