import { LegacyCard, Scrollable, Text, Tooltip } from '@shopify/polaris'
import useSWR from 'swr'

import { backendFetchResultData } from '../common/api'
import { CenteredSpinner, FormattedDate } from '../common/helpers'
import { LegacyHelpIcon } from '../common/HelpIcon'
import styles from './RetentionCohortReport.module.css'

export interface RetentionDataRow {
  startDate: string
  initialCount: number
  activeByMonth: number[]
}

const heatmapColors = (rate: number): React.CSSProperties => ({
  color: rate > 0.5 ? 'white' : 'inherit',
  backgroundColor: `rgba(0, 128, 96, ${rate})`,
})

const fetchRetentionCohortReport = (_: any, query: string) => {
  const url = `/dashboard/retention-cohort-report?${query}`
  const data = backendFetchResultData<RetentionDataRow[]>('GET', url)

  return data
}

export const RetentionCohortReport = ({
  filterQuery,
  filterLabel,
}: {
  filterQuery: string
  filterLabel: string
}) => {
  const { data } = useSWR<RetentionDataRow[]>(
    ['retention-cohort-report', filterQuery],
    fetchRetentionCohortReport,
    {
      revalidateOnFocus: false,
    }
  )

  return (
    <LegacyCard
      sectioned
      title={
        <Text variant="headingMd" as="h2">
          12-month retention by cohort{' '}
          <LegacyHelpIcon>
            Percentage of subscriptions that remain active at the end of each billing cycle. The
            retention is measured from the start date of the subscription, with status updates
            occurring at the conclusion of each billing period. Retention is assessed at the end of
            the billing cycle, rather than the exact date of cancellation.
          </LegacyHelpIcon>
        </Text>
      }
      actions={[{ content: filterLabel, disabled: true }]}
    >
      {!data && <CenteredSpinner />}
      {data && (
        <Scrollable horizontal={true}>
          <table className={styles.retentionChart}>
            <thead>
              <tr>
                <th>Cohort</th>
                <th>Total</th>
                {Array.from(Array(12).keys()).map((i) => (
                  <th key={i}>{`Mo ${i + 1}`}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td className={styles.startDate}>
                    <FormattedDate
                      date={new Date(row.startDate)}
                      format={{ month: 'short', year: 'numeric' }}
                    />
                  </td>
                  <td className={styles.initialCount}>{row.initialCount}</td>
                  {row.activeByMonth.map((activeCount, i) => (
                    <td
                      key={i}
                      className={styles.rate}
                      style={heatmapColors(activeCount / row.initialCount)}
                    >
                      <Tooltip
                        content={`${activeCount} out of ${row.initialCount} subscriptions were still active at the end of the month`}
                      >
                        <span>{`${Math.round((100 * activeCount) / row.initialCount)}%`}</span>
                      </Tooltip>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Scrollable>
      )}
    </LegacyCard>
  )
}
