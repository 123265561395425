import { HorizontalStack } from '@shopify/polaris'

import { AdvancedDashboardFilter } from './advanced-filters'
import { ProductSelector } from './ProductSelector'
import { ProductVariantSelector } from './ProductVariantSelector'
import { SellingPlanSelector } from './SellingPlanSelector'

export const AdvancedDasbordFilterSelectors = ({
  filter,
  disabled,
}: {
  filter: AdvancedDashboardFilter
  disabled?: boolean
}) => {
  return (
    <HorizontalStack blockAlign="center" gap="2">
      <ProductSelector onSelect={filter.onProductSelect} disabled={disabled} />
      <ProductVariantSelector onSelect={filter.onProductVariantSelect} disabled={disabled} />
      <SellingPlanSelector
        sellingPlans={filter.sellingPlans}
        onChange={filter.setSellingPlans}
        disabled={disabled}
      />
    </HorizontalStack>
  )
}
