// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailsRow_row__BhSZs {
  padding: 0.325rem 0;
}

.DetailsRow_rowWithBorder__OoQov {
  border-bottom: 0.0625rem solid var(--p-divider, #dfe3e8)
}
`, "",{"version":3,"sources":["webpack://./src/common/DetailsRow.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE;AACF","sourcesContent":[".row {\n  padding: 0.325rem 0;\n}\n\n.rowWithBorder {\n  border-bottom: 0.0625rem solid var(--p-divider, #dfe3e8)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `DetailsRow_row__BhSZs`,
	"rowWithBorder": `DetailsRow_rowWithBorder__OoQov`
};
export default ___CSS_LOADER_EXPORT___;
