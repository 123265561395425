import { AppliedFilterInterface, Button, FilterInterface, TextField } from '@shopify/polaris'
import { useCallback } from 'react'

import { CustomerModal, CustomerResult } from '../../common/CustomerModal'
import { useDialogActivator } from '../../common/helpers'
import { SubscriptionFilter } from '../api'

const windowWithCustomerNameCache = window as unknown as {
  paywhirlCustomerNameCache: Record<number, string>
}

windowWithCustomerNameCache.paywhirlCustomerNameCache = {}

const formatCustomerId = (id?: number): string | undefined => {
  if (!id) {
    return undefined
  }

  const name = windowWithCustomerNameCache.paywhirlCustomerNameCache[id]

  return name ? `${name} (#${id})` : `#${id}`
}

export const useAddCustomerIdFilter = (
  filter: SubscriptionFilter,
  updateFilter: (newFilter: Partial<SubscriptionFilter>) => any,
  filters: FilterInterface[],
  appliedFilters: AppliedFilterInterface[]
) => {
  const activator = useDialogActivator()

  const onRemove = useCallback(() => updateFilter({ customer_id: undefined }), [updateFilter])

  const onSelect = useCallback(
    (cus: CustomerResult) => {
      windowWithCustomerNameCache.paywhirlCustomerNameCache[cus.restId] = cus.displayName
      updateFilter({ customer_id: cus.restId })
      activator.close()
    },
    [activator, updateFilter]
  )

  const formattedCustomer = formatCustomerId(filter.customer_id)

  filters.push({
    key: 'customer_id',
    label: 'Customer',
    filter: (
      <>
        <TextField
          label=""
          labelHidden
          placeholder="Select customer"
          readOnly={true}
          value={formattedCustomer}
          connectedRight={<Button onClick={activator.show}>Search</Button>}
          autoComplete="off"
        />
        <CustomerModal activator={activator} onSelect={onSelect} />
      </>
    ),
  })

  if (formattedCustomer) {
    appliedFilters.push({
      key: 'customer_id',
      label: `Customer: ${formattedCustomer}`,
      onRemove: onRemove,
    })
  }
}
