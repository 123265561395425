import {
  Button,
  Checkbox,
  ChoiceList,
  FormLayout,
  HorizontalStack,
  Modal,
  TextField,
} from '@shopify/polaris'
import { useCallback } from 'react'

import { DialogActivator } from '../common/helpers'
import { HelpLabel, LegacyHelpLabel } from '../common/HelpIcon'
import { ProductQuantityField } from '../common/ProductQuantityField'
import { SellingPlanField } from '../common/SellingPlanField'
import { SwapProductPayload } from './common'

export const SwapProductSection = ({
  payload,
  setPayload,
  variantTitle,
  variantActivator,
  sourceVariantTitle,
  sourceVariantActivator,
}: {
  payload: SwapProductPayload
  setPayload: (payload: SwapProductPayload) => any
  variantTitle: string
  variantActivator: DialogActivator
  sourceVariantTitle: string
  sourceVariantActivator: DialogActivator
}) => {
  const renderSourceVariantSelector = useCallback(
    (selected: boolean) =>
      selected && (
        <TextField
          label="Product variant to swap from"
          labelHidden
          readOnly={true}
          value={sourceVariantTitle}
          connectedRight={<Button onClick={sourceVariantActivator.show}>Select</Button>}
          autoComplete="off"
        />
      ),
    [sourceVariantTitle, sourceVariantActivator.show]
  )

  const renderSkuField = useCallback(
    (selected: boolean) =>
      selected && (
        <HorizontalStack gap="2" blockAlign="baseline">
          <HelpLabel tooltip="Match the line item title exactly as it appears on the subscription page.">
            Line item title - optional
          </HelpLabel>
          <TextField
            label="Line item title - optional"
            labelHidden
            value={payload.sourceLineItemTitle}
            onChange={(val) => setPayload({ ...payload, sourceLineItemTitle: val })}
            autoComplete="off"
          />
        </HorizontalStack>
      ),
    [payload, setPayload]
  )

  const productVariantOptions = [
    { value: 'existing', label: 'Existing product', renderChildren: renderSourceVariantSelector },
    {
      value: 'removed',
      label: 'Product deleted in Shopify',
      renderChildren: renderSkuField,
    },
  ]

  const onSelectProductSwapFromType = useCallback(
    (selected: string[]) => {
      if (selected[0] === 'removed') {
        setPayload({ ...payload, sourceVariantDeleted: true, sourceVariantId: undefined })
        return
      }

      setPayload({ ...payload, sourceVariantDeleted: false })
    },
    [setPayload, payload]
  )

  return (
    <Modal.Section>
      <FormLayout>
        <ChoiceList
          title="Product variant to swap from"
          choices={productVariantOptions}
          selected={[payload.sourceVariantDeleted ? 'removed' : 'existing']}
          onChange={onSelectProductSwapFromType}
        />

        <TextField
          label="Product variant to swap to"
          readOnly={true}
          value={variantTitle}
          connectedRight={<Button onClick={variantActivator.show}>Select</Button>}
          autoComplete="off"
        />

        <ProductQuantityField
          label={
            <Checkbox
              checked={payload.quantity !== undefined}
              onChange={(checked) => setPayload({ ...payload, quantity: checked ? 1 : undefined })}
              label="Change quantity"
            />
          }
          value={payload.quantity ?? 0}
          onChange={(quantity) => setPayload({ ...payload, quantity })}
          disabled={payload.quantity === undefined}
        />

        <TextField
          label={
            <Checkbox
              checked={payload.currentPrice !== undefined}
              onChange={(checked) =>
                setPayload({ ...payload, currentPrice: checked ? '0.00' : undefined })
              }
              label={
                <LegacyHelpLabel title="Change unit price">
                  The price will be set numerically as-is in the current subscription's currency.
                  <br />
                  <br />
                  For pre-paid subscriptions, you need to manually multiply it by the amount of
                  pre-paid deliveries.
                </LegacyHelpLabel>
              }
            />
          }
          type="number"
          min={0}
          step={0.01}
          value={payload.currentPrice}
          onChange={(currentPrice) => setPayload({ ...payload, currentPrice })}
          autoComplete="off"
          disabled={payload.currentPrice === undefined}
        />

        <SellingPlanField
          label={
            <Checkbox
              checked={payload.sellingPlan !== undefined}
              onChange={(checked) =>
                setPayload({
                  ...payload,
                  sellingPlan: checked ? { id: null, name: null } : undefined,
                })
              }
              label={
                <LegacyHelpLabel title="Change selling plan label">
                  This field only controls the displayed label. It does not affect schedule or any
                  other fields in the subscription
                </LegacyHelpLabel>
              }
            />
          }
          plan={payload.sellingPlan ?? { id: null, name: null }}
          onChange={(sellingPlan) => setPayload({ ...payload, sellingPlan })}
          disabled={payload.sellingPlan === undefined}
        />
      </FormLayout>
    </Modal.Section>
  )
}
