import {
  Badge,
  Button,
  DataTable,
  Icon,
  Layout,
  LegacyCard,
  LegacyStack,
  Loading,
  Modal,
  Page,
  Pagination,
  Select,
  SelectOption,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  TextContainer,
} from '@shopify/polaris'
import { ViewMinor } from '@shopify/polaris-icons'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'

import { backendFetchResultData, SimplePaginator } from '../common/api'
import { CodeSnippet } from '../common/CodeSnippet'
import { Footer } from '../common/Footer'
import { FormattedDate, useQuery } from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'

type CallbackDeliveryStatus = '' | 'Queued' | 'Sending' | 'Cancelled' | 'Failed' | 'Succeeded'

const statusOptions: SelectOption[] = [
  { value: '', label: 'All' },
  { value: 'Succeeded', label: 'Succeeded' },
  { value: 'Failed', label: 'Failed' },
  { value: 'Queued', label: 'Queued' },
  { value: 'Sending', label: 'Sending' },
  { value: 'Cancelled', label: 'Cancelled' },
]

interface CallbackPageParams {
  page: number
  status: CallbackDeliveryStatus
}

const useCallbackPageParams = (): CallbackPageParams => {
  const query = useQuery()
  const page = parseInt(query.get('page') || '1') || 1
  const status = (query.get('status') as CallbackDeliveryStatus | null) || ''

  return useMemo(() => ({ page, status }), [page, status])
}

interface CallbackDelivery {
  event_id: string
  topic: string
  object_id: number
  attempt: number
  status_code: number
  status: CallbackDeliveryStatus
  attempted_at: string
  request: string
  response: string
  url: string
}

export const useCallbackDeliveries = (params: CallbackPageParams) => {
  const url = urls.urlWithParams('/callbacks/deliveries', params)

  return useSWR<SimplePaginator<CallbackDelivery>>(['callback-deliveries', params], () =>
    backendFetchResultData<SimplePaginator<CallbackDelivery>>('GET', url)
  )
}

const MySkeletonPage = () => (
  <SkeletonPage title="Recent webhook deliveries" backAction>
    <Layout>
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
)

const StatusBadge = ({ cd }: { cd: CallbackDelivery }) => {
  const statuses: Record<string, 'success' | 'critical'> = {
    Succeeded: 'success',
    Failed: 'critical',
  }

  const status = statuses[cd.status] ?? undefined

  return <Badge status={status}>{cd.status + (cd.status_code ? ` (${cd.status_code})` : '')}</Badge>
}

const formatEventId = (cd: CallbackDelivery) => cd.event_id.substring(0, 8)

const reformatJson = (data: string | undefined) => {
  try {
    return data ? JSON.stringify(JSON.parse(data), null, 2) : null
  } catch (e) {
    return data
  }
}

interface TableProps {
  items: CallbackDelivery[]
}

const Table = (props: TableProps) => {
  const [openedAttempt, setOpenedAttempt] = useState<CallbackDelivery | null>(null)
  const closeDetails = () => setOpenedAttempt(null)

  const rows = props.items.map((item: CallbackDelivery) => [
    <b>{item.topic}</b>,
    formatEventId(item),
    item.object_id,
    <StatusBadge cd={item} />,
    item.attempt,
    item.attempted_at ? (
      <FormattedDate
        date={new Date(item.attempted_at)}
        format={{ day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }}
      />
    ) : null,
    <Button
      onClick={() => setOpenedAttempt(item)}
      icon={<Icon source={ViewMinor} color="base" />}
    />,
  ])

  return (
    <>
      <DataTable
        columnContentTypes={['text', 'text', 'text', 'text', 'numeric', 'text', 'text']}
        headings={['Topic', 'Event ID', 'Subscription ID', 'Status', 'Attempt', 'Time', '']}
        rows={rows}
        verticalAlign="middle"
      />
      <Modal
        open={!!openedAttempt}
        title={
          openedAttempt &&
          `Delivery attempt #${openedAttempt.attempt} for event ${formatEventId(openedAttempt)}`
        }
        large
        onClose={closeDetails}
        secondaryActions={[{ content: 'Close', onAction: closeDetails }]}
        sectioned
      >
        <LegacyStack vertical spacing="extraLoose">
          <LegacyStack vertical spacing="tight">
            <p>
              <b>URL</b>: {openedAttempt?.url ?? ''}
            </p>
            <Text variant="headingMd" as="h2">
              Request:
            </Text>
            <CodeSnippet
              code={reformatJson(openedAttempt?.request) ?? ''}
              multiline
              maxHeight={'250px'}
              showCopyButton={false}
            />
          </LegacyStack>
          <LegacyStack vertical spacing="tight">
            <Text variant="headingMd" as="h2">
              Response:
            </Text>
            <CodeSnippet
              code={openedAttempt?.response ?? ''}
              multiline
              maxHeight={'250px'}
              showCopyButton={false}
            />
          </LegacyStack>
        </LegacyStack>
      </Modal>
    </>
  )
}

export const CallbackDeliveriesPage = () => {
  const params = useCallbackPageParams()
  const history = useHistory()

  const { data, error, isValidating } = useCallbackDeliveries(params)

  const updateParams = useCallback(
    (newParams: Partial<CallbackPageParams>) => {
      history.replace(urls.callbackDeliveriesUrl({ ...params, ...newParams }))
    },
    [params, history]
  )

  const setStatus = useCallback(
    (status: string) => updateParams({ status: status as CallbackDeliveryStatus, page: 1 }),
    [updateParams]
  )

  return (
    <>
      {isValidating && <Loading />}
      <Tabs selected={TabIndex.Settings} />

      {error && <LoadErrorPage />}
      {!error && !data && <MySkeletonPage />}
      {!error && data && (
        <Page
          title="Recent webhook deliveries"
          backAction={{ content: 'Settings', url: urls.settingsUrl() }}
        >
          <Layout>
            <Layout.Section>
              <LegacyCard>
                <LegacyCard.Section>
                  <LegacyStack distribution="trailing">
                    <Select
                      label="Status:"
                      labelInline
                      options={statusOptions}
                      value={params.status}
                      onChange={setStatus}
                    />
                  </LegacyStack>
                </LegacyCard.Section>
                <LegacyStack vertical>
                  <Table items={data.data} />
                  <LegacyStack distribution="center">
                    <Pagination
                      label={`Page: ${params.page}`}
                      hasPrevious={!!data.prev_page_url}
                      onPrevious={() => updateParams({ page: params.page - 1 })}
                      hasNext={!!data.next_page_url}
                      onNext={() => updateParams({ page: params.page + 1 })}
                    />
                  </LegacyStack>
                  <p />
                </LegacyStack>
              </LegacyCard>
            </Layout.Section>
            <Layout.Section>
              <Footer />
            </Layout.Section>
          </Layout>
        </Page>
      )}
    </>
  )
}
