import { Action, LegacyCard, LegacyStack, Text, TextContainer } from '@shopify/polaris'

import { nl2br, useDialogActivator } from '../common/helpers'
import { HelpTitle } from '../common/HelpIcon'
import { SubscriptionContract } from '../common/subscription-models'
import { customerNotesText, merchantNotesText, NotesEditDialog } from './NotesEditDialog'

export const NotesCard = ({ sc }: { sc: SubscriptionContract }) => {
  const activator = useDialogActivator()
  const customerNotesTitle = <HelpTitle title="Customer Notes">{customerNotesText}</HelpTitle>
  let actions: Action[] = sc.is_editable ? [{ content: 'Edit', onAction: activator.show }] : []

  return (
    <LegacyCard sectioned title={customerNotesTitle} actions={actions}>
      <LegacyStack vertical>
        <TextContainer>
          {sc.gql_data.note && nl2br(sc.gql_data.note)}
          {!sc.gql_data.note && (
            <Text as="span" color="subdued">
              No customer notes
            </Text>
          )}
        </TextContainer>
        <HelpTitle title="Merchant Notes">{merchantNotesText}</HelpTitle>
        <TextContainer>
          {sc.merchant_note && nl2br(sc.merchant_note)}
          {!sc.merchant_note && (
            <Text as="span" color="subdued">
              No merchant notes
            </Text>
          )}
        </TextContainer>
      </LegacyStack>

      <NotesEditDialog activator={activator} sc={sc} />
    </LegacyCard>
  )
}
