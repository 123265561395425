// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledFulfillmentsCard_tableWrap__q4uko td:nth-child(3) .Polaris-ButtonGroup {
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/subscription-page/ScheduledFulfillmentsCard.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[".tableWrap :global(td:nth-child(3) .Polaris-ButtonGroup) {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrap": `ScheduledFulfillmentsCard_tableWrap__q4uko`
};
export default ___CSS_LOADER_EXPORT___;
