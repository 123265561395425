import {
  Layout,
  LegacyCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage as PolarisSkeletonPage,
  TextContainer,
} from '@shopify/polaris'
import React from 'react'

export const SkeletonPage = () => (
  <>
    <PolarisSkeletonPage title="Settings">
      <Layout.Section>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </LegacyCard>
        <LegacyCard sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </LegacyCard>
      </Layout.Section>
    </PolarisSkeletonPage>
  </>
)
